import { Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import { useAppContext } from "src/hooks";
import ActivityInfoDialog from "./ActivityInfoDialog";
import { UserAvatar } from "src/components";

export default function Activity({ task }) {
  const { dispatch_get_user_profile } = useAppContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const allowedActionTypes = [
    "priority_update",
    "target_date_update",
    "status_update",
    "task_title_change",
    "member_added",
    "member_removed",
    "tag_color_added",
    "tag_color_removed",
    "task_description_change",
    "attachments_added",
    "attachments_removed",
    "estimated_time_change",
    "actual_time_change",
    "final_time_change",
  ];

  const handleOpenDialog = (activity) => {
    if (allowedActionTypes.includes(activity.type)) {
      setSelectedActivity(activity);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedActivity(null);
  };

  const getActivityText = (activity) => {
    if (!activity?.action_by?.first_name) {
      return activity.text;
    }

    const user_name = `${activity.action_by.first_name} ${activity.action_by.last_name}`;
    let text = "";

    if (activity.type === "priority_update") {
      text = "update task priority.";
    } else if (activity.type === "target_date_update") {
      text = "update task target date.";
    } else if (activity.type === "status_update") {
      text = "update task status.";
    } else if (activity.type === "order_update") {
      text = "update the task order.";
    } else if (activity.type === "member_added") {
      text = "update task team member.";
    } else if (activity.type === "member_removed") {
      text = "removed task team member.";
    } else if (activity.type === "tag_color_added") {
      text = "update task label.";
    } else if (activity.type === "tag_color_removed") {
      text = "removed task label.";
    } else if (activity.type === "task_title_change") {
      text = "update task title.";
    } else if (activity.type === "task_description_change") {
      text = "update task description.";
    } else if (activity.type === "attachments_added") {
      text = "added attachment.";
    } else if (activity.type === "attachments_removed") {
      text = "removed attachment.";
    } else if (activity.type === "estimated_time_change") {
      text = "update estimated time.";
    } else if (activity.type === "actual_time_change") {
      text = "update complete time.";
    } else if (activity.type === "final_time_change") {
      text = "update billable time.";
    } else {
      text = activity.text;
      return text;
    }

    return (
      <>
        <span className="activity-user-name">{user_name}</span>
        {text}
      </>
    );
  };

  return (
    <div className="task-activity">
      <h5
        style={{
          fontSize: "12px",
          fontWeight: "600",
          marginBottom: 0,
          padding: "8px 0px 0px 10px",
        }}
      >
        Task Activities
      </h5>
      <div style={{ minHeight: "50vh", maxHeight: "100vh" }}>
        <Scrollbar sx={{ minHeight: "50vh", maxHeight: "100vh" }}>
          <Stack spacing={0.5} sx={{ padding: "8px 16px 8px 8px" }}>
            {/* No Activity Found */}
            {(!task?.activity_track?.track_list ||
              task?.activity_track?.track_list.length === 0) && (
              <div className="d-flex align-items-center">
                <Iconify
                  icon="ep:warning-filled"
                  sx={{ color: "#FFC107", marginRight: "5px" }}
                />
                <Typography
                  fontSize="12px"
                  color={(theme) => theme.palette.text.disabled}
                >
                  No activity found
                </Typography>
              </div>
            )}

            {task?.activity_track?.track_list?.length > 0 && (
              <>
                {task.activity_track.track_list
                  // ?.filter((track) =>
                  //   Boolean(
                  //     track.allow_members.find(
                  //       (id) => id === dispatch_get_user_profile().user_id
                  //     )
                  //   )
                  // )
                  .map((activity, i) => (
                    <Stack
                      key={i}
                      direction="row"
                      spacing={1}
                      onClick={() => handleOpenDialog(activity)}
                      className="activity-stack"
                    >
                      <div className="img-div-avtar">
                        <UserAvatar
                          sx={{
                            width: 16,
                            height: 16,
                          }}
                          image={activity?.action_by?.image || " "}
                          alt={activity?.action_by?.first_name}
                        />
                      </div>
                      <Stack spacing={0.3}>
                        <Typography fontSize="11px">
                          {getActivityText(activity)}
                        </Typography>
                        <Typography fontSize="9px">
                          {moment(activity.created_at).format("LLL")}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
              </>
            )}
          </Stack>
        </Scrollbar>
      </div>

      {/* Activity Information Dialog */}
      <ActivityInfoDialog
        open={openDialog}
        onClose={handleCloseDialog}
        activity={selectedActivity}
        getActivityText={getActivityText}
      />
    </div>
  );
}
