import { get_from_localStorage, invokeApi } from "src/utils";

export const _invite_user_to_workspace = async (data) => {
  const requestObj = {
    path: `api/Invitation/send_invitation`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _invite_multiple_user_to_workspace = async (data) => {
  const requestObj = {
    path: `api/invitation/send_multiple_invitations`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _invitation_list = async (data) => {
  const requestObj = {
    path: `api/invitation/invitation_list`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _workspace_invitation_list = async (data) => {
  const requestObj = {
    path: `api/invitation/user_invitation_list`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _reject_workspace_invitation = async (data, id) => {
  const requestObj = {
    path: `api/Invitation/reject_invitation/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _cancel_workspace_invitation = async (data, id) => {
  const requestObj = {
    path: `api/Invitation/cancel_invitation/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _accept_workspace_invitation = async (data, id) => {
  const requestObj = {
    path: `api/Invitation/accept_invitation/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _resend_workspace_invitation = async (id) => {
  const requestObj = {
    path: `api/invitation/resend_invitation/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
