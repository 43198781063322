import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Avatar,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { filter, capitalize } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { _createGroupChat, _getChatTeamList } from "src/DAL";
import { Iconify, MuiDrawer, Scrollbar } from "src/components";
import ImageSelector from "src/components/ImageSelector";
import StyledChatSearch from "src/components/search/StyledChatSearch";
import { s3baseUrl } from "src/config";
import { useAppContext } from "src/hooks";
import { upload_image } from "src/utils";
import SendIcon from "@mui/icons-material/Send";
import { SearchBar } from "src/components";

function AddChatDrawer({
  open,
  onClose,
  handleAddNewChat,
  isLoading,
  setMessageListPage,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    setChatList,
    getModifiedChatItem,
    currentChat,
    setCurrentChat,
    socketEmit,
    setMessageList,
    setMessageCount,
    chatType: contextChatType,
  } = useAppContext();
  const [chatType, setChatType] = useState(contextChatType);
  const [teamList, setTeamList] = useState([]);
  const [loadingTeam, setLoadingTeam] = useState(false);
  const getTeamList = async () => {
    setLoadingTeam(true);
    const result = await _getChatTeamList();
    setTeamList(result.chat_team_list);
    setLoadingTeam(false);
  };
  //////////////Search user by name//////////////////////////////////////////

  const [searchValue, setSearchValue] = useState("");

  const handleLocalChatSearch = (input, array) => {
    const get_merged = (item) => {
      return `${item.first_name} ${item.last_name}`;
    };
    let filtered = filter(
      array,
      (item) =>
        get_merged(item).toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getFilteredList = (data) => {
    if (!data) {
      return [];
    }
    if (searchValue === "") {
      return data;
    }
    if (searchValue !== "") {
      let filtered = handleLocalChatSearch(searchValue, data);
      return filtered;
    }
  };

  ///////////////////////////////////////////////////////////////////////////////

  /////////////////////////Add Group//////////////////////////////////////////////
  const [checked, setChecked] = useState({});
  const [createGroupStep, setCreateGroupStep] = useState("1");
  const [newGroupName, setNewGroupName] = useState("");
  const [groupImage, setGroupImage] = useState();
  const [createGroupLoading, setCreateGroupLoading] = useState(false);
  const handleToggle = (item) => () => {
    if (!checked[item.user_id]) {
      let temp = item;
      delete temp.allow_add_project;
      delete temp.allow_drag_drop;
      delete temp._id;
      delete temp.leave_reason;
      setChecked((prev) => {
        return {
          ...prev,
          [item.user_id]: temp,
        };
      });
    } else {
      let temp = checked;
      delete temp[item.user_id];
      setChecked({ ...temp });
    }
  };

  const handleCreateGroup = async () => {
    try {
      setCreateGroupLoading(true);
      let image = "";
      if (groupImage) {
        const imageResult = await upload_image(groupImage);
        if (imageResult.code == 200) {
          image = imageResult.path;
        }
      }
      const req_obj = {
        group_name: newGroupName,
        group_image: image,
        start_time: "",
        end_time: "",
        team: Object.values(checked),
      };
      const result = await _createGroupChat(req_obj);
      if (result.code == 200) {
        onClose();
        setChatList((prev) => {
          return [getModifiedChatItem(result.group), ...prev];
        });
        setCurrentChat(getModifiedChatItem(result.group));
        setMessageList([]);
        setMessageListPage(0);
        setMessageCount(0);
        enqueueSnackbar("Group created successfully", { variant: "success" });
        socketEmit("addNewChat", result.group);
        console.log("helllo from func");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " error catched while creating group");
    } finally {
      setCreateGroupLoading(false);
    }
  };
  ///////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setChatType(contextChatType);
  }, [contextChatType]);
  useEffect(() => {
    open && getTeamList();
    return () => {
      // setChatType(contextChatType);
      setNewGroupName("");
      setGroupImage();
      setSearchValue("");
      setCreateGroupStep("1");
      setChecked({});
    };
  }, [open]);
  return (
    <MuiDrawer
      paperProps={{ width: { xs: 1, sm: 340 } }}
      isOpen={open}
      onClose={onClose}
      title={<Typography sx={{ fontWeight: "700" }}>Create New</Typography>}
      titleButton={
        (chatType == "all") ? <Button
          sx={{ marginRight: "12px" }}
          variant="contained"
          onClick={() => {
            setCreateGroupStep(1);
            setChatType("group");
          }}
          size="small"
          startIcon={
            <Iconify
              sx={{ height: "1rem", width: "1rem" }}
              icon="mdi:account-group"
            />
          }
        >
          Create Group
        </Button> :
          <Button
            sx={{ marginRight: "12px" }}
            variant="contained"
            onClick={() => { setChatType("all") }}
            size="small"
            startIcon={
              <Iconify
                sx={{ height: "1rem", width: "1rem" }}
                icon="mdi:account"
              />
            }
          >
            New Chat
          </Button>
      }
      bottomComponent={
        (getFilteredList(teamList).length > 0 && chatType !== "all") ?
          (createGroupStep == "1") &&
          <div
            className="d-flex justify-content-center grp-load p-2 m-1 mb-2"
          >
            <LoadingButton
              loading={createGroupLoading}
              variant="contained"
              onClick={() => {
                if (newGroupName.trim() == "") {
                  enqueueSnackbar("Please provide valid Group name", {
                    variant: "error",
                  });
                  return;
                }
                if (Object.keys(checked).length > 0) {
                  handleCreateGroup();
                } else {
                  enqueueSnackbar("Select atleast one member", {
                    variant: "error",
                  });
                }

              }}
            >
              Create
            </LoadingButton>
          </div>
          : <div></div>
      }
      headerComponent=
      {(chatType == "all") ? <div className="chat-drawer-position p-3">
        <div className="search-user-list row mb-2">
          <span
            className="col-12 search-input-container"
          >
            <StyledChatSearch
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeHolder="Search..."
            />
          </span>

        </div>
        <List
          dense
          sx={{
            width: "100%",
            maxWidth: 560,
            bgcolor: "background.paper",
            mx: "0",
          }}
        >
          {(!loadingTeam) &&
            <Scrollbar sx={{ maxHeight: "calc(100vh - 150px)" }}>
              {getFilteredList(teamList).map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    onClick={() => {
                      handleAddNewChat(item)
                    }}
                    secondaryAction={
                      <IconButton className="p-0" edge="end" aria-label="delete">
                        <SendIcon />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton className="px-1 py-2" disableGutters>
                      <ListItemAvatar className="me-2">
                        {item.image !== "" ? (
                          <Tooltip arrow title={`${item.first_name} ${item.last_name}`}>
                            <Avatar src={s3baseUrl + item.image} className="chat-avatar" />
                          </Tooltip>
                        ) : (
                          <Tooltip arrow title={`${item.first_name} ${item.last_name}`}>
                            <Avatar alt="Remy Sharp" className="chat-avatar">
                              {capitalize(item.first_name.substring(0, 1))}
                            </Avatar>
                          </Tooltip>
                        )}
                      </ListItemAvatar>

                      <ListItemText
                        primary={
                          <Typography>
                            {item.first_name + " " + item.last_name}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </Scrollbar>
          }
        </List>
      </div>
        : createGroupStep == "1" ? (
          <>
            <div className="p-3 pt-4">
              <ImageSelector setFile={setGroupImage} />
              <TextField
                sx={{ mt: 2 }}
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                required
                fullWidth
                label="Group Name"
              />

            </div>
            <div className="w-100 pb-1 px-3">
              <span
                className=""
              >
                <StyledChatSearch
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeHolder="Search..."
                />
              </span>
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 560,
                  bgcolor: "background.paper",
                  mx: "0",
                }}
              >
                {(!loadingTeam) &&
                  <Scrollbar sx={{ maxHeight: "calc(100vh - 445px)" }}>
                    {getFilteredList(teamList).map((item) => {
                      return (
                        <ListItem
                          key={item._id}
                          onClick={handleToggle(item)}
                          disablePadding
                        >
                          <ListItemButton className="px-1 py-1" disableGutters>
                            <Checkbox
                              edge="start"
                              checked={checked[item.user_id] ? true : false}
                              tabIndex={-1}
                              disableRipple
                              className="me-1"
                            />
                            <ListItemAvatar className="me-2">
                              {item.image !== "" ? (
                                <Tooltip arrow title={`${item.first_name} ${item.last_name}`}>
                                  <Avatar
                                    src={s3baseUrl + item.image}
                                    className="chat-avatar"
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip arrow title={`${item.first_name} ${item.last_name}`}>
                                  <Avatar
                                    src={s3baseUrl + item.image}
                                    sx={{ width: "25px", height: "25px" }}
                                  >
                                    {capitalize(item.first_name.substring(0, 1))}
                                  </Avatar>
                                </Tooltip>
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography variant="body2">
                                  {item.first_name + " " + item.last_name}
                                </Typography>
                              }
                            />

                          </ListItemButton>
                        </ListItem>
                      );
                    })}

                  </Scrollbar>}
              </List>
            </div>
          </>
        )
          : (
            <div className="p-3 pt-4">
              <ImageSelector setFile={setGroupImage} />
              <TextField
                sx={{ mt: 2 }}
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                required
                fullWidth
                label="Group Name"
              />

            </div>
          )
      }

    >
      {loadingTeam && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <CircularProgress />
        </div>
      )}
    </MuiDrawer >
  );
}

export default AddChatDrawer;
