import React, { useRef, useState } from "react";
import { IconButton, LinearProgress } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "5px"
  },
  progress: {
    flex: 0.95,
  },
}));

// ... (other imports and code)

const AudioPlayer = ({ audioUrl, duration }) => {

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const classes = useStyles();

  const handlePlayPause = () => {
    const audioElement = audioRef.current;
    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleAudioEnded = () => {
    // Reset the audio player controls and state when audio playback ends
    setIsPlaying(false);
    setCurrentTime(0);
    audioRef.current.currentTime = 0;
  };

  return (
    <>
      <div style={{minWidth: "200px", maxWidth: "100%"}}>
        <div className={classes.root}>
          <IconButton style={{padding: 0}} onClick={handlePlayPause}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <LinearProgress
            className={classes.progress}
            variant="determinate"
            value={(((currentTime / duration) * 100) > 100 )? 100 : ((currentTime / duration) * 100)}
          />
        </div>
        <div className=" chat-date audio-timer" >
          {((!isPlaying && currentTime !== 0) || isPlaying) && (
            <>{formatDuration(currentTime)}</>
          )}
          {!isPlaying && currentTime === 0 && <>{formatDuration(duration)}</>}
        </div>
        <audio
          ref={audioRef}
          src={audioUrl}
          onTimeUpdate={handleTimeUpdate}
          onEnded={handleAudioEnded}
        />
      </div>
    </>
  );
};

export default AudioPlayer;
