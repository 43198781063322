import React from "react";
import { Typography } from "@mui/material";
import moment from "moment";

const DatePickerInput = ({ value, open }) => {
  
  return (
    <div className="sidebar-targetdate-box" onClick={open}>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight:"500",
          color: "#00AB55",
          textDecoration:"underline"
        }}
      >
        {value
          ? moment(value).format("DD MMM YYYY hh:mm A")
          : "Target Date/Time"}
      </Typography>
    </div>
  );
};

export default DatePickerInput;
