import { get_from_localStorage, invokeApi } from "src/utils";
// --------------------Dashboard Favourite Project Reorder----------------
export const _update_dashboard_favourite_projects_order = (data) => {
    const requestObj = {
        path: `api/project/update_user_project_order`,
        method: "PUT",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};
// -------------------------Workspaces Reorder-----------------------------
export const _update_workspaces_list_order = (data) => {
    const requestObj = {
        path: `api/workspace/update_user_workspaces_order`,
        method: "PUT",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};
// -------------------------Projects Reorder-----------------------------
export const _update_projects_list_order = (data) => {
    const requestObj = {
        path: `api/project/set_project_list_order_by_user`,
        method: "PUT",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};
