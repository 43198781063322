import { Avatar, Badge, Divider, Tooltip, Typography, Paper } from "@mui/material";
import InfiniteScroller from "src/components/InfiniteScroller";
import { useAppContext } from "src/hooks";
import moment from "moment";
import { capitalize } from "lodash";
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import ImageIcon from '@mui/icons-material/Image';

function ChatList({
  chatList,
  setChatList,
  currentChat,
  setCurrentChat,
  chatCount,
  loadMoreChats,
  chatListPage,
  onChatClick,
}) {
  const { setChatListDrawerOpen } = useAppContext();
  const getBadgeColor = (item) => {
    if (item?.is_online) {
      return "primary";
    } else {
      return "inactive";
    }
  };
  const truncateText = (string, maxLength) => {
    if (maxLength == string?.length) {
      return string;
    }
    if (string?.length > maxLength) {
      let result = string.slice(0, maxLength).concat(" " + "...");
      return result;
    }
    return string;
  };


  const formatAudioDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${minutes.toString()}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <InfiniteScroller
      dataLength={(chatListPage + 1) * 15}
      hasMore={chatCount > chatList.length}
      height={"calc(100vh - 200px)"}
      next={() => {
        console.log("here in the next");
        loadMoreChats();
      }}
      style={{ overflow: "hidden auto"}}
      inverse={false}
    >
      {chatList.map((chat, index) => {
        return (
          <div key={`${chat._id}-${index}`} >
            <Paper square={false} elevation={chat._id === currentChat?._id ? 1 : 0} className="chat-paper">
              <div
                style={{
                  backgroundColor: chat._id === currentChat?._id ? "#D9FDD3" : "",
                }}
                className={`user-card-parent d-flex align-items-center py-2 position-relative 
              item.isActive active-group chat-background`}
                onClick={() => {
                  onChatClick(chat);
                }}
              >
                <div>
                  <Badge
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    overlap="circular"
                    variant="dot"
                    invisible={chat?.type == "1"}
                    color={getBadgeColor(chat)}
                  >
                    <Avatar
                      className="chat-avatar-big"
                      src={chat?.image}
                    >
                      {capitalize(chat?.label?.substring(0, 1))}
                    </Avatar>
                  </Badge>
                </div>
                <div className="avatar-wrapper ms-1">
                  <div className="ps-1 chat-label">
                    <div >
                      <Typography className="chat-label-text truncate-text">
                        {capitalize(chat?.label)}
                      </Typography>
                    </div>
                    <div>
                      {(chat?.last_message) ? 
                      (<Typography
                        className="chat-last-message truncate-text"
                      >

                        {chat?.last_message?.image && !chat?.last_message?.audio_duration && (
                          <>
                          <ImageIcon className="chat-user-icons" />
                          Image
                          </>
                        )}
                        {chat?.last_message?.image && chat?.last_message?.audio_duration && (
                          <>
                          <KeyboardVoiceIcon className="chat-user-icons" />
                          {formatAudioDuration(chat?.last_message?.audio_duration)}
                          </>
                        )}
                        {chat.last_message.image == "" && (
                          capitalize(chat?.last_message?.text)
                        )}
                      </Typography>)
                      :
                      (<Typography
                        className="chat-last-message truncate-text"
                      >
                        No messages Yet!
                      </Typography>)
                      }
                    </div>

                  </div>
                  <div className="message-count-container">
                    {(chat.unread_count > 0) ?
                      <span className="messages-count">
                        <Tooltip arrow title={`${chat?.unread_count} unread`}>
                          <Badge
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            badgeContent={chat?.unread_count}
                            max={99}
                            sx={{
                              "& .MuiBadge-badge": {
                                backgroundColor: "black",
                                color: "white",
                                fontSize: "10px",
                                height: "20px",
                                minWidth: "20px",
                                padding: "2px",
                              },
                            }}
                          >
                            <div></div>
                          </Badge>
                        </Tooltip>
                      </span>
                      :
                      <div >
                        <Typography className="message-timestamp">
                          {moment(chat?.last_message?.createdAt).format("MMM DD")}
                        </Typography>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Paper>
            <Divider sx={{ height: "2px", backgroundColor: "rgba(0, 0, 0, 0.12)" }} />
          </div>
        );
      })}

    </InfiniteScroller>
  );
}

export default ChatList;
