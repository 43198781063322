import { useRef, useState } from "react";
import {
  Link as RouterLink,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
//hooks
import { useAppContext } from "src/hooks";
//
import { s3baseUrl } from "src/config/config";
import { logout_user } from "src/utils";
import { Iconify } from "src/components";
import ManageCard from "src/components/Manage-Card/ManageCard";
import PaymentWrapper from "src/pages/PaymentWrapper/PaymentWrapper";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Profile",
    path: "/profile",
    icon: "carbon:user-profile",
  },
  {
    label: "Settings",
    path: "/settings",
    icon: "ant-design:setting-outlined",
  },
  // {
  //   label: "Manage Payments",
  //   path: "/payment-history",
  //   icon: "ic:baseline-payment",
  // },
  {
    label: "Transactions",
    path: "/transactions",
    icon: "streamline:subscription-cashflow",
  },
  {
    label: "Payment Plans",
    path: "/plans",
    icon: "ic:baseline-star-outline",
  },
  {
    label: "Manage Card",
    path: "",
    icon: "f7:creditcard",
  },
  {
    label: "Recent Updates",
    path: "/recent-updates",
    icon: "ic:baseline-update",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const { dispatch_get_user_profile, disconnectSocket, setLogoutDialogOpen } =
    useAppContext();
  const profile = dispatch_get_user_profile();
  const { pathname } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {profile.image ? (
          <>
            <Avatar src={s3baseUrl + profile.image} alt={profile.first_name} />
          </>
        ) : (
          <>
            <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
              {profile.first_name?.charAt(0)}
            </Avatar>
          </>
        )}
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {profile.first_name + " " + profile.last_name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {profile.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.path}
              component={option.label === "Manage Card" ? "button" : RouterLink}
              onClick={() => {
                if (option.label === "Manage Card") {
                  handleOpenModal();
                } else {
                  handleCloseModal();
                  handleClose();
                }
              }}
              sx={{
                color: match(option.path)
                  ? (theme) => theme.palette.primary.main
                  : "black",
                bgcolor: match(option.path)
                  ? (theme) =>
                      alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity
                      )
                  : "",
              }}
            >
              <Iconify icon={option.icon} sx={{ marginRight: 1 }} />
              {option.label}
            </MenuItem>
          ))}
          <MenuItem onClick={() => setLogoutDialogOpen(true)}>
            <Iconify icon={"tabler:logout-2"} sx={{ marginRight: 1 }} />
            Logout
          </MenuItem>
        </Stack>
        <PaymentWrapper
          openModal={openModal}
          handleCloseModal={handleCloseModal}
        />

        {/* <Divider sx={{ borderStyle: "dashed", color: "red", height: "2px" }} /> */}
      </MenuPopover>
    </>
  );
}
