import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { get_zone_date } from "src/utils";
import { Box, Typography } from "@mui/material";

const CheckListDatePicker = ({ value, onChange, disabled, completed }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (disabled) {
      return;
    }
    setOpen(true);
  };

  const getFormatted = (_format, _date) => {
    let date = _date ? _date : moment();
    const with_zone = get_zone_date(date, _format);
    return moment(with_zone);
  };

  const isDatePast = (_date) => {
    let target_date = getFormatted("YYYY-MM-DD hh:mm A", _date);
    let today_date = getFormatted("YYYY-MM-DD hh:mm A");
    let expired = target_date.isBefore(today_date);
    return expired;
  };

  const getColor = (date) => {
    if (!date) {
      return "#00AB55";
    }
    let expired = isDatePast(date);
    if (expired) {
      return completed == true ? "#00AB55" : "#c23232";
    }
    return "#00AB55";
  };

  const onDateChange = (newValue) => {
    onChange(newValue);
  };

  const handleFilterTimes = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <span id="checklist-date-picker">
        <DatePicker
          selected={value ? new Date(value) : null}
          onChange={onDateChange}
          open={open}
          onClickOutside={() => setOpen(false)}
          onSelect={() => setOpen(false)}
          disabled={disabled}
          showTimeSelect
          timeIntervals={5}
          minDate={new Date()}
          filterTime={handleFilterTimes}
          dateFormat="dd MMM yyyy HH:mm"
          customInput={
            <PickerInputDate
              handleOpen={handleOpen}
              getColor={getColor}
              value={value}
              disabled={disabled}
            />
          }
        />
      </span>
    </>
  );
};

export default CheckListDatePicker;

// ----------------------------------------------------

const PickerInputDate = React.forwardRef((props, ref) => {
  const { handleOpen, getColor, value, disabled } = props;

  return (
    <Box ref={ref}
      className="pointer"
      onClick={handleOpen}
      sx={{ display: "flex", alignItems: "center" }}
    >
      {value ? (
        <div
          style={{
            border: `1px dashed ${getColor(value)}`,
            padding: "1px 6px",
            borderRadius: "5px",
          }}
        >
          <Typography
            sx={{
              color: disabled
                ? (theme) => theme.palette.text.disabled
                : getColor(value),
              fontSize: "11px",
              fontWeight: 500,
            }}
          >
            {moment(value).format("DD MMM YYYY hh:mm A")}
          </Typography>
        </div>
      ) : (
        <Typography
          className="choose-target-date"
          sx={{ fontSize: "12px" }}
          onClick={handleOpen}
        >
          Choose Target Date
        </Typography>
      )}
    </Box>
  );
});
