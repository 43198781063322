import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    CircularProgress,
    IconButton,
    Tooltip,
    Typography,
    Divider,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { useState } from "react";
import { useEffect } from "react";
import { _editMessage, _seenMessages } from "src/DAL";
import { useSnackbar } from "notistack";
import moment from "moment";
import ImageIcon from '@mui/icons-material/Image';
import { formatAudioDuration, truncateText } from "src/utils/audioDurationFormat";
import { useAppContext } from "src/hooks";
import { capitalize } from "lodash";
import { s3baseUrl } from "src/config";

export default function ReplyMessageContainer({
    replyMessage,
    setReplyMessage
}) {
    const { dispatch_get_user_profile } = useAppContext();
    return (
        <div className="textarea-reply-container">
            <div className="reply-item textarea-reply" style={{
                borderLeft: (replyMessage.sender_id == dispatch_get_user_profile().user_id) ? "5px darkgreen solid" : ""
            }}>
                <div className="reply-flex">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                        <Typography variant="h6" fontSize="11px" fontWeight="600"
                            style={{
                                color: (replyMessage.sender_id == dispatch_get_user_profile().user_id) ? "darkgreen" : ""
                            }}
                            className="user-profile">
                            {
                                (replyMessage.sender_id == dispatch_get_user_profile().user_id) ?
                                    "You" : capitalize(replyMessage?.message_sender?.first_name)
                            }

                        </Typography>
                        <IconButton
                            className="p-0"
                            color="secondary"
                            onClick={() => {
                                setReplyMessage("");
                            }}
                        >
                            <CloseIcon fontSize="small" style={{ color: "red" }} />
                        </IconButton>
                    </div>

                    {(replyMessage.image && !replyMessage.audio_duration) ?
                        <img src={s3baseUrl + replyMessage?.image} alt="Image" width="25" height="18" />
                        :
                        <Typography variant="body2" >
                            {
                                (replyMessage.image && replyMessage.audio_duration) &&
                                <>
                                    <KeyboardVoiceIcon className="chat-user-icons" />
                                    {formatAudioDuration(replyMessage?.audio_duration)}
                                </>
                            }
                            {
                                (replyMessage.image == "") &&
                                truncateText(replyMessage?.text, 27)
                            }
                        </Typography>
                    }
                </div>
            </div>
        </div>
    )
}
