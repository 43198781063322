import React, { useRef } from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function MuiAutoCompleteTimes({
  label,
  nameprop,
  value,
  onChange,
  error,
  updateTask,
  placeholder,
  canEditTask,
}) {
  const oldValueRef = useRef(value);
  
  const timeOptions = [
    "05 min",
    "10 min",
    "15 min",
    "30 min",
    "45 min",
    "1 hr",
    "1 hr 30 min",
    "2 hr",
    "2 hr 30 min",
    "3 hr",
    "3 hr 30 min",
    "4 hr",
    "5 hr",
    "6 hr",
    "7 hr",
    "8 hr",
    "9 hr",
    "10 hr",
    "11 hr",
    "12 hr",
    "13 hr",
    "14 hr",
    "15 hr",
    "16 hr",
    "17 hr",
    "18 hr",
    "19 hr",
    "20 hr",
  ];

  const convertToTimeFormat = (timeOption) => {
    const timeParts = timeOption.split(" ");
    let hours = 0;
    let minutes = 0;
    for (let i = 0; i < timeParts.length; i++) {
      if (timeParts[i].includes("hr")) {
        hours = parseInt(timeParts[i - 1] || "0");
      }
      if (timeParts[i].includes("min")) {
        minutes = parseInt(timeParts[i - 1] || "0");
      }
    }
    // Format as "hh:mm" ---------
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    return formattedTime;
  };

  return (
    <Autocomplete
      freeSolo
      options={timeOptions}
      value={value}
      onChange={(event, selectedOption) => {
        const convertedOption = selectedOption
          ? convertToTimeFormat(selectedOption)
          : "";
        onChange(convertedOption);
      }}
      renderOption={(props, option) => (
        <span {...props} style={{ fontWeight: "500" }}>
          {option}
        </span>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={label}
          name={nameprop}
          //   onBlur={updateTask}
          onBlur={() => {
            if (oldValueRef.current !== value) {
              oldValueRef.current = value;
              updateTask();
            }
          }}
          disabled={!canEditTask()}
          placeholder={placeholder}
          error={error}
          onKeyDown={(e) => {
            const validKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Tab",
              "Enter",
            ];
            if (!validKeys.includes(e.key) && !/^[0-9:]$/.test(e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            let value = e.target.value;
            const parts = value.split(":");
            if (parts.length > 2) {
              value = value.substring(0, value.lastIndexOf(":"));
            }
            if (value.length === 2 && !value.includes(":")) {
              value += ":";
            }
            if (parts.length === 2) {
              let hours = parts[0];
              let minutes = parts[1];
              if (parseInt(minutes, 10) > 59) {
                minutes = "59";
              }
              value = `${hours}:${minutes}`;
            }
            if (value.endsWith(":") && value.length > 3) {
              value = value.substring(0, value.length - 1);
            }
            onChange(value);
          }}
        />
      )}
    />
  );
}
