import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  Menu,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import { _add_task_checklist } from "src/DAL/taskChecklist";
import TemplatesList from "./TemplatesList";
import TemplateDialog from "./TemplateDialog";

function CheckList({ task, setTask, setTasks, canEditTask, getTaskDetail }) {
  const [open, setOpen] = useState(null);
  const [newCheckListName, setNewCheckListName] = useState("My List");
  const [addLoading, setAddLoading] = useState(false);
  const [templatedialog, setTemplatedialog] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);

  const handleCloseDialog = () => {
    setTemplatedialog(false);
    setOpen();
  };

  const handleOpen = (e) => {
    setOpen(e.target);
  };

  const handleClose = () => {
    setOpen();
    setNewCheckListName("My List");
    // setShowTemplates(false);
  };

  const handleAdd = async () => {
    try {
      setAddLoading(true);
      const req_data = {
        task_id: task._id,
        name: newCheckListName,
      };
      const result = await _add_task_checklist(req_data);
      if (result.code == 200) {
        setTask(result.task_data);
        setTasks(
          result.task_data.section_id,
          result.task_data._id,
          result.task_data
        );
        handleClose();
      }
    } catch (error) {
      console.log(error, " catched while adding checklist");
    } finally {
      setAddLoading(false);
    }
  };

  return (
    <div>
      <div
        className="sidebar-button d-flex align-items-center"
        onClick={(e) => {
          canEditTask && handleOpen(e);
        }}
      >
        Add Checklist
        <IconButton
          sx={{ bgcolor: (theme) => theme.palette.action.hover }}
          size="small"
        >
          <Iconify
            sx={{ width: "24px", height: "24px" }}
            icon="basil:checked-box-outline"
          />
        </IconButton>
      </div>

      <Menu open={Boolean(open)} onClose={handleClose} anchorEl={open}>
          {/* {!showTemplates ? ( */}
          <div className="p-3">
            <div>
              <TextField
                size="small"
                label="Title"
                value={newCheckListName}
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setNewCheckListName(e.target.value);
                }}
              />
            </div>
            <div className="d-flex gap-2 mt-2">
              <LoadingButton
                loading={addLoading}
                variant="contained"
                onClick={handleAdd}
                fullWidth
              >
                Add
              </LoadingButton>
              <Button
                fullWidth
                variant="text"
                // onClick={() => setShowTemplates(true)}
                onClick={() => setTemplatedialog(true)}
              >
                Templates
              </Button>
            </div>
          </div>
          {/* // ) : (
          //   <div className="p-2">
          //     <div className="d-flex gap-1 align-items-center">
          //       <IconButton
          //         onClick={() => setShowTemplates(false)}
          //         size="small"
          //       >
          //         <Iconify
          //           sx={{ width: "18px", height: "18px" }}
          //           icon="mdi:arrow-left"
          //         />
          //       </IconButton>
          //       <Typography fontSize="13px" color="primary.main" fontWeight="600">
          //         Templates
          //       </Typography>
          //     </div>

          //     <TemplatesList
          //       open={showTemplates}
          //       task={task}
          //       onClose={() => setShowTemplates(false)}
          //       handleClose={handleClose}
          //       getTaskDetail={getTaskDetail}
          //     />
          //   </div>
          // )} */}
      </Menu>

      <TemplateDialog
        open={templatedialog}
        onClose={handleCloseDialog}
        task={task}
        getTaskDetail={getTaskDetail}
      />
    </div>
  );
}

export default CheckList;
