import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import {
  Box,
  Chip,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
// ---------------------------------------------------------
export default function MuiAutoCompleteCopySections({
  label,
  options,
  value,
  onChange,
  disabled,
  required = false,
  disablePortal = false,
  loading,
}) {
  const [inputValue, setInputValue] = React.useState("");

  // ---------------------------------------------------------
  const handleChange = (newValue) => {
    console.log(newValue);
    if (newValue) {
      onChange(newValue);
    } else {
      onChange();
    }
  };

  useEffect(() => {
    if (!value) {
      const defaultSection = options.find((option) => option.is_default);
      if (defaultSection) {
        onChange(defaultSection);
      }
    }
  }, [options]);

  // ---------------------------------------------------------
  return (
    <div className="copy-section-task">
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "40px" }}
        >
          <CircularProgress disableShrink size={20} />
        </div>
      ) : (
        <Autocomplete
          value={value}
          disabled={disabled}
          onChange={(event, newValue) => {
            handleChange(newValue);
          }}
          sx={{ width: "100%" }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          disablePortal={disablePortal}
          //   isOptionEqualToValue={(option, value) => option.title === value.title}
          id="controllable-states-demo"
          options={options}
          renderOption={(props, option) => {
            // const tooltipTitle = getCurrent(option);
            return (
              <div
                {...props}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    maxWidth: "90%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {option.label}
                </Typography>
                <div className="d-flex">
                  {option.is_default && (
                    <Tooltip title="Default Section">
                      <span>
                        <Box
                          sx={{
                            marginRight: "3px",
                            padding: "2px 4px",
                            color: "white",
                            fontSize: "8px",
                            fontWeight: "600",
                            backgroundColor: "primary.main",
                            borderRadius: "4px",
                          }}
                        >
                          Default
                        </Box>
                      </span>
                    </Tooltip>
                  )}
                  {/* {isCurrent(option) && (
                    <>
                      <Tooltip title={tooltipTitle}>
                        <span>
                          <Typography
                            onClick={(event) => event.stopPropagation()}
                            sx={{
                              padding: "3px",
                              color: "white",
                              fontSize: "8px",
                              fontWeight: "600",
                              backgroundColor: "#3366FF",
                              borderRadius: "50%",
                              textTransform: "uppercase",
                              height: "18px",
                              width: "18px",
                              textAlign: "center",
                            }}
                          >
                            C
                          </Typography>
                        </span>
                      </Tooltip>
                    </>
                  )} */}
                </div>
              </div>
            );
          }}
          // Selected Option -----------------------------------
          renderInput={(params) => (
            <TextField
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  maxWidth: "90%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                },
              }}
              required={required}
              {...params}
              label={label}
              // InputProps={{
              //   ...params.InputProps,
              //   endAdornment: (
              //     <>
              //       {loading ? (
              //         <CircularProgress disableShrink size={20} />
              //       ) : null}
              //     </>
              //   ),
              // }}
            />
          )}
        />
      )}
    </div>
  );
}
