
export const truncateText = (string, maxLength) => {
    if (maxLength == string?.length) {
      return string;
    }
    if (string?.length > maxLength) {
      let result = string.slice(0, maxLength).concat(" " + "...");
      return result;
    }
    return string;
  };

  export const formatAudioDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${minutes.toString()}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };