import { filter } from "lodash";
import { capitalCase, sentenceCase } from "change-case";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { noResults } from "src/assets";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  Box,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  ButtonBase,
  Paper,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItem,
  List,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  MuiDrawer,
  MuiDialog,
  CircularLoader,
  NoData,
  MuiAutoComplete,
} from "src/components";
import UserListHead from "./components/UserListHead";
import UserListToolbar from "./components/UserListToolbar";
import UserMoreMenu from "./components/UserMoreMenu";
//
import { useNavigate } from "react-router-dom";
import {
  _cancel_workspace_invitation,
  _remove_user_from_workspace,
  _current_team_list,
  _resend_workspace_invitation,
} from "src/DAL";
import { s3baseUrl } from "src/config";
import InvitationCard from "./components/InviteCard";
import WorkspaceCard from "./components/WorkspaceCard";
import { useSnackbar } from "notistack";
import AddNewCard from "src/components/AddNewCard";
import { Workspaces } from "@mui/icons-material";
import InviteMembersDialog from "src/components/InviteMembersDialog";
import TeamTaskDialog from "./components/TeamTaskDialog";
import { useAppContext } from "src/hooks";
// --------------------------------./components/TeamTaskDialog----

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "user_email", label: "Email", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "workspace", label: "Workspaces", alignRight: false },
  {
    id: "started_tasks",
    label: <span style={{ marginLeft: "7px" }}>User Tasks</span>,
    alignRight: false,
  },
  // { id: "invitation", label: "Pending Invites", alignRight: false },
  // { id: '' },/* to add empty column */
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const getConcat = (item) => {
    return item.first_name + " " + item.last_name;
  };
  if (query) {
    return filter(
      array,
      (_user) =>
        getConcat(_user).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  const temp = stabilizedThis.map((el) => el[0]);
  return temp.filter((member) => member.role !== "admin");
}

export default function Users() {
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch_get_user_profile } = useAppContext();
  const userId = dispatch_get_user_profile().user_id;
  // ------------------TABLE HANDLING--------------------------------
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  // ------------------TEAM STATES--------------------------------
  const [teamData, setTeamData] = useState([]);
  const [currentRow, setCurrentRow] = useState(undefined);
  const [currentTargetItem, setCurrentTargetItem] = useState(undefined);
  const [currentInvItem, setCurrentInvItem] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [invDrawerOpen, setInvDrawerOpen] = useState(false);
  const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false);
  const [cancelInvOpen, setCancelInvOpen] = useState(false);
  const [resendInvOpen, setResendInvOpen] = useState(false);
  const [removeUserDialog, setRemoveUserDialog] = useState(false);
  const [removeUserType, setRemoveUserType] = useState("remove");
  const [autoCompleteValue, setAutoCompleteValue] = useState();
  const [resendLoading, setResendLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("accepted");
  const [currentTeamTab, setCurrentTeamTab] = useState("personal");
  const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  // --------------------- Modal ----------------------------------
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDetailModal, setUserDetailModal] = useState(false);

  const handleDetailModalOpen = (row) => {
    setSelectedUser(row);
    setUserDetailModal(true);
  };

  const handleDetailModalClose = () => {
    setUserDetailModal(false);
  };

  const openInvitationDialog = () => {
    setInvitationDialogOpen(true);
  };
  const closeInvitationDialog = () => {
    setInvitationDialogOpen(false);
  };
  const openInviteMembersDialog = (user) => {
    if (user) {
      console.log(
        `User Name: ${user.first_name} ${user.last_name}, User ID: ${user.user_id}`
      );
      setSelectedUser(user);
      setInvitationDialogOpen(true);
    }
  };

  const closeInviteMembersDialog = () => {
    // setSelectedUser(null);
    setInvitationDialogOpen(false);
    // setWorkspaceModalOpen(false);
    // setCurrentRow(undefined);
    // setCurrentInvItem(undefined);
    // setCurrentTargetItem(undefined);
    // setSelectedUser(null);
  };
  const getTeamList = async () => {
    setInvitationDialogOpen(false);
    const result = await _current_team_list();
    if (result.code === 200) {
      console.log(result, "team-res");

      setTeamData(result.user_workspace);
      // setTeamData(temp);
      setLoading(false);
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleNav = () => {
    navigate("/team/add-team");
  };
  const onViewInvitation = (_row, _data) => {
    console.log(_data, _row, "on-view");
    // setCurrentTargetItem(_data);
    setCurrentRow(_row);
    openInvitationDrawer();
  };
  const onViewWorkspace = (_row, _data) => {
    console.log(_data, _row, "on-view");
    // setCurrentTargetItem(_data);
    setCurrentRow(_row);
    openWorkspaceModal(_row);
  };
  const closeInvitationDrawer = () => {
    setInvDrawerOpen(false);
    setCurrentRow(undefined);
    setCurrentInvItem(undefined);
    setCurrentTargetItem(undefined);
  };
  const openInvitationDrawer = () => {
    setInvDrawerOpen(true);
  };
  const openWorkspaceModal = (row) => {
    setSelectedUser(row);
    setCurrentRow(row);
    setWorkspaceModalOpen(true);
  };

  const closeWorkspaceModal = () => {
    setWorkspaceModalOpen(false);
    setCurrentRow(undefined);
    setCurrentInvItem(undefined);
    setCurrentTargetItem(undefined);
    setSelectedUser(null);
  };
  const openCancelInvDialog = () => {
    setCancelInvOpen(true);
  };
  const closeCancelInvDialog = () => {
    setCancelInvOpen(false);
  };
  const openResendInvDialog = () => {
    setResendInvOpen(true);
  };
  const closeResendInvDialog = () => {
    setResendInvOpen(false);
  };
  const openRemoveUserDialog = () => {
    setRemoveUserDialog(true);
  };
  const closeRemoveUserDialog = () => {
    setRemoveUserDialog(false);
    setRemoveUserType("remove");
    //  Reset error
    setError(null);
  };
  const getInvitations = () => {
    if (!currentRow) {
      return [];
    }
    if (currentRow.invitaion) {
      console.log(currentRow, "invi");
      return currentRow.invitaion;
    }
    return [];
  };
  const getWorkspaces = () => {
    if (!currentRow) {
      return [];
    }
    if (currentRow.workspace) {
      console.log(currentRow, "hereafoj");
      return currentRow.workspace;
    }
    return [];
  };
  const onCancelWorkspaceInvite = (_data) => {
    console.log(_data, "on-cancel-inv");
    setCurrentTargetItem(_data);
    openCancelInvDialog();
  };
  const onResendWorkspaceInvite = (_data) => {
    console.log(_data, "on-cancel-inv");
    setCurrentTargetItem(_data);
    openResendInvDialog();
  };
  const handleResendInvitation = async () => {
    try {
      setResendLoading(true);
      const result = await _resend_workspace_invitation(currentTargetItem._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        closeResendInvDialog();
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while resending invitation");
    } finally {
      setResendLoading(false);
    }
  };
  const handleCancelWorkspaceInvite = async () => {
    console.log(currentRow, "current-row");
    console.log(teamData, "team-data");
    // const id = currentInvItem._id;
    const id = currentTargetItem._id;
    setProcessing(true);
    const result = await _cancel_workspace_invitation(null, id);
    if (result.code === 200) {
      console.log(result, "res");
      let updated_invitations = undefined;
      let updated = teamData.map((row) => {
        const getFiltered = (_data) => {
          let filtered = _data.filter((_item) => {
            if (String(_item._id) !== String(currentTargetItem._id)) {
              return _item;
            }
          });
          updated_invitations = filtered;
          return filtered;
        };
        if (String(row.user_id) === String(currentRow.user_id)) {
          console.log(row, "to be edited");
          return { ...row, ["invitaion"]: getFiltered(row.invitaion) };
        }
        return row;
      });
      console.log(updated, "final");
      setTeamData(
        updated.filter(
          (data) => data.invitaion.length > 0 || data.workspace.length > 0
        )
      );
      let updated_row = { ...currentRow, ["invitaion"]: updated_invitations };
      setCurrentRow(updated_row);
      closeCancelInvDialog();
      enqueueSnackbar("Invitation cancelled successfully", {
        variant: "success",
      });
      setProcessing(false);
      closeCancelInvDialog();
      // closeInvitationDrawer();
      /* filter current row if no workspaces & invitations left  for better ux, */
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
      closeCancelInvDialog();
      // closeInvitationDrawer();
    }
  };

  const onRemoveFromWorkspace = (_data) => {
    console.log(_data);
    openRemoveUserDialog();
    setCurrentTargetItem(_data);
  };
  const handleRemoveFromWorkspace = async () => {
    if (removeUserType === "assign" && !autoCompleteValue) {
      setError("Please select a team member.");
      return;
    }
    // Your existing logic to handle removing from workspace
    console.log(currentRow, "current-row");
    console.log(currentTargetItem, "current-target-item");
    console.log(teamData, "team-data");
    let target_workspace = undefined;
    currentRow.workspace.map((item) => {
      if (String(item._id) === String(currentTargetItem._id)) {
        target_workspace = item;
        console.log(item, "found-item");
      }
    });

    if (!target_workspace) {
      return;
    }
    const data = {
      workspace: [currentTargetItem._id],
      user_id: currentRow.user_id,
      member_id: autoCompleteValue?.value,
    };
    setProcessing(true);
    const result = await _remove_user_from_workspace(data);
    if (result.code === 200) {
      console.log(result, "res");
      let updated_workspaces = undefined;
      let updated = teamData.map((row) => {
        const getFiltered = (_data) => {
          let filtered = _data.filter((_item) => {
            if (String(_item._id) !== String(currentTargetItem._id)) {
              return _item;
            }
          });
          updated_workspaces = filtered;
          return filtered;
        };
        if (String(row.user_id) === String(currentRow.user_id)) {
          console.log(row, "to be edited");
          return { ...row, ["workspace"]: getFiltered(row.workspace) };
        }
        return row;
      });
      console.log(updated, "final");
      setTeamData(
        updated.filter(
          (data) => data.invitaion.length > 0 || data.workspace.length > 0
        )
      );
      let updated_row = { ...currentRow, ["workspace"]: updated_workspaces };
      setCurrentRow(updated_row);
      closeRemoveUserDialog();
      enqueueSnackbar("User removed from workspace successfully", {
        variant: "success",
      });
      setProcessing(false);
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
      closeRemoveUserDialog();
    }
    setAutoCompleteValue();
  };

  const getTooltipItems = (_data) => {
    if (!_data) {
      return [
        {
          name: "Nothing to display",
          workspace: { name: "Nothing to display" },
        },
      ];
    }
    if (_data) {
      if (_data.length === 0) {
        return [
          {
            name: "Nothing to display",
            workspace: { name: "Nothing to display" },
          },
        ];
      }
      return _data;
    }
  };
  // ------------------TABLE HANDLING--------------------------------
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = teamData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - teamData.length) : 0;
  // const filteredUsers = applySortFilter(
  //   teamData,
  //   getComparator(order, orderBy),
  //   filterName
  // );
  const filteredUsers = applySortFilter(
    // personal
    teamData,
    getComparator(order, orderBy),
    filterName
  ).filter((user) =>
    user.workspace.some((workspace) => workspace.user_id === userId)
  );
  const filteredAssignees = applySortFilter(
    // assignees
    teamData,
    getComparator(order, orderBy),
    filterName
  ).filter((user) =>
    user.workspace.some((workspace) => workspace.user_id !== userId)
  );
  console.log(filteredAssignees, "AAAAAAAAAA");

  const isUserNotFound = filteredUsers.length === 0;
  // -----------------------------------------------------------
  useLayoutEffect(() => {
    getTeamList();
  }, []);
  useEffect(() => {}, [getInvitations]);
  // useEffect(() => {
  //   if (!currentRow) {
  //     setCombineWorkspacesAndInvitations([]);
  //   } else {
  //     setCombineWorkspacesAndInvitations();
  //   }
  // }, [currentRow]);

  // -----------------------------------------------------------

  const getTeamMembers = () => {
    const members = currentTargetItem?.team?.map((member) => {
      return {
        label: member.first_name + " " + member.last_name,
        value: member.user_id,
      };
    });
    return members?.filter((member) => member.value !== currentRow.user_id);
  };

  // -----------------------------------------------------------
  if (loading) {
    return (
      <Page title="loading">
        <Container maxWidth="xl">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="mb-4"
          >
            <Typography variant="h4">My Team</Typography>
            <Button
              // onClick={handleNav}
              onClick={openInvitationDialog}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Team
            </Button>
          </Stack>
          <CircularLoader />
        </Container>
      </Page>
    );
  }
  return (
    <Page title="Team">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="mb-4"
        >
          <Typography variant="h4">My Team</Typography>
          <Button
            // onClick={handleNav}
            onClick={openInvitationDialog}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Team
          </Button>
        </Stack>
        {teamData.length !== 0 && (
          <Card
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              mb: 1,
              // minHeight: "70vh",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <Tabs
                value={currentTeamTab}
                onChange={(e, val) => setCurrentTeamTab(val)}
              >
                <Tab
                  value="personal"
                  label={`Personal Team (${filteredUsers.length})`}
                />
                <Tab
                  value="assignees"
                  label={`Assigned Team (${filteredAssignees.length})`}
                />
              </Tabs>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
            </div>
            {/* --------------------------------------------------------- */}
            {currentTeamTab == "personal" && (
              <>
                {filteredUsers?.length === 0 && (
                  <div>
                    <img
                      width="30%"
                      src={noResults}
                      style={{ margin: "auto" }}
                    />
                    <Typography
                      color={(theme) => theme.palette.text.disabled}
                      sx={{ mt: 1, mb: 6 }}
                      textAlign="center"
                    >
                      No team found! <br />
                      In the Personal Team section, you can see teams from your
                      workspaces and projects where you are an admin or the
                      creator.
                    </Typography>
                  </div>
                )}
                {filteredUsers?.length !== 0 && (
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={teamData.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {filteredUsers
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              // row1.team.map((row) => {
                              console.log(row, "row_data personal");
                              const {
                                user_id,
                                first_name,
                                last_name,
                                email,
                                status,
                                image,
                                invitaion,
                                workspace,
                              } = row;
                              const isItemSelected =
                                selected.indexOf(first_name + last_name) !== -1;
                              const combinedData = [
                                ...workspace.map((item) => {
                                  return { ...item, type: "workspace" };
                                }),
                                ...invitaion.map((item) => {
                                  return { ...item, type: "invitation" };
                                }),
                              ];

                              return (
                                <TableRow
                                  hover
                                  key={email}
                                  tabIndex={-1}
                                  role="checkbox"
                                  // selected={isItemSelected}
                                  // aria-checked={isItemSelected}
                                >
                                  {/* <TableCell padding='checkbox'>
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, name)}
                            />
                          </TableCell> */}
                                  <TableCell>
                                    <Typography variant="subtitle2">
                                      {rowsPerPage * page + (index + 1)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      {image ? (
                                        <Avatar
                                          alt={first_name}
                                          src={s3baseUrl + image}
                                        />
                                      ) : (
                                        <Avatar>
                                          {first_name && last_name
                                            ? first_name[0] + last_name[0]
                                            : email.substring(0, 2)}
                                        </Avatar>
                                      )}

                                      <Typography variant="subtitle2" noWrap>
                                        {first_name && last_name
                                          ? first_name + " " + last_name
                                          : email.substring(0, 3)}
                                      </Typography>
                                    </Stack>
                                  </TableCell>

                                  <TableCell align="left">{email}</TableCell>

                                  <TableCell align="left">
                                    <Label
                                      variant="outlined"
                                      color={status ? "success" : "error"}
                                    >
                                      {status
                                        ? sentenceCase("active")
                                        : sentenceCase("not registered")}
                                    </Label>
                                  </TableCell>
                                  <TableCell align="left">
                                    {combinedData.length > 0 && (
                                      <Tooltip title="View">
                                        <List
                                          sx={{ cursor: "pointer" }}
                                          onClick={() =>
                                            combinedData.length > 0 &&
                                            onViewWorkspace({
                                              ...row,
                                              combinedData,
                                            })
                                          }
                                        >
                                          <ListItem sx={{ py: 0, pl: 0 }}>
                                            {(combinedData[0].type ==
                                            "workspace"
                                              ? combinedData[0].name
                                              : combinedData[0].workspace
                                                  .name) + " ("}
                                            {combinedData[0]?.role + ")"}
                                            {combinedData[0].type ==
                                              "invitation" && (
                                              <Label
                                                sx={{ ml: 0.5 }}
                                                variant="ghost"
                                                color="warning"
                                              >
                                                Pending
                                              </Label>
                                            )}
                                          </ListItem>
                                          {combinedData[1] && (
                                            <ListItem sx={{ py: 0, pl: 0 }}>
                                              {(combinedData[1].type ==
                                              "workspace"
                                                ? combinedData[1].name
                                                : combinedData[1].workspace
                                                    .name) + " ("}
                                              {combinedData[1]?.role + ")"}
                                              {combinedData[1].type ==
                                                "invitation" && (
                                                <Label
                                                  sx={{ ml: 0.5 }}
                                                  variant="ghost"
                                                  color="warning"
                                                >
                                                  Pending
                                                </Label>
                                              )}
                                            </ListItem>
                                          )}
                                          {combinedData.length > 2 && (
                                            <ListItem sx={{ py: 0, pl: 0 }}>
                                              + {combinedData.length - 2} more
                                            </ListItem>
                                          )}
                                        </List>
                                      </Tooltip>
                                    )}
                                    {combinedData.length == 0 && (
                                      <Tooltip title="Not added in any workspace">
                                        <Typography
                                          variant="subtitle"
                                          sx={{ px: 2 }}
                                        >
                                          N/A
                                        </Typography>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Button
                                      variant="text"
                                      color="primary"
                                      onClick={() => handleDetailModalOpen(row)} // user detail modal open
                                    >
                                      Started Tasks
                                    </Button>
                                  </TableCell>

                                  {/* <TableCell align="left">
                              {invitaion.length > 0 && (
                                <Tooltip title="View">
                                  <List
                                    sx={{ cursor: "pointer" }}
                                    onClick={() =>
                                      invitaion.length > 0 &&
                                      onViewInvitation(row, invitaion)
                                    }
                                  >
                                    <ListItem sx={{ py: 0 }}>
                                      {invitaion[0]?.workspace?.name + " ("}
                                      {invitaion[0]?.role + ")"}
                                    </ListItem>
                                    {invitaion[1] && (
                                      <ListItem sx={{ py: 0 }}>
                                        {invitaion[1]?.workspace?.name + " ("}
                                        {invitaion[1]?.role + ")"}
                                      </ListItem>
                                    )}
                                    {invitaion.length > 2 && (
                                      <ListItem sx={{ py: 0 }}>
                                        + {invitaion.length - 2} more
                                      </ListItem>
                                    )}
                                  </List>
                                </Tooltip>
                              )}
                              {invitaion.length == 0 && (
                                <Tooltip title="No pending invitations">
                                  <Typography variant="subtitle" sx={{ px: 2 }}>
                                    N/A
                                  </Typography>
                                </Tooltip>
                              )}
                            </TableCell> */}

                                  {/* <TableCell align='right'>
                            <UserMoreMenu />
                          </TableCell> */}
                                </TableRow>
                              );
                              // });
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={5} />
                            </TableRow>
                          )}
                        </TableBody>

                        {isUserNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={6}
                                sx={{ py: 3 }}
                              >
                                <SearchNotFound searchQuery={filterName} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                )}
              </>
            )}

            {/* --------------------------------------------------------- */}
            {currentTeamTab == "assignees" && (
              <>
                {filteredAssignees?.length === 0 && (
                  <div>
                    <img
                      width="30%"
                      src={noResults}
                      style={{ margin: "auto" }}
                    />
                    <Typography
                      color={(theme) => theme.palette.text.disabled}
                      sx={{ mt: 1, mb: 6 }}
                      textAlign="center"
                    >
                      No team found! <br />
                      In the Assignees section, you can view teams from other
                      workspaces and projects where you are a manager.
                    </Typography>
                  </div>
                )}
                {filteredAssignees?.length !== 0 && (
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={teamData.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {filteredAssignees
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                              // row1.team.map((row) => {
                              console.log(row, "row_data other");
                              const {
                                user_id,
                                first_name,
                                last_name,
                                email,
                                status,
                                image,
                                invitaion,
                                workspace,
                              } = row;
                              const isItemSelected =
                                selected.indexOf(first_name + last_name) !== -1;
                              const combinedData = [
                                ...workspace.map((item) => {
                                  return { ...item, type: "workspace" };
                                }),
                                ...invitaion.map((item) => {
                                  return { ...item, type: "invitation" };
                                }),
                              ];

                              return (
                                <TableRow
                                  hover
                                  key={email}
                                  tabIndex={-1}
                                  role="checkbox"
                                  // selected={isItemSelected}
                                  // aria-checked={isItemSelected}
                                >
                                  <TableCell>
                                    <Typography variant="subtitle2">
                                      {rowsPerPage * page + (index + 1)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      {image ? (
                                        <Avatar
                                          alt={first_name}
                                          src={s3baseUrl + image}
                                        />
                                      ) : (
                                        <Avatar>
                                          {first_name && last_name
                                            ? first_name[0] + last_name[0]
                                            : email.substring(0, 2)}
                                        </Avatar>
                                      )}

                                      <Typography variant="subtitle2" noWrap>
                                        {first_name && last_name
                                          ? first_name + " " + last_name
                                          : email.substring(0, 3)}
                                      </Typography>
                                    </Stack>
                                  </TableCell>

                                  <TableCell align="left">{email}</TableCell>

                                  <TableCell align="left">
                                    <Label
                                      variant="outlined"
                                      color={status ? "success" : "error"}
                                    >
                                      {status
                                        ? sentenceCase("active")
                                        : sentenceCase("not registered")}
                                    </Label>
                                  </TableCell>
                                  <TableCell align="left">
                                    {combinedData.length > 0 && (
                                      <Tooltip title="View">
                                        <List
                                          sx={{ cursor: "pointer" }}
                                          onClick={() =>
                                            combinedData.length > 0 &&
                                            onViewWorkspace({
                                              ...row,
                                              combinedData,
                                            })
                                          }
                                        >
                                          <ListItem sx={{ py: 0, pl: 0 }}>
                                            {(combinedData[0].type ==
                                            "workspace"
                                              ? combinedData[0].name
                                              : combinedData[0].workspace
                                                  .name) + " ("}
                                            {combinedData[0]?.role + ")"}
                                            {combinedData[0].type ==
                                              "invitation" && (
                                              <Label
                                                sx={{ ml: 0.5 }}
                                                variant="ghost"
                                                color="warning"
                                              >
                                                Pending
                                              </Label>
                                            )}
                                          </ListItem>
                                          {combinedData[1] && (
                                            <ListItem sx={{ py: 0, pl: 0 }}>
                                              {(combinedData[1].type ==
                                              "workspace"
                                                ? combinedData[1].name
                                                : combinedData[1].workspace
                                                    .name) + " ("}
                                              {combinedData[1]?.role + ")"}
                                              {combinedData[1].type ==
                                                "invitation" && (
                                                <Label
                                                  sx={{ ml: 0.5 }}
                                                  variant="ghost"
                                                  color="warning"
                                                >
                                                  Pending
                                                </Label>
                                              )}
                                            </ListItem>
                                          )}
                                          {combinedData.length > 2 && (
                                            <ListItem sx={{ py: 0, pl: 0 }}>
                                              + {combinedData.length - 2} more
                                            </ListItem>
                                          )}
                                        </List>
                                      </Tooltip>
                                    )}
                                    {combinedData.length == 0 && (
                                      <Tooltip title="Not added in any workspace">
                                        <Typography
                                          variant="subtitle"
                                          sx={{ px: 2 }}
                                        >
                                          N/A
                                        </Typography>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Button
                                      variant="text"
                                      color="primary"
                                      onClick={() => handleDetailModalOpen(row)} // user detail modal open
                                    >
                                      Started Tasks
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                              // });
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={5} />
                            </TableRow>
                          )}
                        </TableBody>

                        {isUserNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={6}
                                sx={{ py: 3 }}
                              >
                                <SearchNotFound searchQuery={filterName} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                )}
              </>
            )}
            <div className="d-flex align-baseline">
              <TablePagination
                rowsPerPageOptions={[50, 100, 150]}
                component="div"
                count={filteredUsers?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Card>
        )}
        {teamData.length == 0 && !loading && (
          <AddNewCard title="team" handleClick={openInvitationDialog} />
        )}
        {/* <NoData
          dataLength={teamData.length}
          title="No team found"
          loading={loading}
        /> */}
        {/* DRAWERS */}
        {/* <MuiDrawer
          title="User invited to following workspaces"
          isOpen={invDrawerOpen}
          onClose={closeInvitationDrawer}
        >
          <Stack spacing={3}>
            {getInvitations().map((item) => (
              <InvitationCard
                onCancel={onCancelWorkspaceInvite}
                onResend={onResendWorkspaceInvite}
                key={item._id}
                item={item}
              />
            ))}
            {getInvitations().length === 0 && (
              <Typography
                color={(theme) => theme.palette.text.disabled}
                sx={{ mt: 35 }}
                textAlign="center"
              >
                Not invited to any workspace
              </Typography>
            )}
          </Stack>
        </MuiDrawer> */}

        <Dialog
          open={workspaceModalOpen}
          onClose={closeWorkspaceModal}
          fullWidth
          maxWidth="sm"
          sx={{ "& .MuiDialog-paper": { maxWidth: "600px" } }}
          inert={!workspaceModalOpen} // Use inert instead of aria-hidden
        >
          <DialogTitle>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                {selectedUser?.image ? (
                  <Avatar
                    alt={selectedUser.first_name}
                    src={s3baseUrl + selectedUser.image}
                  />
                ) : (
                  <Avatar>
                    {selectedUser?.first_name && selectedUser?.last_name
                      ? selectedUser.first_name[0] + selectedUser?.last_name[0]
                      : selectedUser?.email.substring(0, 2)}
                  </Avatar>
                )}
                <Typography variant="h6">
                  {selectedUser?.first_name && selectedUser?.last_name
                    ? selectedUser.first_name + " " + selectedUser?.last_name
                    : selectedUser?.email.substring(0, 3)}
                </Typography>
              </Stack>
              <Button
                onClick={() => openInviteMembersDialog(selectedUser)}
                variant="contained"
              >
                Add To Workspace
              </Button>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Tabs
              value={currentTab}
              onChange={(e, val) => setCurrentTab(val)}
              sx={{ mb: 3 }}
            >
              <Tab
                sx={{ width: "50%" }}
                value={"accepted"}
                label={`Accepted (${getWorkspaces().length})`}
              />
              <Tab
                sx={{ width: "50%" }}
                value={"pending"}
                label={`Pending (${getInvitations().length})`}
              />
            </Tabs>
            {currentTab === "accepted" && (
              <Stack
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "stretch",
                  padding: 0,
                }}
              >
                {getWorkspaces().map((item) => (
                  <Box
                    key={item._id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      background: "#F7F9F8",
                      padding: 3,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography variant="body1" sx={{ width: "40%" }}>
                      {item.name}
                    </Typography>
                    <Typography variant="body1" sx={{ width: "40%" }}>
                      Role: {capitalCase(item.role)}
                      Project {item.projects}
                    </Typography>
                    <Button
                      variant="text"
                      color="error"
                      sx={{ width: "20%" }}
                      onClick={() => onRemoveFromWorkspace(item)}
                    >
                      Remove
                    </Button>
                  </Box>
                ))}
                {getWorkspaces().length === 0 && (
                  <Typography
                    color={(theme) => theme.palette.text.disabled}
                    sx={{ mt: 2 }}
                    textAlign="center"
                  >
                    Not added to any workspace
                  </Typography>
                )}
              </Stack>
            )}
            {currentTab === "pending" && (
              <Stack spacing={3}>
                {getInvitations().map((item) => (
                  <Box
                    key={item._id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      background: "#F7F9F8",
                      padding: 3,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography variant="body1" sx={{ width: "40%" }}>
                      {item.workspace.name}
                    </Typography>
                    <Typography variant="body1" sx={{ width: "40%" }}>
                      Role: {capitalCase(item.role)}
                    </Typography>
                    <Button
                      variant="text"
                      color="warning"
                      sx={{ width: "20%" }}
                      onClick={() => onCancelWorkspaceInvite(item)}
                    >
                      Cancel
                    </Button>
                  </Box>
                ))}
                {getInvitations().length === 0 && (
                  <Typography
                    color={(theme) => theme.palette.text.disabled}
                    sx={{ mt: 2 }}
                    textAlign="center"
                  >
                    Not invited to any workspace
                  </Typography>
                )}
              </Stack>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeWorkspaceModal}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* DIALOGS */}
        {invitationDialogOpen && (
          <InviteMembersDialog
            open={invitationDialogOpen}
            onClose={closeInviteMembersDialog}
            selectedUser={selectedUser}
            onSuccess={() => {
              getTeamList();
            }}
          />
        )}
        <MuiDialog
          open={cancelInvOpen}
          message="Are you sure you want to cancel your invite?"
          title="Cancel invite"
          onToggle={(value) => setCancelInvOpen(value)}
          onAgree={handleCancelWorkspaceInvite}
          loading={processing}
        />
        <MuiDialog
          open={resendInvOpen}
          message="Are you sure you want to re-send this invitation?"
          title="Resend invite"
          onToggle={(value) => setResendInvOpen(value)}
          onAgree={handleResendInvitation}
          loading={resendLoading}
        />
        {/*  */}
        <MuiDialog
          open={removeUserDialog}
          title="Remove user"
          onToggle={(value) => setRemoveUserDialog(value)}
          onAgree={handleRemoveFromWorkspace}
          loading={processing}
          ExtraComponent={
            <>
              <Typography
                component="div"
                dangerouslySetInnerHTML={{
                  __html: `Are you sure you want to remove <strong>${selectedUser?.first_name} ${selectedUser?.last_name}</strong> from <strong>${currentTargetItem?.name}</strong> workspace?`,
                }}
              />
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={removeUserType}
                onChange={(e) => setRemoveUserType(e.target.value)}
              >
                <FormControlLabel
                  value="remove"
                  control={<Radio />}
                  label="Delete all tasks created by this user and remove him as task member"
                />
                <FormControlLabel
                  value="assign"
                  control={<Radio />}
                  label="Assign the tasks, this user has created, to some other team member"
                />
              </RadioGroup>
              {removeUserType === "assign" && (
                <>
                  <MuiAutoComplete
                    onChange={(val) => {
                      setAutoCompleteValue(val);
                      setError(null); // Clear the error state when a team member is selected
                    }}
                    value={autoCompleteValue}
                    label="Team Members"
                    required
                    options={getTeamMembers()}
                  />
                  {error && <Typography color="error">{error}</Typography>}
                </>
              )}
            </>
          }
        />
        <TeamTaskDialog
          open={userDetailModal}
          onClose={handleDetailModalClose}
          selectedUser={selectedUser}
        />
      </Container>
    </Page>
  );
}
