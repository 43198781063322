import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Paper,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Iconify, MuiDrawer } from "src/components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { LoadingButton } from "@mui/lab";

// ===============================================================
export default function ReorderDrawer({
  open,
  onClose,
  list,
  workspaceId,
  updateOrder,
  btnLoading,
  orderChanged,
  loading = false,
}) {
  const [dataList, setDataList] = useState([]);
 
  // Drag and Drop ------------------------
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const updatedList = [...dataList];
    const [movedItem] = updatedList.splice(source.index, 1);
    updatedList.splice(destination.index, 0, movedItem);
    // ---------------- Save order
    // const reorderedList = updatedList.map((item, index) => ({
    //   ...item,
    //   order: index,
    // }));
    // ---------------- local state Delete later
    console.log(updatedList, "reorder locally");
    setDataList(updatedList);
    orderChanged(true); // for backdropClick
  };

  // Move up/down ------------------------------------------------
  const onMoveUpDown = (type, moveList) => {
    const updatedList = [...dataList];
    const currentIndex = updatedList.findIndex(
      (item) => item._id === moveList._id
    );
    const newIndex = type === "up" ? currentIndex - 1 : currentIndex + 1;
    if (newIndex < 0 || newIndex >= updatedList.length) return;

    [updatedList[currentIndex], updatedList[newIndex]] = [
      updatedList[newIndex],
      updatedList[currentIndex],
    ];
    console.log(updatedList, "Move locally");
    setDataList(updatedList);
    orderChanged(true); // for backdropClick
  };

  // handlesaveorder send req_data to api  ----------------------------
  const handleSaveOrder = () => {
    const list_of_ids_array = dataList.map((item) => item._id);
    let req_data;

    if (workspaceId) {
      // project api body
      req_data = {
        workspace_id: workspaceId,
        projects_ids_in_order: list_of_ids_array,
      };
    } else {
      // workspace api body
      req_data = {
        workspace_ids_in_order: list_of_ids_array,
      };
    }
    updateOrder(req_data);
    orderChanged(false);
  };

  // useEffects =====================================================
  useEffect(() => {
    if (open) {
      setDataList(list);
      orderChanged(false);
    }
  }, [open]);

  return (
    <>
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 340 } }}
        isOpen={open}
        onClose={onClose}
        title={
          <Typography sx={{ fontWeight: "700" }}>
            {workspaceId ? "Reorder Projects" : "Reorder Workspaces"}
          </Typography>
        }
        bottomComponent={
          <Stack direction="row" spacing={1} sx={{ px: "20px", py: "10px" }}>
            <LoadingButton
              loading={btnLoading}
              size="large"
              variant="contained"
              className="outline-btn"
              onClick={handleSaveOrder}
              startIcon={<Iconify icon="ix:reorder" />}
              fullWidth
            >
              Update order
            </LoadingButton>
            <LoadingButton
              size="large"
              variant="outlined"
              className="outline-btn"
              onClick={onClose}
            >
              Discard
            </LoadingButton>
          </Stack>
        }
      >
        {/* <hr className="divider" /> */}
        {/* Reorder ======================================== */}
        <Stack spacing={2}>
          {/* <Typography sx={{ fontWeight: "700" }}>Reorder Projects</Typography> */}
          {/* {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="300px"
            >
              <CircularProgress />
            </Box>
          ) : ( */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sectionsList">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {dataList?.map((item, index) => (
                    <Draggable
                      key={item._id || index}
                      draggableId={item._id || index.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          square
                          elevation={2}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            marginBottom: "10px",
                            padding: "0px 5px 0px 5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: snapshot.isDragging
                              ? "#ebf8f2"
                              : "white ",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: snapshot.isDragging
                                ? "primary.main"
                                : "text.primary",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              // maxWidth: "75%",
                            }}
                          >
                            <span className="index-count">{index + 1}.</span>
                            {workspaceId ? item.title : item.name}
                          </Typography>

                          <div className="d-flex align-items-center">
                            {item.is_favorite === true && (
                              <Tooltip title="Favorite Project">
                                <span>
                                  <Iconify
                                    sx={{ color: "#dbb90f" }}
                                    fontSize="16px"
                                    icon="material-symbols:star"
                                  />
                                </span>
                              </Tooltip>
                            )}

                            <Tooltip title="Move Up">
                              <span>
                                <IconButton
                                  onClick={() => onMoveUpDown("up", item)}
                                  disabled={index === 0}
                                >
                                  <ArrowUpwardIcon fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>

                            <Tooltip title="Move Down">
                              <span>
                                <IconButton
                                  onClick={() => onMoveUpDown("down", item)}
                                  disabled={index === dataList.length - 1}
                                >
                                  <ArrowDownwardIcon fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {/* )} */}
        </Stack>
      </MuiDrawer>
    </>
  );
}
