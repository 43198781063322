import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const roleColors = {
  admin: { text: "#229A16", bg: "#E4F8DD" },
  client: { text: "#581b9a", bg: "#581b9a14" },
  team: { text: "#B78103", bg: "#FFF5D7" },
  manager: { text: "#0C53B7", bg: "#DAEDFF" },
  default: { text: "#757575", bg: "#75757514" },
};

const RootStyle = styled("span")(({ ownerState }) => {
  const { text, bg } = ownerState;

  return {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 6,
    cursor: "default",
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "inline-flex",
    justifyContent: "center",
    padding: "0 8px",
    fontSize: "10px",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    letterSpacing: 0.6,
    color: text, 
    backgroundColor: bg, 
  };
});

// ----------------------------------------------------------------------

LabelRole.propTypes = {
  children: PropTypes.node,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  role: PropTypes.oneOf(["admin", "client", "team", "manager"]),
  sx: PropTypes.object,
  onClick: PropTypes.func,
};

export default function LabelRole({
  children,
  role = "default",
  startIcon,
  endIcon,
  sx,
  onClick = () => {},
}) {
  const { text, bg } = roleColors[role] || roleColors.default;

  const style = {
    width: 16,
    height: 16,
    "& svg, img": { width: 1, height: 1, objectFit: "cover" },
  };

  return (
    <RootStyle ownerState={{ text, bg }} sx={sx} onClick={onClick}>
      {startIcon && <Box sx={{ mr: 0.75, ...style }}>{startIcon}</Box>}
      {children || role}
      {endIcon && <Box sx={{ ml: 0.75, ...style }}>{endIcon}</Box>}
    </RootStyle>
  );
}
