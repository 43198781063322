import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Card,
  Typography,
  Stack,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  IconButton,
  ButtonBase,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { baseUri, s3baseUrl } from "src/config/config";
import { MenuPopover, Iconify, Label } from "src/components/";
import moment from "moment";
import { get_zone_date } from "src/utils";

// ----------------------------------------------------------------------

const ProjectImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});
const MenuContainer = styled("div")({
  top: 0,
  right: 0,
  position: "absolute",
  zIndex: 2,
});

// ----------------------------------------------------------------------

InvitationCard.propTypes = {
  item: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default function InvitationCard({ item, onAccept, onReject }) {
  console.log(item, "item");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);
  // ------------------------------------------------------
  const getDate = (date) => {
    return get_zone_date(date, "DD MMM, YYYY");
  };
  const handleClick = (action, item) => {
    if (action === "accept") {
      onAccept(item);
    }
    if (action === "reject") {
      onReject(item);
    }
  };

  // ------------------------------------------------------
  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        {item.workspace.image ? (
          <ProjectImgStyle
            alt={"name"}
            style={{ cursor: "pointer" }}
            src={s3baseUrl + item.workspace.image}
            className="image-hov"
            onClick={() => null}
          />
        ) : (
          <ProjectImgStyle
            alt={"name"}
            style={{ cursor: "pointer" }}
            src="https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg"
            className="image-hov"
            onClick={() => null}
          />
        )}
      </Box>

      <Stack spacing={1} sx={{ p: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="start"
        >
          <Typography
            variant="caption"
            // noWrap
            fontWeight="bold"
            // style={{ cursor: 'pointer' }}
            onClick={() => null}
            component="span"
          >
            Workspace
          </Typography>
          <Typography
            variant="subtitle2"
            noWrap
            style={{ cursor: "pointer" }}
            onClick={() => null}
            component="span"
          >
            {item.workspace.name}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="start"
        >
          <Typography
            variant="caption"
            // noWrap
            fontWeight="bold"
            style={{ cursor: "pointer" }}
            onClick={() => null}
            component="span"
          >
            Admin
          </Typography>
          <Typography
            variant="subtitle2"
            noWrap
            style={{ cursor: "pointer" }}
            onClick={() => null}
            component="span"
          >
            {/* {item.workspace.user.name} */}
            {item.workspace.team.map((member) => {
              if (member.role == "admin") {
                return member.first_name + " " + member.last_name;
              }
            })}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="start"
        >
          <Typography
            variant="caption"
            // noWrap
            fontWeight="bold"
            style={{ cursor: "pointer" }}
            onClick={() => null}
            component="span"
          >
            Role
          </Typography>
          <Typography
            variant="subtitle2"
            noWrap
            style={{ cursor: "pointer" }}
            onClick={() => null}
            component="span"
          >
            {item.role}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="start"
        >
          <Typography
            variant="caption"
            // noWrap
            fontWeight="bold"
            style={{ cursor: "pointer" }}
            onClick={() => null}
            component="span"
          >
            Invitation Date
          </Typography>
          <Typography
            variant="subtitle2"
            noWrap
            style={{ cursor: "pointer" }}
            onClick={() => null}
            component="span"
          >
            {getDate(item.createdAt)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="end"
        >
          <ButtonBase onClick={() => handleClick("reject", item)}>
            <Label
              sx={{ "&:hover": { cursor: "pointer" } }}
              color="error"
              variant="ghost"
            >
              Reject
            </Label>
          </ButtonBase>
          <ButtonBase onClick={() => handleClick("accept", item)}>
            <Label
              sx={{ "&:hover": { cursor: "pointer" } }}
              color="success"
              variant="ghost"
            >
              Accept
            </Label>
          </ButtonBase>
        </Stack>
      </Stack>
    </Card>
  );
}
