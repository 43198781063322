import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { capitalCase } from "change-case";
import { s3baseUrl } from "src/config";
import { MuiAutoComplete, MuiDialog } from "src/components";
import { _project_team, _update_project_team } from "src/DAL";
import { useAppContext } from "src/hooks";
import { useSnackbar } from "notistack";

export default function RemoveFromProjectDialog({
  onOpen,
  onClose,
  selectedUser,
  projectsList,
  getSelectedWorkspaceProjects,
  setMemberSettingDrawerOpen,
}) {
  console.log(projectsList, "projectsList");
  if (!projectsList) {
    return;
  }
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch_get_user_profile } = useAppContext();
  const [removeProjectUserType, setRemoveProjectUserType] = useState("remove");
  const [delUserOpen, setDelUserOpen] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [projectTeam, setProjectTeam] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState();

  const [slug, setSlug] = useState(null);

  const openDelUserDialog = () => {
    setDelUserOpen(true);
  };
  const closeDelUserDialog = () => {
    setDelUserOpen(false);
    setRemoveProjectUserType("remove");
    setSlug(null);
    onClose();
  };

  const onRemove = (item) => {
    console.log(item, "_item");
    setSlug(item);
    openDelUserDialog();
  };

  const getProjectTeam = async () => {
    if (!slug) {
      console.log("no proj id");
      return;
    }
    const result = await _project_team(null, slug._id);
    console.log(result, "proj-team");
    if (result.code === 200) {
      let modified = result.project_team_list.team.map((user) => {
        return {
          ...user,
          query_field: user.first_name + " " + user.last_name,
          creator: user.user_id === dispatch_get_user_profile().user_id,
        };
      });
      console.log(modified, "mft");
      setProjectTeam(modified);
    } else {
      //   result.code == 400 && navigate("/");
      //   result.code !== 400 &&
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getProjectTeamForRemoveUser = () => {
    const members = projectTeam.map((member) => {
      return {
        label: member.first_name + " " + member.last_name,
        value: member.user_id,
      };
    });
    return members.filter((member) => member.value !== selectedUser.user_id);
  };

  const handleUpdateProjectTeam = async (type, member) => {
    if (!member) {
      return;
    }
    setLoadingBtn(true);
    let updated_team = [...projectTeam];
    if (type == "remove") {
      updated_team = updated_team.filter(
        (user) => user.user_id !== member.user_id
      );
    } else {
      updated_team.push(member);
    }
    const req_data = {
      team: updated_team,
      member_id: autoCompleteValue?.value,
    };
    console.log(updated_team, "req_data");
    const result = await _update_project_team(req_data, slug._id);
    if (result.code === 200) {
      getSelectedWorkspaceProjects(slug.workspace.id);
      setMemberSettingDrawerOpen(false);
      enqueueSnackbar(
        `Member ${type == "remove" ? "removed" : "added"} successfully`,
        { variant: "success" }
      );
      closeDelUserDialog();
      setAutoCompleteValue();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setLoadingBtn(false);
  };

  useEffect(() => {
    if (slug) {
      getProjectTeam();
    }
  }, [slug]);

  return (
    <div>
      <Dialog
        open={onOpen}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        sx={{ "& .MuiDialog-paper": { maxWidth: "600px" } }}
        inert={!onOpen} // Use inert instead of aria-hidden
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              {selectedUser?.image ? (
                <Avatar
                  alt={selectedUser.first_name}
                  src={s3baseUrl + selectedUser.image}
                />
              ) : (
                <Avatar>
                  {selectedUser?.first_name && selectedUser?.last_name
                    ? selectedUser.first_name[0] + selectedUser?.last_name[0]
                    : selectedUser?.email.substring(0, 2)}
                </Avatar>
              )}
              <Typography variant="h6">
                {selectedUser?.first_name && selectedUser?.last_name
                  ? selectedUser.first_name + " " + selectedUser?.last_name
                  : selectedUser?.email.substring(0, 3)}
              </Typography>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "stretch",
              padding: 0,
            }}
          >
            {projectsList.map((item, index) => (
              <Box
                key={item._id}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  background: "#F7F9F8",
                  padding: 1,
                  borderRadius: "8px",
                }}
              >
                <Typography variant="body1" sx={{ width: "70%" }} noWrap>
                 {index + 1}. {item.title}
                </Typography>

                <Button
                  variant="text"
                  color="error"
                  sx={{ width: "20%" }}
                  onClick={() => onRemove(item)}
                >
                  Remove
                </Button>
              </Box>
            ))}
            {projectsList.length === 0 && (
              <Typography
                color={(theme) => theme.palette.text.disabled}
                sx={{ mt: 2 }}
                textAlign="center"
              >
                Not added to any project
              </Typography>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* ============================ */}

      <MuiDialog
        open={delUserOpen}
        onToggle={closeDelUserDialog}
        onAgree={() => handleUpdateProjectTeam("remove", selectedUser)}
        loading={loadingBtn}
        title="Remove member"
        message={`Are you sure you want to remove ${
          selectedUser?.first_name + " " + selectedUser?.last_name
        } from project?`}
        ExtraComponent={
          <>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={removeProjectUserType}
              onChange={(e) => setRemoveProjectUserType(e.target.value)}
            >
              <FormControlLabel
                value="remove"
                control={<Radio />}
                label="Delete all tasks created by this user and remove him as task member"
              />
              <FormControlLabel
                value="assign"
                control={<Radio />}
                label="Assign the tasks, this user created, to some other team member"
              />
            </RadioGroup>
            {removeProjectUserType === "assign" && (
              <MuiAutoComplete
                onChange={(val) => setAutoCompleteValue(val)}
                value={autoCompleteValue}
                label="Team Members"
                options={getProjectTeamForRemoveUser()}
              />
            )}
          </>
        }
      />
    </div>
  );
}
