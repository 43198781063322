import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import {
  _create_checklist_template,
  _delete_template,
  _get_templates_data_checklist,
} from "src/DAL/taskChecklist";
import { Label, MuiDialog } from "src/components";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import ViewTemplateDialog from "./ViewTemplateDialog";

export default function TemplateDialog({ open, onClose, task, getTaskDetail }) {
  const { enqueueSnackbar } = useSnackbar();

  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingApply, setLoadingApply] = useState(null);
  const [currentTab, setCurrentTab] = useState("all");
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const closeViewDialog = () => {
    setViewDialogOpen(false);
    setSelectedTemplate(null);
  };
  //   const [loadingDelete, setLoadingDelete] = useState(false);
  //   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  //   const openDelete = (template) => {
  //     setSelectedTemplate(template);
  //     setOpenDeleteDialog(true);
  //   };

  //   const closeDelete = () => {
  //     setOpenDeleteDialog(false);
  //     setLoadingDelete(false);
  //     setSelectedTemplate(null);
  //   };

  //   const deleteTemplate = async () => {
  //     if (!selectedTemplate) return;
  //     setLoadingDelete(true);

  //     const req_data = { checklist_template_id: selectedTemplate._id };
  //     const result = await _delete_template(req_data);

  //     if (result.code === 200) {
  //       setTemplates((prevTemplates) =>
  //         prevTemplates.filter(
  //           (template) => template._id !== selectedTemplate._id
  //         )
  //       );
  //     } else {
  //       console.error(result.message);
  //     }
  //     closeDelete();
  //   };

  const getTemplateslisting = async () => {
    setLoading(true);
    const result = await _get_templates_data_checklist();
    if (result.code == 200) {
      setTemplates(result.checklist_template_list);
      setLoading(false);
    } else {
      console.error(result.message);
      setLoading(false);
    }
  };
  const applyTemplate = async (data) => {
    setLoadingApply(data?._id);
    // if (task?._id === data?.task_id) {
    //   enqueueSnackbar(
    //     "Applying a template within the same task is not allowed.",
    //     {
    //       variant: "warning",
    //     }
    //   );
    //   setLoadingApply(null);
    //   onClose();
    //   return;
    // }
    const req_data = {
      checklist_template_id: data?._id,
      task_id: task?._id,
    };
    const result = await _create_checklist_template(req_data);
    if (result.code == 200) {
      enqueueSnackbar("Template created successfully.", {
        variant: "success",
      });
      setLoadingApply(null);
      onClose();
      getTaskDetail();
    } else {
      console.error(result.message);
      setLoadingApply(null);
      onClose();
    }
  };

  //   -------------------- Counts
  const allCount = templates.length;

  const workspaceCount = templates.filter(
    (template) => template.project_id?.workspace?.id === task?.workspace?.id
  ).length;

  const projectCount = templates.filter(
    (template) => template.project_id?._id === task?.project?.id
  ).length;
  // ------------------------------
  useEffect(() => {
    if (open) {
      getTemplateslisting();
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
          setTemplates([]);
        }}
        fullWidth
        maxWidth="sm"
      >
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={400}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" noWrap>
                  Checklist Templates
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    onClose();
                    setTemplates([]);
                  }}
                >
                  <Iconify
                    icon="ion-close"
                    sx={{
                      height: "24px",
                      width: "24px",
                    }}
                  />
                </IconButton>
              </Box>
            </DialogTitle>

            <DialogContent sx={{ minHeight: "335px" }}>
              {templates.length < 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    fontSize="12px"
                    color="text.disabled"
                    fontWeight="500"
                  >
                    No templates found !
                  </Typography>
                </div>
              )}
              {/* -------------------------------------- Tabs */}
              <div className="mb-2">
                <Tabs
                  value={currentTab}
                  onChange={(e, val) => setCurrentTab(val)}
                >
                  <Tab value="all" label={`All Templates (${allCount})`} />
                  <Tab
                    value="workspace"
                    label={`This Workspace (${workspaceCount})`}
                  />
                  <Tab
                    value="project"
                    label={`This Project (${projectCount})`}
                  />
                </Tabs>
              </div>

              {/* --------------------------------------- all tab */}
              {currentTab === "all" &&
                (templates.length > 0 ? (
                  templates.map((template, index) => (
                    <Paper
                      className="template-paper"
                      key={template._id}
                      variant="outlined"
                      onClick={() => {
                        setViewDialogOpen(true);
                        setSelectedTemplate(template);
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-2">
                          <Tooltip title="Checklist Tasks">
                            <div className="d-flex">
                              <Label
                                sx={{
                                  bgcolor: (theme) =>
                                    theme.palette.primary.main,
                                  color: "white",
                                  height: "18px",
                                  minWidth: "18px",
                                  cursor: "pointer",
                                  padding: "0px",
                                }}
                                variant="ghost"
                                color="success"
                              >
                                {template.items.length}
                              </Label>
                            </div>
                          </Tooltip>
                          <Typography
                            fontSize="12px"
                            fontWeight="500"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "300px",
                            }}
                          >
                            {template.name}
                          </Typography>
                        </div>

                        <div className="d-flex align-items-center gap-1">
                          {/* <Tooltip title="Delete Template">
                            <span>
                              <IconButton
                                className="delete-temp"
                                size="small"
                                onClick={() => openDelete(template)}
                              >
                                <Iconify
                                  icon="fluent:delete-24-regular"
                                  sx={{ height: "16px", width: "16px" }}
                                />
                              </IconButton>
                            </span>
                          </Tooltip> */}
                          <Tooltip title="Created At">
                            <Typography fontSize="10px" fontWeight="400">
                              {moment(template?.createdAt).format(
                                "DD MMM YYYY hh:mm A"
                              )}
                            </Typography>
                          </Tooltip>

                          <LoadingButton
                            loading={loadingApply === template._id}
                            size="small"
                            variant="text"
                            onClick={(e) => {
                              e.stopPropagation();
                              applyTemplate(template);
                            }}
                          >
                            choose
                          </LoadingButton>
                        </div>
                      </div>
                    </Paper>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      fontSize="12px"
                      color="text.disabled"
                      fontWeight="500"
                    >
                      No templates found !
                    </Typography>
                  </div>
                ))}
              {/* ----------------------------------------workspace tab */}
              {currentTab === "workspace" &&
                (templates.length > 0 ? (
                  templates
                    .filter(
                      (template) =>
                        template.project_id?.workspace?.id ===
                        task?.workspace.id
                    )
                    .map((template, index) => (
                      <Paper
                        className="template-paper"
                        key={template._id}
                        variant="outlined"
                        onClick={() => {
                          setViewDialogOpen(true);
                          setSelectedTemplate(template);
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center gap-2">
                            <Tooltip title="Checklist Tasks">
                              <div className="d-flex">
                                <Label
                                  sx={{
                                    bgcolor: (theme) =>
                                      theme.palette.primary.main,
                                    color: "white",
                                    height: "18px",
                                    minWidth: "18px",
                                    cursor: "pointer",
                                    padding: "0px",
                                  }}
                                  variant="ghost"
                                  color="success"
                                >
                                  {template.items.length}
                                </Label>
                              </div>
                            </Tooltip>
                            <Typography
                              fontSize="12px"
                              fontWeight="500"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "300px",
                              }}
                            >
                              {template.name}
                            </Typography>
                          </div>

                          <div className="d-flex align-items-center gap-1">
                            <Tooltip title="Created At">
                              <Typography fontSize="10px" fontWeight="400">
                                {moment(template?.createdAt).format(
                                  "DD MMM YYYY hh:mm A"
                                )}
                              </Typography>
                            </Tooltip>

                            <LoadingButton
                              loading={loadingApply === template._id}
                              size="small"
                              variant="text"
                              onClick={() => applyTemplate(template)}
                            >
                              choose
                            </LoadingButton>
                          </div>
                        </div>
                      </Paper>
                    ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ margin: "20% auto" }}>
                      <Typography
                        fontSize="12px"
                        color="text.disabled"
                        fontWeight="500"
                      >
                        No templates found in this workspace !
                      </Typography>
                    </div>
                  </div>
                ))}

              {/* ------------------------------------------project tab */}
              {currentTab === "project" &&
                (templates.length > 0 ? (
                  templates
                    .filter(
                      (template) => template.project_id._id === task?.project.id
                    )
                    .map((template, index) => (
                      <Paper
                        className="template-paper"
                        key={template._id}
                        variant="outlined"
                        onClick={() => {
                          setViewDialogOpen(true);
                          setSelectedTemplate(template);
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center gap-2">
                            <Tooltip title="Checklist Tasks">
                              <div className="d-flex">
                                <Label
                                  sx={{
                                    bgcolor: (theme) =>
                                      theme.palette.primary.main,
                                    color: "white",
                                    height: "18px",
                                    minWidth: "18px",
                                    cursor: "pointer",
                                    padding: "0px",
                                  }}
                                  variant="ghost"
                                  color="success"
                                >
                                  {template.items.length}
                                </Label>
                              </div>
                            </Tooltip>
                            <Typography
                              fontSize="12px"
                              fontWeight="500"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "300px",
                              }}
                            >
                              {template.name}
                            </Typography>
                          </div>

                          <div className="d-flex align-items-center gap-1">
                            <Tooltip title="Created At">
                              <Typography fontSize="10px" fontWeight="400">
                                {moment(template?.createdAt).format(
                                  "DD MMM YYYY hh:mm A"
                                )}
                              </Typography>
                            </Tooltip>

                            <LoadingButton
                              loading={loadingApply === template._id}
                              size="small"
                              variant="text"
                              onClick={() => applyTemplate(template)}
                            >
                              choose
                            </LoadingButton>
                          </div>
                        </div>
                      </Paper>
                    ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ margin: "20% auto" }}>
                      <Typography
                        fontSize="12px"
                        color="text.disabled"
                        fontWeight="500"
                      >
                        No templates found in this project !
                      </Typography>
                    </div>
                  </div>
                ))}
            </DialogContent>
          </>
        )}
      </Dialog>

      <ViewTemplateDialog
        open={viewDialogOpen}
        onClose={closeViewDialog}
        template={selectedTemplate}
        task={task}
      />
      {/* Delete Confirmation Dialog --------- */}
      {/* <MuiDialog
        loading={loadingDelete}
        open={openDeleteDialog}
        onToggle={closeDelete}
        onAgree={deleteTemplate}
        title="Delete Template"
        itemName={selectedTemplate?.name}
        message="Are you sure you want to delete this template?"
      /> */}
    </>
  );
}
