import React, { useEffect, useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Typography,
  Radio,
  FormControl,
  Checkbox,
  FormControlLabel as MUIFormControlLabel,
  Card,
  CardContent,
  Button,
  Paper,
  Avatar,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { capitalCase } from "change-case";
import { s3baseUrl } from "src/config";
import Iconify from "src/components/Iconify";
import { useSnackbar } from "notistack";

export default function NotifySelectedUser({
  taskTeam,
  projectTeam,
  // save button props -----------
  addCommentLoading,
  handleSendComment,
  setEditingCommentId,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [option, setOption] = useState("all");
  const [selectedMembers, setSelectedMembers] = useState([]);
  console.log(selectedMembers, "radio");
  const [commentType, setCommentType] = useState("0");

  const handleChangePrivacy = (event, newType) => {
    if (newType !== null) {
      setCommentType(newType);
    }
  };

  const handleRadioChange = (event) => {
    setOption(event.target.value);
  };

  const handleCheckboxChange = (event, userId) => {
    if (event.target.checked) {
      setSelectedMembers([...selectedMembers, userId]);
    } else {
      setSelectedMembers(selectedMembers.filter((id) => id !== userId));
    }
  };
  const handleSave = () => {
    console.log(selectedMembers, "_ids selected_Members");
    handleSendComment(selectedMembers, commentType , option);
    setEditingCommentId("");
  };

  useEffect(() => {
    if (commentType === "1") {
      setOption("none");
    } else if (commentType === "0") {
      setOption("all");
    }
  }, [commentType]);

  return (
    <>
      <div className="notify-selected-users">
        <div className="d-flex align-items-center gap-3">
          <div className="d-flex align-items-center gap-1">
            <LoadingButton
              loading={addCommentLoading}
              variant="contained"
              size="small"
              sx={{ mt: 1 }}
              onClick={handleSave}
            >
              Save
            </LoadingButton>
            <Button
              size="small"
              color="inherit"
              variant="text"
              sx={{ mt: 1 }}
              onClick={() => {
                setEditingCommentId("");
              }}
            >
              Cancel
            </Button>
          </div>
          {/* Private / Public ---------------------------------------- */}
          <div style={{ mt: "8px" }}>
            <Tooltip title="Comment Privacy">
              <ToggleButtonGroup
                size="small"
                value={commentType}
                exclusive
                onChange={handleChangePrivacy}
                aria-label="privacy setting"
              >
                <ToggleButton color="primary" value="0">
                  Public
                </ToggleButton>
                <ToggleButton color="info" value="1">
                  Private
                </ToggleButton>
              </ToggleButtonGroup>
            </Tooltip>
          </div>
        </div>

        {/* Radio --------------------------------------------------- */}
        {commentType === "0" && (
          <div className="d-flex align-items-center mt-2">
            <Typography fontWeight={500} paddingRight="15px" variant="body2">
              Notify :
            </Typography>
            <FormControl className="d-flex">
              <RadioGroup
                row
                name="notify-options"
                value={option}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="all"
                  className="custom-radio"
                  control={<Radio size="small" />}
                  // labelPlacement="start"
                  label={<span style={{ fontSize: "12px" }}>All</span>}
                />
                <FormControlLabel
                  value="none"
                  className="custom-radio"
                  control={<Radio size="small" />}
                  // labelPlacement="start"
                  label={<span style={{ fontSize: "12px" }}>None</span>}
                />
                <FormControlLabel
                  value="specific"
                  className="custom-radio"
                  control={<Radio size="small" />}
                  // labelPlacement="start"
                  label={<span style={{ fontSize: "12px" }}>Specific</span>}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
      </div>
      {/* when "Specific" is selected -------------------------------------------------*/}
      {option === "specific" && (
        <>
          {taskTeam?.length > 0 ? (
            <Paper elevation={2} sx={{ mt: 2, p: 1 }}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <Typography
                  fontSize="12px"
                  fontWeight="500"
                  className="d-flex align-items-center"
                >
                  <Iconify
                    color="primary.main"
                    fontSize="14px"
                    marginRight="5px"
                    icon="zondicons:notification"
                  />
                  Notify specific members
                </Typography>
                <div>
                  <Button
                    size="small"
                    variant="text"
                    color="inherit"
                    onClick={() =>
                      setSelectedMembers(
                        taskTeam.map((member) => member.user_id)
                      )
                    }
                  >
                    Select All
                  </Button>
                  <Button
                    size="small"
                    variant="text"
                    color="inherit"
                    onClick={() => setSelectedMembers([])}
                  >
                    Clear All
                  </Button>
                </div>
              </div>
              {taskTeam.map((member) => (
                <label key={member.user_id}>
                  <div className="user-card">
                    <Checkbox
                      className="custom-checkbox"
                      size="small"
                      checked={selectedMembers.includes(member.user_id)}
                      onChange={(event) =>
                        handleCheckboxChange(event, member.user_id)
                      }
                    />
                    <div className="d-flex align-items-center gap-1">
                      <Avatar
                        src={s3baseUrl + member.image}
                        sx={{ width: "25px", height: "25px" }}
                      />
                      <Typography variant="body2" sx={{ userSelect: "none" }}>
                        {capitalCase(
                          member.first_name //+ " " + member.last_name
                        )}
                      </Typography>
                    </div>
                  </div>
                </label>
              ))}
            </Paper>
          ) : (
            <div className="pt-3 d-flex align-items-center justify-content-center gap-1">
              <Iconify
                icon="mdi:user-off-outline"
                sx={{ color: "text.disabled", fontSize: "16px" }}
              />
              <Typography sx={{ color: "text.disabled", fontSize: "12px" }}>
                There are no team members assigned to this task
              </Typography>
            </div>
          )}
        </>
      )}
    </>
  );
}
