import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Autocomplete,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { _add_planned_time } from "src/DAL/taskDetailDrawer";
import { LoadingButton } from "@mui/lab";
import Iconify from "src/components/Iconify";

const timeOptions = [
  "05 min",
  "10 min",
  "15 min",
  "30 min",
  "45 min",
  "1 hr",
  "1 hr 30 min",
  "2 hr",
  "2 hr 30 min",
  "3 hr",
  "3 hr 30 min",
  "4 hr",
  "5 hr",
  "6 hr",
  "7 hr",
  "8 hr",
  "9 hr",
  "10 hr",
  "11 hr",
  "12 hr",
  "13 hr",
  "14 hr",
  "15 hr",
  "16 hr",
  "17 hr",
  "18 hr",
  "19 hr",
  "20 hr",
];
// This Component is called in Timer and TimeBlock
export default function PlannedTimeDialog({
  userId,
  task,
  openPlannedTimeModal,
  setOpenPlannedTimeModal,
  plannedTime,
  setPlannedTime,
  getTaskDetail,
  handleStartPause, // only this prop is passed from Timer
}) {
  const [loading, setLoading] = useState(false);
  // const [showInput, setShowInput] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const validatePlannedTime = (time) => {
    const timeRegex = /^([0-9]+):([0-5][0-9])$/;
    if (!time || !timeRegex.test(time) || time === "00:00") {
      enqueueSnackbar("Please enter a valid time (e.g., 01:30 HH:MM).", {
        variant: "warning",
      });
      return false;
    }
    return true;
  };

  const addPlannedTime = async () => {
    if (plannedTime) {
      if (!validatePlannedTime(plannedTime)) {
        return;
      }
    } else {
    }
    setLoading(true);
    const req_obj = {
      task_id: task?._id,
      user_id: userId,
      planned_time: convertTimeToSeconds(plannedTime),
    };

    const response = await _add_planned_time(req_obj);
    if (response.code === 200) {
      setLoading(false);
      setOpenPlannedTimeModal(false);
      getTaskDetail();
      // setShowInput(false);
      if (handleStartPause) {
        handleStartPause();
      } else {
        enqueueSnackbar("Planned time saved successfully!", {
          variant: "success",
        });
      }
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setLoading(false);
      setOpenPlannedTimeModal(false);
    }
  };

  // Autocomplete --------------------------------------------
  const convertTimeToSeconds = (time) => {
    if (!time) return 0;
    // format ("1 hour", "1 hr 30 min" to 01:30)
    const hourMatch = time.match(
      /^(\d{1,2})\s*(hr|hrs|hour|hours)\s*(\d{1,2})?\s*(min)?/
    );
    if (hourMatch) {
      const hours = parseInt(hourMatch[1], 10);
      const minutes = hourMatch[3] ? parseInt(hourMatch[3], 10) : 0;
      return hours * 3600 + minutes * 60;
    }

    // format ("45 min" to 00:45)
    const minuteMatch = time.match(/^(\d{1,2})\s*(min)/);
    if (minuteMatch) {
      const minutes = parseInt(minuteMatch[1], 10);
      return minutes * 60;
    }

    // format ("02:00" to 02:00)
    const timeMatch = time.match(/^(\d{1,2}):(\d{2})$/);
    if (timeMatch) {
      const hours = parseInt(timeMatch[1], 10);
      const minutes = parseInt(timeMatch[2], 10);
      return hours * 3600 + minutes * 60;
    }
    return 0;
  };
  const convertSecondsToHHmm = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <>
      <Dialog
        fullWidth
        open={openPlannedTimeModal}
        onClose={() => setOpenPlannedTimeModal(false)}
      >
        <DialogTitle sx={{ pb: 1 }}>Enter your planned time</DialogTitle>
        <DialogContent className="planned-time-content">
          <div>
            {/* {showInput == false ? (
              <div className="d-flex align-items-center">
                <Button variant="text" onClick={() => setShowInput(true)}>
                  Set Planned Time
                </Button>
                <Typography fontSize="12px" color="#637381">
                  or click START TASK to begin without planned time.
                </Typography>
              </div>
            ) : ( */}
            <Autocomplete
              size="small"
              sx={{ pt: 1, pb: 1 }}
              options={timeOptions}
              value={plannedTime || ""}
              onChange={(event, selectedOption) => {
                if (!selectedOption) {
                  setPlannedTime("");
                } else {
                  const inSeconds = convertTimeToSeconds(selectedOption);
                  const hhmmFormat = convertSecondsToHHmm(inSeconds);
                  setPlannedTime(hhmmFormat);
                }
              }}
              renderOption={(props, option) => (
                <span {...props} style={{ fontWeight: "500" }}>
                  {option}
                </span>
              )}
              // onBlur={() => {
              //   if (!plannedTime) {
              //     setShowInput(false); // Hide if no value
              //   }
              // }}
              renderInput={(option) => (
                <TextField
                  {...option}
                  value={plannedTime}
                  label="Set planned time"
                  placeholder="hh:mm"
                  type="text"
                  fullWidth
                  inputProps={{
                    ...option.inputProps,
                    maxLength: 5,
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    const parts = value.split(":");
                    if (parts.length > 2) {
                      value = value.substring(0, value.lastIndexOf(":"));
                    }
                    if (value.length === 2 && !value.includes(":")) {
                      value += ":";
                    }
                    if (parts.length === 2) {
                      let hours = parts[0];
                      let minutes = parts[1];
                      if (parseInt(minutes, 10) > 59) {
                        minutes = "59";
                      }
                      value = `${hours}:${minutes}`;
                    }
                    if (value.endsWith(":") && value.length > 3) {
                      value = value.substring(0, value.length - 1);
                    }
                    setPlannedTime(value);
                  }}
                  onKeyDown={(e) => {
                    const validKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      "Tab",
                      "Enter",
                    ];
                    if (!validKeys.includes(e.key) && !/^[0-9]$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
              freeSolo
              fullWidth
            />
            {/* )} */}
          </div>
          <div className="points-box">
            <Typography sx={{ fontSize: "14px", fontWeight: "500", pb: 1 }}>
              Why need planned time?
            </Typography>
            <Typography className="points-planned-time">
              <Iconify
                icon="mingcute:check-2-fill"
                className="points-check-icon"
              />
              Gives a clear view of how your time is spent on tasks.
            </Typography>
            <Typography className="points-planned-time">
              <Iconify
                icon="mingcute:check-2-fill"
                className="points-check-icon"
              />
              Maximizes productivity, Helps avoid distractions and maintain
              focus.
            </Typography>
            <Typography className="points-planned-time">
              <Iconify
                icon="mingcute:check-2-fill"
                className="points-check-icon"
              />
              Reduces last-minute rush, cuts down on stress, and helps meet
              deadlines
            </Typography>
            <Typography className="points-planned-time">
              <Iconify
                icon="mingcute:check-2-fill"
                className="points-check-icon"
              />
              Track time on tasks to find areas for improvement and increase
              efficiency.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "6px 24px 16px" }}>
          {!plannedTime && (
            <div className="d-flex align-items-center me-auto">
              {/* <Iconify
               icon="mdi:progress-clock"
                color="primary.main"
                fontSize="18px"
              /> */}
              <Typography
                sx={{
                  mr: 2,
                  ml: 1,
                  mb: 0,
                  color: "primary.main",
                  fontSize: "12px",
                  fontWeight: 500,
                  userSelect: "none",
                }}
              >
                Proceed without planned time
                {/* {"Proceed without planned time".toUpperCase()} */}
              </Typography>
            </div>
          )}
          <LoadingButton
            loading={loading}
            color="primary"
            variant="contained"
            onClick={addPlannedTime}
          >
            Start Task
          </LoadingButton>
          <Button
            color="primary"
            onClick={() => setOpenPlannedTimeModal(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
