import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Tooltip,
  Grid,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Flag as FlagIcon, Close as CloseIcon } from "@mui/icons-material";
import RichEditor from "src/components/editor/RichEditor";
import MembersButton from "src/components/TaskDetailDrawer/components/MembersButton";
import { string_with_limited_length } from "src/utils/common";
import { capitalCase } from "change-case";
import { CircularLoader, Iconify } from "src/components";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import DatePicker from "react-datepicker";
import { parseISO } from "date-fns";
import DatePickerInput from "./DatePickerInput";

const MAP_TASK_PRIORITY_OPTIONS = [
  { label: "Low", value: 3, color: "#969292" },
  { label: "Medium", value: 2, color: "#54D62C" },
  { label: "High", value: 1, color: "#ffd500" },
  { label: "Critical", value: 0, color: "#FF4842" },
];

const MAP_TASK_STATUS_OPTIONS = [
  { label: "Pending", value: 0, color: "#FFC107" },
  { label: "In Progress", value: 2, color: "#3366FF" },
  { label: "Ready for Testing", value: 11, color: "#a4bdfc" },
  { label: "Completed", value: 3, color: "#00AB55" },
  { label: "Cancelled", value: 1, color: "#FF4842" },
];

const NewTaskCreation = ({
  open,
  onClose,
  onCreateTask,
  sectionTitle,
  projectTeam,
  projectDetail,
  loading,
}) => {
  const autoFocusRef = useRef(null);
  const [newTaskName, setNewTaskName] = useState("");
  const [newTaskPriority, setNewTaskPriority] = useState(3); // Default to Low
  const [newTaskStatus, setNewTaskStatus] = useState("0"); // Default to Pending
  const [uploadedImages, setUploadedImages] = useState([]);
  const [endTime, setEndTime] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [addFilesLoading, setAddFilesLoading] = useState(false);
  const [localPreview, setLocalPreview] = useState([]);
  // ----------------------------------------------------
  const [openTargetDate, setOpenTargetDate] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const MAX_IMAGE_SIZE = 1048576; // 1Mb in byts

  const handleOpen = () => {
    // if (!disabled) {
    setOpenTargetDate(true);
    // }
  };

  const handleImageUpload = async (files) => {
    console.log(files, "files");
    // const MAX_SIZE = 1048576; // 1MB in bytes
    // for (let i = 0; i < files.length; i++) {
    //   const file = files[i];
    //   if (file.size > MAX_SIZE) {
    //     enqueueSnackbar("Image upload size is up to 1 MB", {
    //       variant: "error",
    //     });
    //     return;
    //   }
    // }
    setAddFilesLoading(true);
    const newImages = [];
    const preview = [];
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = () => {
        if (file.size <= MAX_IMAGE_SIZE) {
          newImages.push(file);
        }
        // only for Preview
        preview.push({
          name: file.name,
          size: file.size,
          type: file.type,
          preview: reader.result,
        });
        if (preview.length === files.length) {
          setUploadedImages((prevImages) => [...prevImages, ...newImages]);
          console.log(newImages, "newImages");

          setLocalPreview((prevImages) => [...prevImages, ...preview]);
          setAddFilesLoading(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragLeave = (e) => {
    if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget)) {
      return;
    }
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files) {
      handleImageUpload(files);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleCreateTask = (event) => {
    event.preventDefault();
    const taskData = {
      task_title: newTaskName.trim(),
      task_description: description,
      status: true,
      task_type: "0",
      team: selectedTeam, // Pass the selected team
      end_date: endTime,
      task_status: parseInt(newTaskStatus, 10),
      priority: newTaskPriority,
    };
    onCreateTask(event, taskData, uploadedImages);
    setNewTaskName("");
    setNewTaskPriority(3); // Reset to Low
    setNewTaskStatus("0"); // Reset to Pending
    setUploadedImages([]); // Clear uploaded images
    setEndTime("");
    setDescription("");
    setSelectedTeam([]); // Clear selected team
  };

  const handleRemoveImage = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setLocalPreview((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleTeamChange = (targetUser, taskTeam, isSelected, action) => {
    if (action === "add") {
      if (
        !selectedTeam.some((member) => member.user_id === targetUser.user_id)
      ) {
        setSelectedTeam([...selectedTeam, targetUser]);
      }
    } else if (action === "remove") {
      setSelectedTeam(
        selectedTeam.filter((member) => member.user_id !== targetUser.user_id)
      );
    }
  };

  const handleFilterTimes = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  useEffect(() => {
    if (open) {
      // use delay for force focus the input when dialog open
      setTimeout(() => {
        autoFocusRef.current?.focus();
      }, 100);
    }
  }, [open]);

  return (
    <Dialog
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      {/* {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <CircularProgress />
        </div>
      )} */}

      {/* {!loading && ( */}
      <>
        <div className="d-flex align-items-center justify-content-between border-bottom px-3 py-1">
          <div className="d-flex align-items-center">
            <Typography className="fs-12 fw-5">
              {string_with_limited_length(projectDetail.workspace_name, 30)}
            </Typography>
            <Iconify
              icon="grommet-icons:next"
              sx={{ m: 1, fontSize: "10px" }}
            />
            <Typography className="fs-12 fw-5">
              {string_with_limited_length(capitalCase(projectDetail.title), 30)}
            </Typography>
            <Iconify
              icon="grommet-icons:next"
              sx={{ m: 1, fontSize: "10px" }}
            />
            <Typography className="fs-12 fw-5">
              {string_with_limited_length(capitalCase(sectionTitle), 30)}
            </Typography>
          </div>
          <div className="">
            <Tooltip title="Close" onClick={onClose}>
              <span>
                <Iconify
                  icon="maki:cross"
                  sx={{ cursor: "pointer", fontSize: "18px" }}
                />
              </span>
            </Tooltip>
          </div>
        </div>
        <DialogContent
          sx={{
            padding: "10px 24px",
            "&::-webkit-scrollbar": { display: "none" },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
            Task Title
          </Typography>
          <form onSubmit={handleCreateTask}>
            <Box sx={{ marginBottom: "12px" }}>
              <Box
                sx={{
                  width: "100%",
                  padding: "6px 10px",
                  border: "1px solid #dcdcde",
                  borderRadius: "5px",
                  overflow: "hidden",
                  minHeight: "36px",
                  fontSize: "0.75rem",
                }}
              >
                <input
                  autoFocus
                  ref={autoFocusRef}
                  type="text"
                  placeholder="Add Task Title"
                  value={newTaskName}
                  onChange={(e) => setNewTaskName(e.target.value)}
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    paddingTop: "2px",
                    backgroundColor: "transparent",
                  }}
                  required
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "8fr 4fr",
                gap: 3,
              }}
            >
              <div>
                {/* Priority and Status Section */}
                <Box sx={{ mb: 2, gap: 3 }}>
                  <Box sx={{ flex: 1, mb: 2 }}>
                    <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                      Priority
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      {MAP_TASK_PRIORITY_OPTIONS.map((priority) => (
                        <Box
                          key={priority.value}
                          onClick={() => setNewTaskPriority(priority.value)}
                          sx={{
                            minHeight: "36px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            padding: "6px 12px",
                            backgroundColor:
                              newTaskPriority === priority.value
                                ? "#ebf8f2"
                                : "#F5F5F5",
                            // border: "1px solid",
                            // borderColor:
                            //   newTaskPriority === priority.value
                            //     ? priority.color
                            //     : "#F5F5F5",
                            borderRadius: "5px",
                          }}
                        >
                          <FlagIcon
                            sx={{
                              color: priority.color,
                              fontSize: "18px",
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              color: "black",
                            }}
                          >
                            {priority.label}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  {/* <Box sx={{ flex: 1, mb: 2 }}>
                  <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                    Status
                  </Typography>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    {MAP_TASK_STATUS_OPTIONS.map((status) => (
                      <Box
                        key={status.value}
                        onClick={() =>
                          setNewTaskStatus(status.value.toString())
                        }
                        sx={{
                          minHeight: "36px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          padding: "6px 12px",
                          backgroundColor: "#F5F5F5",
                          border: "1px solid",
                          borderColor:
                            newTaskStatus === status.value.toString()
                              ? status.color
                              : "#F5F5F5",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "black",
                            // fontWeight:
                            //   newTaskStatus === status.value.toString()
                            //     ? "bold"
                            //     : "normal",
                          }}
                        >
                          {status.label}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box> */}
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <Box sx={{ mb: 2 }}>
                      <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                        Target Date
                      </Typography>
                      {/* Date Picker ----------------------- */}
                      <DatePicker
                        selected={endTime ? parseISO(endTime) : ""}
                        onChange={(date) => {
                          setEndTime(date ? date.toISOString() : null);
                          setOpenTargetDate(false);
                        }}
                        filterTime={handleFilterTimes} // filter time 12Hrs
                        minDate={new Date()} // disable old days
                        showTimeSelect
                        timeIntervals={5}
                        dateFormat="dd MMM yyyy HH:mm"
                        open={openTargetDate}
                        onClickOutside={() => setOpenTargetDate(false)}
                        customInput={
                          <DatePickerInput value={endTime} open={handleOpen} />
                        }
                      />
                    </Box>
                  </Grid>

                  {/* Members Section */}
                  <Grid item xs={6} md={4}>
                    <Box sx={{ mb: 2 }}>
                      <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                        Members
                      </Typography>
                      <MembersButton
                        projectTeam={projectTeam}
                        taskTeam={selectedTeam}
                        onChange={handleTeamChange}
                        canEditTask={true}
                        loadingMember={false}
                      />
                    </Box>
                  </Grid>
                </Grid>

                {/* Description Section */}
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                    Description
                  </Typography>
                  <RichEditor
                    value={description}
                    setValue={setDescription}
                    editorHeight="150px"
                  />
                </Box>
              </div>

              {/* Right Column:------------------------------- */}
              <div
                className="right-side-attachments"
                style={{ height: "100%" }}
              >
                {/* Image Upload Section */}
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Attachments
                </Typography>
                <div
                  // onDrop={handleDrop}
                  // onDragOver={handleDragOver}
                  // onDragLeave={handleDragLeave}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    border: isDragging
                      ? "2px dashed #00AB55"
                      : "1px dashed #dcdcde",
                    padding: "8px 3px 8px 8px",
                    borderRadius: "5px",
                    height: "91.5%",
                    backgroundColor: isDragging ? "#ebf8f2" : "white",
                    position: "relative",
                  }}
                >
                  {isDragging && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        padding: "10px",
                        borderRadius: "8px",
                        color: "#929fac",
                        fontWeight: "600",
                        fontSize: "16px",
                        zIndex: 12,
                        textAlign: "center",
                      }}
                    >
                      Drop Here
                    </div>
                  )}
                  {/* -------------------------------------------------------- */}
                  {!localPreview.length && !isDragging && (
                    <div
                      className="d-flex justify-content-center pointer  h-100"
                      onClick={() =>
                        document.getElementById("image-upload").click()
                      }
                    >
                      <Box className="text-center m-auto">
                        <Iconify
                          sx={{
                            fontSize: "30px",
                            color: "#929fac",
                          }}
                          icon="material-symbols-light:attach-file-add"
                        />
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "#929fac",
                          }}
                        >
                          Drag & Drop
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontWeight: 400,
                            color: "#929fac",
                          }}
                        >
                          Recommended File upload size is up to <b>1 MB</b>
                        </Typography>
                      </Box>
                    </div>
                  )}
                  <label htmlFor="image-upload">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e.target.files)}
                      style={{ display: "none" }}
                      id="image-upload"
                      multiple
                    />
                    {localPreview.length > 0 && (
                      <div className="d-flex justify-content-end">
                        <Button
                          endIcon={<Iconify icon="stash:plus-solid" />}
                          size="small"
                          variant="text"
                          onClick={() =>
                            document.getElementById("image-upload").click()
                          }
                        >
                          Add File
                        </Button>
                      </div>
                    )}
                  </label>
                  {!isDragging && (
                    <div
                      style={{
                        maxHeight: "46vh",
                        overflowY: "auto",
                        paddingRight: "5px",
                        scrollbarWidth: "thin",
                      }}
                    >
                      {localPreview.map((image, index) => (
                        <Paper
                          variant="outlined"
                          key={index}
                          sx={{
                            width: "100%",
                            marginTop: "5px",
                            padding: "3px",
                            display: "flex",
                            gap: "8px",
                            borderRadius: "5px",
                            backgroundColor:
                              image.size > MAX_IMAGE_SIZE && "#fbf6f6",
                          }}
                        >
                          <div style={{ flexShrink: 0 }}>
                            <img
                              src={image.preview}
                              alt={image.name}
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "5px",
                                objectFit: "cover",
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flex: 1,
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <div>
                                {image.size > MAX_IMAGE_SIZE && (
                                  <Typography
                                    sx={{
                                      fontSize: "10px",
                                      fontWeight: 500,
                                      color: "#bf2626",
                                    }}
                                  >
                                    This image is too big to upload.
                                  </Typography>
                                )}
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: 500,
                                    wordBreak: "break-word",
                                  }}
                                  title={image.name}
                                >
                                  {image.name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "9px",
                                  }}
                                >
                                  Size:{" "}
                                  {image.size > MAX_IMAGE_SIZE
                                    ? (image.size / (1024 * 1024)).toFixed(2) +
                                      " MB"
                                    : (image.size / 1024).toFixed(2) +
                                      " KB"}{" "}
                                  {image.size > MAX_IMAGE_SIZE &&
                                    "/ Max upload size is 1 MB"}
                                </Typography>
                              </div>
                              {/* )} */}
                            </div>

                            {/* Icons */}
                            <div
                              className="d-flex align-items-center ms-1 remove-file-name"
                              onClick={() => handleRemoveImage(index)}
                            >
                              <Tooltip title="Remove file">
                                <span>
                                  <Iconify
                                    // icon="maki:cross"
                                    icon="weui:delete-on-filled"
                                    sx={{
                                      fontSize: "16px",
                                      color: "#BF2828",
                                    }}
                                  />
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </Paper>
                      ))}
                    </div>
                  )}

                  {/* {addFilesLoading && <CircularProgress size={"15px"} />} */}
                </div>
              </div>
            </Box>

            {/* Actions */}
            <DialogActions sx={{ p: 0 }}>
              <Grid container className="d-flex justify-content-center">
                <Grid item xs={4} md={4} lg={4}>
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Create Task
                  </LoadingButton>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </DialogContent>
      </>
      {/* )} */}
    </Dialog>
  );
};

export default NewTaskCreation;
