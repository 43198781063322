import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { useSnackbar } from "notistack";
import {
  _add_task_comment,
  _all_tasks,
  _dashboard_tasks,
  _delete_task,
  _delete_task_comment,
  _edit_task,
  _task_comment_list,
  _update_task_comment,
  _update_task_end_date,
  _update_task_members,
  _update_task_priority,
  _update_task_status,
  _update_task_tags_in_task,
} from "src/DAL";

import { ALLOWED_TASK_FILES_WITH_ICONS } from "src/constants";
import { useAppContext } from "src/hooks";
import TaskDetailDialog from "src/components/TaskDetailDrawer/TaskDetailDrawer";
import DaashboardTaskCard from "src/pages/dashboard/components/DaashboardTaskCard";
import { MuiDialog } from "src/components";
import { useNavigate } from "react-router-dom";

/* <<--------------------------------------------------------->> */

function OpenTaskDetail({ task, getCalendarTasks, selectedUser, isWorking }) {
  const navigate = useNavigate();
  const [projectOptions, setProjectOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [taskDetailOpen, setTaskDetailOpen] = useState(false);
  const [projectTeam, setProjectTeam] = useState([]);
  const [projectTags, setProjectTags] = useState([]);
  const [editCommentTitle, setEditCommentTitle] = useState("");
  const [selectedComment, setSelectedComment] = useState();
  const [editingCommentId, setEditingCommentId] = useState("");
  const [taskDetailLoading, setTaskDetailLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(
    localStorage.getItem("filters")
      ? JSON.parse(localStorage.getItem("filters"))
      : {
          task_status: [],
          priority: [],
          search: "",
          project_ids: [],
          sort_status: "newest_first",
        }
  );
  const [processing, setProcessing] = useState(false);
  const { dispatch_get_user_profile, socketEmit } = useAppContext();
  const [newComment, setNewComment] = useState("");
  const [delTaskOpen, setDelTaskOpen] = useState(false);

  const [delCommentOpen, setDelCommentOpen] = useState(false);
  const [deletingTaskComment, setDeletingTaskComment] = useState(false);

  const onOpenTaskDetail = (task) => {
    setSelectedTask(task);
  };

  const handleOpenDelTask = async (data) => {
    openDelTask();
  };
  const openDelTask = () => {
    setDelTaskOpen(true);
  };
  const closeDelTask = () => {
    setDelTaskOpen(false);
  };

  const ucbOnDelTask = useCallback((data, workspace_id) => {
    handleOpenDelTask(data, workspace_id);
  }, []);

  const handleDeleteTask = async () => {
    let selectedTask = currentTask();
    console.log(selectedTask, "-on-del-task");
    const data = {
      task_id: selectedTask?._id,
      task_type: selectedTask?.task_type,
      task_order: selectedTask?.order,
    };
    console.log(data, "del-task-req");
    setProcessing(true);
    const result = await _delete_task(selectedTask?._id);
    if (result.code === 200) {
      if(getCalendarTasks){
        getCalendarTasks();
      }
      closeTaskDetail();
      closeDelTask();
      console.log(result, "res");
      setProcessing(false);
      enqueueSnackbar("Task Deleted Successfully", { variant: "success" });
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const closeTaskDetail = () => {
    setTaskDetailOpen(false);
    setEditingCommentId("");
    setNewComment("");
    setSelectedTask();
  };

  const onDelComment = (_comment) => {
    setSelectedComment(_comment);
    openDelCommentDialog();
  };
  const openDelCommentDialog = () => {
    setDelCommentOpen(true);
  };
  const closeDelCommentDialog = () => {
    setDelCommentOpen(false);
  };
  const handleDelTaskComment = async () => {
    console.log(selectedComment, "to-del");
    if (!selectedComment) {
      console.log("no selectedComment");
      return;
    }
    setDeletingTaskComment(true);

    const result = await _delete_task_comment(
      { comment_id: selectedComment._id },
      selectedTask?._id
    );
    if (result.code === 200) {
      console.log(result, "del-com-res");
      setDeletingTaskComment(false);
      closeDelCommentDialog();
      enqueueSnackbar("Comment deleted successfully", { variant: "success" });
      const updated_comments = (_comments) => {
        return _comments.filter(
          (item) => String(item._id) !== String(selectedComment._id)
        );
      };
      setSelectedTask((prev) => {
        return { ...prev, task_comment: updated_comments(prev.task_comment) };
      });
      // setSelectedTaskComments((prev) => updated_comments(prev));
      const updated_tasks = (_tasks) => {
        let updated = _tasks.map((item) => {
          if (String(item._id) === String(selectedTask?._id)) {
            return {
              ...item,
              task_comment_count:
                item.task_comment_count > 0
                  ? Number(item.task_comment_count) - 1
                  : 0,
              task_comment: updated_comments(item.task_comment),
            };
          }
          return item;
        });
        return updated;
      };
      let temp = allFilteredTasksBySections[selectedTask?.section_id];
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [selectedTask?.section_id]: {
            ...temp,
            task_list: updated_tasks(temp.task_list),
          },
        };
      });
      setSelectedComment(undefined);
      closeDelCommentDialog();
    } else {
      setDeletingTaskComment(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  /* <<--------------------------------------------------------->> */

  const getTaskComments = async (task, noLoader) => {
    if (!noLoader) {
      setLoadingComments(true);
    }
    const data = {
      workspace_id: task.workspace_id,
    };
    const result = await _task_comment_list(task._id);
    console.log(result, "com-list");
    setLoadingComments(false);
  };

  const afterCommentAction = () => {
    getDashboardData();
    getTaskComments(selectedTask, true);
    setSelectedComment();
    setEditCommentTitle("");
    setEditingCommentId("");
  };
  const handleAddComment = async (payload) => {
    if (!selectedTask) {
      return { code: 400, message: "Retry" };
    }
    const { title, type } = payload;
    const data = {
      comment_title: title.trim().replace(/<a /g, "<a target='_blank' "),
      comment_type: type,
    };

    console.log(data, "req-add-comm");
    const result = await _add_task_comment(data, selectedTask._id);
    afterCommentAction();
    if (result.code == 200) {
      setSelectedTask((prev) => {
        let found = prev.task_comment.findIndex(
          (item) => item._id == result.task_comment._id
        );
        if (found !== -1) {
          let temp = prev.task_comment;
          temp.splice(found, 1);
          temp.push(result.task_comment);
          return {
            ...prev,
            task_comment: [...temp],
          };
        } else {
          return {
            ...prev,
            task_comment: [...prev.task_comment, result.task_comment],
          };
        }
        // return { ...prev };
      });
      if (type == "0") {
        const { project, section_id, _id ,workspace } = selectedTask;
        socketEmit("send_notification_to_all", {
          receiver: result.task_comment.allow_members,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } added a comment in task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    }
    enqueueSnackbar(result.message, {
      variant: result.code == 200 ? "success" : "error",
    });
    return result;
  };

  const handleEditComment = async (selectedComment) => {
    if (!selectedTask) {
      return { code: 400, message: "Retry" };
    }
    console.log(selectedComment, "asuyaiosuioasdjasodi");
    const data = {
      comment_id: selectedComment._id,
      comment_title: selectedComment.comment_title,
      comment_type: selectedComment.comment_type,
    };
    console.log(data, "req-update-com");
    const result = await _update_task_comment(data, selectedTask._id);
    afterCommentAction();
    if (result.code == 200) {
      setSelectedTask((prev) => {
        return {
          ...prev,
          task_comment: prev.task_comment.map((oldComment) => {
            if (oldComment._id === result.task_comment._id) {
              return { ...result.task_comment };
            } else {
              return { ...oldComment };
            }
          }),
        };
      });
    }
    return result;
  };
  const getDashboardData = async () => {
    const result = await _all_tasks(page, 40, filters);
    if (result.code === 200) {
      const getModified = (data) => {
        let modified = data.map((item) => {
          return { ...item, ["query_field"]: item.task_title };
        });
        console.log(modified, "modi");
        return modified;
      };
      console.log(result, "dashboard-res");
      const { project_list } = result;
      setTasks(getModified(project_list.all_task_list));

      const isProjSelected = (proj) => {
        let selected = false;
        let local = localStorage.getItem("filters");
        if (local) {
          let parsed = JSON.parse(local);
          let found = parsed.project_ids.find(
            (item) => String(item) === String(proj._id)
          );
          if (found) {
            selected = true;
          }
        }
        return selected;
      };
      const project_options = project_list.all_task_projects.map((item) => {
        return {
          ...item,
          selected: isProjSelected(item),
          label: item.title,
          value: item,
        };
      });
      setProjectOptions(project_options);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleOpenTaskDetail = (_task) => {
    setSelectedTask(_task);
    setTaskDetailOpen(true);
  };
  const handleCloseTaskDetail = () => {
    setSelectedTask(undefined);
    setTaskDetailOpen(false);
    if (getCalendarTasks) {
      getCalendarTasks();
    }
  };
  const handleCopyTaskLink = async (task) => {
    const { project, section_id, _id } = task;
    console.log(task, "to-copy");
    const scroll_task_data = {
      task_id: _id,
      section_id: section_id,
    };
    let newLink = "";
    newLink = `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`;
    console.log(newLink);
    // unsecuredCopyToClipboard(newLink);
    let has_error = "";
    try {
      await window.navigator.clipboard.writeText(newLink);
    } catch (error) {
      if (error) {
        if (error.message) {
          has_error = error.message;
        }
      }
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
    if (has_error === "") {
      enqueueSnackbar("Task link copied to clipboard", { variant: "success" });
      return;
    }
    enqueueSnackbar("Could not copy task link", { variant: "error" });
  };
  const handleAction = (action, data) => {
    console.log(action, "action");
    console.log(data, "payload");
    if (action === "change_status") {
      handleChangeStatus(data);
    }
    if (action === "change_date") {
      handleChangeDate(data);
    }
    if (action === "change_priority") {
      handleChangePriority(data);
    }
    if (action === "change_members") {
      handleChangeMembers(data);
    }
    if (action === "del_task") {
      handleDelTask(data);
    }
    if (action === "copy_task_link") {
      handleCopyTaskLink(data);
    }
    if (action === "open_task_detail") {
      handleOpenTaskDetail(data);
    }
  };

  const handleChangeStatus = async (data) => {
    console.log(data, "change-status");
    const { is_checkbox, status, checked, task } = data;

    const updateTaskStatus = async (req_data, section_id) => {
      const result = await _update_task_status(req_data, section_id);
      getDashboardData();
      if (result.code === 200) {
        taskDetailOpen && setSelectedTask(result.task_detail);
        enqueueSnackbar("Task Status Updated Successfully", {
          variant: "success",
        });
        // let receiver = [result.task_detail.creator_profile.user_id];
        // result.task_detail.team.map((member) => {
        //   receiver.push(member.user_id);
        // });
        // receiver = receiver.filter(
        //   (id) => id !== dispatch_get_user_profile().user_id
        // );
        const { project, section_id, _id,workspace } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.allow_members,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.hidden_members,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `A member updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };
    if (is_checkbox === false) {
      setTasks((prev) =>
        prev.map((item) => {
          if (String(item._id) === String(task._id)) {
            return { ...task, task_status: status };
          }
          return item;
        })
      );
      const req_data = {
        task_id: task._id,
        task_status: status,
        workspace_id: task.workspace_id,
        list_type: "0",
      };
      updateTaskStatus(req_data, task.section_id);
    }

    if (is_checkbox === true) {
      let updated_status = checked === true ? 3 : 0;
      setTasks((prev) =>
        prev.map((item) => {
          if (String(item._id) === String(task._id)) {
            return { ...task, task_status: updated_status };
          }
          return item;
        })
      );
      const req_data = {
        task_id: task._id,
        task_status: updated_status,
        workspace_id: task.workspace_id,
        list_type: "0",
      };
      updateTaskStatus(req_data, task.section_id);
    }
  };

  const handleChangePriority = async (data) => {
    console.log(data, "change-priority");
    const { priority, task } = data;
    setTasks((prev) =>
      prev.map((item) => {
        if (String(item._id) === String(task._id)) {
          return { ...task, priority: priority };
        }
        return item;
      })
    );
    const req_data = {
      task_id: task._id,
      task_priority: priority,
      workspace_id: task.workspace_id,
      list_type: "0",
    };
    const result = await _update_task_priority(req_data, task.section_id);

    if (result.code === 200) {
      taskDetailOpen && setSelectedTask(result.task_detail);
      console.log(result, "res");
      enqueueSnackbar("Task Priority Updated Successfully", {
        variant: "success",
      });
      let receiver = [result.task_detail.creator_profile.user_id];
      result.task_detail.team.map((member) => {
        receiver.push(member.user_id);
      });
      receiver = receiver.filter(
        (id) => id !== dispatch_get_user_profile().user_id
      );
      const { project, section_id, _id,workspace } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.allow_members,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.hidden_members,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `A member updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
    getDashboardData();
  };
  const onEditComment = (_comment) => {
    setSelectedComment(_comment);
    setEditCommentTitle(_comment.comment_title);
  };
  const handleChangeDate = async (data) => {
    console.log(data, "change-date");
    const { endDate, task } = data;
    setTasks((prev) =>
      prev.map((item) => {
        if (String(item._id) === String(task._id)) {
          return { ...task, end_date: endDate };
        }
        return item;
      })
    );
    const req_data = {
      task_id: task._id,
      end_date: endDate,
      workspace_id: task.workspace_id,
      list_type: "0",
    };
    const result = await _update_task_end_date(req_data, task.section_id);
    getDashboardData();
    if (result.code === 200) {
      taskDetailOpen && setSelectedTask(result.task_detail);
      console.log(result, "res");
      let receiver = [result.task_detail.creator_profile.user_id];
      result.task_detail.team.map((member) => {
        receiver.push(member.user_id);
      });
      receiver = receiver.filter(
        (id) => id !== dispatch_get_user_profile().user_id
      );
      const { project, section_id, _id,workspace } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the target date of the task`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      // enqueueSnackbar("Target Date Updated Successfully", {
      //   variant: "success",
      // });
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
  };
  const handleDelTask = async (task) => {
    setSelectedTask(task);
    openDelTaskDialog();
  };

  const handleChangeMembers = async (data) => {
    console.log(data, "change-team");
    const { task, taskTeam, isSelected, targetUser, action } = data;

    let updated_team = Array.from(taskTeam);
    if (action === "add") {
      if (isSelected) {
        return;
      }
      if (!isSelected) {
        updated_team.push(targetUser);
      }
    }
    if (action === "remove") {
      if (isSelected) {
        updated_team = updated_team.filter(
          (user) => String(user.user_id) !== String(targetUser.user_id)
        );
      }
    }

    console.log(updated_team, "new-team");
    let updated_task = { ...task, team: updated_team };

    setTasks((prev) =>
      prev.map((_task) => {
        if (String(_task._id) === String(task._id)) {
          return updated_task;
        }
        return _task;
      })
    );
    let updated_task_team = [];
    updated_team.map((user) => {
      updated_task_team.push(user);
    });
    const req_data = {
      task_id: task._id,
      team: updated_task_team,
      workspace_id: task.workspace_id,
      list_type: "0",
    };

    const result = await _update_task_members(req_data);
    getDashboardData();
    if (result.code === 200) {
      taskDetailOpen && setSelectedTask(result.task_detail);
      // enqueueSnackbar("Task Members Updated Successfully", {
      //   variant: "success",
      // });
      if (action == "add") {
        let receiver = [targetUser.user_id];
        receiver = receiver.filter(
          (id) => id !== dispatch_get_user_profile().user_id
        );
        const { project, section_id, _id,workspace } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } assigned you a task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const currentTask = () => {
    if (selectedTask) {
      return selectedTask;
    }
    return undefined;
  };

  const onChangeTags = async (data) => {
    try {
      const result = await _update_task_tags_in_task(data);
      if (result.code == 200) {
        setSelectedTask(result.task_data);
        setTasks((prev) => {
          return prev.map((task) => {
            if (task._id == result.task_data._id) {
              return { ...result.task_data };
            } else {
              return { ...task };
            }
          });
        });
      }
    } catch (error) {
      console.log(error, " catched while updating task tags");
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };
  ////==============Files///////////////////////////////////////////
  const getFileIconType = (path) => {
    if (!path) {
      return "";
    }
    let ext = path.split("/")[0];
    let found = undefined;
    ALLOWED_TASK_FILES_WITH_ICONS.map((item) => {
      item.baseTypes.map((_type) => {
        if (String(_type) === String(ext)) {
          found = item;
        }
      });
    });

    if (found) {
      return { file_type: "file", file_path: found.icon, color: found.color };
    }
    if (!found) {
      return { file_type: "image", file_path: path };
    }
  };
  const getTaskImages = () => {
    let files = [];
    let task = currentTask();
    const { image } = task;
    for (let path of image) {
      let data = getFileIconType(path);
      const { file_type, file_path, color } = data;
      files.push({
        path: file_path,
        type: file_type,
        db_path: path,
        color: color,
      });
    }
    return files;
  };
  const handleNavigate = (task) => {
    const { section_id, _id } = task;
    const project_id = task?.project.id;
    navigate(
      `/workspaces/copied-task-link/${project_id}/${section_id}/${_id}`,
      { state: { task_id: _id } }
    );
  };
  /* <<--------------------------------------------------------->> */

  useEffect(() => {
    if (selectedTask) {
      setProjectTeam(
        projectOptions.find((project) => project._id == selectedTask.project.id)
          ?.team
      );
      setProjectTags(
        projectOptions.find((project) => project._id == selectedTask.project.id)
          ?.tag_colors
      );
    }
  }, [selectedTask]);
  /* <<--------------------------------------------------------->> */

  useEffect(() => {
    setPage(0);
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);
  // useLayoutEffect(() => {
  //   getDashboardData();
  //   return () => {
  //     setTasks([]);
  //   };
  // }, [page, filters]);
  return (
    <div>
      <DaashboardTaskCard
        task={task}
        onOpenDetail={onOpenTaskDetail}
        onTaskAction={handleAction}
        selectedUser={selectedUser}
        isWorking={isWorking}
      />

      {currentTask() && (
        <TaskDetailDialog
          open={taskDetailOpen}
          onClose={handleCloseTaskDetail}
          task={selectedTask}
          setTask={setSelectedTask}
          taskFiles={getTaskImages()}
          getFileIconType={getFileIconType}
          projectTeam={projectTeam}
          projectTags={projectTags}
          setProjectTags={setProjectTags}
          onChangeTags={onChangeTags}
          onChangePriority={handleChangePriority}
          onChangeStatus={handleChangeStatus}
          onChangeMembers={handleChangeMembers}
          handleSendComment={() =>
            handleAddComment({ title: newComment, type: 0 })
          }
          handleUpdateTaskComment={(e, type, comment) =>
            handleEditComment({ ...comment, comment_title: editCommentTitle })
          }
          newComment={newComment}
          setNewComment={setNewComment}
          onDelComment={onDelComment}
          editCommentTitle={editCommentTitle}
          setEditCommentTitle={setEditCommentTitle}
          onEditComment={onEditComment}
          editingCommentId={editingCommentId}
          setEditingCommentId={setEditingCommentId}
          setTasks={(section_id, task_id, updated_task) => {
            setTasks((prev) => {
              return prev.map((oldTask) => {
                if (oldTask._id == task_id) {
                  return { ...updated_task };
                } else {
                  return { ...oldTask };
                }
              });
            });
          }}
          onChangeDate={handleChangeDate}
          getListing={getDashboardData}
          onDelTask={ucbOnDelTask}
          taskDetailLoading={taskDetailLoading}
          setTaskDetailLoading={setTaskDetailLoading}
          handleNavigate={handleNavigate}
        />
      )}

      <MuiDialog
        open={delTaskOpen}
        onToggle={closeDelTask}
        onAgree={handleDeleteTask}
        loading={processing}
        title="Delete Task"
        message="Are you sure you want to delete this task?"
      />
      <MuiDialog
        open={delCommentOpen}
        onToggle={closeDelCommentDialog}
        onAgree={handleDelTaskComment}
        loading={deletingTaskComment}
        title="Delete Comment"
        message={`Are you sure you want to delete this comment?`}
      />
    </div>
  );
}

export default OpenTaskDetail;
