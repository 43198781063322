import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { Avatar, Box, Tooltip } from "@mui/material";
import { s3baseUrl } from "src/config";
import Label from "./Label";
import { capitalCase } from "change-case";
// ---------------------------------------------------------
export default function MuiAutoComplete({
  label,
  options,
  value,
  onChange,
  disabled,
  required = false,
  disablePortal = false,
}) {
  const [inputValue, setInputValue] = React.useState("");
  // ---------------------------------------------------------
  const handleChange = (newValue) => {
    console.log(newValue);
    if (newValue) {
      onChange(newValue);
    } else {
      onChange();
    }
  };
  // Check if even one option has member image ---------------
  const optionsWithimg = options.some((option) => option.image);
  // ---------------------------------------------------------
  return (
    <div>
      <Autocomplete
        value={value}
        disabled={disabled}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        sx={{ width: "100%" }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        disablePortal={disablePortal}
        // isOptionEqualToValue={(option, value) => option.title === value.title}
        id="controllable-states-demo"
        options={options}
        renderInput={(params) => (
          <TextField
            sx={{ width: "100%" }}
            required={required}
            {...params}
            label={label}
          />
        )}
        {...(optionsWithimg && {
          renderOption: (props, option) => (
            <Box
              {...props}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Avatar
                  src={s3baseUrl + option.image}
                  alt={option.label}
                  sx={{ width: 32, height: 32 }}
                />
                {option.label}
              </Box>
              {option?.role && (
                <Box sx={{ marginLeft: "auto" }}>
                  <Tooltip title="Role">
                    <Label color="success" variant="ghost">
                      {capitalCase(option.role)}
                    </Label>
                  </Tooltip>
                </Box>
              )}
            </Box>
          ),
        })}
      />
    </div>
  );
}
MuiAutoComplete.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
