import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Stack,
  Typography,
  Paper,
  Badge,
  TextField,
  FormControlLabel,
  FormGroup,
  Radio,
} from "@mui/material";
import { MuiDrawer, Iconify } from "..";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { capitalCase } from "change-case";
import moment from "moment";

// ---------------------------------------------- Shamroz khan ---
const ICON_SIZE = { height: "10px", width: "10px" };

const MAP_TASK_PRIORITY_OPTIONS = [
  { label: "critical", value: 0, color: "#FF4842" },
  { label: "high", value: 1, color: "#ffd500" },
  { label: "medium", value: 2, color: "#54D62C" },
  { label: "low", value: 3, color: "#969292" },
];

const MAP_TASK_STATUS_OPTIONS = [
  { label: "pending", value: 0, color: "#FFC107" },
  { label: "inProgress", value: 2, color: "#3366FF" },
  { label: "readyForTesting", value: 11, color: "#a4bdfc" },
  { label: "completed", value: 3, color: "#00AB55" },
  { label: "cancelled", value: 1, color: "#FF4842" },
  // { label: "Verified", value: 4, color: "#969292" },
];

const FiltersDrawerAllTask = ({
  open,
  onClose,
  onChange,
  clearFilter,
  selectedFilters,
}) => {
  // ------------ Date Filter -------------//
  const [dateType, setDateType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const isEndDateInvalid =
    endDate && moment(endDate).isBefore(moment(startDate));

  const handleClose = () => {
    onClose();
  };

  const onClear = () => {
    clearFilter();
    onClose();
  };

  const handleChange = (e, item, type) => {
    onChange(e, item, type);
  };

  const handleClearFilter = (type) => {
    clearFilter(type);
  };

  useEffect(() => {
    if (endDate) {
      if (isEndDateInvalid) {
        return;
      }
      onChange(
        null,
        {
          start_date: startDate,
          end_date: endDate,
          date_type: dateType,
        },
        "date"
      );
    }
  }, [endDate, dateType]);

  useEffect(() => {
    if (open) {
      setDateType(selectedFilters?.date_type || "createdAt");
      setStartDate(selectedFilters?.start_date || "");
      setEndDate(selectedFilters?.end_date || "");
    }
  }, [open]);

  return (
    <>
      <MuiDrawer
        isOpen={open}
        onClose={handleClose}
        paperProps={{ width: { xs: 1, sm: 320 } }}
        title={<Typography sx={{ fontWeight: "700" }}>Task Filters</Typography>}
        bottomComponent={<FiltersFooter onClear={onClear} />}
      >
        <Stack spacing={2}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
          >
            {/* Date Filter ---------------------- */}
            <Paper elevation={2}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  className="accordion-Summary"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="95%"
                  >
                    <Typography fontWeight="bold">Date Range</Typography>
                    <div>
                      {(startDate || endDate || dateType === "target_date") && (
                        <Button
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClearFilter("task_date");
                            // setDateType("");
                            setStartDate("");
                            setEndDate("");
                          }}
                          color="primary"
                        >
                          Clear
                        </Button>
                      )}
                      <Badge
                        badgeContent={
                          [
                            selectedFilters.start_date,
                            selectedFilters.end_date,
                            selectedFilters.date_type === "target_date",
                          ].filter(Boolean).length
                        }
                        color="primary"
                        sx={{ marginLeft: 2 }}
                      />
                    </div>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "10px" }} className="pb-0">
                  <TextField
                    label="Start Date"
                    type="date"
                    size="small"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    // when click textfield open calander
                    onClick={(e) =>
                      e.currentTarget.querySelector("input").showPicker()
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& input": {
                          color: isEndDateInvalid && "#C23232",
                          fontSize: "12px",
                        },
                      },
                    }}
                  />
                  <TextField
                    disabled={!startDate}
                    label="End Date"
                    type="date"
                    size="small"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    // when click textfield open calander
                    onClick={(e) =>
                      e.currentTarget.querySelector("input").showPicker()
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& input": {
                          color: isEndDateInvalid && "#C23232",
                          fontSize: "12px",
                        },
                      },
                    }}
                  />

                  {isEndDateInvalid && (
                    <div className="d-flex align-items-center justify-content-center mb-2 mt-1">
                      <Iconify
                        icon="dashicons:warning"
                        color="#FF9800"
                        fontSize="14px"
                      />
                      <Typography
                        sx={{
                          marginLeft: "5px",
                          fontSize: "11px",
                          color: "text.disabled",
                          fontWeight: "500",
                        }}
                      >
                        Start date must be earlier than End date
                      </Typography>
                    </div>
                  )}
                  {endDate && startDate && (
                    <div className="ms-2 mt-2">
                      <Typography fontSize="12px" fontWeight="500">
                        Filtering Tasks By :
                      </Typography>
                      <FormGroup row className="ms-1">
                        <FormControlLabel
                          className="row-h-effect px-2"
                          control={
                            <Radio
                              className="check-box-filter me-2"
                              value="createdAt"
                              checked={dateType === "createdAt" || !dateType}
                              onChange={(e) => setDateType(e.target.value)}
                            />
                          }
                          label={
                            <Typography sx={{ fontSize: "12px" }}>
                              Created Date
                            </Typography>
                          }
                        />

                        <FormControlLabel
                          className="row-h-effect px-2 me-0"
                          control={
                            <Radio
                              className="check-box-filter me-2"
                              value="target_date"
                              checked={dateType === "target_date"}
                              onChange={(e) => setDateType(e.target.value)}
                            />
                          }
                          label={
                            <Typography sx={{ fontSize: "12px" }}>
                              Target Date
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            </Paper>

            {/* Status Filters ---------------------- */}
            <Paper elevation={2}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  className="accordion-Summary"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="95%"
                  >
                    <Typography fontWeight="bold">Status</Typography>
                    <div>
                      {selectedFilters.task_status.length > 0 && (
                        <Button
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClearFilter("task_status");
                          }}
                          color="primary"
                        >
                          Clear
                        </Button>
                      )}
                      <Badge
                        badgeContent={
                          MAP_TASK_STATUS_OPTIONS.filter((option) =>
                            selectedFilters.task_status.includes(option.value)
                          ).length
                        }
                        color="primary"
                        sx={{ marginLeft: 2 }}
                      />
                    </div>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails className="p-1 pb-0">
                  {MAP_TASK_STATUS_OPTIONS.map((item, i) => (
                    <label key={i} style={{ width: "100%" }}>
                      <Stack
                        className="row-h-effect"
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ width: "100%" }}
                      >
                        <Checkbox
                          className="check-box-filter"
                          sx={{ marginRight: "10px" }}
                          value={item.label}
                          onChange={(e) => handleChange(e, item, "status")}
                          checked={
                            selectedFilters.task_status.findIndex(
                              (option) => option === item.value
                            ) >= 0
                          }
                        />
                        <Box
                          sx={{
                            width: ICON_SIZE.width,
                            height: ICON_SIZE.height,
                            borderRadius: "50%",
                            backgroundColor: item.color || "transparent",
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, userSelect: "none" }}
                        >
                          {capitalCase(item.label)}
                        </Typography>
                      </Stack>
                    </label>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Paper>

            {/* Priority Filters ----------------------  */}
            <Paper elevation={2}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  className="accordion-Summary"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="95%"
                  >
                    <Typography fontWeight="bold">Priority</Typography>
                    <div>
                      {selectedFilters.priority.length > 0 && (
                        <Button
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClearFilter("priority");
                          }}
                          color="primary"
                        >
                          Clear
                        </Button>
                      )}
                      <Badge
                        badgeContent={
                          MAP_TASK_PRIORITY_OPTIONS.filter((option) =>
                            selectedFilters.priority.includes(option.value)
                          ).length
                        }
                        color="primary"
                        sx={{ marginLeft: 2 }}
                      />
                    </div>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails className="p-1 pb-0">
                  {MAP_TASK_PRIORITY_OPTIONS.map((item, i) => (
                    <label key={i} style={{ width: "100%" }}>
                      <Stack
                        className="row-h-effect"
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ width: "100%" }}
                      >
                        <Checkbox
                          sx={{ marginRight: "10px" }}
                          className="check-box-filter"
                          value={item.label}
                          checked={
                            selectedFilters.priority.findIndex(
                              (option) => option === item.value
                            ) >= 0
                          }
                          onChange={(e) => handleChange(e, item, "priority")}
                        />
                        <Iconify
                          icon="carbon:flag-filled"
                          sx={{
                            width: "15px",
                            height: "15px",
                            color: item.color,
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ flex: 1, userSelect: "none" }}
                        >
                          {capitalCase(item.label)}
                        </Typography>
                      </Stack>
                    </label>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Paper>
          </div>
        </Stack>
      </MuiDrawer>
    </>
  );
};

const FiltersFooter = ({ onClear }) => {
  return (
    <Box sx={{ pl: 2, pr: 2, pt: 2, pb: 1 }}>
      <Button
        fullWidth
        size="large"
        type="submit"
        color="primary"
        variant="outlined"
        className="border"
        onClick={onClear}
        startIcon={<Iconify icon={"material-symbols:clear-all-rounded"} />}
      >
        Clear All
      </Button>
    </Box>
  );
};

export default FiltersDrawerAllTask;
