import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Tooltip,
  Tabs,
  Tab,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import { capitalCase } from "change-case";
import { string_with_limited_length } from "src/utils/common";
import {
  _project_list_for_task_shift,
  _project_sections_filter,
  _workspace_list_for_task_shift,
} from "src/DAL";
import MuiAutoCompleteCopySections from "./MuiAutoCompleteCopySections";
import { _copy_whole_section_tasks_or_all_tasks_only } from "src/DAL/taskShift";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

export default function CopySectionDialog({
  open,
  onClose,
  workspaceId,
  section,
  loadingDialog,
  setLoadingDialog,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [syncData, setSyncData] = useState(true);
  const [copyOnlyTasks, setCopyOnlyTasks] = useState(true);
  const [newTitle, setNewTitle] = useState("");
  const [autoCompleteValues, setAutoCompleteValues] = useState({
    workspace: null,
    project: null,
    section: null,
  });
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    workspaces: [],
    projects: [],
    sections: [],
  });
  //   ---------------------loading states
  const [projectLoading, setProjectLoading] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleClose = () => {
    onClose();
    setCopyOnlyTasks(true);
    setAutoCompleteValues({
      project: null,
      section: null,
      workspace: null,
    });
    setAutoCompleteOptions({
      projects: [],
      sections: [],
      workspaces: [],
    });
    setLoadingDialog(false);
    setProjectLoading(false);
    setSectionLoading(false);
    setButtonLoading(false);
  };

  const getWorkspaces = async () => {
    try {
      const result = await _workspace_list_for_task_shift(workspaceId);
      if (result.code == 200) {
        setLoadingDialog(false);
        setAutoCompleteOptions({
          projects: [],
          workspaces: result.workspace.workspace_list.map((workspace) => {
            return { ...workspace, label: workspace.name, value: workspace };
          }),
          sections: [],
        });
        console.log(autoCompleteOptions, "options_");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setLoadingDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      setLoadingDialog(false);
    }
  };

  const getProjects = async () => {
    setProjectLoading(true);
    try {
      const result = await _project_list_for_task_shift(
        autoCompleteValues.workspace._id
      );
      if (result.code === 200) {
        setAutoCompleteOptions((prev) => {
          return {
            ...prev,
            projects: result.project_list.project_list.map((project) => {
              return { ...project, label: project.title, value: project };
            }),
            sections: [],
          };
        });
        setProjectLoading(false);
        console.log(autoCompleteOptions, "options_");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setProjectLoading(false);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      setProjectLoading(false);
    }
  };

  const getSections = async () => {
    setSectionLoading(true);
    try {
      const result = await _project_sections_filter(
        {
          list_type: 0,
          priority: [],
          sort_status: "",
          task_status: [],
          workspace_id: autoCompleteValues.workspace._id,
        },
        autoCompleteValues.project._id,
        0,
        200
      );
      if (result.code == 200) {
        setAutoCompleteOptions((prev) => {
          return {
            ...prev,
            sections: result.section_list.section_lists.map((section) => {
              return {
                ...section,
                label: section.section_title,
                value: section,
              };
            }),
          };
        });
        setSectionLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setSectionLoading(false);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      setSectionLoading(false);
    }
  };

  const handleCopySections = async () => {
    setButtonLoading(true);
    const data = {
      project_id: autoCompleteValues.project._id,
      source_section_id: section._id, // the section we want to copy
      copy_only_tasks: copyOnlyTasks,
      source_section_title: copyOnlyTasks ? "" : newTitle, // Set to empty if copy_only_tasks is true
      destination_section_id: copyOnlyTasks
        ? autoCompleteValues.section._id
        : null,
      link_task: syncData,
    };
    console.log(data, "req_data");
    const result = await _copy_whole_section_tasks_or_all_tasks_only(data);
    if (result.code == 200) {
      enqueueSnackbar("Sucessfully copied", { variant: "success" });
      handleClose();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getWorkspaces();
    }
  }, [open]);

  useEffect(() => {
    if (autoCompleteValues.workspace) {
      getProjects();
    }
  }, [autoCompleteValues.workspace]);

  useEffect(() => {
    if (autoCompleteValues.project) {
      getSections();
    }
  }, [autoCompleteValues.project]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        {loadingDialog ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={515}
          >
            <CircularProgress disableShrink />
          </Box>
        ) : (
          <>
            <DialogTitle>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                Copy Section Tasks
                <IconButton size="small" onClick={handleClose}>
                  <Iconify
                    icon="ion-close"
                    sx={{
                      height: "24px",
                      width: "24px",
                    }}
                  />
                </IconButton>
              </Box>
            </DialogTitle>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!autoCompleteValues.project) return;
                handleCopySections();
              }}
            >
              <DialogContent
                sx={{
                  height: "400px",
                  padding: "0px 20px 10px 20px",
                }}
              >
                <Box p={1} pt={0}>
                  <Typography variant="body2">
                    You're going to copy the
                    <b className="px-1">
                      "{string_with_limited_length(section?.section_title, 30)}"
                    </b>
                    section's task. Please choose a workspace and a project.
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap={2} pt={1.5}>
                  {/* ----------------- Select Workspace*/}
                  {open && (
                    <MuiAutoCompleteCopySections
                      disablePortal
                      label="Select Workspace"
                      value={autoCompleteValues.workspace}
                      options={autoCompleteOptions.workspaces}
                      onChange={(val) =>
                        setAutoCompleteValues({
                          workspace: val,
                          section: null,
                          project: null,
                        })
                      }
                      loading={false}
                    />
                  )}
                  {/* ----------------- Select Project*/}
                  {autoCompleteValues.workspace && (
                    <MuiAutoCompleteCopySections
                      disablePortal
                      label="Select Project"
                      value={autoCompleteValues.project}
                      options={autoCompleteOptions.projects}
                      onChange={(val) =>
                        setAutoCompleteValues((prev) => ({
                          ...prev,
                          project: val,
                          section: null,
                        }))
                      }
                      loading={projectLoading}
                    />
                  )}
                  {/* ----------------- Copy Section*/}
                  {autoCompleteValues.project && (
                    <Box px={1}>
                      <Typography fontWeight={500} variant="body2">
                        How would you like to copy the tasks?
                      </Typography>
                      <RadioGroup
                        row
                        value={copyOnlyTasks ? "yes" : "no"}
                        onChange={(e) => {
                          setCopyOnlyTasks(e.target.value === "yes");
                        }}
                      >
                        <FormControlLabel
                          sx={{ marginRight: 3 }}
                          value="yes"
                          control={<Radio color="primary" size="small" />}
                          label={
                            <Typography variant="body2">
                              Copy tasks in existing section
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" size="small" />}
                          label={
                            <Typography variant="body2">
                              Create new section
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </Box>
                  )}
                  {/* ----------------- New Section Name*/}
                  {autoCompleteValues.project && !copyOnlyTasks && (
                    <>
                      <TextField
                        autofocus
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        label="New Section Name"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </>
                  )}
                  {/* ----------------- Select Section*/}
                  {autoCompleteValues.project && copyOnlyTasks && (
                    <MuiAutoCompleteCopySections
                      disablePortal
                      label="Select Existing Section"
                      value={autoCompleteValues.section}
                      options={autoCompleteOptions.sections}
                      onChange={(val) =>
                        setAutoCompleteValues((prev) => ({
                          ...prev,
                          section: val,
                        }))
                      }
                      loading={sectionLoading}
                    />
                  )}
                  {/* ----------------- Sync Tasks*/}
                  {autoCompleteValues.project &&
                    autoCompleteValues.workspace && (
                      <Box px={1}>
                        <Typography fontWeight={500} variant="body2">
                          Do you want to sync the tasks?
                        </Typography>
                        <RadioGroup
                          row
                          value={syncData ? "yes" : "no"}
                          onChange={(e) =>
                            setSyncData(e.target.value === "yes")
                          }
                        >
                          <FormControlLabel
                            sx={{ marginRight: 3 }}
                            value="yes"
                            control={<Radio color="primary" size="small" />}
                            label={<Typography variant="body2">Yes</Typography>}
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio color="primary" size="small" />}
                            label={<Typography variant="body2">No</Typography>}
                          />
                        </RadioGroup>
                      </Box>
                    )}
                </Box>
              </DialogContent>
              <DialogActions>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={buttonLoading}
                >
                  copy Tasks
                </LoadingButton>
                <Button variant="text" onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </>
  );
}
