import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  Box,
  Stack,
  Autocomplete,
  Divider,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import {
  _add_planned_time,
  _start_pause_stop_time_tracking,
} from "src/DAL/taskDetailDrawer";
import { useAppContext } from "src/hooks";
import { useSnackbar } from "notistack";
import moment from "moment";
import PlannedTimeDialog from "./PlannedTimeDialog";

const timeOptions = [
  "05 min",
  "10 min",
  "15 min",
  "30 min",
  "45 min",
  "1 hr",
  "1 hr 30 min",
  "2 hr",
  "2 hr 30 min",
  "3 hr",
  "3 hr 30 min",
  "4 hr",
  "5 hr",
  "6 hr",
  "7 hr",
  "8 hr",
  "9 hr",
  "10 hr",
  "11 hr",
  "12 hr",
  "13 hr",
  "14 hr",
  "15 hr",
  "16 hr",
  "17 hr",
  "18 hr",
  "19 hr",
  "20 hr",
];

// =============================================================
export default function Timer({
  task,
  getTaskDetail,
  canEditTask,
  getFilteredSections,
}) {
  const [time, setTime] = useState(0);
  const [isRunningTimer, setIsRunningTimer] = useState(false);
  const [isTaskStarted, setIsTaskStarted] = useState(false);
  const [taskFinished, setTaskFinished] = useState(false);
  // -----------------------------------------------------------
  const [openModal, setOpenModal] = useState(false);
  const [systemTime, setSystemTime] = useState(0);
  const [manualTime, setManualTime] = useState("");
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const [openPlannedTimeModal, setOpenPlannedTimeModal] = useState(false);
  const [plannedTime, setPlannedTime] = useState("");

  // -----------------------------------------------------------
  const { getActiveTaskList } = useAppContext();

  const { dispatch_get_user_profile } = useAppContext();
  const userId = dispatch_get_user_profile().user_id;
  const taskId = task._id;
  const startTimeRef = useRef(0);
  const intervalIdRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  // const timerCounter = useCallback(() => {
  //   return moment.utc(time).format("HH:mm:ss");
  // }, [time]);

  const timerCounter = useCallback(() => {
    if (isNaN(time) || time <= 0) {
      return "00:00:00";
    }
    const inToSeconds = Math.floor(time / 1000); // Convert milliseconds to seconds
    const hours = Math.floor(inToSeconds / 3600);
    const minutes = Math.floor((inToSeconds % 3600) / 60);
    const seconds = inToSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  }, [time]);

  const startTimer = (initialTime) => {
    startTimeRef.current = Date.now() - initialTime;
    intervalIdRef.current = setInterval(() => {
      setTime(Date.now() - startTimeRef.current);
    }, 1000);
  };

  const handleStartTaskButton = () => {
    // setOpenPlannedTimeModal(true);
    const timeTracking = task?.time_tracking?.find(
      (tracking) => tracking.assignee.user_id === userId
    );
    if (timeTracking?.planned_time > 0) {
      handleStartPause();
    } else {
      setOpenPlannedTimeModal(true);
    }
  };

  const stopTimer = () => {
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;
  };
  const handleStop = () => {
    setOpenModal(true);
  };

  const handleStartPause = async () => {
    const action = isRunningTimer ? "pause" : "start";
    const response = await _start_pause_stop_time_tracking(taskId, {
      action,
    });
    if (response.code === 200) {
      getTaskDetail();
      if (getFilteredSections) {
        getFilteredSections(); // from workspace - update task card for active user
      }
      if (action === "start") {
        startTimer(time);
        setIsTaskStarted(true);
        getActiveTaskList();
        enqueueSnackbar("Task Started", { variant: "success" });
      } else if (action === "pause") {
        stopTimer();
        getActiveTaskList();
        enqueueSnackbar("Task Paused", { variant: "success" });
      }
      setIsRunningTimer(!isRunningTimer);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleSave = async () => {
    // const timeToSend = systemTime === 0 ? 0 : manualTime;
    const timeToSend = systemTime === 0 ? 0 : convertTimeToSeconds(manualTime);
    const response = await _start_pause_stop_time_tracking(taskId, {
      action: "stop",
      // manual_time: timeToSend,
    });
    if (response.code === 200) {
      stopTimer();
      setOpenModal(false);
      setTaskFinished(true);
      getTaskDetail();
      enqueueSnackbar("Task Finished", { variant: "success" });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (value.split(":").length > 2) {
      value = value.substring(0, value.lastIndexOf(":"));
    }
    if (value.length === 2 && !value.includes(":")) {
      value += ":";
    }
    if (value.length > 2 && !value.includes(":")) {
      value = value.substring(0, 2) + ":" + value.substring(2);
    }
    // If there's an extra colon at the end (after deletion), remove it
    if (value.endsWith(":") && value.length > 3) {
      value = value.substring(0, value.length - 1);
    }
    setManualTime(value);
  };

  const handleKeyDown = (e) => {
    const validKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Enter"];

    if (!validKeys.includes(e.key) && !/^[0-9]$/.test(e.key)) {
      e.preventDefault();
    }
  };
  // ----------------------------------------------------------
  useEffect(() => {
    if (!canEditTask) {
      setIsTaskStarted(false);
      return;
    }
    const initializeTimer = () => {
      let timeTracking = {};
      if (task.time_tracking) {
        timeTracking = task.time_tracking.find(
          (tracking) => tracking.assignee.user_id === userId
        );
      }

      if (!timeTracking) {
        setIsTaskStarted(false);
        return;
      }

      let trackedTime = timeTracking.tracked_time * 1000; // convert into Milli second

      const isActive = timeTracking.tracking_status === "start";
      if (isActive) {
        const timeSinceStart =
          Date.now() -
          new Date(timeTracking.last_time_slot_start_time).getTime();
        setTime(trackedTime + timeSinceStart);
        startTimer(trackedTime + timeSinceStart);
        setIsRunningTimer(true);
        setIsTaskStarted(true);
      } else {
        setTime(trackedTime);
        setTaskFinished(timeTracking.tracking_status === "stop"); // return (false)
        setIsTaskStarted(trackedTime > 0); // if trackeTime > 0 return (true)
      }
    };
    initializeTimer();
    return () => clearInterval(intervalIdRef.current);
  }, [task]);

  return (
    <>
      {!isTaskStarted ? (
        <Button
          onClick={handleStartTaskButton}
          variant="contained"
          disabled={!canEditTask}
          color="primary"
          endIcon={<Iconify icon="material-symbols:timer-play-outline" />}
          fullWidth
          sx={{
            borderRadius: "4px",
            minHeight: "36px",
            // width: "170px",
            fontWeight: 600,
          }}
        >
          Start This Task
        </Button>
      ) : (
        <div
          className="sidebar-button d-flex align-items-center"
          style={{
            minHeight: "36px",
            width: "100%",
            padding: "0px 4px 0px 8px",
          }}
        >
          <div>
            <Typography variant="subtitle">{timerCounter()}</Typography>
          </div>
          <div>
            {taskFinished ? (
              <Tooltip title="Resume Task">
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() => setOpenResumeModal(true)}
                  style={{ fontWeight: "600" }}
                  disabled={!canEditTask}
                >
                  Resume
                </Button>
              </Tooltip>
            ) : (
              <>
                <Tooltip title={isRunningTimer ? "Pause" : "Start"}>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={handleStartPause}
                  >
                    <Iconify
                      icon={
                        isRunningTimer
                          ? "material-symbols:pause-circle-outline"
                          : "material-symbols:play-circle-outline"
                      }
                      sx={{ fontSize: "20px" }}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Finish Task">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={handleStop}
                    sx={{
                      "&:hover": {
                        borderRadius: "5px",
                      },
                    }}
                    disabled={isRunningTimer}
                  >
                    <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                      Complete
                    </Typography>
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      )}
      {/* Planned Time Modal ================================================== */}
      <PlannedTimeDialog
        userId={userId}
        task={task}
        openPlannedTimeModal={openPlannedTimeModal}
        setOpenPlannedTimeModal={setOpenPlannedTimeModal}
        plannedTime={plannedTime}
        setPlannedTime={setPlannedTime}
        handleStartPause={handleStartPause}
        getTaskDetail={getTaskDetail}
      />
      {/* Task Stop Modal ================================================== */}
      <Dialog
        className="timer-dialog"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle sx={{ fontWeight: 700, width: "450px" }}>
          How much time did you spend?
        </DialogTitle>
        <DialogContent sx={{ minHeight: "150px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {/* Tabs -------------- */}
            <Box>
              <Tabs
                value={systemTime === 0 ? 0 : 1} // 0 for system time, 1 for manual
                onChange={(e, newValue) => {
                  setSystemTime(newValue === 0 ? 0 : null);
                  if (newValue === 0) setManualTime("");
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="System Time" />
                {/* <Tab label="Enter Time Manually" /> */}
              </Tabs>
            </Box>

            {/* Tab Content ----------- */}
            <Box>
              {systemTime === 0 ? (
                <Stack direction="row" alignItems="center" spacing={1} p={2}>
                  <Iconify
                    icon="icon-park-outline:stopwatch"
                    sx={{
                      color: "primary.main",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  <Typography variant="subtitle" fontWeight={600}>
                    {timerCounter()}
                  </Typography>
                </Stack>
              ) : (
                <Autocomplete
                  options={timeOptions}
                  value={manualTime}
                  onChange={(event, selectedOption) => {
                    if (!selectedOption) {
                      setManualTime("");
                    } else {
                      const inSeconds = convertTimeToSeconds(selectedOption);
                      const hhmmFormat = convertSecondsToHHmm(inSeconds);
                      setManualTime(hhmmFormat);
                    }
                  }}
                  renderOption={(props, option) => (
                    <span {...props} style={{ fontWeight: "500" }}>
                      {option}
                    </span>
                  )}
                  renderInput={(option) => (
                    <TextField
                      {...option}
                      label="Manual Time"
                      placeholder="hh:mm"
                      type="text"
                      fullWidth
                      value={manualTime}
                      inputProps={{
                        ...option.inputProps,
                        maxLength: 5,
                      }}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                    />
                  )}
                  freeSolo
                  fullWidth
                />
              )}
            </Box>
          </Box>
        </DialogContent>
        {/* --------------------------------------------------------------- */}
        <Divider variant="middle" component="li" sx={{ listStyle: "none" }} />
        {/* --------------------------------------------------------------- */}
        <DialogActions sx={{ p: 2 }}>
          <Button color="inherit" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={systemTime !== 0 && convertTimeToSeconds(manualTime) <= 0}
          >
            Finish Task
          </Button>
        </DialogActions>
      </Dialog>
      {/* Resume Task Modal ================================================ */}
      <Dialog open={openResumeModal} onClose={() => setOpenResumeModal(false)}>
        <DialogTitle sx={{ fontWeight: 700 }}>Resume Task?</DialogTitle>
        <DialogContent>
          <Typography>
            This task has already been finished. Do you want to resume it?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setOpenResumeModal(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenResumeModal(false);
              setIsRunningTimer(true); // Start/resume the timer
              setTaskFinished(false); // Mark task as not finished
              handleStartPause();
            }}
          >
            Resume Task
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
