import React, { useLayoutEffect } from "react";
import { capitalCase } from "change-case";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AllTasksVariant,
  CircularLoader,
  CommentsDrawer,
  Iconify,
  MuiDialog,
  NoData,
  Page,
  SearchBar,
  TaskCard,
} from "src/components";
import { Container, Grid, IconButton, Stack, Typography } from "@mui/material";
import {
  add_task_comment,
  _add_task_comment,
  _dashboard_tasks,
  _delete_task,
  _delete_task_comment,
  _task_comment_list,
  _update_task_comment,
  _update_task_end_date,
  _update_task_members,
  _update_task_priority,
  _update_task_status,
} from "src/DAL";
import {
  get_month_first_day,
  get_month_last_day,
  handle_localSearch,
} from "src/utils";
import { useSnackbar } from "notistack";
import { AnimatePresence, motion } from "framer-motion";
import { useAppContext } from "src/hooks";

const TargetDatePassedTasks = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tasks, setTasks] = useState([]);
  const [loaders, setLoaders] = useState({ tasks: true });
  const [search, setSearch] = useState("");
  const [delDialogOpen, setDelDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [processing, setProcessing] = useState(false);
  const [loadingComments, setLoadingComments] = useState(true);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [selectedTaskComments, setSelectedTaskComments] = useState([]);
  const { dispatch_get_user_profile, socketEmit } = useAppContext();
  /* <<--------------------------------------------------------->> */
  const openDelTaskDialog = () => {
    setDelDialogOpen(true);
  };
  const closeDelTaskDialog = () => {
    setDelDialogOpen(false);
  };
  const getDashboardData = async () => {
    let first_day = get_month_first_day();
    let last_day = get_month_last_day();
    const data = { start_date: first_day, end_date: last_day };

    const result = await _dashboard_tasks(data);
    if (result.code === 200) {
      const getModified = (data) => {
        let modified = data.map((item) => {
          return { ...item, ["query_field"]: item.task_title };
        });
        console.log(modified, "modi");
        return modified;
      };
      console.log(result, "dashboard-res");
      const { project_list } = result;
      setTasks(getModified(project_list.date_passed_task));
      setLoaders((prev) => {
        return { ...prev, tasks: false };
      });
    } else {
      setLoaders((prev) => {
        return { ...prev, tasks: false };
      });
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleNavBack = async () => {
    navigate(-1);
  };
  const getFilteredTasks = () => {
    return handle_localSearch(search, tasks);
  };

  const onSetSelectedTask = (task) => {
    console.log(task);
  };
  const onOpenTaskDetail = (task) => {
    console.log(task, "task-");
    setSelectedTask(task);
  };
  const onOpenComments = (task) => {
    setSelectedTask(task);
    getTaskComments(task);
    openCommentsDrawer();
  };
  const handleCopyTaskLink = async (task) => {
    const { section_id, _id, project_id } = task;

    console.log(task, "to-copy");
    let newLink = "";
    newLink = `${window.location.origin}/workspaces/copied-task-link/${project_id}/${section_id}/${_id}`;
    console.log(newLink);
    // unsecuredCopyToClipboard(newLink);
    let has_error = "";
    try {
      await window.navigator.clipboard.writeText(newLink);
    } catch (error) {
      if (error) {
        if (error.message) {
          has_error = error.message;
        }
      }
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    }
    if (has_error === "") {
      enqueueSnackbar("Task link copied to clipboard", { variant: "success" });
      return;
    }
    enqueueSnackbar("Could not copy task link", { variant: "error" });
  };
  const handleAction = (action, data) => {
    console.log(action, "action");
    console.log(data, "payload");
    if (action === "change_status") {
      handleChangeStatus(data);
    }
    if (action === "change_date") {
      handleChangeDate(data);
    }
    if (action === "change_priority") {
      handleChangePriority(data);
    }
    if (action === "change_members") {
      handleChangeMembers(data);
    }
    if (action === "del_task") {
      handleDelTask(data);
    }
    if (action === "copy_task_link") {
      handleCopyTaskLink(data);
    }
  };
  const handleChangeStatus = async (data) => {
    console.log(data, "change-status");
    const { is_checkbox, status, checked, task } = data;

    const updateTaskStatus = async (req_data, section_id) => {
      const result = await _update_task_status(req_data, section_id);
      getDashboardData();
      if (result.code === 200) {
        enqueueSnackbar("Task Status Updated Successfully", {
          variant: "success",
        });
        // let receiver = [result.task_detail.creator_profile.user_id];
        // result.task_detail.team.map((member) => {
        //   receiver.push(member.user_id);
        // });
        // receiver = receiver.filter(
        //   (id) => id !== dispatch_get_user_profile().user_id
        // );
        const { project, section_id, _id, workspace } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.allow_members,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.hidden_members,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `A member updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };
    if (is_checkbox === false) {
      setTasks((prev) =>
        prev.map((item) => {
          if (String(item._id) === String(task._id)) {
            return { ...task, task_status: status };
          }
          return item;
        })
      );
      const req_data = {
        task_id: task._id,
        task_status: status,
        workspace_id: task.workspace_id,
        list_type: "0",
      };
      updateTaskStatus(req_data, task.section_id);
    }

    if (is_checkbox === true) {
      let updated_status = checked === true ? 3 : 0;
      setTasks((prev) =>
        prev.map((item) => {
          if (String(item._id) === String(task._id)) {
            return { ...task, task_status: updated_status };
          }
          return item;
        })
      );
      const req_data = {
        task_id: task._id,
        task_status: updated_status,
        workspace_id: task.workspace_id,
        list_type: "0",
      };
      updateTaskStatus(req_data, task.section_id);
    }
  };

  const handleChangePriority = async (data) => {
    console.log(data, "change-priority");
    const { priority, task } = data;
    setTasks((prev) =>
      prev.map((item) => {
        if (String(item._id) === String(task._id)) {
          return { ...task, priority: priority };
        }
        return item;
      })
    );
    const req_data = {
      task_id: task._id,
      task_priority: priority,
      workspace_id: task.workspace_id,
      list_type: "0",
    };
    const result = await _update_task_priority(req_data, task.section_id);
    getDashboardData();
    if (result.code === 200) {
      console.log(result, "res");
      enqueueSnackbar("Task Priority Updated Successfully", {
        variant: "success",
      });
      let receiver = [result.task_detail.creator_profile.user_id];
      result.task_detail.team.map((member) => {
        receiver.push(member.user_id);
      });
      receiver = receiver.filter(
        (id) => id !== dispatch_get_user_profile().user_id
      );
      const { project, section_id, _id,workspace } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.allow_members,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.allow_members,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `A member updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
  };
  const handleChangeDate = async (data) => {
    console.log(data, "change-date");
    const { endDate, task } = data;
    setTasks((prev) =>
      prev.map((item) => {
        if (String(item._id) === String(task._id)) {
          return { ...task, end_date: endDate };
        }
        return item;
      })
    );
    const req_data = {
      task_id: task._id,
      end_date: endDate,
      workspace_id: task.workspace_id,
      list_type: "0",
    };
    const result = await _update_task_end_date(req_data, task.section_id);
    getDashboardData();
    if (result.code === 200) {
      console.log(result, "res");
      // enqueueSnackbar("Target Date Updated Successfully", {
      //   variant: "success",
      // });
      let receiver = [result.task_detail.creator_profile.user_id];
      result.task_detail.team.map((member) => {
        receiver.push(member.user_id);
      });
      receiver = receiver.filter(
        (id) => id !== dispatch_get_user_profile().user_id
      );
      const { project, section_id, _id ,workspace } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the target date of the task`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      // enqueueSnackbar("Target Date Updated Successfully", {
      //   variant: "success",
      // });
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
      });
    }
  };
  const handleDelTask = async (task) => {
    setSelectedTask(task);
    openDelTaskDialog();
  };
  const deleteTask = async () => {
    console.log(selectedTask, "slc task");
    if (!selectedTask) {
      return;
    }
    setProcessing(true);
    const result = await _delete_task(selectedTask._id);
    getDashboardData();
    if (result.code === 200) {
      setTasks((prev) => prev.filter((item) => item._id != task._id));
      closeDelTaskDialog();
      enqueueSnackbar("Task Deleted Successfully", { variant: "success" });
    } else {
      closeDelTaskDialog();
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeMembers = async (data) => {
    console.log(data, "change-team");
    const { task, taskTeam, isSelected, targetUser, action } = data;

    let updated_team = Array.from(taskTeam);
    if (action === "add") {
      if (isSelected) {
        return;
      }
      if (!isSelected) {
        updated_team.push(targetUser);
      }
    }
    if (action === "remove") {
      if (isSelected) {
        updated_team = updated_team.filter(
          (user) => String(user.user) !== String(targetUser.user)
        );
      }
    }

    console.log(updated_team, "new-team");
    let updated_task = { ...task, team: updated_team };

    setTasks((prev) =>
      prev.map((_task) => {
        if (String(_task._id) === String(task._id)) {
          return updated_task;
        }
        return _task;
      })
    );
    let updated_task_team = [];
    updated_team.map((user) => {
      updated_task_team.push(String(user));
    });
    const req_data = {
      task_id: task._id,
      team: updated_task_team,
      workspace_id: task.workspace_id,
      list_type: "0",
    };

    const result = await _update_task_members(req_data);
    getDashboardData();
    if (result.code === 200) {
      // enqueueSnackbar("Task Members Updated Successfully", {
      //   variant: "success",
      // });
      if (action == "add") {
        let receiver = [targetUser.user_id];
        receiver = receiver.filter(
          (id) => id !== dispatch_get_user_profile().user_id
        );
        console.log(receiver, "Aasckljschals");
        const { project, section_id, _id,workspace } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } assigned you a task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const openCommentsDrawer = () => {
    setCommentsOpen(true);
  };
  const closeCommentsDrawer = () => {
    setCommentsOpen(false);
    setSelectedTaskComments([]);
  };
  const getTaskComments = async (task, noLoader) => {
    if (!noLoader) {
      setLoadingComments(true);
    }
    const data = {
      workspace_id: task.workspace_id,
    };
    const result = await _task_comment_list(task._id);
    console.log(result, "com-list");
    setLoadingComments(false);
    setSelectedTaskComments(result.project_list.comment_list);
  };
  const afterCommentAction = () => {
    getDashboardData();
    getTaskComments(selectedTask, true);
  };
  const handleCommentAction = async (action, payload) => {
    console.log(payload, "payload");
    console.log(action, "action");
    if (action === "add_comment") {
      return handleAddComment(payload);
    }
    if (action === "edit_comment") {
      return handleEditComment(payload);
    }
    if (action === "delete_comment") {
      return handleDeleteComment(payload);
    }
  };
  const handleAddComment = async (payload) => {
    if (!selectedTask) {
      return { code: 400, message: "Retry" };
    }
    const { title, type } = payload;
    const data = {
      comment_title: title.trim().replace(/<a /g, "<a target='_blank' "),
      comment_type: type,
    };

    console.log(data, "req-add-comm");
    const result = await _add_task_comment(data, selectedTask._id);
    afterCommentAction();
    if (result.code == 200) {
      if (type == "0") {
        // let receiver = [selectedTask.creator_profile.user_id];
        // selectedTask.team.map((member) => {
        //   receiver.push(member.user_id);
        // });
        // receiver = receiver.filter(
        //   (id) => id !== dispatch_get_user_profile().user_id
        // );
        const { project, section_id, _id, workspace} = selectedTask;
        socketEmit("send_notification_to_all", {
          receiver: result.task_comment.allow_members,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } added a comment in task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    }
    return result;
  };
  const handleEditComment = async (selectedComment) => {
    if (!selectedTask) {
      return { code: 400, message: "Retry" };
    }

    const data = {
      comment_id: selectedComment._id,
      comment_title: selectedComment.comment_title,
      comment_type: selectedComment.comment_type,
    };
    console.log(data, "req-update-com");
    const result = await _update_task_comment(data, selectedTask._id);
    afterCommentAction();
    return result;
  };
  const handleDeleteComment = async (selectedComment) => {
    if (!selectedTask) {
      return undefined;
    }

    const result = await _delete_task_comment(
      { comment_id: selectedComment._id },
      selectedTask._id
    );
    afterCommentAction();
    return result;
  };
  /* <<--------------------------------------------------------->> */
  useLayoutEffect(() => {
    getDashboardData();
    return () => {
      setTasks([]);
      setLoaders({ tasks: true });
    };
  }, []);
  /* <<--------------------------------------------------------->> */
  return (
    <Page title="Target Date Passed Tasks">
      <Container maxWidth="xl">
        <Grid container className="project-header">
          <Grid item display="flex" alignItems="center">
            <IconButton onClick={handleNavBack} sx={{ mr: "6px" }}>
              <Iconify icon="ep:back" />
            </IconButton>
            <Typography fontWeight="bold">
              {capitalCase("target date passed tasks")}
            </Typography>
          </Grid>
        </Grid>
        <Stack sx={{ my: 3 }} direction="row" alignItems="center">
          <SearchBar
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Stack>
        {!loaders.tasks && (
          /* limit 10 on dashboard*/
          <Stack spacing={2}>
            <AnimatePresence layout initial={false}>
              {getFilteredTasks().map((task, i) => (
                <motion.div
                  key={task._id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  layout
                >
                  <AllTasksVariant
                    task={task}
                    onSetSelectedTask={onSetSelectedTask}
                    taskTeam={task.team}
                    onOpenDetail={onOpenTaskDetail}
                    onOpenComments={onOpenComments}
                    onTaskAction={handleAction}
                  />
                  {/* <TaskCard
                    variant="allTasks"
                    task={task}
                    onSetSelectedTask={onSetSelectedTask}
                    taskTeam={task.team}
                    onOpenDetail={onOpenTaskDetail}
                    onOpenComments={onOpenComments}
                    onTaskAction={handleAction}
                  /> */}
                </motion.div>
              ))}
            </AnimatePresence>
          </Stack>
        )}
        {loaders.tasks && <CircularLoader />}
      </Container>
      <CommentsDrawer
        open={commentsOpen}
        selectedTask={selectedTask}
        onClose={closeCommentsDrawer}
        comments={selectedTaskComments}
        disabled={false}
        onCommentAction={handleCommentAction}
        loading={loadingComments}
      />
      <MuiDialog
        open={delDialogOpen}
        onAgree={deleteTask}
        onToggle={closeDelTaskDialog}
        title="Delete Task"
        loading={processing}
        message="Are you sure you want to delete this task?"
      />
      <div className="no-data-container">
        <NoData
          title="No Tasks Found"
          loading={loaders.tasks}
          dataLength={getFilteredTasks().length}
        />
      </div>
    </Page>
  );
};

export default TargetDatePassedTasks;
