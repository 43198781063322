import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Tooltip,
  Tabs,
  Tab,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import { capitalCase } from "change-case";
import { string_with_limited_length } from "src/utils/common";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import {
  _mute_workspace_project_notifications,
  _un_mute_notifications,
} from "src/DAL/notifications";

export default function MuteNotificationsDialog({
  open,
  onClose,
  loadingDialog = false,
  entityType,
  item,
  update,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [muteDuration, setMuteDuration] = useState(1);

  const handleClose = () => {
    onClose();
    setLoadingBtn(false);
  };

  //--------------------- Mute Notifications ---------
  const muteNotifications = async () => {
    setLoadingBtn(true);
    const req_data = {
      entity_type: entityType,
      id: item._id,
      mute_date: new Date().toISOString(),
      expiry_date: new Date(
        muteDuration < 1
          ? new Date().getTime() + 3600000 // 1 hour in milliseconds
          : new Date().setDate(new Date().getDate() + muteDuration)
      ).toISOString(),
    };
    console.log(req_data, "req_data");
    const result = await _mute_workspace_project_notifications(req_data);
    if (result.code == 200) {
      update();
      handleClose();
      setLoadingBtn(false);
      enqueueSnackbar("All notifications muted successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoadingBtn(false);
    }
  };
  //--------------------- Un Mute Notifications ---------
  const unMuteNotifications = async () => {
    setLoadingBtn(true);
    const req_data = {
      entity_type: item.mute_type === "workspace" ? "workspace" : entityType,
      id: item.mute_type === "workspace" ? item.workspace.id : item._id,
    };
    console.log(req_data, "req_data");
    const result = await _un_mute_notifications(req_data);
    if (result.code == 200) {
      update();
      handleClose();
      setLoadingBtn(false);
      enqueueSnackbar("All notifications un-muted successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoadingBtn(false);
    }
  };

  console.log(item, "--item");
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        {loadingDialog ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={515}
          >
            <CircularProgress disableShrink />
          </Box>
        ) : (
          <>
            <DialogTitle>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {item?.is_mute === false ? (
                  <div>Mute {capitalCase(entityType)} Notifications</div>
                ) : (
                  <div>
                    Un-Mute{" "}
                    {item?.mute_type === "workspace"
                      ? "Workspace"
                      : capitalCase(entityType)}{" "}
                    Notifications
                  </div>
                )}

                <IconButton size="small" onClick={handleClose}>
                  <Iconify
                    icon="ion-close"
                    sx={{
                      height: "24px",
                      width: "24px",
                    }}
                  />
                </IconButton>
              </Box>
            </DialogTitle>

            <DialogContent
              sx={{
                // height: "400px",
                padding: "0px 24px 10px 24px",
              }}
            >
              {item?.is_mute === false ? (
                <Box pt={0}>
                  <Typography variant="body1" fontWeight={500}>
                    What will get muted in this {entityType}?
                  </Typography>
                  <Typography variant="body2">
                    {entityType === "workspace"
                      ? "All projects in this workspace, including their tasks notification alerts and sounds will be muted. You will still receive muted notifications."
                      : "All tasks in this project will be muted. You won't receive any notification alerts or sounds, You will still receive muted notifications."}
                  </Typography>

                  {/* ----------------------------------------------- */}
                  <div className="menu-select-date mt-3">
                    <Typography variant="body2" sx={{ pb: 0.8 }}>
                      Mute all notifications alerts for the
                      <b className="px-1">
                        "{entityType === "project" ? item?.title : item?.name}"
                      </b>
                    </Typography>
                    <Select
                      size="small"
                      value={muteDuration}
                      onChange={(e) => setMuteDuration(e.target.value)}
                      fullWidth
                      variant="outlined"
                      displayEmpty
                    >
                      <MenuItem value={1 / 24}>1 Hour</MenuItem>
                      <MenuItem value={1}>1 Day</MenuItem>
                      <MenuItem value={7}>1 Week</MenuItem>
                      <MenuItem value={30}>1 Month</MenuItem>
                    </Select>
                  </div>
                </Box>
              ) : (
                <Box pt={0}>
                  {item?.mute_type === "workspace" ? (
                    <div>
                      <Typography variant="body1">
                        This project is muted because you have muted the <br />
                        <b className="px-1">
                          "{item.workspace ? item?.workspace.name : item.name}"
                        </b>
                        workspace.
                      </Typography>
                      <Typography variant="body2" mt={1}>
                        <Iconify
                          icon="dashicons:warning"
                          marginBottom="2px"
                          sx={{
                            color: "#FF9800",
                            height: "16px",
                            width: "16px",
                            flexShrink: 0,
                            mr: 0.5,
                          }}
                        />
                        This action will unmute your workspace and all the
                        projects in it. Are you sure?
                      </Typography>
                    </div>
                  ) : (
                    <Typography variant="body1">
                      Un-mute all notifications alerts for the
                      <b className="px-1">
                        "{entityType === "project" ? item?.title : item?.name}"
                      </b>
                      {entityType}
                      <br />
                      Are you sure?
                    </Typography>
                  )}
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              {item?.is_mute === false ? (
                <LoadingButton
                  variant="contained"
                  onClick={muteNotifications}
                  loading={loadingBtn}
                >
                  Mute {entityType}
                </LoadingButton>
              ) : (
                <>
                  <LoadingButton
                    variant="contained"
                    onClick={unMuteNotifications}
                    loading={loadingBtn}
                  >
                    {item?.mute_type === "workspace"
                      ? "Unmute Workspace"
                      : `Unmute ${entityType}`}
                  </LoadingButton>
                </>
              )}

              <Button variant="text" onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
