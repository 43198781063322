import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import moment from "moment";
import { capitalCase } from "change-case";
import { string_with_limited_length } from "src/utils/common";

export default function ViewTemplateDialog({ open, onClose, template, task }) {

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        fullWidth
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize="14px" fontWeight={500} noWrap>
              {template?.name}
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                onClose();
              }}
            >
              <Iconify
                icon="ion-close"
                sx={{
                  height: "24px",
                  width: "24px",
                }}
              />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent
          sx={{
            minHeight: "335px",
            padding: "20px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <>
              {template?.items.map((item, index) => (
                <Box
                  key={index}
                  sx={{ mb: 0.5, borderBottom: "1px solid #e9ecef" }}
                >
                  <div className="d-flex gap-1">
                    <div style={{ width: "15px" }}>
                      <Typography className="ps-1" fontSize="12px">
                        {index + 1}.
                      </Typography>
                    </div>
                    <Typography className="pb-1" fontSize="12px">
                      {capitalCase(item.name)}
                    </Typography>
                  </div>
                </Box>
              ))}

              {template?.items.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "250px",
                  }}
                >
                  <Typography fontSize="12px" color="text.disabled">
                    No Tasks Found!
                  </Typography>
                </Box>
              )}
            </>
          </Box>

          {/* Date section at the bottom */}
          <Box sx={{ mt: "auto", pt: 2 }}>
            {/* <div className="d-flex align-items-center mb-1">
              <Typography className="fs-10 fw-5">
                {string_with_limited_length(task?.workspace.name, 25)}
              </Typography>
              <Iconify icon="grommet-icons:next" sx={{ mx: 0.5 , fontSize:"10px" }} />
              <Typography className="fs-10 fw-5">
                {string_with_limited_length(capitalCase(task.project.name), 25)}
              </Typography>
              <Iconify icon="grommet-icons:next" sx={{ mx: 0.5, fontSize:"10px" }} />
              <Typography className="fs-10 fw-5">
                {string_with_limited_length(
                  task?.sectionData.section_title,
                  25
                )}
              </Typography>
              <Iconify icon="grommet-icons:next" sx={{ mx: 0.5, fontSize:"10px" }} />
              <Typography className="fs-10 fw-5">
                {string_with_limited_length(
                  task?.task_title,
                  25
                )}
              </Typography>
            </div> */}

            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-2">
                <Typography fontSize="12px" fontWeight="500">
                  Updated At:
                </Typography>
                <Typography fontSize="11px" fontWeight="400">
                  {moment(template?.updatedAt).format("DD MMM YYYY hh:mm A")}
                </Typography>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Typography fontSize="12px" fontWeight="500">
                  Created At:
                </Typography>
                <Typography fontSize="11px" fontWeight="400">
                  {moment(template?.createdAt).format("DD MMM YYYY hh:mm A")}
                </Typography>
              </div>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
