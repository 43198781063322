import React, { useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Box,
  Typography,
  Avatar,
  ButtonBase,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { capitalCase } from "change-case";
import { s3baseUrl } from "src/config/";
import { Iconify, Label } from "src/components";
import { useAppContext } from "src/hooks";
import { capitalize } from "lodash";
import LabelRole from "src/components/LabelRole";
import RemoveFromProjectDialog from "./RemoveFromProjectDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// ---------------------------------------------------------
const UserCard = ({
  item,
  userRole,
  onRemoveUser,
  onSettings,
  setMemberSettings = () => {},
  allowedMembersForClient = [],
  userProjects = [],
  getSelectedWorkspaceProjects = () => {},
  setMemberSettingDrawerOpen,
}) => {
  // ---------------------------------------------------------
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleOpenModal = () => {
    setModalOpen(true);
    setSelectedUser(item);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedUser(null);
    handleClose();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // const { user_profile, role } = item;
  let membersToDisplayForClient = [];
  allowedMembersForClient.map((member) => {
    let index = item.allow_users.indexOf(member.user_id);
    if (index !== -1) {
      membersToDisplayForClient = [...membersToDisplayForClient, member];
    }
  });
  const { image, first_name, last_name, role } = item;
  const { dispatch_set_current_selected_item } = useAppContext();
  const canRemove = (_role) => {
    if (String(userRole) === "admin") {
      return true;
    }
    if (String(userRole) == "manager") {
      if (_role == "admin") {
        return false;
      }
      return true;
    }
    if (String(userRole) === "team") {
      return false;
    }
    // if (String(_role) === String(userRole)) {
    //   return false;
    // }
    return false;
  };
  const canChangeSettings = (_role) => {
    if (userRole == "admin" && _role !== "admin") {
      return true;
    }
    if (userRole == "manager") {
      return true;
    }
    return false;
  };
  const handleRemove = () => {
    dispatch_set_current_selected_item(item);
    onRemoveUser();
    handleClose();
  };
  const handleSettings = () => {
    setMemberSettings(item);
    dispatch_set_current_selected_item(item);
    onSettings();
    handleClose();
  };
  // ---------------------------------------------------------
  return (
    <>
      <Paper onClick={() => console.log(item)} elevation={3} sx={{ p: 1 }}>
        <Stack spacing={1} direction="column">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            // alignItems={"center"}
          >
            <Stack direction="row" alignItems={"center"}>
              <Box alignItems="center" justifyContent="center">
                {image ? (
                  <Avatar src={s3baseUrl + image} />
                ) : (
                  <Avatar>{first_name.substring(0, 2)}</Avatar>
                )}
              </Box>
              <Stack spacing={0.5} sx={{ ml: 1 }}>
                <Typography variant="body1" noWrap>
                  {first_name + " " + last_name}
                </Typography>
              </Stack>
            </Stack>
            <div className="d-flex align-items-center gap-2">
              <div>
                <Box sx={{ marginLeft: "auto" }}>
                  <Tooltip title="Your Role">
                    <div>
                      <LabelRole role={role}>{capitalCase(role)}</LabelRole>
                    </div>
                  </Tooltip>
                </Box>
              </div>
              {canChangeSettings(role) && role !== "client_team" && (
                <div >
                  {/* ------------ Menu ------------ */}
                  <IconButton size="small" onClick={handleClick}>
                    <Iconify icon="eva:more-vertical-fill" />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      sx={{
                        fontSize: "12px",
                      }}
                      onClick={handleSettings}
                    >
                      <Iconify
                        icon="ic:baseline-settings"
                        sx={{ height: "16px", width: "16px", mr: 1 }}
                      />
                      Member Settings
                    </MenuItem>
                    {canRemove(role) && (
                      <>
                        <MenuItem
                          sx={{
                            fontSize: "12px",
                          }}
                          onClick={handleOpenModal}
                        >
                          <Iconify
                            icon="iconoir:grid-remove"
                            sx={{
                              height: "16px",
                              width: "16px",
                              mr: 1,
                            }}
                          />
                          Remove From Projects
                        </MenuItem>
                        <MenuItem
                          sx={{ fontSize: "12px" }}
                          onClick={handleRemove}
                        >
                          <Iconify
                            icon="mdi:folder-remove-outline"
                            sx={{
                              height: "16px",
                              width: "16px",
                              mr: 1,
                            }}
                          />
                          Remove From Workspace
                        </MenuItem>
                      </>
                    )}
                  </Menu>
                </div>
              )}
            </div>
          </Stack>
          <Stack spacing={1}>
            <Stack spacing={0}>
              <Accordion sx={{ background: "#F7F9F8" }}>
                <AccordionSummary
                  className="accordion-Summary"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography fontWeight={600} fontSize="12px">
                    Projects
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "10px" }} className="pt-0">
                  {userProjects.length > 0 ? (
                    <>
                      {userProjects.map((project, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          spacing={1}
                          sx={{ mt: 0 }}
                        >
                          <Typography fontSize="12px" noWrap>
                            {project.title}
                          </Typography>
                        </Stack>
                      ))}
                    </>
                  ) : (
                    <Typography fontSize="12px">
                      Not invited in any project
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Stack>
            {(role == "client" || role == "client_team") &&
              userRole !== "client" &&
              userRole !== "client_team" && (
                <Stack spacing={0}>
                  <Accordion sx={{ background: "#F7F9F8" }}>
                    <AccordionSummary
                      className="accordion-Summary"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography fontWeight={600} fontSize="12px">
                        Team members visible to the client
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "10px" }} className="pt-0">
                      <List sx={{ pt: 0, pb: 0 }}>
                        {membersToDisplayForClient.map((member) => {
                          return (
                            <ListItem key={member.user_id} sx={{ p: 0.3 }}>
                              <Avatar
                                src={s3baseUrl + member.image}
                                sx={{ width: "22px", height: "22px", mr: 1 }}
                              >
                                {capitalize(member.first_name.substring(0, 1))}
                              </Avatar>
                              <Typography fontSize="12px" noWrap>
                                {member.first_name +
                                  " " +
                                  member.last_name +
                                  " (" +
                                  member.role +
                                  ")"}
                              </Typography>
                            </ListItem>
                          );
                        })}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              )}
          </Stack>
        </Stack>
      </Paper>
      {/* ==================================================== */}
      <RemoveFromProjectDialog
        onOpen={modalOpen}
        onClose={handleCloseModal}
        selectedUser={selectedUser}
        projectsList={userProjects}
        getSelectedWorkspaceProjects={getSelectedWorkspaceProjects}
        setMemberSettingDrawerOpen={setMemberSettingDrawerOpen}
      />
    </>
  );
};

export default UserCard;
