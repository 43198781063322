import React, { useRef } from "react";
import MessageItem from "./MessageItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { capitalize } from "lodash";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Divider,
} from "@mui/material";
import { upload_audio, upload_image } from "src/utils/fileUpload";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { useState } from "react";
import ImageSelector from "src/components/ImageSelector";
import { useAppContext } from "src/hooks";
import { Iconify } from "src/components";
import { useEffect } from "react";
import { _editMessage, _seenMessages } from "src/DAL";
import { useSnackbar } from "notistack";
import moment from "moment";
import VoiceRecorder from "../../../components/VoiceRecorder";
import ImageIcon from '@mui/icons-material/Image';
import ReplyMessageContainer from "./ReplyMessage";


function MessageContainer({
  loadingMessageList,
  messageCount,
  loadMoreMessages,
  currentChat,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { messageList, setMessageList, generateUniqueIdForMessage } =
    useAppContext();
  const { socketEmit, dispatch_get_user_profile } = useAppContext();
  const [isEditingMessage, setIsEditingMessage] = useState(false);
  const [targetEditMessage, setTargetEditMessage] = useState();
  const [repliedMessage, setRepliedMessage] = useState();
  const textareaRef = useRef(null);
  ///////////////////////////////Send Message/////////////////////////////////////////////////////////

  const [chatFile, setChatFile] = useState();
  const [chatAudio, setChatAudio] = useState();
  const [newMessageText, setNewMessageText] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isShowRecordingElement, setIsShowRecordingElement] = useState(false);
  const [isDiscardRecording, setIsDiscardRecording] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioDurationInSecond, setAudioDurationInSecond] = useState(0);
  const [isReplyIconVisible, setIsReplyIconVisible] = useState(false);
  const [hoveredMessageId, setHoveredMessageId] = useState();
  const [replyMessage, setReplyMessage] = useState(null);

  const handleSendMessage = async () => {
    if (isSendingMessage || (newMessageText.trim() == "" && !chatFile && !chatAudio)) {
      return;
    }

    setIsSendingMessage(true);
    try {
      let chatImg = "";
      if (chatFile) {
        console.log(chatFile, "Img File");

        const imgUplaodResult = await upload_image(chatFile);
        chatImg = imgUplaodResult.path;
      }
      if (chatAudio) {
        console.log("chat audio File", chatAudio);
        console.log("chat audio Duration", audioDurationInSecond);
        const audioUploadResult = await upload_audio(chatAudio, audioDurationInSecond);

        chatImg = audioUploadResult.path;
      }

      let req_data = {
        text: newMessageText,
        image: chatImg,
        message_status: 0,
        chat_id: currentChat._id,
        receiver_id: currentChat.user.map((user) => {
          return user.user_id;
        }),
        reply_message_id: replyMessage?._id || null,
        temp_id: generateUniqueIdForMessage(),
        chat_type: currentChat.type,
        audio_duration: (chatAudio ? audioDurationInSecond : ""),
      };

      console.log(req_data, "kdk");
      let tempMessageData = {
        ...req_data,
        audio_duration: (chatAudio ? audioDurationInSecond : ""),
        createdAt: moment().toISOString(),
        message_exist: true,
        message_sender: dispatch_get_user_profile(),
        message_type: "general",
        sender_id: dispatch_get_user_profile().user_id,
        _id: "",
      };

      setMessageList((prev) => [tempMessageData, ...prev]);
      socketEmit("sendMessage", {
        ...req_data,
        user_id: dispatch_get_user_profile().user_id,
      });
      setChatFile();
      setNewMessageText("");
      setChatAudio();
      setAudioDurationInSecond(0);
      setReplyMessage(null);
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched while sending message");
    } finally {
      setIsSendingMessage(false);
    }
  };

  const handleSendEditMessage = async (e) => {
    e.preventDefault();
    setReplyMessage(null);
    if (isSendingMessage || (newMessageText.trim() == "" && !chatFile)) {
      return;
    }
    if (!targetEditMessage) {
      return;
    }
    setIsSendingMessage(true);
    try {
      const data = { text: newMessageText, };
      const result = await _editMessage(data, targetEditMessage._id);
      if (result.code == 200) {
        socketEmit("editMessage", {
          message_id: targetEditMessage._id,
          user_id: targetEditMessage.sender_id,
          text: newMessageText,
        });
        setNewMessageText("");
        setIsEditingMessage(false);
        enqueueSnackbar("Message Edited successfully", { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched while editing message");
    } finally {
      setIsSendingMessage(false);
    }
  };
  /////////////////////////Text area change////////////////////////////////////////////
  let shift_pressed = false;
  const handleAreaChange = (e) => {
    const { value } = e.target;
    if (isEditingMessage && value.trim() == "") {
      setIsEditingMessage(false);
    }
    if (shift_pressed) {
      setNewMessageText(value);
      return;
    }
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      setNewMessageText(value);
      return;
    }
    if (e.nativeEvent.inputType === "deleteWordBackward") {
      setNewMessageText(value);
      return;
    }
    if (e.nativeEvent.inputType === "insertText") {
      setNewMessageText(value);
      return;
    }
    if (e.nativeEvent.inputType === "insertFromPaste") {
      setNewMessageText(value);
      return;
    }
    if (e.nativeEvent.data === null) {
      if (e.nativeEvent.inputType === "insertFromPaste") {
        return;
      }
      if (e.nativeEvent.inputType === "deleteWordBackward") {
        return;
      }
      !isEditingMessage && handleSendMessage(e);
      isEditingMessage && handleSendEditMessage(e);
      return;
    }
    setNewMessageText(value);
  };
  const handleAreaKeyUp = (e) => {
    const { key } = e;
    if (key == "Shift") {
      shift_pressed = false;
    }
  };
  const handleAreaKeyDown = (e) => {
    if (e.shiftKey) {
      shift_pressed = true;
    }
  };

  const formatMessageDate = (date) => {
    const messageDate = moment(date);
    if (messageDate.isSame(moment(), 'day')) {
      return "Today";
    } else if (messageDate.isSame(moment().subtract(1, 'day'), 'day')) {
      return "Yesterday";
    } else {
      return messageDate.format("MMM DD");
    }
  };

  const handleClosePage = () => {
    onStop();
    setNewMessageText("");
    setChatAudio();
    setChatFile();
    setAudioDurationInSecond(0);
    setReplyMessage(null);
    console.log("called by close");
  }

  ////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (currentChat) {
      _seenMessages(currentChat._id);
      setReplyMessage(null);
    }
    return () => { handleClosePage() };
  }, [currentChat]);
  //////////////////////////TEXT AREA EVENT LISTENERS FOR IMAGE///////
  const handlePaste = (e) => {
    let file = e.clipboardData.files[0];
    if (file) {
      if (!file.type.includes("image")) return;
      setChatFile(file);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    let file = e.dataTransfer.files[0];
    if (file) {
      if (!file.type.includes("image")) return;
      setChatFile(file);
    }
  };
  function checkDateSame(item1, item2) {
    const date1 = item1?.createdAt.slice(0, 10);
    const date2 = item2?.createdAt.slice(0, 10);

    return date1 == date2;
  }


  const onStop = (audioBlob, audioDuration) => {
    if (!isDiscardRecording) {
      console.log("Recording completed: ", audioBlob);
      console.log("Recording File Duration: ", audioDuration);
      setAudioDurationInSecond(audioDuration);
      setChatAudio(audioBlob);
    } else {
      setIsDiscardRecording(false);
      console.log("Recording Discarded ... ");
    }
    setIsShowRecordingElement(false);
  };

  function handleDoubleClick(message) {
    return () => {
      setReplyMessage(message);
      textareaRef.current.focus();
      console.log("Message selected: ", message);
    };
  }

  useEffect(() => {
    if (chatAudio !== "") {
      handleSendMessage();
    }
  }, [chatAudio]);
  ////////////////////////////////////////////////////////////////////
  return (
    <div className="chat-messages-parent">
      <div className={`messages-container 
        ${replyMessage ? "messages-container-margin" : ""} 
        ${chatFile ? "messages-container-file" : ""}
        ${(replyMessage && chatFile) ? "messages-container-both" : ""}`
      }>
        {!loadingMessageList && (
          <>
            {messageList.length > 0 && (
              <div
                id="scrollableDiv"
                className="scroller scroll-div"
                style={{}}
              >
                <InfiniteScroll
                  dataLength={messageList.length}
                  next={() => {
                    loadMoreMessages();
                  }}
                  style={{
                    overflow: "hidden",
                  }}
                  className="infinite-scroll"
                  inverse={true}
                  hasMore={messageCount > messageList.length}
                  loader={
                    <CircularProgress
                      className="circular-progress"
                    />
                  }
                  scrollableTarget="scrollableDiv"
                >
                  {[...messageList].map((message, index) => {
                    return (
                      <div key={index} id={message._id}
                        onDoubleClick={handleDoubleClick(message)}
                        onMouseEnter={() => {
                          setIsReplyIconVisible(true);
                          setHoveredMessageId(message._id);
                        }}
                        onMouseLeave={() => {
                          setIsReplyIconVisible(false);
                        }}
                      >
                        {!checkDateSame(messageList[index], messageList[index + 1]) &&
                          <div className="divider-pad">
                            <Divider className="divider-content" style={{ marginTop: (messageList.length - 1 === index) ? "30px" : "0px" }}>
                              {formatMessageDate(message.createdAt)}
                            </Divider>
                          </div>
                        }
                        <MessageItem
                          hoveredMessageId={hoveredMessageId}
                          isReplyIconVisible={isReplyIconVisible}
                          setRepliedMessage={setRepliedMessage}
                          repliedMessage={repliedMessage}
                          setReplyMessage={setReplyMessage}
                          loadMoreMessages={loadMoreMessages}
                          isDateSame={checkDateSame(messageList[index], messageList[index + 1])}
                          key={message.temp_id ? message.temp_id : message._id}
                          message={message}
                          setIsEditingMessage={setIsEditingMessage}
                          setNewMessageText={setNewMessageText}
                          setTargetEditMessage={setTargetEditMessage}
                          nextMessage={
                            index !== messageList.length - 1 ? messageList[index + 1] : null
                          }
                          textareaRef={textareaRef}
                        />
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </div>
            )}
            {messageList.length == 0 && (
              <div className="h-100 d-flex justify-content-center align-items-center">
                <Typography>No messages yet!</Typography>
              </div>
            )}
            <div className={`chat-bottom-container 
              ${replyMessage ? "chat-bottom-container-margin" : ""}
              ${chatFile ? "chat-bottom-container-file" : ""}
              ${(replyMessage && chatFile) ? "chat-bottom-container-both" : ""}`
            }>
              <div className="chat-bottom-bar">
                <div className="message-editor">
                  <div className="d-flex align-items-center justify-content-between">
                    {!(isShowRecordingElement) &&
                      <div className="pe-0 d-flex justify-content-start">
                        <div className="image-icon-parent pe-0 d-flex align-items-end">
                          <label htmlFor="icon-button-file" className="m-0">
                            <input
                              className="d-none"
                              accept="image/png,image/jpg,image/jpeg"
                              id="icon-button-file"
                              type="file"
                              onChange={(e) => setChatFile(e.target.files[0])}
                            />
                            <Tooltip arrow title="Upload Image">
                              <IconButton
                                aria-label="upload picture"
                                component="span"
                                className="icon-button-upload textarea-btn me-2"
                              >
                                <PhotoSizeSelectActualIcon className="voice-icon" />
                              </IconButton>
                            </Tooltip>
                          </label>
                        </div>
                      </div>
                    }
                    {!(isShowRecordingElement) &&
                      <div className="text-area-parent">
                        <div className="textarea-container">
                          {chatFile && (
                            <div
                              className="image-div"
                              style={{ bottom: (!isEditingMessage && replyMessage) ? "calc(100% + 63px)" : "100%" }}
                            >
                              <ImageSelector
                                file={chatFile}
                                setFile={setChatFile}
                              />
                            </div>
                          )}
                          <textarea
                            ref={textareaRef}
                            value={newMessageText}
                            autoFocus={true}
                            onPaste={handlePaste}
                            onDrop={handleDrop}
                            onChange={handleAreaChange}
                            onKeyDown={handleAreaKeyDown}
                            onKeyUp={handleAreaKeyUp}
                            className="send-message-area"
                            style={{ borderTopLeftRadius: (chatFile || replyMessage) ? "0" : "10px", borderTopRightRadius: (chatFile || replyMessage) ? "0" : "10px" }}
                            placeholder="Message..."
                          />
                          {(!isEditingMessage && replyMessage) &&
                            <ReplyMessageContainer replyMessage={replyMessage} setReplyMessage={setReplyMessage} />
                          }
                        </div>
                      </div>
                    }
                    <div className="d-flex justify-content-evenly align-items-center ps-0"
                      style={{ width: isShowRecordingElement ? "100%" : "" }}
                    >
                      {(!isEditingMessage) && (isShowRecordingElement) &&
                        <div style={{ position: "relative", width: "100%" }}>
                          <VoiceRecorder
                            className="recording-container"
                            onStop={onStop}
                            hidden={false}
                            isStartRecording={isRecording}
                            isBorderRadius={replyMessage}
                          />
                          {(replyMessage) &&
                            <ReplyMessageContainer replyMessage={replyMessage} setReplyMessage={setReplyMessage} />
                          }
                        </div>
                      }

                      {(!isEditingMessage) && (
                        (isShowRecordingElement) ?
                          <Tooltip arrow title="Cancel Recording">
                            <IconButton
                              className="textarea-btn mx-2"
                              color="secondary"
                              onClick={() => {
                                setIsRecording(false);
                                setIsDiscardRecording(true);
                              }}
                            >
                              <CancelIcon className="voice-icon" color="error" />
                            </IconButton>
                          </Tooltip>
                          :
                          <Tooltip arrow title="Start Recording">
                            <IconButton
                              className="textarea-btn mx-2"
                              onClick={(e) => {
                                setIsRecording(true);
                                setIsShowRecordingElement(true);
                              }}
                            >
                              <KeyboardVoiceIcon className="voice-icon" />
                            </IconButton>
                          </Tooltip>
                      )}

                      {(isShowRecordingElement) && (
                        <Tooltip arrow title="Send Recording">
                          <IconButton
                            className="textarea-btn"
                            onClick={(e) => {
                              setIsRecording(false);
                            }}
                          >
                            <SendIcon className="voice-icon" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {(!isShowRecordingElement) && ((isEditingMessage) ? (
                        <div className="d-flex">
                          <Tooltip arrow title="Cancel Edit">
                            <IconButton
                              className="textarea-btn mx-2"
                              color="secondary"
                              onClick={() => {
                                setNewMessageText("");
                                setChatFile(null);
                                setIsEditingMessage(false);
                              }}
                            >
                              <CancelIcon className="voice-icon" color="error" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Send Message">
                            <div>
                              <IconButton
                                className="textarea-btn"
                                disabled={
                                  isSendingMessage ||
                                  (newMessageText.trim() === "" && !chatFile)
                                }
                                onClick={(e) => {
                                  !isEditingMessage
                                    ? handleSendMessage(e)
                                    : handleSendEditMessage(e);
                                }}
                              >
                                <SendIcon className="voice-icon" />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </div>
                      ) :
                        (
                          <div className="send-icon-parent">
                            {isSendingMessage ? (
                              <IconButton className="textarea-btn">
                                <CircularProgress
                                  style={{ width: 22, height: 22 }}
                                />
                              </IconButton>
                            ) : (
                              <Tooltip arrow title="Send Message">
                                <div>
                                  <IconButton
                                    className="textarea-btn"
                                    disabled={
                                      isSendingMessage ||
                                      (newMessageText.trim() === "" && !chatFile)
                                    }
                                    onClick={(e) => {
                                      !isEditingMessage
                                        ? handleSendMessage(e)
                                        : handleSendEditMessage(e);
                                    }}
                                  >
                                    <SendIcon className="voice-icon" />
                                  </IconButton>
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {loadingMessageList && (
          <div className="h-100 d-flex justify-content-center align-items-center">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default MessageContainer;
