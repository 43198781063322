import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MuiDialog } from "src/components";
import { useSnackbar } from "notistack";
import { useAppContext } from "src/hooks";
import { useState } from "react";
export default function MessageMenu({
  anchorEl,
  setAnchorEl,
  message,
  setIsEditingMessage,
  setNewMessageText,
  setTargetEditMessage,
}) {
  const { socketEmit } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteMessageLoading, setDeleteMessageLoading] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleEditMessage = (message) => {
    if (message.text !== "") {
      setTargetEditMessage(message);
      setAnchorEl(null);
      setIsEditingMessage(true);
      setNewMessageText(message.text);
    }
  };

  const handleDeleteMessage = async () => {
    setDeleteMessageLoading(true);
    try {
      socketEmit("deleteMessage", message._id);
      setDeleteDialogOpen(false);
      setAnchorEl(null);
      enqueueSnackbar("Message Deleted Successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched while deleting message");
    } finally {
      setDeleteMessageLoading(false);
    }
  };
  return (
    <div>
      <Popover
        id={id}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <List>
          {message.text !== "" && (
            <ListItem className="list-item">
              <ListItemButton onClick={() => handleEditMessage(message)}>
                <ListItemText>
                  <Typography className="typography">Edit</Typography>
                </ListItemText>
                <ListItemIcon className="icon">
                  <EditOutlinedIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          )}
          <ListItem className="list-item">
            <ListItemButton onClick={() => setDeleteDialogOpen(true)}>
              <ListItemText>
                <Typography className="typography">Delete</Typography>
              </ListItemText>
              <ListItemIcon className="icon">
                <DeleteForeverOutlinedIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>

      </Popover>
      <MuiDialog
        open={deleteDialogOpen}
        onToggle={() => setDeleteDialogOpen(false)}
        title="Delete Message"
        message="Are you sure you want to delete this message?"
        onAgree={() => handleDeleteMessage()}
        loading={deleteMessageLoading}
      />
    </div>
  );
}
