import React, { useEffect, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

const VoiceRecorder = ({ hidden, onStop, isStartRecording, isBorderRadius }) => {
  const recorderControls = useAudioRecorder();
  const [hasStarted, setHasStarted] = useState(false);
  const [recordingPause, setRecordingPause] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);


  useEffect(() => {
    if (recorderControls.isRecording && !hasStarted) {
      console.log("Recording started");
      setHasStarted(true);
    }

    if (recorderControls.isPaused) {
      setRecordingPause(true);
      console.log("Recording is paused");
    } else if (recordingPause) {
      console.log("Recording resumed");
      setRecordingPause(false);
    }

  }, [recorderControls.isRecording, recorderControls.isPaused]);

  // useEffect(() => {
  //   if (recorderControls.recordingTime !== 0) {
  //     setAudioDuration(recorderControls.recordingTime);
  //   }
  // }, [recorderControls.recordingTime]);

  useEffect(() => {
    let timer;
    if (hasStarted) {
        timer = setInterval(() => {
            setAudioDuration((prev) => prev + 0.1);
        }, 100);
    }

    return () => {
        clearInterval(timer);
    };
}, [hasStarted]);

  useEffect(() => {
    if(isStartRecording && !hasStarted){
      recorderControls.startRecording();
    }
    else if(hasStarted){
      recorderControls.stopRecording();
    }
  }, [isStartRecording]);

  return (
    <div className={`voice-recorder-container ${hidden ? "hidden" : ""} ${isBorderRadius ? "hide-borRadius": ""}`}>
      <AudioRecorder
      className="no-elevation"
        onRecordingComplete={(audioBlob) => {
          onStop(audioBlob, audioDuration);
          setHasStarted(false);
          setRecordingPause(false);
          setAudioDuration(0);
        }}
        onNotAllowedOrFound={(error) => console.error("Mic access denied or not found", error)}
        onError={(error) => console.error("Recording error", error)}
        audioTrackConstraints={{
          noiseSuppression: true,
          echoCancellation: true,
          // autoGainControl,
          // channelCount,
          // deviceId,
          // groupId,
          // sampleRate,
          // sampleSize,
        }}
        showVisualizer="true"
        mediaRecorderOptions={{
          audioBitsPerSecond: 128000,
          mimeType: "audio/webm",
        }}
        downloadOnSavePress={false}
        downloadFileExtension="mp3"

        recorderControls={recorderControls}
      />
    </div>
  );
};

export default VoiceRecorder;
