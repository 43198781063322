import { get_from_localStorage, invokeApi } from "src/utils";

export const _get_updates_list = async () => {
    const reqObj = {
      method: "GET",
      path: `api/new_updates/features_list_only`,
      headers: {
        "x-sh-auth": get_from_localStorage("token"),
      },
    };
    return invokeApi(reqObj);
};

export const _get_last_update = () => {
  const reqObj = {
    method: "GET",
    path: `api/new_updates/whats_new_latest`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(reqObj);
};

export const _update_view = (data) => {
  const reqObj = {
    method: "POST",
    path: `api/new_updates/mark_update_as_viewed`,
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};

