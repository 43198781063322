import {
  Typography,
  Grid,
  Avatar,
  Button,
  IconButton,
  Tooltip,
  Box,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useAppContext } from "src/hooks";
import { s3baseUrl } from "src/config";
import Iconify from "src/components/Iconify";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimeProgressBar from "./TimeProgressBar";
import { useEffect, useState } from "react";
import moment from "moment";
import TimeSlotsDialog from "./TimeSlotsDialog";
import PlannedTimeDialog from "./PlannedTimeDialog";
import { useSnackbar } from "notistack";

export default function TimeBlock({ task, getTaskDetail }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [spentTime, setSpentTime] = useState(0);
  const [totalPlannedTime, setTotalPlannedTime] = useState(0);
  const [selectedTimeBlock, setSelectedTimeBlock] = useState(null);
  const [openEditPlannedTime, setOpenEditPlannedTime] = useState(false);
  const [editedPlannedTime, setEditedPlannedTime] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const { dispatch_get_user_profile } = useAppContext();
  const userProfile = dispatch_get_user_profile();
  const userId = dispatch_get_user_profile().user_id;

  const calculateSpentTime = (timeTracking) => {
    let sumTime = 0;
    if (timeTracking) {
      timeTracking.forEach((list) => {
        if (list.tracked_time) {
          sumTime += list.tracked_time;
        }
      });
    }
    return sumTime;
  };

  const calculatePlannedTime = (timeTracking) => {
    let sumTime = 0;
    if (timeTracking) {
      timeTracking.forEach((list) => {
        if (list.planned_time) {
          sumTime += list.planned_time;
        }
      });
    }
    return sumTime;
  };

  const formatTimeFromSeconds = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    // const remainingSeconds = seconds % 60;
    // return `${hours.toString().padStart(2, "0")}:${minutes
    //   .toString()
    //   .padStart(2, "0")}`;
    return `${hours > 0 ? `${hours} hr ` : ""}${
      minutes > 0 ? `${minutes} min ` : ""
    }${minutes === 0 && hours === 0 ? `${remainingSeconds} sec` : ""}`;
  };

  // const formatOldTimeToSeconds = (oldFormat) => {
  //   const [hours, minutes] = oldFormat.split(":").map(Number);
  //   return hours * 3600 + minutes * 60;
  // };

  const onClickEditTimeOpen = (timeBlock) => {
    if (timeBlock.assignee.user_id === userProfile?.user_id) {
      const hhmFormat = formatTimeFromSeconds(timeBlock?.planned_time);
      setEditedPlannedTime(hhmFormat);
      setOpenEditPlannedTime(true);
    } else {
      enqueueSnackbar("Cannot edit others planned time", {
        variant: "warning",
      });
      return null;
    }
  };

  const handleViewSlots = (timeBlock) => {
    setSelectedTimeBlock(timeBlock._id);
    setOpenDialog(true);
  };
  const handleViewSlotsClose = () => {
    setSelectedTimeBlock(null);
    setOpenDialog(false);
  };
  const isButtonDisabled = (timeBlock) => {
    return timeBlock.assignee.user_id !== userProfile?.user_id;
  };
  // const isButtonDisabled = (timeBlock) => {
  //   return (
  //     task.role === "admin" ||
  //     task.role === "manager" ||
  //     timeBlock.assignee.user_id === userProfile?.user_id
  //   );
  // };

  useEffect(() => {
    const sumTrackedtime = calculateSpentTime(task.time_tracking);
    setSpentTime(sumTrackedtime);
    const sumPlannedtime = calculatePlannedTime(task.time_tracking);
    setTotalPlannedTime(sumPlannedtime);
  }, [task.time_tracking]);

  return (
    <div className="time-block">
      {/* ---------------------------------------------------------------- */}
      <Divider className="divider-li" component="li" />
      {/* ---------------------------------------------------------------- */}
      <Accordion>
        <AccordionSummary
          className="accordion-Summary ps-0"
          expandIcon={<ExpandMoreIcon className="expand-more-Icon" />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <div className="d-flex justify-content-end pt-3">
                <Iconify
                  icon="mdi:clock-outline"
                  sx={{ height: "24px", width: "24px" }}
                />
              </div>
            </Grid>
            <Grid item xs={11}>
              <div className="pt-3">
                <h5 style={{ fontSize: "1.15rem" }}>Time Blocks</h5>
              </div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div className="ms-5">
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography className="body-one">
                    <Iconify
                      icon="material-symbols:circle"
                      className="round-icon"
                      sx={{
                        color: "primary.main",
                      }}
                    />
                    Spent Time:
                  </Typography>
                  <Typography className="time-display-stats">
                    {formatTimeFromSeconds(spentTime)}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography className="body-one">
                    <Iconify
                      icon="material-symbols:circle"
                      className="round-icon"
                      sx={{
                        color: "#c5ead7",
                      }}
                    />
                    Estimated Time:
                  </Typography>
                  <Typography className="time-display-stats">
                    {formatTimeFromSeconds(totalPlannedTime)}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            {/* ---------------------------------------------------------------- */}
            <TimeProgressBar
              estimatedTime={totalPlannedTime}
              spentTime={spentTime}
            />
            {/* ---------------------------------------------------------------- */}
            <>
              {task?.time_tracking?.map((time_block, i) => {
                return (
                  <Grid container key={i} className="user-info">
                    {/* User Info --------- */}
                    <Grid item xs={4} className="d-flex align-items-center b-r">
                      <Tooltip
                        title={`${time_block.assignee?.first_name} ${time_block.assignee?.last_name}`}
                      >
                        <div>
                          <Avatar
                            sx={{ width: 24, height: 24, fontSize: "12px" }}
                            src={s3baseUrl + time_block.assignee.image}
                          >
                            {!time_block.assignee.image &&
                              `${time_block.assignee?.first_name?.charAt(
                                0
                              )}${time_block.assignee?.last_name?.charAt(0)}`}
                          </Avatar>
                        </div>
                      </Tooltip>
                      <div className="d-flex">
                        <Typography className="body-one">Estimate</Typography>
                        {/* <Tooltip title="Edit"> */}
                        <Typography
                          className="estimate-time"
                          // onClick={() => onClickEditTimeOpen(time_block)}
                        >
                          {formatTimeFromSeconds(time_block.planned_time)}
                        </Typography>
                        {/* </Tooltip> */}
                      </div>
                    </Grid>

                    {/* Spent Time --------- */}
                    <Grid item xs={4} className="d-flex align-items-center b-r">
                      <div className="d-flex">
                        <Typography className="body-one">Spent</Typography>
                        <Typography className="d-flex align-items-center time-display-stats">
                          {formatTimeFromSeconds(time_block.tracked_time)}
                        </Typography>
                      </div>
                    </Grid>
                    {/* Status --------- */}
                    <Grid item xs={2} className="d-flex align-items-center">
                      <Typography className="body-one">Status</Typography>
                      {time_block.tracking_status === "start" && (
                        <Typography className="time-display-stats">
                          Started
                        </Typography>
                      )}
                      {time_block.tracking_status === "pause" && (
                        <Typography className="time-display-stats">
                          Paused
                        </Typography>
                      )}
                      {time_block.tracking_status === "stop" && (
                        <Typography
                          className="time-display-stats"
                          color="primary.main"
                        >
                          Completed
                        </Typography>
                      )}
                    </Grid>
                    {/* View Time Slots --------- */}
                    <Grid item xs={2} className="d-flex justify-content-end">
                      <Tooltip title="View Time Slots">
                        <span>
                          <IconButton
                            color="info"
                            size="small"
                            onClick={() => handleViewSlots(time_block)}
                            disabled={isButtonDisabled(time_block)}
                            sx={{
                              "&:hover": {
                                borderRadius: "5px",
                              },
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: 700 }}
                            >
                              View
                            </Typography>
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* ----------------------------------------------------*/}
      {/* Edit Planned Time Dialog ---------------------------*/}
      {/* <PlannedTimeDialog
        userId={userId}
        task={task}
        openPlannedTimeModal={openEditPlannedTime}
        setOpenPlannedTimeModal={setOpenEditPlannedTime}
        plannedTime={editedPlannedTime}
        setPlannedTime={setEditedPlannedTime}
        getTaskDetail={getTaskDetail}
      /> */}
      {/* ---------------------------------------------*/}
      {/* Time Slots Dialog ---------------------------*/}
      <TimeSlotsDialog
        task={task}
        open={openDialog}
        onClose={handleViewSlotsClose}
        timeBlockID={selectedTimeBlock}
        formatTimeFromSeconds={formatTimeFromSeconds}
      />
      {/* ---------------------------------------------------------------- */}
      <Divider className="divider-li" component="li" />
    </div>
  );
}
