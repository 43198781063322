import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Tooltip,
  Tabs,
  Tab,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import { capitalCase } from "change-case";
import { string_with_limited_length } from "src/utils/common";
import { useSnackbar } from "notistack";

import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { PriorityPopover, StatusPopover, UserAvatar } from "src/components";
import { useAppContext } from "src/hooks";
import PriorityButton from "./PriorityButton";
import ActivityStatusButton from "./ActivityStatusButton";
import MembersButton from "./MembersButton";
import { isColorLight } from "src/utils";
import { s3baseUrl } from "src/config";

export default function ActivityInfoDialog({
  open,
  onClose,
  activity,
  getActivityText,
}) {
  console.log(activity, "Acti_vity");
  const [imageError, setImageError] = useState(false);

  const handleClose = () => {
    onClose();
    setImageError(false);
  };

  return (
    <div className="task-activity-dialog">
      {activity && (
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              Activity Detail
              <IconButton size="small" onClick={handleClose}>
                <Iconify
                  icon="ion-close"
                  sx={{ height: "24px", width: "24px" }}
                />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent sx={{ padding: "0px 20px 20px 20px" }}>
            {activity.action_by.user_id && (
              <>
                <div className="mb-2">
                  <Typography fontSize="12px">
                    {getActivityText(activity)}
                  </Typography>
                </div>

                <div>
                  {/* target date update ----------------------- */}
                  {activity?.type === "target_date_update" && (
                    <div className="d-flex align-items-center gap-3">
                      {activity?.more_data?.old_data !== "null" && (
                        <>
                          <div style={{ minWidth: "180px" }}>
                            <Typography fontSize={"10px"} fontWeight={600}>
                              Old Value
                            </Typography>
                            <Typography
                              className="date-update"
                              fontSize={"12px"}
                            >
                              {moment(activity?.more_data?.old_data).format(
                                "LLL"
                              )}
                            </Typography>
                          </div>

                          <Iconify
                            icon="mingcute:arrow-right-line"
                            className="arrow-icon-activity"
                          />
                        </>
                      )}

                      <div style={{ minWidth: "180px" }}>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Updated Value
                        </Typography>
                        <Typography className="date-update" fontSize={"12px"}>
                          {moment(activity?.more_data?.new_data).format("LLL")}
                        </Typography>
                      </div>
                    </div>
                  )}
                  {/* priority update ------------------------ */}
                  {activity?.type === "priority_update" && (
                    <div className="d-flex align-items-center gap-3">
                      <div style={{ minWidth: "180px" }}>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Old Value
                        </Typography>
                        <PriorityButton
                          value={activity?.more_data?.old_data}
                          allowOpen={false}
                          onChange={() => {}}
                        />
                      </div>
                      <Iconify
                        icon="mingcute:arrow-right-line"
                        className="arrow-icon-activity"
                      />
                      <div style={{ minWidth: "180px" }}>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Updated Value
                        </Typography>
                        <PriorityButton
                          value={activity?.more_data?.new_data}
                          allowOpen={false}
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  )}
                  {/* status update -------------------------- */}
                  {activity?.type === "status_update" && (
                    <div className="d-flex align-items-center gap-3">
                      <div style={{ minWidth: "200px" }}>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Old Value
                        </Typography>
                        <ActivityStatusButton
                          value={activity?.more_data?.old_data}
                          allowOpen={false}
                        />
                      </div>
                      <Iconify
                        icon="mingcute:arrow-right-line"
                        className="arrow-icon-activity"
                      />
                      <div style={{ minWidth: "200px" }}>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Updated Value
                        </Typography>
                        <ActivityStatusButton
                          value={activity?.more_data?.new_data}
                          allowOpen={false}
                        />
                      </div>
                    </div>
                  )}
                  {/* task title update ---------------------- */}
                  {activity?.type === "task_title_change" && (
                    <>
                      <div>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Old Title
                        </Typography>
                        <div className="activity-paper">
                          <Typography className="activity-title">
                            {activity?.more_data?.old_data.task_title}
                          </Typography>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center pt-3 pb-2">
                        <Iconify
                          icon="mingcute:arrow-down-line"
                          className="arrow-icon-down-activity"
                        />
                      </div>

                      <div>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Updated Title
                        </Typography>
                        <div className="activity-paper">
                          <Typography className="activity-title">
                            {activity?.more_data?.new_data.task_title}
                          </Typography>
                        </div>
                      </div>
                    </>
                  )}
                  {/* member added / removed ---------------------- */}
                  {(activity?.type === "member_added" ||
                    activity?.type === "member_removed") && (
                    <div>
                      <Typography fontSize={"10px"} fontWeight={600}>
                        {activity?.type === "member_added"
                          ? "Added Member"
                          : "Removed Member"}
                      </Typography>
                      <div className="activity-member">
                        <UserAvatar
                          sx={{ width: 25, height: 25 }}
                          image={activity?.more_data?.new_data?.image || " "}
                          alt={activity?.more_data?.new_data?.first_name}
                        />
                        <Typography fontSize="13px" fontWeight={500}>
                          {activity?.more_data?.new_data?.first_name}{" "}
                          {activity?.more_data?.new_data?.last_name}
                        </Typography>
                      </div>
                    </div>
                  )}
                  {/* label added / removed ---------------------- */}
                  {(activity?.type === "tag_color_added" ||
                    activity?.type === "tag_color_removed") && (
                    <div>
                      <Typography fontSize={"10px"} fontWeight={600}>
                        {activity?.type === "tag_color_added"
                          ? "Added Label"
                          : "Removed Label"}
                      </Typography>
                      <div
                        style={{
                          backgroundColor:
                            activity?.more_data.new_data.color_code,
                          borderRadius: "5px",
                          maxWidth: "max-content",
                          minHeight: "30px",
                          padding: "0px 10px",
                          fontSize: "13px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          color: isColorLight(
                            activity?.more_data.new_data.color_code
                          )
                            ? "#000"
                            : "#fff",
                        }}
                      >
                        {activity?.more_data.new_data.name}
                      </div>
                    </div>
                  )}
                  {/* Description --------------------------------- */}

                  {activity?.type === "task_description_change" && (
                    <>
                      {activity?.more_data?.old_data.task_description && (
                        <>
                          <div>
                            <Typography fontSize={"10px"} fontWeight={600}>
                              Old Description
                            </Typography>
                            <div className="activity-description">
                              {activity?.more_data?.old_data
                                ?.task_description ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      activity?.more_data?.old_data
                                        ?.task_description,
                                  }}
                                />
                              ) : (
                                <Typography className="description-text">
                                  { activity?.more_data?.old_data?.task_description}
                                </Typography>
                              )}
                            </div>
                          </div>

                          <div className="d-flex justify-content-center pt-3 pb-2">
                            <Iconify
                              icon="mingcute:arrow-down-line"
                              className="arrow-icon-down-activity"
                            />
                          </div>
                        </>
                      )}

                      <div>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Updated Description
                        </Typography>
                        <div className="activity-description">
                          {activity?.more_data?.new_data?.task_description ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  activity?.more_data?.new_data
                                    ?.task_description,
                              }}
                            />
                          ) : (
                            <Typography className="description-text">
                              {activity?.more_data?.new_data?.task_description}
                              {/* task_description.replace(/<\/?p>/gi, "").trim()} */}
                            </Typography>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {/* attachment add --------------------------------- */}
                  {activity?.type === "attachments_added" && (
                    <>
                      <div>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Attachment
                        </Typography>
                        <div>
                          {!imageError ? (
                            <img
                              style={{ height: "50px", marginLeft: "10px" }}
                              src={s3baseUrl + activity.more_data.new_data}
                              onError={() => setImageError(true)} // Show icon when image is broken
                            />
                          ) : (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Iconify
                                icon="ph:file-x-light"
                                height="50px"
                                width="50px"
                                marginLeft="10px"
                                color="text.disabled"
                              />
                              <Typography
                                fontSize={"10px"}
                                color="text.disabled"
                                marginLeft="5px"
                              >
                                Attachment has been removed!
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {/* attachment removed --------------------------------- */}
                  {activity?.type === "attachments_removed" && (
                    <>
                      <div>
                        <Typography fontSize={"10px"} fontWeight={600}>
                          Attachment
                        </Typography>
                        <Iconify
                          icon="ph:file-x-light"
                          height="50px"
                          width="50px"
                          marginLeft="10px"
                          color="text.disabled"
                        />
                      </div>
                    </>
                  )}

                  {(activity?.type === "estimated_time_change" ||
                    activity?.type === "actual_time_change" ||
                    activity?.type === "final_time_change") && (
                    <>
                      <div className="d-flex align-items-center gap-3">
                        {activity?.more_data?.old_data.time_track !== "" && (
                          <>
                            <div style={{ minWidth: "180px" }}>
                              <Typography fontSize={"10px"} fontWeight={600}>
                                Old Time
                              </Typography>
                              <Typography
                                className="time-update"
                                fontSize={"14px"}
                              >
                                {activity?.more_data?.old_data.time_track}
                              </Typography>
                            </div>

                            <Iconify
                              icon="mingcute:arrow-right-line"
                              className="arrow-icon-activity"
                            />
                          </>
                        )}

                        <div style={{ minWidth: "180px" }}>
                          <Typography fontSize={"10px"} fontWeight={600}>
                            Updated Time
                          </Typography>
                          <Typography className="time-update" fontSize={"13px"}>
                            {activity?.more_data?.new_data.time_track ||
                              "Time Removed"}
                          </Typography>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* =======================================================================*/}
                <hr className="divider" />
                {/* =======================================================================*/}
                <div>
                  <Typography
                    fontSize={"10px"}
                    fontWeight={500}
                    paddingLeft={"3px"}
                  >
                    Action by
                  </Typography>
                  <div
                    className="d-flex align-items-center gap-2"
                    style={{
                      backgroundColor: "#F6F7F8",
                      padding: "5px 10px",
                      borderRadius: "4px",
                      width: "max-content",
                    }}
                  >
                    <UserAvatar
                      sx={{ width: 25, height: 25 }}
                      image={activity?.action_by?.image || " "}
                      alt={activity?.action_by?.first_name}
                    />
                    <Typography fontSize={"12px"}>
                      {activity?.action_by?.first_name}{" "}
                      {activity?.action_by?.last_name} {" - "}{" "}
                      {moment(activity?.created_at).format("LLL")}
                    </Typography>
                  </div>
                </div>
              </>
            )}

            {!activity.action_by.user_id && (
              <Stack direction={"row"} spacing={0.5}>
                <Iconify
                  icon="octicon:dot-fill-24"
                  color="#00ab55"
                  sx={{
                    height: "20px",
                    width: "20px",
                    flexShrink: 0,
                  }}
                />
                <Stack spacing={0.3}>
                  <Typography fontSize={"12px"}>{activity.text}</Typography>
                  <Typography fontSize={"9px"}>
                    {moment(activity.created_at).format("LLL")}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
