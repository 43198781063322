import {
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Tooltip,
  InputLabel,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  Box,
  Avatar,
  ButtonBase,
  DialogActions,
  Paper,
  List,
  ListItem,
} from "@mui/material";
import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useTransition,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Iconify,
  Label,
  MuiAutoComplete,
  MuiDrawer,
  MuiMultiAutocomplete,
  MuiSelect,
  NavBackHeader,
  Page,
} from "src/components";
import { debounce } from "lodash";
import {
  _current_team_list,
  _getAllProjectsInWorkspace,
  _getWorkspaceTeamWithAdmin,
  _invite_user_to_workspace,
  _search_users,
  _user_workspaces,
} from "src/DAL";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { get_from_localStorage } from "src/utils";
import { useAppContext } from "src/hooks";
import {
  _user_workspace_detail,
  _workspace_admin_detail,
} from "src/DAL/workspace";
import TeamUserCard from "src/pages/add-team/components/TeamUserCard";
import DummyUserCard from "src/pages/add-team/components/DummyUserCard";
import { s3baseUrl } from "src/config";
import { _invite_multiple_user_to_workspace } from "src/DAL/invitation";
import { capitalCase } from "change-case";
// ----------------------------------------------------
/* DEBOUNCE SEARCH */
const fetchData = async (query, cb) => {
  const res = await _search_users(undefined, query);
  cb(res);
};
const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 1000);
// ----------------------------------------------------
const ROLE_OPTIONS = [
  {
    label: "Manager",
    value: "manager",
  },
  {
    label: "Team",
    value: "team",
  },
  {
    label: "Client",
    value: "client",
  },
];

function InviteMultipleMembersDialog(props) {
  const isClient = Boolean(props?.client);
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
  const {
    dispatch_get_search_value,
    dispatch_set_search_value,
    socketEmit,
    dispatch_get_user_profile,
    setLimitReachedDialogOpen,
    dispatch_get_plan_limits,
    dispatch_set_plan_limits,
  } = useAppContext();
  const [searchValue, setSearchValue] = useState(dispatch_get_search_value());
  const [roleValue, setRoleValue] = useState(isClient ? "client" : "team");
  const [dragValue, setDragValue] = useState("1");
  const [addProjValue, setAddProjValue] = useState("1");
  const [perHourRate, setPerHourRate] = useState("");
  const [loading, setLoading] = useState(false);
  const [inviteDrawerOpen, setInviteDrawerOpen] = useState(false);
  const [sendingInv, setSendingInv] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userList, setUserList] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [membersAutoComplete, setMembersAutoComplete] = useState([]);
  const [workspaceAdminDetail, setWorkspaceAdminDetail] = useState();
  // console.log(workspaceAdminDetail, "dettttailll");
  const [workspaceDataLoading, setWorkspaceDataLoading] = useState(false);
  const [stateWorkspaceDetail, setStateWorkspaceDetail] = useState();
  const [workspaceAutoComplete, setWorkspaceAutoComplete] = useState(
    props?.workspace
      ? { ...props?.workspace, label: props?.workspace?.name }
      : null
  );
  const [memberOptions, setMemberOptions] = useState([]);
  console.log(memberOptions, "memberOptions");
  const [projects, setProjects] = useState([]);
  const [projectAutoComplete, setProjectAutoComplete] = useState(
    props?.project ? [{ ...props?.project, label: props?.project.title }] : []
  );

  const [selectedUser, setSelectedUser] = useState(null);
  console.log(selectedUser, "isSelected selectedUser");

  const [suggestedMembers, setSuggestedMembers] = useState([]);
  const [suggestedLoading, setSuggestedLoading] = useState(false);

  const [selectedMembersList, setSelectedMembersList] = useState([]);

  const [search, setSearch] = useState("");

  // search suggested members
  const filteredSuggestions = suggestedMembers.filter((member) =>
    `${member.first_name} ${member.last_name} ${member.email}`
      .toLowerCase()
      .includes(search.toLowerCase())
  );

  const handleSaveMember = () => {
    if (!selectedUser || !workspaceAutoComplete) return;
    setSearchValue("");
    const isSelected = {
      user: selectedUser,
      workspace: workspaceAutoComplete,
      role: roleValue,
      dragDrop: dragValue,
      addProject: addProjValue,
    };
    console.log(isSelected, "isSelected");
    setSelectedMembersList((prev) => [...prev, isSelected]);
    setInviteDrawerOpen(false);
  };
  const handleRemoveMember = (email) => {
    setSelectedMembersList((prev) =>
      prev.filter((member) => member.user.email !== email)
    );
  };

  // ----------------------------------------------------
  const getTeamList = async () => {
    setSuggestedLoading(true);
    const result = await _current_team_list();
    if (result.code === 200) {
      console.log(result, "team-res");
      // let temp = [];
      // result.user_workspace.map((workspace)=>{
      //   workspace.team.map((member)=>{
      //     temp.push(member)
      //   })
      // })
      setSuggestedMembers(result.user_workspace);
      setSuggestedLoading(false);
    } else {
      setSuggestedLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // ----------------------------------------------------

  const closeDialog = (event, clickType) => {
    if (clickType !== "backdropClick") {
      if (props?.newWorkspace) {
        props.navigate();
      }
      setWorkspaceAutoComplete(
        props?.workspace
          ? { ...props?.workspace, label: props?.workspace?.name }
          : null
      );
      setProjectAutoComplete(
        props?.project
          ? [{ ...props?.project, label: props?.project.title }]
          : []
      );
      setSelectedMembersList([]);
      setMemberOptions([]);
      setMembersAutoComplete([]);
      setRoleValue("team");
      setInviteDrawerOpen(false);
      setDragValue("1");
      setAddProjValue("1");
      setSearchValue("");
      props?.onClose();
    }
  };

  const getProjectsOfWorkspace = async (id) => {
    try {
      const result = await _getAllProjectsInWorkspace(id);
      if (result.code == 200) {
        setProjects(
          result.project_list.map((project) => {
            return {
              ...project,
              label: project.title,
              value: project,
            };
          })
        );
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " error catched while fetching projects");
    }
  };
  useEffect(() => {
    if (workspaceAutoComplete) {
      getProjectsOfWorkspace(workspaceAutoComplete._id);
    }
  }, [workspaceAutoComplete]);

  const handleAutocompleteChange = (value, type) => {
    console.log(value, "val");
    if (type == "workspace") {
      setWorkspaceAutoComplete(value);

      setProjectAutoComplete([]);
      // getWorkspaceAllTeam(value._id);
    } else if (type == "project") {
      if (value.find((obj) => obj.value == "all")) {
        setProjectAutoComplete([...projects]);
      } else {
        setProjectAutoComplete(value);
      }
    } else if (type == "member") {
      setMembersAutoComplete(value);
    }
  };

  const getWorkspaceAdminDetail = async (id) => {
    try {
      setWorkspaceDataLoading(true);
      const result = await _workspace_admin_detail(id);
      console.log(result, "resultworkspceeeee");
      setWorkspaceAdminDetail(result);
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching admin details");
    } finally {
      setWorkspaceDataLoading(false);
    }
  };
  const getWorkspaceDetail = async () => {
    const result = await _user_workspace_detail(null, props?.workspace?._id);
    if (result.code === 200) {
      setStateWorkspaceDetail(result.workspace);
      props?.setWorkspace(result.workspace);
    } else if (result.code == 400) {
      navigate("/workspaces");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    if (workspaceAutoComplete) {
      console.log(workspaceAutoComplete, "workspaceAutoComplete useeffect");
      let value = { ...workspaceAutoComplete };
      setMembersAutoComplete([
        // {
        //   ...value?.admin,
        //   label:
        //     value?.admin?.first_name +
        //     " " +
        //     value?.admin?.last_name +
        //     " (admin)",
        //   value: { ...value?.admin },
        // },
        ...value.team
          .filter((member) => member.role == "manager")
          .map((member) => {
            return {
              ...member,
              label: member.first_name + " " + member.last_name + " (manager)",
              value: member,
            };
          }),
      ]);
      setMemberOptions([
        // {
        //   ...value?.admin,
        //   label:
        //     value?.admin?.first_name +
        //     " " +
        //     value?.admin?.last_name +
        //     " (admin)",
        //   value: { ...value.admin },
        // },
        ...value.team?.map((member) => {
          return {
            ...member,
            label:
              member.first_name +
              " " +
              member.last_name +
              " (" +
              member.role +
              ")",
            value: member,
          };
        }),
      ]);
      getWorkspaceAdminDetail(workspaceAutoComplete.user_id);
    }
  }, [workspaceAutoComplete]);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleChangeDrag = (e) => {
    setDragValue(e.target.value);
  };
  const handleChangeAddProj = (e) => {
    setAddProjValue(e.target.value);
  };
  const onChangeRole = (e) => {
    setRoleValue(e.target.value);
  };
  const onInviteUser = (_data) => {
    console.log(_data);
    setSelectedUser(_data);
    setInviteDrawerOpen(true);
    getWorkSpaces();
  };
  const closeInvDrawer = (_data) => {
    setWorkspaceAutoComplete(
      props?.workspace
        ? { ...props?.workspace, label: props?.workspace?.name }
        : null
    );
    setProjectAutoComplete(
      props?.project ? [{ ...props?.project, label: props?.project.title }] : []
    );
    setMemberOptions([]);
    setMembersAutoComplete([]);
    setRoleValue("team");
    setInviteDrawerOpen(false);
    setDragValue("1");
    setAddProjValue("1");
  };
  //////////////////////////////////
  var isEmailFormat = function (string) {
    return /^[\w\.-]+@[\w\.-]+\.\w+$/.test(string);
  };

  ////////////////////////////////////////
  const getText = () => {
    const user_count = userList.length;
    let not_found = !isEmailFormat(searchValue)
      ? `No Results found for "${searchValue}"`
      : "";
    if (searchValue === "") {
      if (!loading) {
        if (userList.length === 0) {
          return "Start Searching Users";
        }
        if (userList.length > 0) {
          return `Found ${user_count} ${user_count === 1 ? "User" : "Users"}`;
        }
      }
      if (loading) {
        return "Searching Users...";
      }
    }
    if (searchValue !== "") {
      if (!loading) {
        if (userList.length === 0) {
          return not_found;
        }
        if (userList.length > 0) {
          return `Found ${user_count} ${user_count === 1 ? "User" : "Users"}`;
        }
      }
      if (loading) {
        return "Searching Users...";
      }
    }
  };
  const onRoleOptionClick = (e, _option) => {
    console.log(_option);
    // setRoleValue(e.target.value);
  };
  const getWorkSpaces = async () => {
    const result = await _user_workspaces();
    const getPersonal = (_data) => {
      let data = _data.map((item) => {
        return {
          ...item,
          ["role"]: item.role,
          ["workspace_type"]: "mine",
          ["label"]: item.name,
          ["value"]: item,
        };
      });
      return data;
    };
    if (result.code === 200) {
      console.log(result, "workspace-res");
      const with_manager_role = result.workspace?.share
        .filter(
          (item) =>
            String(item.role) === "manager" || String(item.role) === "client"
        )
        .map((_item) => {
          return { ..._item, ["label"]: _item.name, ["value"]: _item };
        });
      if (isClient) {
        setWorkspaces(
          [
            ...getPersonal(result.workspace?.personal),
            ...with_manager_role,
          ].filter((item) => item.role !== "client")
        );
      } else {
        setWorkspaces([
          ...getPersonal(result.workspace?.personal),
          ...with_manager_role,
        ]);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSendInvite = async () => {
    if (!workspaceAutoComplete) {
      enqueueSnackbar("Please Choose a Workspace", { variant: "error" });
      return;
    }
    if (!roleValue) {
      enqueueSnackbar("Please Choose a Role", { variant: "error" });
      return;
    }
    if (!selectedUser) {
      enqueueSnackbar("No user selected", { variant: "error" });
      return;
    }
    if (roleValue == "team" || roleValue == "manager") {
      if (
        workspaceAdminDetail?.count_data?.team_manager_count >=
        workspaceAdminDetail?.current_plans?.team_member_allow
      ) {
        if (
          workspaceAdminDetail.customer.user_id ==
          dispatch_get_user_profile().user_id
        ) {
          setLimitReachedDialogOpen(true);
        } else {
          enqueueSnackbar(
            "Team limit for the admin of this workspace reached.",
            {
              variant: "error",
            }
          );
        }
        return;
      }
    } else {
      if (
        workspaceAdminDetail?.count_data?.client_count >=
        workspaceAdminDetail?.current_plans?.client_allow
      ) {
        if (
          workspaceAdminDetail.customer.user_id ==
          dispatch_get_user_profile().user_id
        ) {
          setLimitReachedDialogOpen(true);
        } else {
          enqueueSnackbar(
            "Client limit for the admin of this workspace reached.",
            {
              variant: "error",
            }
          );
        }
        return;
      }
    }

    console.log(workspaceAutoComplete, "workspace");
    console.log(roleValue, "role");
    console.log(selectedUser, "selected-user");

    const data = {
      invitation_objects: selectedMembersList.map((member) => ({
        workspace: member.workspace._id,
        email: member.user.email,
        role: member.role,
        allow_drag_drop: member.dragDrop === "1" ? true : false,
        allow_add_project: member.addProject === "1" ? true : false,
      })),
    };
    console.log(data, "inv-req");
    setSendingInv(true);
    const result = await _invite_multiple_user_to_workspace(data);
    if (result.code === 200) {
      props.navigate();
      console.log(result, "inv-res");
      setSelectedMembersList([]);
      setSendingInv(false);
      const receiver = [selectedUser.user_id];
      socketEmit("send_notification_to_all", {
        receiver,
        data: result.invitation,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } invites you to join ${
          workspaceAutoComplete.name
        } workspace as ${roleValue}`,
        link: `${window.location.origin}/invitations`,
      });
      enqueueSnackbar("Invitation Sent Successfully", { variant: "success" });
      if (props?.workspace) {
        setUserList((prev) =>
          prev.map((user) => {
            if (user.user_id === selectedUser.user_id) {
              return { ...user, is_invited: true };
            } else {
              return user;
            }
          })
        );
      }
      closeInvDrawer();
      props?.onSuccess(result.invitation);
    } else {
      setSendingInv(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // ----------------------------------------------------
  useLayoutEffect(() => {
    getWorkSpaces();
  }, []);
  useLayoutEffect(() => {
    if (props?.workspace) {
      getWorkspaceDetail();
    }
  }, []);

  React.useEffect(() => {
    if (searchValue === "") {
      setUserList([]);
      setLoading(false);
    }
    if (searchValue !== "") {
      setLoading(true);
      debouncedFetchData(searchValue, (res) => {
        let modified = res.data.customer.map((item) => {
          return {
            ...item,
            is_invited: Boolean(
              stateWorkspaceDetail?.invitations?.find(
                (inv) => inv.email == item.email
              )
            ),
            is_added: Boolean(
              stateWorkspaceDetail?.team?.find((inv) => inv.email == item.email)
            ),
          };
        });
        setUserList(modified);
        console.log(modified, "res");
        setLoading(false);
      });
    }
    return () => {
      debouncedFetchData.cancel(); //cancel debounce when searchValue changes before timeout
    };
  }, [searchValue]);

  React.useEffect(() => {
    if (searchValue === "") {
      if (userList.length !== 0) {
        setUserList([]);
      }
    }
  });
  // --------------------------------------------------------
  // only run this effect when have prop.newWorkspace from CreateWorkspace.jsx
  useEffect(() => {
    if (props?.newWorkspace) {
      setWorkspaceAutoComplete({
        ...props.newWorkspace,
        label: props.newWorkspace.name,
      });
    }
  }, [props.newWorkspace]);

  useEffect(() => {
    if (props?.open) {
      getTeamList();
    }
  }, [props?.open]);

  return (
    <Dialog open={props?.open} onClose={closeDialog} maxWidth="lg" fullWidth>
      <DialogTitle className="d-flex justify-content-between align-items-center ">
        {!isClient ? "Invite Team Member" : "Invite Client"}
        <IconButton onClick={closeDialog}>
          <Iconify icon="maki:cross" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: "90vh" }}>
        {!inviteDrawerOpen && (
          <Stack spacing={2.5} pt={1}>
            <Grid alignItems="center" columnSpacing={1} container>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Search user"
                  value={searchValue}
                  onChange={handleChange}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position='end'>
                  //       <IconButton edge='end'>
                  //         <CircularProgress
                  //           sx={{
                  //             display: `${loading ? 'block' : 'none'}`,
                  //           }}
                  //           size={'1.5rem'}
                  //         />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
            </Grid>
            <div style={{ height: "1.5rem" }}>
              {getText() === "Searching Users..." ? (
                <div className="centered-row">
                  <CircularProgress
                    sx={{
                      display: `${loading ? "block" : "none"}`,
                    }}
                    size={"1.5rem"}
                  />
                </div>
              ) : (
                <Typography
                  sx={{
                    color: getText().includes("No Results")
                      ? (theme) => theme.palette.text.disabled
                      : (theme) => theme.palette.text.primary,
                  }}
                  fontWeight="600"
                  textAlign="center"
                  variant="body1"
                  noWrap
                >
                  {getText()}
                </Typography>
              )}
            </div>

            {searchValue !== "" ? (
              <Grid container>
                {userList.map((user, index) => (
                  <Grid
                    sx={{ pr: 3, pb: 3 }}
                    key={index}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <TeamUserCard
                      onInvite={onInviteUser}
                      item={user}
                      selectedMembers={selectedMembersList}
                    />
                  </Grid>
                ))}
                {userList.length == 0 &&
                  !loading &&
                  isEmailFormat(searchValue) &&
                  searchValue !== dispatch_get_user_profile().email && (
                    <Grid sx={{ pr: 3, pb: 3 }} item xs={12} sm={6} md={3}>
                      <DummyUserCard
                        email={searchValue}
                        onInvite={onInviteUser}
                        isInvited={props?.workspace?.invitations?.some(
                          (inv) => inv.email == searchValue.trim()
                        )}
                      />
                    </Grid>
                  )}
              </Grid>
            ) : (
              <>
                <Grid container spacing={2}>
                  {/* Left Grid | Selected Members ---------------------------------- */}
                  <Grid xs={12} md={6} sx={{ p: 1 }}>
                    {suggestedMembers.length !== 0 && (
                      <Typography
                        paddingLeft={"6px"}
                        paddingTop={"8px"}
                        fontWeight={"600"}
                      >
                        Selected Users
                      </Typography>
                    )}
                    <div className="scrollable-div">
                      {selectedMembersList.length === 0 &&
                        suggestedMembers.length !== 0 && (
                          <div className="d-flex align-items-center justify-content-center pt-4">
                            <Iconify
                              icon="lucide:user-x"
                              sx={{
                                marginRight: "5px",
                                fontSize: "16px",
                                color: "text.disabled",
                              }}
                            />
                            <Typography
                              sx={{
                                color: "text.disabled",
                                fontSize: "12px",
                              }}
                            >
                              No Selected Users!
                            </Typography>
                          </div>
                        )}
                      {selectedMembersList.map((member, index) => (
                        <Paper
                          className="selected-user-paper"
                          key={index}
                          elevation={1}
                          sx={{
                            mt: 1,
                            p: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: "#f5fcf9",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <Avatar
                              src={s3baseUrl + member.user.image}
                              sx={{ width: "40px", height: "40px" }}
                            >
                              {member.user.first_name.substring(0, 1)}{" "}
                              {member.user.last_name.substring(0, 1)}
                            </Avatar>

                            <Box>
                              <div>
                                <Typography
                                  fontSize="12px"
                                  fontWeight="600"
                                  noWrap
                                >
                                  {member.user.first_name}{" "}
                                  {member.user.last_name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {member.user.email}
                                </Typography>
                              </div>
                              <div className="selected-user-info">
                                <Typography
                                  color="textSecondary"
                                  fontSize="10px"
                                >
                                  <span className="info-title">Role :</span>
                                  {capitalCase(member.role)}
                                </Typography>
                                <Typography
                                  className="d-flex align-items-center"
                                  color="textSecondary"
                                  lineHeight={0.5}
                                  fontSize="10px"
                                >
                                  <span className="info-title">
                                    Drag-Drop :
                                  </span>
                                  <Tooltip
                                    title={
                                      member.dragDrop === "1"
                                        ? "Allow"
                                        : "Disallow"
                                    }
                                  >
                                    <span>
                                      <Iconify
                                        fontSize="16px"
                                        icon={
                                          member.dragDrop === "1"
                                            ? "dashicons:yes"
                                            : "entypo:cross"
                                        }
                                        style={{
                                          color:
                                            member.dragDrop === "1"
                                              ? "#00AB55"
                                              : "#C23232",
                                        }}
                                      />
                                    </span>
                                  </Tooltip>
                                </Typography>
                                <Typography
                                  className="d-flex align-items-center"
                                  color="textSecondary"
                                  lineHeight={0.5}
                                  fontSize="10px"
                                >
                                  <span className="info-title">
                                    Add Project :
                                  </span>
                                  <Tooltip
                                    title={
                                      member.addProject === "1"
                                        ? "Allow"
                                        : "Disallow"
                                    }
                                  >
                                    <span>
                                      <Iconify
                                        fontSize="16px"
                                        icon={
                                          member.addProject === "1"
                                            ? "dashicons:yes"
                                            : "entypo:cross"
                                        }
                                        style={{
                                          color:
                                            member.addProject === "1"
                                              ? "#00AB55"
                                              : "#C23232",
                                        }}
                                      />
                                    </span>
                                  </Tooltip>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          {/* Remove -------------------------- */}
                          <ButtonBase
                            onClick={() =>
                              handleRemoveMember(member.user.email)
                            }
                            sx={{ borderRadius: "5px" }}
                          >
                            <Label
                              sx={{ "&:hover": { cursor: "pointer" } }}
                              color="error"
                              variant="ghost"
                            >
                              Remove
                            </Label>
                          </ButtonBase>
                        </Paper>
                      ))}
                    </div>
                  </Grid>

                  {/* Right Grid | Suggestions ---------------------------------- */}
                  {suggestedMembers.length !== 0 && (
                    <Grid
                      xs={12}
                      md={6}
                      sx={{
                        p: 1,
                        height: "53vh",
                        borderLeft: { xs: "none", md: "1px solid #dce0e4" },
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center mb-1 me-2">
                        <Typography paddingLeft={"6px"} fontWeight={"600"}>
                          All Team
                        </Typography>
                        {/* Search Input */}
                        <TextField
                          label="Search Team"
                          variant="outlined"
                          size="small"
                          placeholder="Search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>

                      {/* Loading Indicator */}
                      {suggestedLoading && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "40vh",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      )}

                      {/* Suggestions List */}
                      {!suggestedLoading && (
                        <div className="scrollable-div">
                          {filteredSuggestions.length > 0 ? (
                            filteredSuggestions.map((member, index) => {
                              const isSelected = selectedMembersList?.some(
                                (user) => user.user.email === member.email
                              );
                              return (
                                <Paper
                                  className="suggestions-paper"
                                  key={index}
                                  elevation={1}
                                  sx={{
                                    mt: 1,
                                    p: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    opacity: isSelected ? 0.4 : 1,
                                    backgroundColor: isSelected
                                      ? "#F7F9F8"
                                      : "white",
                                  }}
                                >
                                  {/* Left: Avatar */}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 2,
                                    }}
                                  >
                                    {member.image ? (
                                      <Avatar
                                        sx={{ width: "40px", height: "40px" }}
                                        alt="user-image"
                                        src={s3baseUrl + member.image}
                                      />
                                    ) : (
                                      <Avatar
                                        sx={{ width: "40px", height: "40px" }}
                                        alt={"name"}
                                      >
                                        {member.first_name.substring(0, 1)}
                                        {member.last_name.substring(0, 1)}
                                      </Avatar>
                                    )}

                                    {/* Right: Name & Email */}
                                    <Box>
                                      <Typography
                                        fontSize="12px"
                                        fontWeight="600"
                                        noWrap
                                      >
                                        {member.first_name +
                                          " " +
                                          member.last_name}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {member.email}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  {/* Invite Button */}
                                  {!member.is_added &&
                                    (isSelected ? (
                                      <ButtonBase
                                        sx={{ borderRadius: "5px" }}
                                        disabled
                                      >
                                        <Label
                                          sx={{
                                            "&:hover": { cursor: "default" },
                                          }}
                                          color="success"
                                          variant="ghost"
                                        >
                                          Selected
                                        </Label>
                                      </ButtonBase>
                                    ) : (
                                      <ButtonBase
                                        onClick={() => onInviteUser(member)}
                                        sx={{ borderRadius: "5px" }}
                                      >
                                        <Label
                                          sx={{
                                            "&:hover": { cursor: "pointer" },
                                          }}
                                          color="info"
                                          variant="ghost"
                                        >
                                          Invite
                                        </Label>
                                      </ButtonBase>
                                    ))}
                                </Paper>
                              );
                            })
                          ) : (
                            <div className="d-flex align-items-center pt-3 justify-content-center">
                              <Iconify
                                icon="lucide:user-x"
                                sx={{
                                  marginRight: "5px",
                                  fontSize: "16px",
                                  color: "text.disabled",
                                }}
                              />
                              <Typography
                                sx={{
                                  color: "text.disabled",
                                  fontSize: "12px",
                                }}
                              >
                                No Team Member Found!
                              </Typography>
                            </div>
                          )}
                        </div>
                      )}
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Stack>
        )}
        {inviteDrawerOpen && (
          <Grid container spacing={2.5}>
            <Grid item xs={12} display={"flex"}>
              <Typography fontWeight="bold" sx={{ mr: 1 }}>
                Member:
              </Typography>
              <Box display={"flex"} alignItems={"center"} gap={1}>
                <Avatar
                  src={s3baseUrl + selectedUser.image}
                  sx={{ width: "20px", height: "20px", fontSize: "11px" }}
                >
                  {selectedUser.first_name.substring(0, 1)}
                  {selectedUser.last_name.substring(0, 1)}
                </Avatar>
                <Typography>
                  {selectedUser.first_name
                    ? `${selectedUser.first_name} ${selectedUser.last_name}`
                    : selectedUser.email}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiAutoComplete
                value={workspaceAutoComplete}
                onChange={(val) => handleAutocompleteChange(val, "workspace")}
                // options={workspaces.filter(
                //   (workspace) =>
                //     workspace?.role == "admin" || workspace?.role == "manager"
                // )}
                options={[
                  ...workspaces.filter(
                    (workspace) =>
                      !workspace.invitations.some(
                        (inv) => inv.email == selectedUser?.email
                      ) &&
                      !workspace.team.some(
                        (member) => member.email == selectedUser?.email
                      )
                  ),
                ]}
                disabled={props?.workspace || props?.newWorkspace}
                label="Choose workspace*"
              />
            </Grid>
            {workspaceAutoComplete && (
              <Grid item xs={12} sm={6}>
                <MuiMultiAutocomplete
                  value={projectAutoComplete}
                  onChange={(val) => handleAutocompleteChange(val, "project")}
                  options={
                    projects.length > projectAutoComplete.length
                      ? [
                          { label: "Select all projects", value: "all" },
                          ...projects,
                        ]
                      : [...projects]
                  }
                  label="Choose project (optional)"
                  disabled={props?.project || props?.newWorkspace}
                />
              </Grid>
            )}
            {(!workspaceAutoComplete ||
              workspaceAutoComplete.role !== "client") && (
              <>
                <Grid item xs={12} sm={6}>
                  <MuiSelect
                    disabled={isClient}
                    onOptionClick={onRoleOptionClick}
                    options={ROLE_OPTIONS}
                    value={roleValue}
                    onChange={onChangeRole}
                    label="Choose Role"
                  />
                </Grid>
                {roleValue === "client" &&
                  workspaceAutoComplete &&
                  workspaceAutoComplete.role !== "client" && (
                    <Grid item xs={12} sm={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControl fullWidth>
                          <MuiMultiAutocomplete
                            sx={{ width: "100%" }}
                            value={memberOptions}
                            onChange={(val) =>
                              handleAutocompleteChange(val, "member")
                            }
                            options={memberOptions}
                            label="Choose members (optional)"
                            fixedOptions={memberOptions.filter(
                              (member) =>
                                member.role == "admin" ||
                                member.user_id ==
                                  dispatch_get_user_profile().user_id
                            )}
                          />
                        </FormControl>
                        <Tooltip title="Select the team members that will be visible for the client, you cannot unselect the admin and if you are manager you also cannot unselect yourself">
                          <div>
                            <Iconify
                              icon="fe:info"
                              sx={{
                                width: "20px",
                                height: "20px",
                                ml: 1,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                      {/* <FormControl>
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Per hour rate(optional)
                        </InputLabel>
                        <OutlinedInput
                          value={perHourRate}
                          id="outlined-adornment-amount"
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          label="Per hour rate(optional)"
                          onChange={(event) => {
                            const newValue = event.target.value;

                            if (/^$|^\d*\.?\d+$/.test(newValue)) {
                              setPerHourRate(newValue);
                            }
                          }}, 
                        />
                      </FormControl> */}
                    </Grid>
                  )}
                {(roleValue === "team" || roleValue === "client") && (
                  <Grid item xs={12}>
                    <Stack direction={"row"} spacing={5}>
                      {/* <Grid item xs={12} sm={6}> */}
                      <FormControl>
                        <FormLabel>Task Drag-Drop</FormLabel>
                        <RadioGroup
                          title="Drag-Drop"
                          row
                          disabled={sendingInv}
                          aria-labelledby="gender-select-radio-buttons-label"
                          name="row-radio-buttons-group"
                          value={dragValue}
                          onChange={handleChangeDrag}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Allow"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Disallow"
                          />
                        </RadioGroup>
                      </FormControl>
                      {/* </Grid>
                      <Grid item xs={12} sm={6}> */}
                      <FormControl>
                        <FormLabel>Add project</FormLabel>
                        <RadioGroup
                          title="Add-Project"
                          row
                          disabled={sendingInv}
                          aria-labelledby="gender-select-radio-buttons-label"
                          name="row-radio-buttons-group"
                          value={addProjValue}
                          onChange={handleChangeAddProj}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Allow"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Disallow"
                          />
                        </RadioGroup>
                      </FormControl>
                      {/* </Grid> */}
                    </Stack>
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <div className="centered-row gap-2">
                <Button
                  onClick={() => setInviteDrawerOpen(false)}
                  variant="text"
                >
                  cancel
                </Button>
                <LoadingButton onClick={handleSaveMember} variant="contained">
                  invite
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      {!inviteDrawerOpen && selectedMembersList?.length > 0 && (
        <DialogActions>
          <Grid container justifyContent="center">
            <Grid item xs={6} md={4} lg={3}>
              <LoadingButton
                fullWidth
                disabled={workspaceDataLoading}
                loading={sendingInv}
                onClick={handleSendInvite}
                color="primary"
                variant="contained"
              >
                send invite
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default InviteMultipleMembersDialog;
