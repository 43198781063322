import React, { useEffect, useState } from "react";
import {
  Switch,
  Stack,
  Typography,
  Paper,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Iconify, MuiDrawer } from "src/components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { _update_section_order } from "src/DAL";
import { _project_sections_all_list } from "src/DAL/project";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { _update_section_order_list } from "src/DAL/sections";

// ===============================================================
export default function SectionSettingsDrawer({
  settingsAndStatsOpen,
  closeSettingsAndStats,
  isPending,
  onExpandAll,
  settingOptions,
  workspaceId,
  slug,
  getFilteredSections,
  orderChanged,
}) {
  const [fullSectionsList, setFullSectionsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderLoading, setOrderLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Get all sections List API ------------------------------------
  const getAllSectionsList = async () => {
    setLoading(true);
    const req_data = {
      workspace_id: workspaceId,
      list_type: "0",
    };
    const response = await _project_sections_all_list(req_data, slug);
    if (response.code === 200) {
      const sectionsList = response.project_list.section_lists;
      sectionsList.sort((a, b) => a.order - b.order);
      setFullSectionsList({ section_lists: sectionsList });
      //   setFullSectionsList((prevData) => ({
      //     ...prevData,
      //     section_lists: sectionsList,
      //   }));
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setLoading(false);
  };

  // Drag and Drop ------------------------
  const onDragSectionEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;

    const updatedList = [...fullSectionsList.section_lists];
    const [movedItem] = updatedList.splice(source.index, 1);
    updatedList.splice(destination.index, 0, movedItem);

    const reorderedSections = updatedList.map((section, index) => ({
      ...section,
      order: index,
    }));
    console.log(reorderedSections, "reordered_Sections_Drag_Drop_locally");
    setFullSectionsList((prevData) => ({
      ...prevData,
      section_lists: reorderedSections,
    }));
    orderChanged(true);
    // const req_data = {
    //   section_id: result.draggableId,
    //   list_type: "0",
    //   order: result.destination.index,
    // };
    // updateSectionOrder(req_data);
  };

  // Move up/down ------------------------------------------------
  const onMoveUpDown = (type, section) => {
    const updatedList = [...fullSectionsList.section_lists];
    const currentIndex = updatedList.findIndex(
      (item) => item._id === section._id
    );
    const newIndex = type === "up" ? currentIndex - 1 : currentIndex + 1;
    // Swap the elements
    [updatedList[currentIndex], updatedList[newIndex]] = [
      updatedList[newIndex],
      updatedList[currentIndex],
    ];
    const reorderedSections = updatedList.map((section, index) => ({
      ...section,
      order: index,
    }));

    setFullSectionsList({
      ...fullSectionsList,
      section_lists: reorderedSections,
    });
    orderChanged(true);
    // const req_data = {
    //   section_id: section._id,
    //   list_type: "0",
    //   order: newIndex,
    // };
    // updateSectionOrder(req_data);
  };

  // handlesaveorder send req_data to api  ----------------------------
  const handleSaveOrder = () => {
    const sectionId = fullSectionsList.section_lists.map(
      (section) => section._id
    );
    const projectId = fullSectionsList.section_lists[0]?.project.id;

    const req_data = {
      section_ids: sectionId,
      project_id: projectId,
    };
    console.log(req_data, "req_data");
    updateSectionOrder(req_data);
    orderChanged(false);
    // getFilteredSections(null, null, true);
  };

  // Update section order API ----------------------------------------
  const updateSectionOrder = async (req_data) => {
    setOrderLoading(true);
    const response = await _update_section_order_list(req_data, slug);
    if (response.code === 200) {
      getFilteredSections(null, null, true);
      closeSettingsAndStats();
      enqueueSnackbar("Section Order Updated Sucessfully!", {
        variant: "success",
      });
      orderChanged(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setOrderLoading(false);
  };
  
  // useEffects =====================================================
  useEffect(() => {
    if (settingsAndStatsOpen) {
      getAllSectionsList();
      orderChanged(false);
    }
  }, [settingsAndStatsOpen]);

  return (
    <>
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 340 } }}
        isOpen={settingsAndStatsOpen}
        onClose={closeSettingsAndStats}
        title={
          <Typography sx={{ fontWeight: "700" }}>Section Settings</Typography>
        }
        bottomComponent={
          <Stack direction="row" spacing={1} sx={{ px: "20px", py: "10px" }}>
            <LoadingButton
              loading={orderLoading}
              size="large"
              variant="contained"
              className="outline-btn"
              onClick={handleSaveOrder}
              startIcon={<Iconify icon="ix:reorder" />}
              fullWidth
            >
              Update order
            </LoadingButton>
            <LoadingButton
              size="large"
              variant="outlined"
              className="outline-btn"
              onClick={closeSettingsAndStats}
              // startIcon={<Iconify icon="tdesign:rollback" />}
            >
              Discard
            </LoadingButton>
          </Stack>
        }
      >
        <Stack spacing={2} mb={2}>
          <Box className="expand-sections">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Typography fontWeight={500}>Expand All Sections</Typography>
              <Stack direction="row" alignItems="center">
                {isPending && (
                  <CircularProgress
                    size="small"
                    sx={{ height: "1rem", width: "1rem" }}
                  />
                )}
                <Switch
                  onChange={onExpandAll}
                  checked={settingOptions.expandAllSections}
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <hr className="divider" />
        {/* Reorder_Sections ======================================== */}
        <Stack spacing={2}>
          <Typography sx={{ fontWeight: "700" }}>Reorder Sections</Typography>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="300px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <DragDropContext onDragEnd={onDragSectionEnd}>
              <Droppable droppableId="sectionsList">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {fullSectionsList.section_lists?.map((section, index) => (
                      <Draggable
                        key={section._id || index}
                        draggableId={section._id || index.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Paper
                            square
                            elevation={2}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              borderLeft: section.is_default
                                ? `4px solid`
                                : `4px solid transparent`,
                              borderLeftColor: section.is_default
                                ? "primary.main"
                                : "transparent",
                              marginBottom: "10px",
                              padding: "0px 5px 0px 5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              backgroundColor: snapshot.isDragging
                                ? "#ebf8f2"
                                : "white ",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                color: snapshot.isDragging
                                  ? "primary.main"
                                  : "text.primary",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                // maxWidth: "75%",
                              }}
                            >
                              <span className="index-count">{index + 1}.</span>
                              {section.section_title}
                            </Typography>

                            <div className="d-flex align-items-center">
                              {section.is_default === true && (
                                <Box
                                  onClick={(event) => event.stopPropagation()}
                                  sx={{
                                    marginRight: "7px",
                                    padding: "2px 4px",
                                    color: "white",
                                    fontSize: "8px",
                                    fontWeight: "600",
                                    backgroundColor: "primary.main",
                                    borderRadius: "4px",
                                  }}
                                >
                                  Default
                                </Box>
                              )}

                              <Tooltip title="Move Up">
                                <span>
                                  <IconButton
                                    onClick={() => onMoveUpDown("up", section)}
                                    disabled={index === 0}
                                  >
                                    <ArrowUpwardIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Tooltip>

                              <Tooltip title="Move Down">
                                <span>
                                  <IconButton
                                    onClick={() =>
                                      onMoveUpDown("down", section)
                                    }
                                    disabled={
                                      index ===
                                      fullSectionsList.section_lists.length - 1
                                    }
                                  >
                                    <ArrowDownwardIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </div>
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Stack>
      </MuiDrawer>
    </>
  );
}
