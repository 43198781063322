import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Box,
  Link,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Tab,
  Paper,
  Tooltip,
  Menu,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  IconButton
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Masonry from '@mui/lab/Masonry';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Page,
  RichEditor,
  SearchBar,
  Label,
  Iconify,
  MuiDialog,
} from "src/components";
import { useSnackbar } from "notistack";
import { truncateText } from "src/utils/audioDurationFormat";
import { capitalize } from "lodash";
import moment from "moment";
import { _get_updates_list } from "src/DAL/manageUpdates";
import { s3baseUrl } from "src/config";
import { CheckBox } from '@mui/icons-material';

function RecentUpdates() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [updatesList, setUpdatesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [showCardId, setShowCardId] = useState();

  const getUpdatesList = async () => {
    setLoading(true);
    const result = await _get_updates_list();
    if (result.code == 200) {
      setUpdatesList(result.features_list);
      console.log(result.features_list, "list");
    }
    else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    getUpdatesList();
  }, []);

  if (loading) {
    return (
      <CircularProgress
        sx={{ position: "absolute", top: "50%", right: "50%" }}
      />
    );
  }
  ////////////////////////////////////////////////////////////
  return (
    <Page title="Recent Updates" height="87.1vh">
      <Container maxWidth="xl">
        <div className="page-header">
          <Grid rowSpacing={2} alignItems="center" container columnSpacing={2}>
            <Grid item>
              <Typography className="page-title" variant="h4">
                Recent Updates
              </Typography>
            </Grid>
            <Grid item className="new-note-btn-container">
              {/* <Button
                startIcon={<Iconify icon="eva:plus-fill" />}
                variant="contained"
                onClick={() => {
                  
                }}
              >
                Add Notes
              </Button> */}
            </Grid>
          </Grid>
        </div>
        {/* <div className="ms-4 ps-2">
          <Stepper className='ps-5 ms-5' activeStep={activeStep} orientation="vertical">
            {updatesList.map((updateItem, index) => (
              <Step className='position-relative mt-1' key={updateItem.createdAt}>
                <StepLabel
                  id={updateItem.createdAt}
                  StepIconComponent={() => <AdjustOutlinedIcon color="primary" />}
                  className='align-items-start p-0'
                  optional={
                    <Typography variant="caption" className='update-createDate'>
                      {moment(updateItem.createdAt).format("LLL")}
                    </Typography>
                  }
                >
                  <div className={`w-100 d-flex justify-content-between ${activeStep == index ? "Updates-step-clicked": "Updates-step"} `}>
                    <Typography variant="body1" fontWeight="700" noWrap className="mb-2">
                      {updateItem.update_name}
                    </Typography>
                    <div className="d-flex align-items-center">
                      <Typography textAlign="start" className="ms-4">
                        {updateItem.features.length}
                      </Typography>
                      <IconButton className='recent-update-icons ms-2'
                        onClick={() => {
                          setActiveStep(activeStep === index ? -1 : index);
                          // console.log(updateItem.createdAt);
                          // document.getElementById(updateItem.createdAt).scrollIntoView({ behavior: "smooth" })
                        }}
                      >
                        {activeStep == index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </div>
                  </div>
                </StepLabel>
                <StepContent className='p-0'>
                  {updateItem.features.map((featureItem, idx) => (
                    <div className="row Updates-step-desc" key={idx}>
                      {featureItem.image && (
                        <div className="col-3 p-2">
                          <img
                            className="recent-image"
                            src={s3baseUrl + featureItem.image}
                            alt={featureItem.text}
                          />
                        </div>
                      )}
                      <div className={featureItem.image ? "col-9 p-2 m-auto" : "col-12 p-2 m-auto"}>
                        <Typography variant="body1" fontWeight="600" noWrap className="mb-2">
                          {featureItem.text}
                        </Typography>
                        <Typography variant="body2">
                          {featureItem.description}
                        </Typography>
                      </div>
                      {(idx !== updateItem.features.length - 1) &&
                        <div className="ps-2">
                          <hr className='' />
                        </div>
                      }
                    </div>
                  ))}

                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div> */}
        <Box>
          <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={1} className="w-100 d-flex justify-content-start align-content-start">
            {updatesList.map((updateItem) => {
              return updateItem.features.map((featureItem, index) => {
                return <Paper
                  key={updateItem._id}
                  elevation={2}
                  className="p-3 recent-card-styling"
                  onClick={() => {
                  }}
                >
                  <div className="">
                    {featureItem.image && <img alt={featureItem.text} src={s3baseUrl + featureItem.image}
                      className="recent-image" />}
                    <Typography
                      variant="body1"
                      fontWeight="600"
                      noWrap
                      // textAlign="center"
                      className="mb-2"
                    >
                      {featureItem.text}
                    </Typography>
                    {(featureItem.description.length > 500 && showCardId !== featureItem._id) 
                      ? featureItem.description.substring(0, 500) + "   "
                      : featureItem.description}
                    {(featureItem.description.length > 500 && showCardId !== featureItem._id) &&
                      <Link variant="subtitle2" component={RouterLink} to=""
                        onClick={() => {
                          console.log(featureItem._id);
                          setShowCardId(featureItem._id);
                        }}>
                        See More
                      </Link>
                    }
                  </div>
                </Paper>
              })
            })}
          </Masonry>
        </Box>
      </Container>
    </Page>
  );
}

export default RecentUpdates;
