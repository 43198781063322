import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Iconify from "src/components/Iconify";

export default function SaveTemplateDialog({
  open,
  onClose,
  item,
  handleSaveTemplate,
  loading,
}) {
  const [newName, setNewName] = useState("");


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newName.trim()) return;
    handleSaveTemplate({ ...item, checklist_template_name: newName });
  };

  useEffect(() => {
    if (open) {
      setNewName(item?.name || "");
    }
  }, [open]);
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="dialog-title"
    >
      <DialogTitle>Save Checklist As Template</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ padding: "10px 24px" }}>
          <TextField
            fullWidth
            required
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            autoFocus
            size="small"
            label="Checklist Template Name"
            variant="outlined"
          />
          <div className="temp-box pt-2">
            <Accordion>
              <AccordionSummary
                className="accordion-Summary-template mb-0"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="temp1-content"
                id="temp1-header"
              >
                <div className="d-flex align-items-center gap-1">
                  <Iconify icon="entypo:info-with-circle" fontSize="16px" color="#1890FF" />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    What is a checklist template?
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "2px" }} className="pb-0 pt-0">
                <Typography className="points-template">
                  A checklist template is a ready-to-use list of tasks that you
                  can reuse anytime. Instead of making a new checklist from
                  scratch every time, just save it as a template and use it
                  whenever needed.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Where can you see saved checklist templates?
                </Typography>
                <Typography className="points-template">
                  When you create a new checklist. There is a "Template" button
                  which allows you to browse and reuse your saved templates
                  easily.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Why is it useful?
                </Typography>
                <Typography className="points-template">
                  A checklist template is useful because it helps you save time,
                  stay organized, and reduce mistakes. Instead of creating the
                  same checklist over and over, you can simply reuse a saved
                  template.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit"
           loading={loading} 
           variant="contained">
            Save template
          </LoadingButton>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}