import { get_from_localStorage, invokeApi } from "src/utils";

export const _add_task_checklist = async (data) => {
    const requestObj = {
        path: `api/task/enable_checklist`,
        method: "POST",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};

export const _edit_checklist_title = async (data) => {
    const requestObj = {
        path: `api/task/edit_checklist_name`,
        method: "PUT",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};

export const _remove_task_checklist = async (data) => {
    const requestObj = {
        path: `api/task/disable_checklist`,
        method: "POST",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};

export const _update_checklist_item_priority = async (data) => {
    const requestObj = {
        path: `api/task/update_checklist_item_priority`,
        method: "POST",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};

export const _update_checklist_assignee = async (data) => {
    const requestObj = {
        path: `api/task/update_checklist_item_assigne_to`,
        method: "POST",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};

export const _edit_checklist_target_date = async (data) => {
    const requestObj = {
        path: `api/task/edit_checklist_target_date`,
        method: "PUT",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};

export const _update_task_checklist_item = async (data) => {
    const requestObj = {
        path: `api/task/update_checklist_item`,
        method: "POST",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};

export const _add_checklist_template = async (data) => {
    const requestObj = {
        path: `api/checklist_template/add_checklist_template`,
        method: "POST",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};
export const _create_checklist_template = async (data) => {
    const requestObj = {
        path: `api/checklist_template/create_checklist_from_template`,
        method: "POST",
        headers: {
            "x-sh-auth": get_from_localStorage("token"),
        },
        postData: data,
    };
    return invokeApi(requestObj);
};

export const _get_templates_data_checklist = (data) => {
    const requestObj = {
      path: `api/checklist_template/checklist_template_list`,
      method: "GET",
      headers: {
        "x-sh-auth": get_from_localStorage("token"),
      },
    };
    return invokeApi(requestObj);
  };

export const _delete_template = (data) => {
    const requestObj = {
      path: `api/checklist_template/delete_checklist_template`,
      method: "DELETE",
      headers: {
        "x-sh-auth": get_from_localStorage("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };