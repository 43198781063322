import { ALLOWED_IMAGE_TYPES, ALLOWED_TASK_FILE_TYPES } from "src/constants";
import { _upload_audio, _upload_file } from "src/DAL/uploads";


export const upload_image = async (file) => {
  if (!file) {
    console.error("No File Provided");
    return;
  }
  if (file) {
    const { type } = file;
    let valid_type = false;
    console.log(file, "target-file");
    ALLOWED_IMAGE_TYPES.map((item, i) => {
      if (item === String(type)) {
        valid_type = true;
      }
    });
    if (valid_type === false) {
      console.error("invalid image type");
      return { code: 400, message: "Invalid file type" };
    }
    const data = new FormData();
    data.append("directory", "images/");
    data.append("upload_file", file);
    const result = await _upload_file(data);
    return result;
  }
};


export const upload_audio = async (file, duration) => {
  if (!file) {
    console.error("No File Provided");
    return;
  }
  if (file) {
    console.log(file, "target-file");
    
    const data = new FormData();
    data.append("duration", duration);
    data.append("audio", file);
    const result = await _upload_audio(data);
    return result;
  }
};


export const upload_task_files = async (file) => {
  if (!file) {
    console.error("No File Provided");
    return;
  }
  if (file) {
    const { type } = file;
    let valid_type = false;
    console.log(file, "target-file");
    ALLOWED_TASK_FILE_TYPES.map((item, i) => {
      if (item === String(type)) {
        valid_type = true;
      }
    });
    if (valid_type === false) {
      console.error("invalid image type");
      return { code: 400, message: "Invalid file type" };
    }
    const data = new FormData();
    data.append("directory", "images/");
    data.append("upload_file", file);
    const result = await _upload_file(data);
    console.log(result, "file-upload-res");
    return result;
  }
};
