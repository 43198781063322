import React from "react";
import CheckListDatePicker from "./CheckListDatePicker";

function CheckListTargetDate({ checklist, onDateChange, canEditTask , completed }) {

  const handleDateChange = async (newDate) => {
    const data = {
      checklistId: checklist._id,
      targetDate: newDate,
    };
    if (onDateChange) {
      await onDateChange(data);
    }
  };

  return (
    <div>
      <CheckListDatePicker
        hasPortal
        completed={completed}
        value={checklist.target_date}
        onChange={handleDateChange}
        task={checklist}
        disabled={false}
      />
    </div>
  );
}

export default CheckListTargetDate;
