import { IconButton, Typography } from "@mui/material";
import { useAppContext } from "src/hooks";
import { capitalize } from "lodash";
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import ImageIcon from '@mui/icons-material/Image';
import { truncateText, formatAudioDuration } from "src/utils/audioDurationFormat";
import { _getMessageDetail } from "src/DAL/chat";
import { useSnackbar } from "notistack";
import { useEffect, useState, useRef } from "react";

export default function TempReply({
    setRepliedMessage,
    loadMoreMessages,
    message,
}) {
    const { messageList, setMessageList, socketEmit } = useAppContext();
    const { dispatch_get_user_profile } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();

    const [targetMessageId, setTargetMessageId] = useState(null);
    const messageListRef = useRef([]);

    useEffect(() => {
        messageListRef.current = messageList;
    }, [messageList]);

    const handleReplyClick = async () => {
        let result = await _getMessageDetail(message.reply_message_detail._id);
        if (result.code !== 200) {
            enqueueSnackbar("Message not Found", { variant: "error" });
            return;
        }

        while (true) {
            let updatedList = messageListRef.current;
            let findedMessage = updatedList.find((item) => item._id === message.reply_message_detail._id);

            if (findedMessage) {
                document.getElementById(message.reply_message_detail._id)?.scrollIntoView({ behavior: "smooth" });
                console.log("Message found and scrolled to it.");
                setRepliedMessage(findedMessage);
                break;
            } else if (updatedList.length > 0) {
                document.getElementById(updatedList[updatedList.length - 1]?._id)?.scrollIntoView({ behavior: "smooth" });
                console.log("Message not found, scrolling to the last message.");
            }
            await new Promise((resolve) => setTimeout(resolve, 10));
        }
    };


    return (
        <div className="reply-item" style={{
            borderLeft: (message.reply_message_detail.sender_id == dispatch_get_user_profile().user_id) ? "5px darkgreen solid" : ""
        }} onClick={handleReplyClick}>
            <div className="reply-flex">
                <Typography variant="h6" fontSize="11px" fontWeight="600"
                    style={{
                        color: (message.reply_message_detail.sender_id == dispatch_get_user_profile().user_id) ? "darkgreen" : ""
                    }}
                    className="user-profile">
                    {
                        (message.reply_message_detail.sender_id == dispatch_get_user_profile().user_id) ?
                            "You" : capitalize(message.reply_message_detail?.message_sender?.first_name)
                    }

                </Typography>
                <Typography variant="caption2" className="reply-style">
                    {
                        (message.reply_message_detail.image && !message.reply_message_detail.audio_duration) &&
                        <>
                            <ImageIcon className="chat-user-icons chat-icon-reply" />
                            Image
                        </>
                    }
                    {
                        (message.reply_message_detail.image && message.reply_message_detail.audio_duration) &&
                        <>
                            <KeyboardVoiceIcon className="chat-user-icons" />
                            {formatAudioDuration(message.reply_message_detail?.audio_duration)}
                        </>
                    }
                    {
                        (message.reply_message_detail.image == "") &&
                        truncateText(message.reply_message_detail?.text, 22)
                    }
                </Typography>
            </div>
        </div>
    )
}
