import { LoadingButton } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Editor } from "@tinymce/tinymce-react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Container,
  Typography,
  Skeleton,
  Box,
  Stack,
  Grid,
  IconButton,
  Button,
  Switch,
  Tooltip,
  AvatarGroup,
  useMediaQuery,
  TextField,
  Radio,
  Paper,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  InputAdornment,
  CircularProgress,
  Avatar,
  Checkbox,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Input,
} from "@mui/material";
import { capitalCase } from "change-case";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useSnackbar } from "notistack";
import React, { useCallback } from "react";
import { useLayoutEffect, useEffect, useState, useRef } from "react";
import {
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from "react-router-dom";
import {
  ButtonSelect,
  CircularLoader,
  Iconify,
  Label,
  MuiAutoComplete,
  MuiDialog,
  MuiDrawer,
  MuiMultiAutocomplete,
  MuiSelect,
  Page,
  PriorityPopover,
  RichEditor,
  Scrollbar,
  SearchBar,
  StatusPopover,
  UserAvatar,
} from "src/components";
import {
  ALLOWED_IMAGE_TYPES,
  ALLOWED_TASK_FILES_WITH_ICONS,
  ALLOWED_TASK_FILE_TYPES,
  FILE_ACCEPT_TYPE_STRING,
  TASK_PRIORITY_OPTIONS,
  TASK_SORT_OPTIONS,
  TASK_STATUS_OPTIONS,
} from "src/constants";
import {
  _add_task_section,
  _create_task,
  _delete_task,
  _delete_section_without_task,
  _delete_section_with_task,
  _project_detail,
  _project_sections,
  _project_sections_filter,
  _project_tasks_filter,
  _project_team,
  _update_task_end_date,
  _update_task_members,
  _update_task_priority,
  _update_task_status,
  _update_section,
  _move_task_to_section,
  _update_section_order,
  _edit_task,
  _update_task_order,
  _update_project_team,
  _add_task_comment,
  _update_task_comment,
  _delete_task_comment,
  _task_comment_list,
  _load_more_tasks,
  _load_more_comments,
  _workspace_list_for_task_shift,
  _project_list_for_task_shift,
  _shift_task_to_other_workspace,
  _update_task_tags_in_task,
  _user_workspace_detail,
  _resend_workspace_invitation,
  _cancel_workspace_invitation,
} from "src/DAL";
import { useAppContext } from "src/hooks";
import Progress from "./components/Progress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  del_from_local_storage,
  get_from_localStorage,
  get_zone_date,
  handle_localSearch,
  isColorLight,
  set_to_localStorage,
  upload_image,
  upload_task_files,
} from "src/utils";
import moment from "moment";
import { avatarTeam } from "src/assets";
import SectionSelect from "./components/SectionSelect";
import TaskSection from "./components/TaskSection";
import { useTransition } from "react";
import { useMemo } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import UserCard from "./components/UserCard";
import { s3baseUrl } from "src/config";
import CommentCard from "./components/CommentCard";
import CommentsDrawer from "./components/CommentsDrawer";
import { flushSync } from "react-dom";
import { _make_section_default } from "src/DAL/sections";
import InfiniteScroll from "react-infinite-scroll-component";
import TaskDetailDialog from "src/components/TaskDetailDrawer/TaskDetailDrawer";
import {
  DateTimePicker,
  DesktopDatePicker,
  DesktopDateTimePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { makeStyles } from "@mui/styles";
import ProjectUserCard from "../workspace-detail/components/ProjectUserCard";
import InvitationCard from "../workspace-detail/components/InvitationCard";
import InviteMembersDialog from "src/components/InviteMembersDialog";
import { CleaningServices } from "@mui/icons-material";
import SectionSettingsDrawer from "./components/SectionSettingsDrawer";
import CreateTaskInput from "./components/CreateTaskInput";
import AddSection from "./components/AddSection";
import MuiAutoCompleteDefault from "src/components/MuiAutoCompleteDefault";
import FiltersDrawer from "./components/FiltersDrawer";
// ---------------------------------------------------------
// const ICON_SIZE = { height: "10px", width: "10px" };

// const MAP_TASK_PRIORITY_OPTIONS = [
//   { label: "critical", value: 0, color: "#FF4842" },
//   { label: "high", value: 1, color: "#ffd500" },
//   { label: "medium", value: 2, color: "#54D62C" },
//   { label: "low", value: 3, color: "#969292" },
// ];
// /*  */
// const MAP_TASK_STATUS_OPTIONS = [
//   { label: "pending", value: 0, color: "#FFC107" },
//   { label: "inProgress", value: 2, color: "#3366FF" },
//   { label: "readyForTesting", value: 11, color: "#a4bdfc" },
//   { label: "completed", value: 3, color: "#00AB55" },
//   { label: "cancelled", value: 1, color: "#FF4842" },
// ];

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
});
// ---------------------------------------------------------
const WorkspaceProject = () => {
  const classes = useStyles();
  const [isPending, startTransition] = useTransition();
  const matches_mobile = useMediaQuery("(max-width:768px)");
  const matches_break_point = useMediaQuery("(max-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const slug = params.id;
  const { state } = useLocation();
  const {
    dispatch_get_current_selected_item,
    dispatch_set_current_selected_item,
    dispatch_get_user_profile,
    dispatch_set_search_visibility,
    socketEmit,
  } = useAppContext();
  const user = dispatch_get_user_profile();
  //////===========================================///////////
  const [editingCommentId, setEditingCommentId] = useState("");
  const [filteredSectionsData, setFilteredSectionsData] = useState({
    load_more_url: "",
    section_lists: [],
    total: 0,
  });
  const [allFilteredTasksBySections, setAllFilteredTasksBySections] = useState(
    {}
  );
  const [syncData, setSyncData] = useState(true);
  const [sectionPage, setSectionPage] = useState(0);
  const [moreTasksLoading, setMoreTasksLoading] = useState(false);
  const [moreCommentsLoading, setMoreCommentsLoading] = useState(false);

  /////////====================================/////////////////
  const [openProjectDetails, setOpenProjectDetails] = useState(false);
  const pageRef = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState("team");
  const [workspaceId, setWorkspaceId] = useState("");
  const [autoCompleteValues, setAutoCompleteValues] = useState({
    workspace: null,
    project: null,
    section: null,
  });
  console.log(autoCompleteValues, "_autoCompleteValues task");

  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    workspaces: [],
    projects: [],
    sections: [],
  });
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [delUserOpen, setDelUserOpen] = useState(false);
  const [fullRefresh, setFullRefresh] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [teamOpen, setTeamOpen] = useState(false);
  const [delTaskOpen, setDelTaskOpen] = useState(false);
  const [delSectionOpen, setDelSectionOpen] = useState(false);
  const [taskSortOptionIndex, setTaskSortOptionIndex] = useState(0);
  const [addingTask, setAddingTask] = useState(false);
  const [taskField, setTaskField] = useState(false);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const [taskDetailOpen, setTaskDetailOpen] = useState(false);
  const [editSectionOpen, setEditSectionOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [selectedMember, setSelectedMember] = useState(undefined);
  const [selectedSection, setSelectedSection] = useState(undefined);
  const [selectedSectionTitle, setSelectedSectionTitle] = useState("");
  const [selectedSectionSprint, setSelectedSectionSprint] = useState({
    start_date_time: "",
    end_date_time: "",
    complete_date_time: "",
  });
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [delSectionWithTasks, setDelSectionWithTasks] = useState(false);
  const [settingsAndStatsOpen, setSettingsAndStatsOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectedTaskDescription, setSelectedTaskDescription] = useState("");
  const [selectedTaskTitle, setSelectedTaskTitle] = useState("");
  const [projectTeam, setProjectTeam] = useState([]);
  const [editingComment, setEditingComment] = useState(false);
  const [processingEditComment, setProcessingEditComment] = useState(false);
  const [delCommentOpen, setDelCommentOpen] = useState(false);
  const [deletingTaskComment, setDeletingTaskComment] = useState(false);
  const [sendingComment, setSendingComment] = useState(false);
  const [selectedComment, setSelectedComment] = useState(undefined);
  const [editCommentTitle, setEditCommentTitle] = useState("");
  const [editCommentType, setEditCommentType] = useState("0");
  const [newComment, setNewComment] = useState("");
  const [newCommentType, setNewCommentType] = useState("0");
  const [tagExpand, setTagExpand] = useState(false);
  const [moveToWorkspaceDrawerOpen, setMoveToWorkspaceDrawerOpen] =
    useState(false);
  const [currentTaskToMove, setCurrentTaskToMove] = useState();
  const [movingTaskLoading, setMovingTaskLoading] = useState(false);

  const [loadingCopyTask, setLoadingCopyTask] = useState(false);
  const [loadingCopyTaskWorspace, setLoadingCopyTaskWorkspace] =
    useState(false);
  const [loadingCopyTaskProject, setLoadingCopyTaskProject] = useState(false);

  const [taskFilterOptions, setTaskFilterOptions] = useState({
    status: [],
    priority: [],
    sort_status: "",
    search_users: [],
    type: 0,
    date: {
      date_type: "",
      start_date: "",
      end_date: "",
    },
  });
  const [filterOptions, setFilterOptions] = useState(
    get_from_localStorage("taskfilterOptions") ?? {
      priority: {
        critical: false,
        high: false,
        medium: false,
        low: false,
      },
      status: {
        pending: false,
        inProgress: false,
        completed: false,
        cancelled: false,
      },
      date: {
        date_type: "",
        start_date: "",
        end_date: "",
      },
      search_users: [],
    }
  );
  const [tempFilters, setTempFilters] = useState(filterOptions);
  const [projectDetail, setProjectDetail] = useState({
    _id: "",
    title: "",
    description: "",
    image: "",
    status: true,
    creator: "",
    workspace: "",
    team: [],
    createdAt: "",
    start_date: "",
    end_date: "",
    total_task: 0,
    pending_task: 0,
    completed_task: 0,
    on_time_completed_sprint_count: 0,
    all_sprint_count: 0,
    in_progress_sprint_count: 0,
    late_sprint_count: 0,
  });
  const [taskInputs, setTaskInputs] = useState({
    description: "",
    type: "0",
    team: [],
    status: 0,
    priority: 3,
    endDate: "",
  });
  const [progress, setProgress] = useState(0);

  const [settingOptions, setSettingOptions] = useState({
    expandAllSections: false,
  });
  const firstExpand = useRef(true);
  const editorRef = useRef();
  const scrollTaskOnce = useRef(false);
  const [updatingTask, setUpdatingTask] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [addTaskFiles, setAddTaskFiles] = useState([]);
  const [selectedTaskComments, setSelectedTaskComments] = useState([]);
  const [selectedTaskCommentsData, setSelectedTaskCommentsData] = useState({});
  const [makeDefaultSection, setMakeDefaultSection] = useState(false);
  const [removeProjectUserType, setRemoveProjectUserType] = useState("remove");
  const [autoCompleteValue, setAutoCompleteValue] = useState();
  const [projectTags, setProjectTags] = useState([]);
  const [taskDetailLoading, setTaskDetailLoading] = useState(true);
  const [tempSectionsLength, setTempSectionsLength] = useState(0);
  const [progressDrawerOpen, setProgressDrawerOpen] = useState(false);
  const [workspaceData, setWorkspaceData] = useState(
    get_from_localStorage("workspaceData") ?? null
  );
  const [currentTab, setCurrentTab] = useState("added");
  const [projectTeamSearch, setProjectTeamSearch] = useState("");
  const [orderChanged, setOrderChanged] = useState(false);
  const [loadingMember, setLoadingMember] = useState({});
  //////=====Invitation new flow===========///
  const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);
  const [currentTargetItem, setCurrentTargetItem] = useState();
  const [cancelInvOpen, setCancelInvOpen] = useState(false);
  const [resendInvOpen, setResendInvOpen] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const openCancelInvDialog = () => {
    setCancelInvOpen(true);
  };
  const closeCancelInvDialog = () => {
    setCancelInvOpen(false);
    setCurrentTargetItem();
  };
  const openResendInvDialog = () => {
    setResendInvOpen(true);
  };
  const closeResendInvDialog = () => {
    setResendInvOpen(false);
    setCurrentTargetItem();
  };
  const openInvitationDialog = () => {
    setInvitationDialogOpen(true);
  };
  const closeInvitationDialog = () => {
    setInvitationDialogOpen(false);
  };
  const onCancelWorkspaceInvite = (_data) => {
    console.log(_data, "on-cancel-inv");
    setCurrentTargetItem(_data);
    openCancelInvDialog();
  };
  const onResendWorkspaceInvite = (_data) => {
    console.log(_data, "on-cancel-inv");
    setCurrentTargetItem(_data);
    openResendInvDialog();
  };
  const handleResendInvitation = async () => {
    try {
      setResendLoading(true);
      const result = await _resend_workspace_invitation(currentTargetItem._id);
      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });
      if (result.code == 200) {
        closeResendInvDialog();
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while resending invitation");
    } finally {
      setResendLoading(false);
    }
  };
  const handleCancelWorkspaceInvite = async () => {
    const id = currentTargetItem._id;
    setProcessing(true);
    const result = await _cancel_workspace_invitation(null, id);
    if (result.code === 200) {
      setWorkspaceData((prev) => {
        return {
          ...prev,
          invitations: prev.invitations.filter(
            (inv) => inv._id !== currentTargetItem._id
          ),
        };
      });
      enqueueSnackbar("Invitation cancelled successfully", {
        variant: "success",
      });
      setProcessing(false);
      closeCancelInvDialog();

      // closeInvitationDrawer();
      /* filter current row if no workspaces & invitations left  for better ux, */
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
      closeCancelInvDialog();
      // closeInvitationDrawer();
    }
  };

  const getFilteredProjectTeam = () => {
    let tempTeam = [...workspaceData?.team].filter(
      (user) => user.role !== "admin"
    );
    if (projectTeamSearch) {
      tempTeam = tempTeam.filter((user) =>
        (user.first_name + " " + user.last_name)
          .toLowerCase()
          .includes(projectTeamSearch.toLowerCase())
      );
    }
    tempTeam = tempTeam.map((user) => {
      return {
        ...user,
        is_added: projectDetail?.team.some(
          (projectUser) => projectUser.user_id === user.user_id
        ),
      };
    });
    return {
      added: tempTeam.filter((user) => user.is_added),
      toAdd: tempTeam.filter((user) => !user.is_added),
    };
  };
  // const getFilteredInvitations = (invs, search) => {
  //   if (!search) {
  //     return invs;
  //   } else {
  //     return invs.filter((inv) =>
  //       inv?.user_data
  //         ? (inv.user_data.first_name + " " + inv.user_data.last_name)
  //             .toLowerCase()
  //             .includes(search.toLowerCase())
  //         : //    ||
  //           // inv.email.toLowerCase().includes(search.toLowerCase())
  //           inv.email.toLowerCase().includes(search.toLowerCase())
  //     );
  //   }
  // };
  const getFilteredInvitations = (invs, search, projectId) => {
    console.log("invs---", invs);
    console.log("projectId---", projectId);
    if (!invs) return [];
    const filteredByProject = invs.filter((inv) =>
      inv.project.includes(projectId)
    );
    console.log("filteredByProject---", filteredByProject);
    if (!search) {
      return filteredByProject;
    } else {
      return filteredByProject.filter((inv) =>
        inv?.user_data
          ? (inv.user_data.first_name + " " + inv.user_data.last_name)
              .toLowerCase()
              .includes(search.toLowerCase())
          : inv.email.toLowerCase().includes(search.toLowerCase())
      );
    }
  };
  ////////////////////////////////////////////
  // -------------------------OPTIMIZATION--------------------------------
  /* momoization */
  const memoFilteredSections = useMemo(() => {
    let sectionsWithTasks = filteredSectionsData.section_lists.map(
      (section) => {
        return {
          ...section,
          task: allFilteredTasksBySections[section._id].task_list,
          task_count: allFilteredTasksBySections[section._id].total,
          load_more_tasks:
            allFilteredTasksBySections[section._id].load_more_url,
          completed_task_count:
            allFilteredTasksBySections[section._id].completed_task_count,
        };
      }
    );
    return sectionsWithTasks;
  }, [filteredSectionsData, allFilteredTasksBySections]);

  const memoProjectDetail = useMemo(() => {
    return projectDetail;
  }, [
    projectDetail.completed_task,
    projectDetail.image,
    projectDetail.team,
    projectDetail.pending_task,
    projectDetail.total_task,
  ]);
  const memoProjectTeam = useMemo(() => {
    console.log(projectTeam, "avaaiovhaoivs");
    return projectTeam;
  }, [projectTeam]);

  /* callbacks -------------------------*/
  const ucbOnExpand = useCallback(
    (section) => handleExpandSection(section),
    []
  );
  const ucbOnSetSelectedTask = useCallback(
    (task) => onSetSelectedTask(task),
    []
  );
  const ucbOpenTaskDetail = useCallback((task, workspace_id, section) => {
    setSelectedTaskDescription(task.task_description);
    setSelectedTaskTitle(task.task_title);
    setSelectedTask({ ...task, sectionData: { ...section } });
    openTaskDetail();
  }, []);
  const ucbOpenComments = useCallback(
    (task) => {
      getTaskComments(task._id);
      openComments(task);
    },
    [allFilteredTasksBySections]
  );
  const ucbOnChangePriority = useCallback(
    (data, workspace_id) => {
      handleChangePriority(data, workspace_id);
    },
    [allFilteredTasksBySections, taskDetailOpen]
  );
  const ucbOnChangeStatus = useCallback(
    (data, workspace_id) => {
      handleChangeStatus(data, workspace_id);
    },
    [allFilteredTasksBySections, taskDetailOpen]
  );
  const handleEditSectionName = useCallback(
    (e) => {
      setSelectedSectionTitle(e.target.value);
    },
    [selectedSectionTitle]
  );
  const ucbOnUpdatetask = useCallback(
    (section_id, task_id, updated_task) => {
      let temp = allFilteredTasksBySections[section_id];
      console.log(temp,"Temp")
      // Prevents page blank error if temp is undefined while adding checklist - shamroz
      if (temp) {
        setAllFilteredTasksBySections((prev) => {
          return {
            ...prev,
            [section_id]: {
              ...temp,
              task_list: temp.task_list.map((oldTask) => {
                if (oldTask._id == task_id) {
                  return updated_task;
                } else {
                  return { ...oldTask };
                }
              }),
            },
          };
        });
      }
      setSelectedTask(updated_task);
    },
    [allFilteredTasksBySections, taskDetailOpen]
  );
  const ucbOnChangeDate = useCallback(
    (data, workspace_id) => {
      handleChangeDate(data, workspace_id);
    },
    [allFilteredTasksBySections, taskDetailOpen]
  );
  const ucbOnChangeTags = useCallback(
    (data) => {
      handleChangeTags(data);
    },
    [allFilteredTasksBySections, taskDetailOpen]
  );
  const ucbOnChangeMembers = useCallback(
    (data, workspace_id) => {
      handleChangeMembers(data, workspace_id);
    },
    [allFilteredTasksBySections, taskDetailOpen]
  );
  const ucbOnDelTask = useCallback((data, workspace_id) => {
    handleOpenDelTask(data, workspace_id);
  }, []);

  const ucbOnMovetoWorkspace = useCallback((data, workspace_id) => {
    setCurrentTaskToMove(data);
    setMoveToWorkspaceDrawerOpen(true);
  }, []);

  const ucbOnDelSection = useCallback((data, type) => {
    console.log(type, "del-type");
    if (type === "with_tasks") {
      setDelSectionWithTasks(true);
    }
    if (type === "without_tasks") {
      setDelSectionWithTasks(false);
    }
    handleOpenDelSection();
    setSelectedSection(data);
  }, []);

  const usbOnMakeSectionDefault = useCallback((data) => {
    openMakeDefaultSection();
    setSelectedSection(data);
    setSelectedSectionTitle(data.section_title);
  }, []);

  const ucbOnEditSection = useCallback((data) => {
    console.log(data, "edit-section");
    setSelectedSection(data);
    setSelectedSectionTitle(data.section_title);
    setSelectedSectionSprint({
      start_date_time: data.start_date_time,
      end_date_time: data.end_date_time,
      complete_date_time: data.complete_date_time,
    });
    openEditSection();
  }, []);

  const ucbOnMoveSection = useCallback(
    (data) => {
      handleMoveSection(data);
    },
    [
      filteredSectionsData.section_lists,
      filteredSectionsData.total,
      filteredSectionsData.load_more_url,
    ]
  );
  const ucbOnMoveTask = useCallback(
    (data) => {
      handleMoveTask(data);
    },
    [
      filteredSectionsData.section_lists,
      filteredSectionsData.total,
      filteredSectionsData.load_more_url,
      allFilteredTasksBySections,
    ]
  );

  const ucbOnDragEnd = useCallback(
    (data, workspaceId) => {
      handleDragEnd(data, workspaceId);
    },
    [
      filteredSectionsData.section_lists,
      filteredSectionsData.total,
      filteredSectionsData.load_more_url,
      allFilteredTasksBySections,
    ]
  );
  const ucbOnDragStart = useCallback(
    (data, workspaceId) => {
      handleDragStart(data, workspaceId);
    },
    [
      filteredSectionsData.section_lists,
      filteredSectionsData.total,
      filteredSectionsData.load_more_url,
      allFilteredTasksBySections,
    ]
  );
  // ---------------------------------------------------------

  const expandFirstSectionOnly = (_id, _index) => {
    if (settingOptions.expandAllSections === true) {
      return true;
    }
    if (_index === 0) {
      return true;
    }
    const expand_section_id = state?.expand_section_id;
    const scroll_to_task = state?.scroll_to_task;
    if (String(expand_section_id) === String(_id)) {
      return true;
    }
    return false;
  };
  const isSectionExpanded = (_id, _index) => {
    if (settingOptions.expandAllSections === true) {
      return true;
    }
    const expand_section_id = state?.expand_section_id;
    const scroll_to_task = state?.scroll_to_task;
    if (String(expand_section_id) === String(_id)) {
      return true;
    }
    let local_sections = get_from_localStorage("sections-expand-states");
    let [found] = local_sections.filter(
      (item) => String(item._id) === String(_id)
    );
    if (found) {
      return found.expanded;
    }
    return false;
  };
  const onSetSelectedTask = (task) => {
    setSelectedTask(task);
  };
  const currentTask = () => {
    if (selectedTask) {
      return selectedTask;
    }
    return undefined;
  };
  const shouldScroll = (_id) => {
    if (!state) {
      return false;
    }
    const { scroll_to_task } = state;
    if (!scroll_to_task) {
      return false;
    }

    if (String(scroll_to_task) === String(_id)) {
      return true;
    }
    return false;
  };
  const getModifiedTasks = (_tasks) => {
    return _tasks.map((task) => {
      return {
        ...task,
        query_field: task.task_title,
        ["scroll_to_view"]: shouldScroll(task._id),
      };
    });
  };
  const getModifiedSectionList = (data) => {
    const first_expand = firstExpand.current;
    let modified = data.map((item, index) => {
      return {
        ...item,
        task: [],
        expanded: first_expand
          ? expandFirstSectionOnly(item._id, index)
          : isSectionExpanded(item._id, index),
        move_up: index !== 0,
        move_down: index + 1 !== data.length,
        scroll_to_view: state?.expand_section_id == item._id,
        scroll_to_task: state?.scroll_to_task,
      };
    });
    console.log(modified, "modified-sections");
    return modified;
  };
  const getUpdatedSectionList = (data) => {
    let modified = data.map((item, index) => {
      return {
        ...item,
        task: getModifiedTasks(item.task),
        move_up: index !== 0,
        move_down: index + 1 !== data.length,
      };
    });
    console.log(modified, "modified-sections");
    return modified;
  };
  const getWorkspaceDetail = async (id) => {
    const result = await _user_workspace_detail(null, id);
    if (result.code === 200) {
      setWorkspaceData(result.workspace);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getProjectDetail = async () => {
    const result = await _project_detail(null, slug);
    if (result.code === 200) {
      console.log(result, "detail-res");
      startTransition(() => {
        setWorkspaceId(result.project_detail.workspace);
        setLoadingDetail(false);
        setProjectDetail(result.project_detail);
        setProjectTeam(
          result.project_detail.team.map((user) => {
            return {
              ...user,
              creator: user.user_id === dispatch_get_user_profile().user_id,
            };
          })
        );
        setProjectTags(result.project_detail.tag_colors);
        setCurrentUserRole(result.project_detail.role);
      });
      getWorkspaceDetail(result.project_detail.workspace);
    } else {
      setLoadingDetail(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getFilteredSections = async (
    workspace_id,
    explicit_req_data,
    filters
  ) => {
    console.log(sectionPage, "<===REQUEST-OBJECT===> ");
    if (filters) {
      setSectionPage(0);
    }
    let workspace = workspaceId;
    if (workspace_id) {
      workspace = workspace_id;
    }
    if (!workspace) {
      console.log("no workspaceId");
      return;
    }
    console.log(taskFilterOptions, "taskFilterOptions-req");
    console.log(filterOptions, "filterOptions-req");

    let req_data = {
      date: {
        date_type: "",
        start_date: "",
        end_date: "",
      },
      task_status: [],
      priority: [],
      workspace_id: workspace,
      sort_status: taskFilterOptions.sort_status,
      list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
    };
    let task_FilterOptions = get_from_localStorage("taskFilterOptions");
    let sidebar_Filters = get_from_localStorage("sidebarFilters");
    if (task_FilterOptions) {
      req_data = {
        date: {
          date_type:
            task_FilterOptions.date?.start_date === "createdAt"
              ? "createdAt"
              : "createdAt",
          start_date: task_FilterOptions.date?.start_date,
          end_date: task_FilterOptions.date?.end_date,
        },
        task_status: task_FilterOptions.status,
        priority: task_FilterOptions.priority,
        workspace_id: workspace,
        sort_status:
          task_FilterOptions.sort_status === "default"
            ? ""
            : task_FilterOptions.sort_status,
        list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
        search_users: task_FilterOptions.search_users,
      };
    }
    if (explicit_req_data) {
      console.log("explicit", explicit_req_data);
      req_data = explicit_req_data;
    }

    console.log(task_FilterOptions, "taskFilterOptions");
    console.log(sidebar_Filters, "sidebarFilters");
    console.log(req_data, "req-data-task-list");
    let tempPage = filters ? 0 : sectionPage;
    try {
      const result = await _project_sections_filter(req_data, slug, tempPage);
      if (result.code === 200) {
        console.log(result, "sections-res");
        const { section_list } = result;
        startTransition(() => {
          let old_sections = filteredSectionsData.section_lists;
          let new_sections = !filters
            ? old_sections.concat(section_list.section_lists)
            : section_list.section_lists;
          setFilteredSectionsData({
            loadMoreUrl: section_list.load_more_url,
            section_lists: getModifiedSectionList(new_sections).map(
              (section, index) => {
                if (index == 0) {
                  return {
                    ...section,
                    task: getModifiedTasks(section_list.task_list),
                    task_count: 0,
                    load_more_tasks: "",
                  };
                } else {
                  return { ...section };
                }
              }
            ),
            total: section_list.total,
          });

          console.log(section_list, "section_list");
          let default_index = 0;

          section_list.section_lists.forEach((sec, i) => {
            if (sec.is_default) {
              default_index = i;
            }
          });

          setSelectedSectionIndex(default_index);
          let temp_tasks = { ...allFilteredTasksBySections };
          section_list.section_lists.map((section, index) => {
            temp_tasks = {
              ...temp_tasks,
              [section._id]: {
                total: section.task_count,
                task_list:
                  index == 0 && tempPage == 0
                    ? getModifiedTasks(section_list.task_list)
                    : temp_tasks[section._id]
                    ? temp_tasks[section._id].task_list
                    : [],
                load_more_url: `api/task/task_filter_list/${section._id}?page=0&limit=15`,
                completed_task_count: section.completed_task_count,
              },
            };
          });
          setAllFilteredTasksBySections(temp_tasks);
          setSectionPage((prev) => prev + 1);
        });
        setTimeout(() => {
          setLoadingTasks(false);
        }, 0);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setLoadingTasks(false);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      setLoadingTasks(false);
      console.log(error, " catched while fetching sections");
    } finally {
      // setLoadingTasks(false);
    }
  };
  const getFilteredTasksBySection = async (section_id) => {
    let req_data = {
      date: {
        date_type: "",
        start_date: "",
        end_date: "",
      },
      task_status: [],
      priority: [],
      sort_status: taskFilterOptions.sort_status,
      list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
    };
    let task_FilterOptions = get_from_localStorage("taskFilterOptions");
    let sidebar_Filters = get_from_localStorage("sidebarFilters");
    if (task_FilterOptions) {
      req_data = {
        date: {
          date_type: task_FilterOptions.date?.date_type,
          start_date: task_FilterOptions.date?.start_date,
          end_date: task_FilterOptions.date?.end_date,
        },
        task_status: task_FilterOptions.status,
        priority: task_FilterOptions.priority,
        sort_status:
          task_FilterOptions.sort_status === "default"
            ? ""
            : task_FilterOptions.sort_status,
        search_users: task_FilterOptions.search_users,
        list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
      };
    }
    const result = await _project_tasks_filter(req_data, section_id);
    if (result.code == 200) {
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section_id]: {
            completed_task_count: prev[section_id].completed_task_count,
            ...result.task_detail,
            task_list: getModifiedTasks(result.task_detail.task_list),
          },
        };
      });
    }
  };
  const handleLoadMoreTasks = async (path, section_id) => {
    setMoreTasksLoading(true);
    let req_data = {
      task_status: [],
      priority: [],
      sort_status: taskFilterOptions.sort_status,
      list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
    };
    let task_FilterOptions = get_from_localStorage("taskFilterOptions");
    let sidebar_Filters = get_from_localStorage("sidebarFilters");
    if (task_FilterOptions) {
      req_data = {
        task_status: task_FilterOptions.status,
        priority: task_FilterOptions.priority,
        sort_status:
          task_FilterOptions.sort_status === "default"
            ? ""
            : task_FilterOptions.sort_status,
        list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
      };
    }
    const result = await _load_more_tasks(path, req_data);
    if (result.code == 200) {
      let temp = allFilteredTasksBySections[section_id];
      let old_tasks = temp.task_list;
      let new_tasks = old_tasks.concat(result.task_detail.task_list);
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section_id]: {
            ...temp,
            load_more_url: result.task_detail.load_more_url,
            total: result.task_detail.total,
            task_list: getModifiedTasks(new_tasks),
          },
        };
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setMoreTasksLoading(false);
  };
  const handleLoadMoreComments = async () => {
    setMoreCommentsLoading(true);
    const result = await _load_more_comments(
      selectedTaskCommentsData.load_more_url.substring(1)
    );
    if (result.code == 200) {
      let old_list = selectedTaskComments;
      let new_list = old_list.concat(result.project_list.comment_list);
      setSelectedTaskComments(new_list);
      setSelectedTaskCommentsData({
        total: result.project_list.total_comments,
        load_more_url: result.project_list.load_more_url,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setMoreCommentsLoading(false);
  };
  const getProjectTeam = async () => {
    if (!slug) {
      console.log("no proj id");
      return;
    }
    const result = await _project_team(null, slug);
    console.log(result, "proj-team");
    if (result.code === 200) {
      let modified = result.project_team_list.team.map((user) => {
        return {
          ...user,
          query_field: user.first_name + " " + user.last_name,
          creator: user.user_id === dispatch_get_user_profile().user_id,
        };
      });
      console.log(modified, "mft");
      setProjectTeam(modified);
    } else {
      result.code == 400 && navigate("/");
      result.code !== 400 &&
        enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getProjectTeamForRemoveUser = () => {
    const members = projectTeam.map((member) => {
      return {
        label: member.first_name + " " + member.last_name,
        value: member.user_id,
      };
    });
    return members.filter((member) => member.value !== selectedMember.user_id);
  };
  const handleNavBack = () => {
    navigate(-1);
  };
  const handleNavToProjects = () => {
    navigate(`/workspaces/${projectDetail.workspace}`, {
      state: {
        workspace_data: {
          name: projectDetail.workspace_name,
          role: projectDetail.role,
          _id: projectDetail.workspace,
        },
      },
    });
  };
  const getSkeletonCount = () => {
    let dummy = [];
    let length = Number(filteredSectionsData.section_lists.length);
    // let length = Number(projectDetail.total_task);
    if (length === 0) {
      return [];
    }
    for (let i = 0; i < length; i++) {
      dummy.push("dummy");
    }
    return dummy;
  };
  const openTaskDetail = (task) => {
    setTaskDetailOpen(true);
  };
  const closeTaskDetail = () => {
    setTaskDetailOpen(false);
    setAddTaskFiles([]);
    setEditingCommentId("");
    setNewComment("");
    setSelectedTask();
  };
  const openComments = (task) => {
    console.log(task, "on open comments");
    setSelectedTask(task);
    setCommentsOpen(true);
    let temp = allFilteredTasksBySections[task.section_id];
    let tempTask = temp.task_list.map((tsk) => {
      if (tsk._id == task._id) {
        return {
          ...tsk,
          task_comment_unread_count: 0,
        };
      } else {
        return { ...tsk };
      }
    });
    setAllFilteredTasksBySections((prev) => {
      return {
        ...prev,
        [task.section_id]: {
          ...temp,
          task_list: tempTask,
        },
      };
    });
  };
  const closeComments = () => {
    setCommentsOpen(false);
    pageRef.current.focus();
    startTransition(() => {
      setEditingComment(false);
      setSelectedComment(undefined);
      setEditCommentTitle("");
      setEditCommentType("0");
      setSelectedTaskComments([]);
      setSelectedTask();
    });
  };
  const openSettingsAndStats = () => {
    setSettingsAndStatsOpen(true);
  };

  const closeSettingsAndStats = (event, clickType) => {
    if (clickType === "backdropClick") {
      if (orderChanged) {
        setSettingsAndStatsOpen(true);
        enqueueSnackbar("Please save or discard changes before closing.", {
          variant: "info",
        });
      } else {
        setSettingsAndStatsOpen(false);
      }
    } else {
      setSettingsAndStatsOpen(false);
    }
  };
  const openFilters = () => {
    setFiltersOpen(true);
  };
  const closeFilters = (applied) => {
    console.log("-----tempFilters-----", tempFilters);
    if (!applied) {
      setFilterOptions({ ...tempFilters });
    } else {
      setTempFilters({ ...filterOptions });
    }
    setFiltersOpen(false);
  };
  const openDelSection = () => {
    setDelSectionOpen(true);
  };
  const closeDelSection = () => {
    setDelSectionOpen(false);
  };
  const openMakeDefaultSection = () => {
    setMakeDefaultSection(true);
  };
  const closeMakeDefaultSection = () => {
    setMakeDefaultSection(false);
  };
  const openEditSection = () => {
    setEditSectionOpen(true);
  };
  const closeEditSection = () => {
    setEditSectionOpen(false);
  };
  const openTeam = () => {
    setTeamOpen(true);
  };
  const closeTeam = () => {
    setTeamOpen(false);
  };
  const handleTaskSortClick = (_option, _index) => {
    console.log(_option, _index);
    setTaskSortOptionIndex(_index);
    setTaskFilterOptions((prev) => {
      return { ...prev, sort_status: _option.value };
    });

    let explicit_req_data = {
      task_status: [],
      priority: [],
      sort_status: _option.value !== "default" ? _option.value : "",
      workspace_id: workspaceId,
      list_type: taskFilterOptions.type,
    };

    getFilteredSections(undefined, explicit_req_data, true);
  };
  const getProgress = () => {
    if (!projectDetail) {
      setProgress(0);
      return;
    }
    if (
      !projectDetail.completed_task ||
      projectDetail.completed_task === Number(0)
    ) {
      setProgress(0);
      return;
    }
    if (!projectDetail.total_task || projectDetail.total_task === Number(0)) {
      setProgress(0);
      return;
    }
    let progress = Number(
      projectDetail.completed_task / (projectDetail.total_task / 100)
    );
    setProgress(progress);
  };

  const getSelectedSection = () => {
    if (filteredSectionsData.section_lists.length > 0) {
      let selected_section =
        filteredSectionsData.section_lists[selectedSectionIndex];
      if (selected_section) {
        return selected_section;
      }
    }
    return undefined;
  };

  const handleNewCommentValue = useCallback((val) => {
    setNewComment(val);
  }, []);

  const showAddTask = () => {
    setTaskField((prev) => !prev);
  };

  const isSection = (task) => {
    task = task.trim();
    let length = task.length;
    let char = task.charAt(length - 1);
    if (String(char) === ":") {
      return true;
    }
    return false;
  };
  const isDetailDisabled = () => {
    let task = currentTask();
    if (!task) {
      return true;
    }
    if (currentUserRole === "admin") {
      return false;
    }
    if (currentUserRole === "manager") {
      return false;
    }
    if (task.creator === true) {
      return false;
    }
    if (task.user_task_status === true) {
      return false;
    }
    return true;
  };
  const getFormattedDate = (date, format) => {
    if (!date) {
      return "";
    }
    return get_zone_date(date, format);
  };

  const createTask = async (e, task) => {
    e.preventDefault();
    let section = getSelectedSection();
    if (addingTask) {
      return;
    }
    if (!section) {
      console.log("no section selected");
      return;
    }
    if (section._id.trim() === "") {
      console.log("no section selected");
      return;
    }
    if (task.trim() === "") {
      enqueueSnackbar("Task cannot be empty", { variant: "error" });
      return;
    }
    const data = {
      task_title: task.trim(),
      task_description: taskInputs.description.trim(),
      status: true,
      task_type: "0" /* 0---> public task , 1---> private task */,
      team: taskInputs.team,
      end_date: null,
      image: [],
      task_status: taskInputs.status,
      priority: taskInputs.priority,
    };

    console.log(data, "add-task-req");
    console.log(section, "task-section");
    let [target_section] = filteredSectionsData.section_lists.filter(
      (item) => String(item._id) === String(section._id)
    );
    setAddingTask(true);
    const result = await _create_task(data, section._id);
    /* returns updated section obj */
    if (result.code === 200) {
      console.log(result, "create-task-res");
      let new_task = { ...result.task_detail, task_comment_unread_count: 0 };
      let temp = allFilteredTasksBySections[section._id];
      temp.task_list.unshift(new_task);
      console.log(temp, "ascaskcjhaskcjhas");
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section._id]: {
            ...temp,
            total: temp.total + 1,
            task_list: getModifiedTasks(temp.task_list),
          },
        };
      });
      setAddingTask(false);
      setTaskInputs({
        description: "",
        type: "0",
        team: [],
        status: 0,
        priority: 3,
        endDate: "",
      });
      // setTask("");

      /* don't call this after api response fix */
      enqueueSnackbar("Task Created Successfully", { variant: "success" });
    } else {
      setAddingTask(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSectionItemClick = (_option, _index) => {
    console.log(_option);
    setSelectedSectionIndex(_index);
  };
  const expandTargetSection = (data, target_id) => {
    let updated = data.map((item) => {
      if (String(item._id) === String(target_id)) {
        return { ...item, expanded: !item.expanded };
      }
      return item;
    });
    return updated;
  };

  const trueExpandTargetSection = (data, target_id) => {
    let updated = data.map((item) => {
      if (String(item._id) === String(target_id)) {
        return { ...item, expanded: true };
      }
      return item;
    });
    return updated;
  };
  const handleExpandSection = (section) => {
    firstExpand.current = false;
    setFilteredSectionsData((prev) => {
      return {
        ...prev,
        section_lists: expandTargetSection(prev.section_lists, section._id),
      };
    });
  };
  const closeDelTask = () => {
    setDelTaskOpen(false);
  };
  const openDelTask = () => {
    setDelTaskOpen(true);
  };
  /*  */
  const handleChangePriority = async (data, workspace_id) => {
    if (!workspace_id) {
      console.log("no wps id");
      return;
    }
    const { task, section, priority } = data;
    let prev_state_section = null;
    console.log(data, "on-change-Priority");
    console.log(priority, "new-priority");
    const req_data = {
      task_id: task._id,
      task_priority: priority,
      list_type: "0",
    };
    console.log(req_data, "req_data");

    let updated_task = { ...task, priority: priority };
    const getUpdatedTasks = (_data) => {
      let updated = _data.map((_task) => {
        if (String(_task._id) === String(task._id)) {
          return updated_task;
        }
        return _task;
      });
      console.log(updated, "updated tasks");
      return updated;
    };
    const old_state = allFilteredTasksBySections;
    let temp = allFilteredTasksBySections[section._id];

    // this condition check if the sections is loaded or not in the pagination.
    // Other wise it will blank the page.
    if (temp) {
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section._id]: {
            ...temp,
            task_list: getUpdatedTasks(temp.task_list),
          },
        };
      });
    }
    const result = await _update_task_priority(req_data);
    if (result.code === 200) {
      console.log(result, "res");
      getFilteredTasksBySection(result.task_detail.section_id._id);
      taskDetailOpen && setSelectedTask(result.task_detail);
      // let receiver = [result.task_detail.creator_profile.user_id];
      // result.task_detail.team.map((member) => {
      //   receiver.push(member.user_id);
      // });
      // receiver = receiver.filter(
      //   (id) => id !== dispatch_get_user_profile().user_id
      // );
      const { project, section_id, _id, workspace } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.allow_members,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.hidden_members,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `A member updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      enqueueSnackbar("Priority Updated Successfully", { variant: "success" });
    } else {
      setAllFilteredTasksBySections(old_state);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeDate = async (data, workspace_id) => {
    if (!workspace_id) {
      console.log("no wps id");
      return;
    }
    const { task, section, endDate } = data;
    let prev_state_section = undefined;
    console.log(data, "on-change-date");
    console.log(endDate, "new-date");
    let updated_task = { ...task, end_date: endDate };
    const getUpdatedTasks = (_data) => {
      let updated = _data.map((_task) => {
        if (String(_task._id) === String(task._id)) {
          return updated_task;
        }
        return _task;
      });
      console.log(updated, "updated tasks");
      return updated;
    };
    let temp = allFilteredTasksBySections[section._id];

    // this condition check if the sections is loaded or not in the pagination.
    // Other wise it will blank the page.
    if (temp) {
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section._id]: {
            ...temp,
            task_list: getUpdatedTasks(temp.task_list),
          },
        };
      });
    }
    const req_data = {
      task_id: task._id,
      end_date: endDate,
      list_type: "0",
    };
    const result = await _update_task_end_date(req_data);
    if (result.code === 200) {
      getFilteredTasksBySection(result.task_detail.section_id._id);
      taskDetailOpen && setSelectedTask(result.task_detail);
      console.log(result, "res");
      let receiver = [result.task_detail.creator_profile.user_id];
      result.task_detail.team.map((member) => {
        receiver.push(member.user_id);
      });
      receiver = receiver.filter(
        (id) => id !== dispatch_get_user_profile().user_id
      );
      const { project, section_id, _id, workspace } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the target date of the task`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      // enqueueSnackbar("Target Date Updated Successfully", {
      //   variant: "success",
      // });
    } else {
      const getResetSections = (_data) => {
        let reset = _data.map((_section) => {
          if (String(_section._id) === String(section._id)) {
            return prev_state_section;
          }
          return _section;
        });
        console.log(reset, "reset section");
        return reset;
      };
      // setTaskData((prev) => {
      //   return { ...prev, sections: getResetSections(prev.sections) };
      // });
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeStatus = async (data) => {
    const { checked, section, task, is_checkbox, status } = data;
    console.log(data, "on-change-Status");
    if (task.task_status == status) return;

    const old_state = allFilteredTasksBySections;
    const hitStatusApi = async (req_data, custom_msg) => {
      const result = await _update_task_status(req_data);
      if (result.code === 200) {
        console.log(result, "res");
        getFilteredTasksBySection(result.task_detail.section_id._id);
        taskDetailOpen && setSelectedTask(result.task_detail);
        if (custom_msg) {
          enqueueSnackbar(custom_msg, { variant: "info" });
          // setFilteredSectionsData((prev) => {
          //   return {
          //     ...prev,
          //     section_lists: prev.section_lists.map((sec) => {
          //       if (sec._id == result.task_detail.section_id) {
          //         return {
          //           ...sec,
          //           completed_task_count: sec.completed_task_count - 1,
          //         };
          //       } else {
          //         return { ...sec };
          //       }
          //     }),
          //   };
          // });
          return;
        }
        // let receiver = [result.task_detail.creator_profile.user_id];
        // result.task_detail.team.map((member) => {
        //   receiver.push(member.user_id);
        // });
        // receiver = receiver.filter(
        //   (id) => id !== dispatch_get_user_profile().user_id
        // );
        const { project, section_id, _id, workspace } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.allow_members,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.hidden_members,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `A member updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
        enqueueSnackbar("Status Updated Successfully", { variant: "success" });
      } else {
        setAllFilteredTasksBySections(old_state);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };
    if (is_checkbox) {
      let updated_status = checked === true ? 3 : 0;
      console.log(updated_status, "new-status");
      let updated_task = { ...task, task_status: updated_status };
      const getUpdatedTasks = (_data) => {
        let updated = _data.map((_task) => {
          if (String(_task._id) === String(task._id)) {
            return updated_task;
          }
          return _task;
        });
        console.log(updated, "updated tasks");
        return updated;
      };
      let temp = allFilteredTasksBySections[section._id];

      // this condition check if the sections is loaded or not in the pagination.
      // Other wise it will blank the page.
      if (temp) {
        setAllFilteredTasksBySections((prev) => {
          return {
            ...prev,
            [section._id]: {
              ...temp,
              task_list: getUpdatedTasks(temp.task_list),
              completed_task_count:
                updated_status == 3
                  ? temp.completed_task_count + 1
                  : data.task.task_status == 3
                  ? temp.completed_task_count - 1
                  : temp.completed_task_count,
            },
          };
        });
      }
      const req_data = {
        task_id: task._id,
        task_status: updated_status,
        list_type: "0",
      };
      hitStatusApi(req_data);
    }
    if (!is_checkbox) {
      console.log(status, "new-status");
      let updated_task = { ...task, task_status: status };
      const getUpdatedTasks = (_data) => {
        let updated = _data.map((_task) => {
          if (String(_task._id) === String(task._id)) {
            return updated_task;
          }
          return _task;
        });
        console.log(updated, "updated tasks");
        return updated;
      };
      const getUpdatedTasksVerified = (_data) => {
        return _data.filter((_task) => String(_task._id) !== String(task._id));
      };
      let temp = allFilteredTasksBySections[section._id];

      // this condition check if the sections is loaded or not in the pagination.
      // Other wise it will blank the page.
      if (temp) {
        if (String(status) === "4") {
          setAllFilteredTasksBySections((prev) => {
            return {
              ...prev,
              [section._id]: {
                ...temp,
                task_list: getUpdatedTasksVerified(temp.task_list),
                total: temp.total - 1,
                completed_task_count:
                  data.task.task_status == 3
                    ? temp.completed_task_count - 1
                    : temp.completed_task_count,
              },
            };
          });
        }
        if (String(status) !== "4") {
          setAllFilteredTasksBySections((prev) => {
            return {
              ...prev,
              [section._id]: {
                ...temp,
                task_list: getUpdatedTasks(temp.task_list),
                completed_task_count:
                  status == 3
                    ? temp.completed_task_count + 1
                    : data.task.task_status == 3
                    ? temp.completed_task_count - 1
                    : temp.completed_task_count,
                // : temp.completed_task_count,
              },
            };
          });
        }
      }
      let custom_msg = undefined;
      const req_data = {
        task_id: task._id,
        task_status: status,
        list_type: "0",
      };
      if (String(status) === "4") {
        custom_msg = "Task moved to verified listing";
      }
      if (custom_msg) {
        hitStatusApi(req_data, custom_msg);
        return;
      }
      hitStatusApi(req_data);
    }
  };
  const handleChangeTags = async (data, sectionId) => {
    try {
      const result = await _update_task_tags_in_task(data);
      if (result.code == 200) {
        taskDetailOpen && setSelectedTask(result.task_data);
        console.log(result, "result_task");
        getFilteredTasksBySection(result.task_data.section_id._id);
        // Prevents page blank error if temp is undefined while adding label - shamroz
        let temp = allFilteredTasksBySections[data?.section?._id];
        if (temp) {
          setAllFilteredTasksBySections((prev) => {
            return {
              ...prev,
              [result.task_data.section_id]: {
                ...prev[result.task_data.section_id],
                task_list: prev[result.task_data.section_id].task_list.map(
                  (task) => {
                    if (task._id == data.task_id) {
                      return { ...result.task_data };
                    } else {
                      return { ...task };
                    }
                  }
                ),
              },
            };
          });
        }
      }
    } catch (error) {
      console.log(error, " catched while updating task tags");
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };
  const handleChangeMembers = async (data, workspace_id) => {
    if (!workspace_id) {
      console.log("no wps id");
      return;
    }
    const { section, task, taskTeam, isSelected, targetUser, action } = data;
    console.log(data, "payload");
    setLoadingMember((prev) => ({ ...prev, [targetUser.user_id]: true }));
    let updated_team = Array.from(taskTeam);
    if (action === "add") {
      if (isSelected) {
        return;
      }
      if (!isSelected) {
        updated_team.push(targetUser);
      }
    }
    if (action === "remove") {
      if (isSelected) {
        updated_team = updated_team.filter(
          (user) => String(user.user_id) !== String(targetUser.user_id)
        );
      }
    }

    console.log(data, "on-change-Members");

    console.log(updated_team, "new-team");
    let updated_task = { ...task, team: updated_team };
    const getUpdatedTasks = (_data) => {
      let updated = _data.map((_task) => {
        if (String(_task._id) === String(task._id)) {
          return updated_task;
        }
        return _task;
      });
      console.log(updated, "updated tasks");
      return updated;
    };

    let updated_task_team = [];
    updated_team.map((user) => {
      updated_task_team.push(user);
    });
    const req_data = {
      task_id: task._id,
      team: updated_task_team,
      list_type: "0",
    };
    const updatedTasks = (_data) => {
      return _data.map((item) => {
        if (String(item._id) === String(task._id)) {
          return { ...task_detail, query_field: item.task_title };
        }
        return item;
      });
    };
    const old_state = allFilteredTasksBySections;
    let temp = allFilteredTasksBySections[section._id];

    // this condition check if the sections is loaded or not in the pagination.
    // Other wise it will blank the page.
    if (temp) {
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section._id]: {
            ...temp,
            task_list: getUpdatedTasks(temp.task_list),
          },
        };
      });
    }
    const result = await _update_task_members(req_data);
    if (result.code === 200) {
      taskDetailOpen && setSelectedTask(result.task_detail);
      console.log(result, "update-members-res");
      const { task_detail } = result;

      // enqueueSnackbar("Task Members Updated Successfully", {
      //   variant: "success",
      // });
      if (action == "add") {
        let receiver = [targetUser.user_id];
        receiver = receiver.filter(
          (id) => id !== dispatch_get_user_profile().user_id
        );
        const { project, section_id, _id, workspace } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } assigned you a task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    } else {
      setAllFilteredTasksBySections(old_state);
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setLoadingMember((prev) => ({ ...prev, [targetUser.user_id]: false }));
  };
  const handleOpenDelTask = async (data) => {
    openDelTask();
  };
  const handleDeleteTask = async () => {
    let selectedTask = currentTask();
    const sectionData = allFilteredTasksBySections[selectedTask.section_id];
    console.log(sectionData, "section_Data");
    let prev_state_section = undefined;
    const updatedTasks = (_data) => {
      return _data.filter(
        (item) => String(item._id) !== String(selectedTask?._id)
      );
    };
    let updated_section = {
      ...sectionData,
      task: updatedTasks(sectionData.task_list),
    };
    console.log(selectedTask, "-on-del-task");
    const data = {
      task_id: selectedTask?._id,
      task_type: selectedTask?.task_type,
      task_order: selectedTask?.order,
    };
    console.log(data, "del-task-req");
    const updated_SectionsList = (_data) => {
      let updated = _data.map((item) => {
        if (String(item._id) === String(selectedTask.section_id)) {
          prev_state_section = item;
          return updated_section;
        }
        return item;
      });
      return updated;
    };
    setProcessing(true);
    const result = await _delete_task(selectedTask?._id);
    if (result.code === 200) {
      let temp = allFilteredTasksBySections[selectedTask.section_id];
      // Prevents page blank error if "task_list" is undefined while deleting task - shamroz
      if (temp) {
        let new_task_list = temp.task_list.filter(
          (task) => task._id !== selectedTask?._id
        );
        setAllFilteredTasksBySections((prev) => {
          return {
            ...prev,
            [selectedTask.section_id]: {
              ...temp,
              total: temp.total - 1,
              task_list: new_task_list,
              completed_task_count:
                selectedTask.task_status == 3
                  ? temp.completed_task_count - 1
                  : temp.completed_task_count,
            },
          };
        });
      }
      // if (selectedTask.task_status == 3) {
      //   setFilteredSectionsData((prev) => {
      //     return {
      //       ...prev,
      //       section_lists: prev.section_lists.map((sec) => {
      //         if (sec._id == selectedTask.section_id) {
      //           return {
      //             ...sec,
      //             completed_task_count: sec.completed_task_count - 1,
      //           };
      //         } else {
      //           return { ...sec };
      //         }
      //       }),
      //     };
      //   });
      // }
      closeTaskDetail();
      closeDelTask();
      console.log(result, "res");
      setProcessing(false);
      enqueueSnackbar("Task Deleted Successfully", { variant: "success" });
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleOpenDelSection = async () => {
    openDelSection();
  };
  const expandAllSections = () => {
    let updated = filteredSectionsData.section_lists.map((item) => {
      return { ...item, expanded: true };
    });
    startTransition(() => {
      setFilteredSectionsData((prev) => {
        return { ...prev, section_lists: updated };
      });
    });
  };

  const copytaskToSections = (sectionId) => {
    let updated = filteredSectionsData.section_lists.map((item) => {
      if (sectionId == item?._id) {
        console.log(sectionId, "sectionId", item);
        item.task_count = item.task_count + 1;
      }
      return { ...item };
    });
    startTransition(() => {
      setFilteredSectionsData((prev) => {
        return { ...prev, section_lists: updated };
      });
    });
  };

  const onExpandAll = (e) => {
    const { target } = e;
    setExpandAllState(target.checked);
    setSettingOptions((prev) => {
      return { ...prev, expandAllSections: target.checked };
    });
    if (target.checked) {
      expandAllSections();
    } else {
      let updated = filteredSectionsData.section_lists.map((item, index) => {
        return { ...item, expanded: index === 0 };
      });
      startTransition(() => {
        setFilteredSectionsData((prev) => {
          return { ...prev, section_lists: updated };
        });
      });
    }
  };
  const handleFilterOptionChange = (type, _filterOptions) => {
    let selected_priority = [];
    let selected_status = [];
    if (type === "date") {
      setTaskFilterOptions((prev) => ({
        ...prev,
        date: {
          date_type: _filterOptions.date_type,
          start_date: _filterOptions.start_date,
          end_date: _filterOptions.end_date,
        },
      }));
    }
    if (type === "status") {
      Object.keys(_filterOptions).map((item, i) => {
        if (_filterOptions[item] === true) {
          let [found] = TASK_STATUS_OPTIONS.filter((_item, i) => {
            if (
              String(_item.lookup.toLowerCase()) === String(item.toLowerCase())
            ) {
              return _item;
            }
          });
          if (found) {
            selected_status.push(found.value);
          }
        }
      });
      setTaskFilterOptions((prev) => {
        return { ...prev, status: selected_status };
      });
      console.log(selected_status, "selected_status");
    }
    if (type === "priority") {
      Object.keys(_filterOptions).map((item, i) => {
        if (_filterOptions[item] === true) {
          let [found] = TASK_PRIORITY_OPTIONS.filter((_item, i) => {
            if (
              String(_item.lookup.toLowerCase()) === String(item.toLowerCase())
            ) {
              return _item;
            }
          });
          if (found) {
            selected_priority.push(found.value);
          }
        }
      });
      setTaskFilterOptions((prev) => {
        return { ...prev, priority: selected_priority };
      });
      console.log(selected_priority, "selected_priority");
    }
  };
  const handleChangeFilter = (e, _type, _value) => {
    const { target } = e;
    const updateObject = (Obj) => {
      let updated = { ...Obj, [_value]: target.checked };
      return updated;
    };
    if (_type === "date") {
      let updated_filter_options = filterOptions;
      updated_filter_options = {
        ...updated_filter_options,
        date: {
          date_type: _value?.date_type ?? "",
          start_date: _value?.start_date ?? "",
          end_date: _value?.end_date ?? "",
        },
      };
      console.log(updated_filter_options, "updated_date_filter_options");
      setFilterOptions(updated_filter_options);
      handleFilterOptionChange("date", updated_filter_options.date);
    }
    if (_type === "status") {
      let updated_filter_options = filterOptions;
      updated_filter_options = {
        ...updated_filter_options,
        status: updateObject(updated_filter_options.status),
      };
      console.log(updated_filter_options, "updated_filter_options");
      setFilterOptions(updated_filter_options);
      handleFilterOptionChange("status", updated_filter_options.status);
    }
    if (_type === "priority") {
      let updated_filter_options = filterOptions;
      updated_filter_options = {
        ...updated_filter_options,
        priority: updateObject(updated_filter_options.priority),
      };
      console.log(updated_filter_options, "updated_filter_options");
      setFilterOptions(updated_filter_options);
      handleFilterOptionChange("priority", updated_filter_options.priority);
    }
    if (_type === "search_users") {
      if (target.checked) {
        setFilterOptions((prev) => {
          return {
            ...prev,
            search_users: !prev.search_users?.find((id) => id == _value)
              ? [...(prev.search_users ? prev.search_users : []), _value]
              : [...prev.search_users],
          };
        });
        setTaskFilterOptions((prev) => {
          return {
            ...prev,
            search_users: !prev.search_users?.find((id) => id == _value)
              ? [...(prev.search_users ? prev.search_users : []), _value]
              : [...prev.search_users],
          };
        });
      } else {
        setFilterOptions((prev) => {
          return {
            ...prev,
            search_users: prev.search_users
              ? prev.search_users.filter((id) => id !== _value)
              : [],
          };
        });
        setTaskFilterOptions((prev) => {
          return {
            ...prev,
            search_users: prev.search_users
              ? prev.search_users.filter((id) => id !== _value)
              : [],
          };
        });
      }
    }
  };

  const handleDelSection = async () => {
    console.log(selectedSection, "section-to-del");
    if (!selectedSection) {
      return;
    }

    if (delSectionWithTasks) {
      console.log("del with tasks");
      setProcessing(true);
      const result = await _delete_section_with_task(null, selectedSection._id);
      if (result.code === 200) {
        console.log(result, "res");
        setProcessing(false);
        enqueueSnackbar("Section And Its Tasks Deleted Successfully", {
          variant: "success",
        });
        const updated_SectionsList = (_data) => {
          return _data.filter(
            (item) => String(item._id) !== String(selectedSection._id)
          );
        };
        setFilteredSectionsData((prev) => {
          return {
            ...prev,
            total: prev.total - 1,
            section_lists: updated_SectionsList(prev.section_lists),
          };
        });
        let defaultSection = null;
        let defaultSectionIndex = null;
        filteredSectionsData.section_lists.map((section, index) => {
          if (section.is_default) {
            defaultSection = section;
            defaultSectionIndex = index;
          }
        });
      } else {
        setProcessing(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
      closeDelSection();
    }
    if (!delSectionWithTasks) {
      console.log("del without tasks");
      const req_data = {
        section_type: 0,
      };
      setProcessing(true);
      const result = await _delete_section_without_task(
        req_data,
        selectedSection._id
      );
      if (result.code === 200) {
        console.log(result, "res");
        setProcessing(false);
        const updated_SectionsList = (_data) => {
          return _data.filter(
            (item) => String(item._id) !== String(selectedSection._id)
          );
        };
        setFilteredSectionsData((prev) => {
          return {
            ...prev,
            total: prev.total - 1,
            section_lists: updated_SectionsList(prev.section_lists),
          };
        });
        let temp0 = allFilteredTasksBySections;
        let temp = allFilteredTasksBySections[selectedSection._id];
        let defaultSection = null;
        let defaultSectionIndex = null;
        filteredSectionsData.section_lists.map((section, index) => {
          if (section.is_default) {
            defaultSection = section;
            defaultSectionIndex = index;
          }
        });
        let tempDefaultSection = allFilteredTasksBySections[defaultSection._id];
        let tempTasks = temp.task_list.concat(tempDefaultSection.task_list);
        let tempCount = temp.total + tempDefaultSection.total;
        delete temp0[selectedSection._id];
        setSelectedSection(defaultSection);
        setSelectedSectionIndex(defaultSectionIndex);
        setAllFilteredTasksBySections((prev) => {
          return {
            ...temp0,
            [defaultSection._id]: {
              ...defaultSection,
              task_list: tempTasks,
              total: tempCount,
            },
          };
        });

        console.log(defaultSection, "asasjcklasjcklasclks", temp);

        enqueueSnackbar("Section Deleted Without Tasks Successfully", {
          variant: "success",
        });
      } else {
        setProcessing(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
      closeDelSection();
    }
  };
  const handleMakeDeafultSection = async () => {
    if (!selectedSection) {
      return;
    }
    const data = {
      project_id: slug,
    };
    setProcessing(true);
    const result = await _make_section_default(data, selectedSection._id);
    if (result.code == 200) {
      let updated = (temp) => {
        temp.map((section) => {
          if (section._id == selectedSection._id) {
            section.is_default = true;
          } else {
            section.is_default = false;
          }
        });
        return temp;
      };
      setFilteredSectionsData((prev) => {
        return { ...prev, section_lists: updated(prev.section_lists) };
      });
      console.log(filteredSectionsData, "asdasdhkasjchkas");
      enqueueSnackbar("Default section changed successfuly", {
        variant: "success",
      });
      closeMakeDefaultSection();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setProcessing(false);
  };
  const handleUpdateSection = async (req_data, sectionId) => {
    if (!selectedSection && !req_data && !sectionId) {
      return;
    }
    let section_id;
    let data;

    if (req_data && sectionId) {
      data = req_data;
      section_id = sectionId;
    } else {
      let feedback = [];
      if (selectedSection?.feed_back?.length > 0) {
        feedback = selectedSection?.feed_back;
        delete feedback[0]._id;
      }
      data = {
        ...selectedSectionSprint,
        section_title: selectedSectionTitle.trim(),
        list_type: 0,
        project_id: slug,
        feed_back: feedback,
        sprint_status: selectedSectionSprint.complete_date_time
          ? moment(selectedSectionSprint.complete_date_time).isSame(
              moment(selectedSectionSprint.end_date_time),
              "day"
            ) ||
            moment(selectedSectionSprint.complete_date_time).isBefore(
              moment(selectedSectionSprint.end_date_time),
              "day"
            )
            ? "on_time"
            : "late"
          : "",
      };
      section_id = selectedSection._id;
    }
    if (!data.section_title) {
      enqueueSnackbar("Please add valid name for Section", {
        variant: "error",
      });
      return;
    } else if (data.start_date_time && !data.end_date_time) {
      enqueueSnackbar("End date is required with start date", {
        variant: "error",
      });
      return;
    } else if (!data.start_date_time && data.end_date_time) {
      enqueueSnackbar("Start date is required with end date", {
        variant: "error",
      });
      return;
    }
    setProcessing(true);
    const result = await _update_section(data, section_id);
    if (result.code === 200) {
      const updated = (_data) => {
        let updated_sections = _data.map((item) => {
          if (String(item._id) === String(section_id)) {
            return {
              ...item,
              section_title: data.section_title,
              end_date_time: data.end_date_time,
              start_date_time: data.start_date_time,
              complete_date_time: data.complete_date_time,
              feedback: data.feedback,
            };
          }
          return item;
        });
        return updated_sections;
      };
      console.log(result, "edit-section-res");
      enqueueSnackbar("Section Updated Successfully", { variant: "success" });
      let temp = filteredSectionsData.section_lists;
      setFilteredSectionsData((prev) => {
        return {
          ...prev,
          section_lists: updated(prev.section_lists),
        };
      });
      setProcessing(false);
      closeEditSection();
      if (data.sprint_status) {
        setProjectDetail((prev) => {
          return {
            ...prev,
            in_progress_sprint_count: prev.in_progress_sprint_count - 1,
            late_sprint_count:
              data.sprint_status == "late"
                ? prev.late_sprint_count + 1
                : prev.late_sprint_count,
            on_time_completed_sprint_count:
              data.sprint_status == "on_time"
                ? prev.on_time_completed_sprint_count + 1
                : prev.on_time_completed_sprint_count,
          };
        });
      }
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const openDelUserDialog = () => {
    setDelUserOpen(true);
  };
  const closeDelUserDialog = () => {
    setDelUserOpen(false);
    setRemoveProjectUserType("remove");
  };
  const onRemoveUser = (data) => {
    console.log(data, "on remove user");
    setSelectedMember(data);
    openDelUserDialog();
  };
  const handleUpdateProjectTeam = async (type, member) => {
    if (!member) {
      return;
    }
    setFullRefresh(true);
    let updated_team = [...projectTeam];
    if (type == "remove") {
      updated_team = updated_team.filter(
        (user) => user.user_id !== member.user_id
      );
    } else {
      updated_team.push(member);
    }
    const req_data = {
      team: updated_team,
      member_id: autoCompleteValue?.value,
    };
    console.log(updated_team, "req_data");
    const result = await _update_project_team(req_data, projectDetail._id);
    if (result.code === 200) {
      enqueueSnackbar(
        `Member ${type == "remove" ? "removed" : "added"} successfully`,
        { variant: "success" }
      );
      closeDelUserDialog();
      setProjectTeam(updated_team);
      setProjectDetail((prev) => {
        return {
          ...prev,
          team: updated_team,
        };
      });
      setFullRefresh(false);
      setAutoCompleteValue();
    } else {
      setFullRefresh(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // const getFilteredProjectTeam = () => {
  //   return handle_localSearch(projectTeamSearch, projectTeam);
  // };

  const handleMoveTask = async (data) => {
    console.log(data, "move-task");
    const { sourceTaskSection, sourceTask, moveTo } = data;
    console.log(data, "payload");
    let updated_source_section_tasks = sourceTaskSection.task.filter(
      (item) => String(item._id) !== String(sourceTask._id)
    );
    let updated_source_section = {
      ...sourceTaskSection,
      task: updated_source_section_tasks,
      completed_task_count:
        sourceTask.task_status == 3
          ? sourceTaskSection.completed_task_count - 1
          : sourceTaskSection.completed_task_count,
    };
    let [updated_destination_section] =
      filteredSectionsData.section_lists.filter(
        (item) => String(item._id) === String(moveTo._id)
      );
    updated_destination_section = {
      ...updated_destination_section,
      task: [...updated_destination_section.task, sourceTask],
      completed_task_count:
        sourceTask.task_status == 3
          ? sourceTaskSection.completed_task_count + 1
          : sourceTaskSection.completed_task_count,
    };
    console.log(updated_source_section, "updated_source_section");
    console.log(updated_destination_section, "updated_destination_section");
    const updated_SectionsList = (_data) => {
      let updated = _data.map((item) => {
        if (String(item._id) === String(sourceTaskSection._id)) {
          return updated_source_section;
        }
        if (String(item._id) === String(moveTo._id)) {
          return updated_destination_section;
        }
        return item;
      });
      return updated;
    };
    const req_data = {
      task_id: sourceTask._id,
      destination_section_id: moveTo._id,
    };
    const result = await _move_task_to_section(req_data);
    if (result.code === 200) {
      console.log(result, "move-to-res");
      let old_sectionId = sourceTaskSection._id;
      let new_sectionId = moveTo._id;
      let task = sourceTask;
      let old_sectionData = { ...allFilteredTasksBySections[old_sectionId] };
      let updated_oldSectionTasks = old_sectionData.task_list.filter(
        (tempTask) => tempTask._id !== task._id
      );
      let new_sectionData = allFilteredTasksBySections[new_sectionId];
      let updated_newSectionTasks = [task, ...new_sectionData.task_list];
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [old_sectionId]: {
            ...old_sectionData,
            task_list: updated_oldSectionTasks,
            total: old_sectionData.total - 1,
            completed_task_count:
              sourceTask.task_status == 3
                ? old_sectionData.completed_task_count - 1
                : old_sectionData.completed_task_count,
          },
          [new_sectionId]: {
            ...new_sectionData,
            task_list: updated_newSectionTasks,
            total: new_sectionData.total + 1,
            completed_task_count:
              sourceTask.task_status == 3
                ? new_sectionData.completed_task_count + 1
                : new_sectionData.completed_task_count,
          },
        };
      });
      enqueueSnackbar("Task Moved Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  // ------------------------------ -----------
  const handleMoveSection = async (data) => {
    console.log(data, "payload");
    const { source_section, type } = data;

    if (source_section.order < 0) {
      console.log("order cannot be 0 or less");
      return;
    }
    let updated_order = undefined;
    let destination_section = undefined;
    if (type === "up") {
      let prev = undefined;
      filteredSectionsData.section_lists.filter((item, index) => {
        if (String(item._id) === String(source_section._id)) {
          prev = Number(index) - 1;
        }
      });
      destination_section = filteredSectionsData.section_lists[prev];
      console.log(destination_section, "destination_section");
      updated_order = Number(source_section.order) - 1;
      if (!destination_section) {
        console.log("no destination section");
        return;
      }
    }
    if (type === "down") {
      let next = undefined;
      filteredSectionsData.section_lists.filter((item, index) => {
        if (String(item._id) === String(source_section._id)) {
          next = Number(index) + 1;
        }
      });
      destination_section = filteredSectionsData.section_lists[next];
      console.log(destination_section, "destination_section");
      updated_order = Number(source_section.order) + 1;
      if (!destination_section) {
        console.log("no destination section");
        return;
      }
    }
    let updated_sections = filteredSectionsData.section_lists.map(
      (_item, index) => {
        if (String(_item._id) === String(source_section._id)) {
          return {
            ...destination_section,
            order: source_section.order,
            move_up: index !== 0,
            move_down: filteredSectionsData.section_lists.length !== index + 1,
          };
        }
        if (String(_item._id) === String(destination_section._id)) {
          return {
            ...source_section,
            order: destination_section.order,
            move_up: index !== 0,
            move_down: filteredSectionsData.section_lists.length !== index + 1,
          };
        }
        return _item;
      }
    );
    console.log(updated_sections, "updated_sections");

    const req_data = {
      section_id: source_section._id,
      list_type: "0",
      order: updated_order,
    };

    const result = await _update_section_order(req_data, slug);

    if (result.code === 200) {
      setFilteredSectionsData((prev) => {
        return { ...prev, section_lists: updated_sections };
      });
      if (type == "down") {
        setSelectedSectionIndex((prev) => prev + 1);
      } else {
        setSelectedSectionIndex((prev) => prev - 1);
      }
      console.log(result, "order-change-res");
      enqueueSnackbar("Section Order Updated", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const removeLocalImage = async (_item, _index) => {
    let filtered = addTaskFiles.filter((item, index) => index !== _index);
    setAddTaskFiles(filtered);
  };
  const getFileIcon = (type) => {
    const [found] = ALLOWED_TASK_FILES_WITH_ICONS.filter(
      (item) => String(item.type) === String(type)
    );
    if (found) {
      return { path: found.icon, color: found.color };
    }
    return { path: "akar-icons:file", color: "black" };
  };
  const getLocalImages = () => {
    let paths = [];
    for (let i = 0; i < addTaskFiles.length; i++) {
      if (addTaskFiles[i].type === "file" || addTaskFiles[i].type === "video") {
        paths.push({ type: "file", ...getFileIcon(addTaskFiles[i].file.type) });
      }
      if (addTaskFiles[i].type === "image") {
        let path = URL.createObjectURL(addTaskFiles[i].file);
        paths.push({ path: path, type: "image" });
      }
    }
    return paths;
  };
  const removeTaskFile = (_path) => {
    const task = currentTask();
    if (!task) {
      return;
    }
    let filtered = task.image.filter((path) => String(path) !== String(_path));
    let updated_task = { ...task, image: filtered };
    setSelectedTask(updated_task);
  };
  const getFileIconType = (path) => {
    if (!path) {
      return "";
    }
    let ext = path.split("/")[0];
    let found = undefined;
    ALLOWED_TASK_FILES_WITH_ICONS.map((item) => {
      item.baseTypes.map((_type) => {
        if (String(_type) === String(ext)) {
          found = item;
        }
      });
    });

    if (found) {
      return { file_type: "file", file_path: found.icon, color: found.color };
    }
    if (!found) {
      return { file_type: "image", file_path: path };
    }
  };
  const getTaskImages = () => {
    let files = [];
    let task = currentTask();
    if (task?.image) {
      const { image } = task;
      for (let path of image) {
        let data = getFileIconType(path);
        const { file_type, file_path, color } = data;
        files.push({
          path: file_path,
          type: file_type,
          db_path: path,
          color: color,
        });
      }
    }
    return files;
  };
  const uploader = async () => {
    if (addTaskFiles.length === 0) {
      console.log("no files");
      return [];
    }
    let paths = [];
    for (let i = 0; i < addTaskFiles.length; i++) {
      let res = await upload_task_files(addTaskFiles[i].file);
      paths.push(res.path);
    }
    return paths;
  };
  const handleFileChange = async (e) => {
    console.log(e.target.files[0]);
    let final_files = [];
    if (updatingTask) {
      return;
    }
    let files = e.target.files;
    console.log(files, "target-files");
    for (let i = 0; i < files.length; i++) {
      console.log(files[i], "file");
    }
    for (let i = 0; i < files.length; i++) {
      ALLOWED_TASK_FILE_TYPES.map((item, index) => {
        if (item === String(files[i].type)) {
          console.log(files[i].type, "type");
          if (
            files[i].type.includes("application") ||
            files[i].type.includes("text")
          ) {
            final_files.push({ file: files[i], type: "file" });
          }
          if (files[i].type.includes("image")) {
            final_files.push({ file: files[i], type: "image" });
          }
          if (files[i].type.includes("video")) {
            final_files.push({ file: files[i], type: "video" });
          }
        }
      });
    }
    console.log(final_files, "final_files");
    setAddTaskFiles([...addTaskFiles, ...final_files]);
    //
  };
  const handleEditTask = async (e) => {
    let target_task = currentTask();
    console.log(target_task);
    let task_file_paths = [...target_task.image];
    setUpdatingTask(true);
    let image_paths = await uploader();
    if (image_paths) {
      console.log("file-paths", image_paths);
      task_file_paths = [...target_task.image, ...image_paths];
    }
    console.log(task_file_paths, "final-paths");
    const req_data = {
      task_id: target_task._id,
      image: task_file_paths /* include current task paths too */,
      task_title: selectedTaskTitle,
      task_description: selectedTaskDescription,
    };
    console.log(req_data, "edit-req");

    const result = await _edit_task(req_data);
    if (result.code === 200) {
      setAddTaskFiles([]);
      let temp_tasks = allFilteredTasksBySections[result.task_data.section_id];
      let new_tasks = [];
      temp_tasks.task_list.map((task) => {
        if (task._id === result.task_data._id) {
          new_tasks.push(result.task_data);
        } else {
          new_tasks.push(task);
        }
      });
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [result.task_data.section_id]: {
            ...temp_tasks,
            task_list: getModifiedTasks(new_tasks),
          },
        };
      });
      setUpdatingTask(false);
      closeTaskDetail();
      enqueueSnackbar("Task Updated Successfully", { variant: "success" });
    } else {
      setUpdatingTask(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDragEnd = async (data, workspaceId) => {
    console.log(data, "on-drag end");
    const prev_state = structuredClone(filteredSectionsData);
    const { dragResult, section } = data;
    const { draggableId, destination, source } = dragResult;
    const destination_task_index = destination.index;
    let source_task_index = source.index;
    const source_task = section.task[source_task_index];
    const destination_task = section.task[destination_task_index];
    console.log(source_task, "source_task");
    console.log(destination_task, "destination_task");
    console.log(destination_task_index, source_task_index, "dest+src index");

    const getReorderedTasks = (_tasks) => {
      let spliced_section_tasks = Array.from(_tasks);
      console.log(spliced_section_tasks, "spl");
      const [splicedItem] = spliced_section_tasks.splice(source.index, 1);
      spliced_section_tasks.splice(destination.index, 0, splicedItem);
      return spliced_section_tasks;
    };

    if (String(source_task._id) === String(destination_task._id)) {
      console.log("same dest");
      return;
    }
    if (Number(destination_task.order) === 0) {
      console.log("zero new order");
      return;
    }
    const req_data = {
      new_order: destination_task.order,
      task_id: source_task._id,
      past_order: source_task.order,
    };
    let old_state = allFilteredTasksBySections;
    let temp = allFilteredTasksBySections[section._id];
    setAllFilteredTasksBySections((prev) => {
      return {
        ...prev,
        [section._id]: {
          ...temp,
          task_list: getReorderedTasks(temp.task_list),
        },
      };
    });
    console.log(req_data, "req-reorder");
    const result = await _update_task_order(req_data);
    if (result.code === 200) {
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section._id]: {
            ...temp,
            task_list: getModifiedTasks(result.task_order),
            // task_list: getReorderedTasks(temp.task_list),
          },
        };
      });
      enqueueSnackbar("Task Order Updated", { variant: "success" });
    } else {
      // setFilteredSectionsData(prev_state);
      setAllFilteredTasksBySections(old_state);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDragStart = (data, workspaceId) => {
    console.log(data, "on-drag start");
  };
  const canUpdateTeam = () => {
    if (String(currentUserRole) === "admin") {
      return true;
    }
    if (String(currentUserRole) === "manager") {
      return true;
    }
    if (String(currentUserRole) === "team") {
      return false;
    }
    return false;
  };

  const onEditComment = (_comment) => {
    setEditorKey((prevKey) => prevKey + 1); 
    setSelectedComment(_comment);
    setEditingComment(true);
    setEditCommentTitle(_comment.comment_title);
    setEditCommentType(String(_comment.comment_type));
  };
  const onDelComment = (_comment) => {
    setSelectedComment(_comment);
    openDelCommentDialog();
  };
  const handleCancelEditComment = async (e) => {
    setEditorKey((prevKey) => prevKey + 1); 
    setEditingComment(false);
    setSelectedComment(undefined);
    setEditCommentTitle("");
    setEditCommentType("0");
  };
  const closeDelCommentDialog = () => {
    setDelCommentOpen(false);
  };
  const openDelCommentDialog = () => {
    setDelCommentOpen(true);
  };
  const handleUpdateTaskComment = async (e, type = "") => {
    console.log(selectedComment, "to-update");
    if (!selectedComment) {
      console.log("no selectedComment");
      return;
    }
    let data;
    if (type == "detail") {
      data = {
        comment_id: selectedComment._id,
        comment_title: editCommentTitle,
        comment_type: selectedComment.comment_type,
      };
    } else {
      data = {
        comment_id: selectedComment._id,
        comment_title: selectedComment.comment_title,
        comment_type: selectedComment.comment_type,
      };
    }

    console.log(data, "req-update-com");
    setProcessingEditComment(true);
    const result = await _update_task_comment(data, selectedTask?._id);
    if (result.code === 200) {
      setEditorKey((prevKey) => prevKey + 1); 
      console.log(result, "upd-com-res");
      setProcessingEditComment(false);
      enqueueSnackbar("Comment updated successfully", { variant: "success" });
      const updated_comments = (_comments) => {
        return _comments.map((item) => {
          if (String(item._id) === String(result.task_comment._id)) {
            return result.task_comment;
          }
          return item;
        });
      };
      setSelectedTaskComments((prev) => updated_comments(prev));
      setSelectedTask((prev) => {
        // if (String(selectedComment.comment_type) === "0") {
        //   return {
        //     ...prev,
        //     task_comment_count: Number(prev.task_comment_count) + 1,
        //     task_comment: updated_comments(prev.task_comment),
        //   };
        // }
        // if (String(selectedComment.comment_type) === "1") {
        //   return {
        //     ...prev,
        //     task_comment_count: Number(prev.task_comment_count) - 1,
        //     task_comment: updated_comments(prev.task_comment),
        //   };
        // }
        return { ...prev, task_comment: updated_comments(prev.task_comment) };
      });
      const updated_tasks = (_tasks) => {
        let updated = _tasks.map((item) => {
          if (String(item._id) === String(selectedTask?._id)) {
            if (String(selectedComment.comment_type) === "0") {
              return {
                ...item,
                task_comment_count: Number(item.task_comment_count) + 1,
                task_comment: updated_comments(item.task_comment),
              };
            }
            if (String(selectedComment.comment_type) === "1") {
              return {
                ...item,
                task_comment_count: Number(item.task_comment_count) - 1,
                task_comment: updated_comments(item.task_comment),
              };
            }
          }
          return item;
        });
        return updated;
      };
      let temp = allFilteredTasksBySections[selectedTask?.section_id];
      // Prevents page blank error if (task not assigned) and editing comment - shamroz
      if (temp) {
        setAllFilteredTasksBySections((prev) => {
          return {
            ...prev,
            [selectedTask?.section_id]: {
              ...temp,
              task_list: updated_tasks(temp.task_list),
            },
          };
        });
      }
      setEditingCommentId("");
      setEditingComment(false);
      setSelectedComment(undefined);
    } else {
      setProcessingEditComment(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }

    //
  };
  const handleDelTaskComment = async () => {
    console.log(selectedComment, "to-del");
    if (!selectedComment) {
      console.log("no selectedComment");
      return;
    }
    setDeletingTaskComment(true);

    const result = await _delete_task_comment(
      { comment_id: selectedComment._id },
      selectedTask?._id
    );
    if (result.code === 200) {
      console.log(result, "del-com-res");
      setDeletingTaskComment(false);
      enqueueSnackbar("Comment deleted successfully", { variant: "success" });
      const updated_comments = (_comments) => {
        return _comments.filter(
          (item) => String(item._id) !== String(selectedComment._id)
        );
      };
      setSelectedTask((prev) => {
        return { ...prev, task_comment: updated_comments(prev.task_comment) };
      });
      setSelectedTaskComments((prev) => updated_comments(prev));
      const updated_tasks = (_tasks) => {
        let updated = _tasks.map((item) => {
          if (String(item._id) === String(selectedTask?._id)) {
            return {
              ...item,
              task_comment_count:
                item.task_comment_count > 0
                  ? Number(item.task_comment_count) - 1
                  : 0,
              task_comment: updated_comments(item.task_comment),
            };
          }
          return item;
        });
        return updated;
      };
      let temp = allFilteredTasksBySections[selectedTask?.section_id];
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [selectedTask?.section_id]: {
            ...temp,
            task_list: updated_tasks(temp.task_list),
          },
        };
      });
      setSelectedComment(undefined);
      closeDelCommentDialog();
    } else {
      setDeletingTaskComment(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSendComment = async (is_selected, type , opt) => {
    if (newComment === "") {
      enqueueSnackbar("Comment cannot be empty", { variant: "warning" });
      return;
    }
    if (!workspaceId) {
      console.log("no workspaceId");
      return;
    }
    if (!selectedTask) {
      console.log("no selectedTask");
      return;
    }
    let idsArray;
    if (opt === "all") {
      idsArray = null;
    } else if (opt === "none") {
      idsArray = [];
    } else {
      idsArray = is_selected.length > 0 ? is_selected : null;
    }

    try {
      setSendingComment(true);
      const data = {
        comment_title: newComment.trim().replace(/<a /g, "<a target='_blank' "),
        comment_type: type,
        ids_array : idsArray,
      };

      console.log(data, "req_data");

      const result = await _add_task_comment(data, selectedTask?._id);
      if (result.code === 200) {
        setNewComment("");
        setEditorKey((prevKey) => prevKey + 1);
        const new_comment = result.task_comment;
        console.log(result, "add-com-res");
        const updated = (_comments) => {
          let updated = Array.from(_comments);
          updated.unshift({ ...new_comment, comment_creator: true });
          return updated;
        };
        setSelectedTaskComments((prev) => updated(prev));
        setSelectedTask((prev) => {
          let found = prev?.task_comment.findIndex(
            (item) => item._id == result.task_comment._id
          );
          if (found !== -1) {
            let temp = prev?.task_comment;
            temp.splice(found, 1);
            temp.push(result.task_comment);
            return {
              ...prev,
              task_comment: [...temp],
            };
          } else {
            return {
              ...prev,
              task_comment: [...prev?.task_comment, result.task_comment],
            };
          }
          // return { ...prev };
        });
        const updated_tasks = (_tasks) => {
          let updated = _tasks.map((item) => {
            if (String(item._id) === String(selectedTask?._id)) {
              let tempTaskComments = item.task_comment;
              // tempTaskComments.unshift({
              //   ...new_comment,
              //   comment_creator: true,
              // });
              tempTaskComments.unshift({ ...result.task_comment });
              return {
                ...item,
                task_comment: tempTaskComments,
              };
            }
            return item;
          });
          return updated;
        };
        let temp = allFilteredTasksBySections[selectedTask?.section_id];
        // Prevents page blank error if temp is undefined while adding comment - shamroz
        if (temp) {
          setAllFilteredTasksBySections((prev) => {
            return {
              ...prev,
              [selectedTask?.section_id]: {
                ...temp,
                task_list: updated_tasks(temp.task_list),
              },
            };
          });
        }
        setNewComment("");
        if (newCommentType == "0") {
          // let receiver = [selectedTask?.creator_profile.user_id];
          // selectedTask?.team.map((member) => {
          //   receiver.push(member.user_id);
          // });
          // receiver = receiver.filter(
          //   (id) => id !== dispatch_get_user_profile().user_id
          // );
          const { project, section_id, _id, workspace } = selectedTask;
          socketEmit("send_notification_to_all", {
            receiver: result.task_comment.allow_members,
            // data: result.Project,
            data: {
              task_id: _id,
              project_id: project.id,
              workspace_id: workspace.id,
            },
            message: `${dispatch_get_user_profile().first_name} ${
              dispatch_get_user_profile().last_name
            } added a comment in task`,
            link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
          });
        }
        setEditingCommentId("");
        enqueueSnackbar("Comment added successfully", { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while adding comment");
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    } finally {
      setSendingComment(false);
    }
  };
  const getTaskComments = async (task_id) => {
    setLoadingComments(true);
    const result = await _task_comment_list(task_id);
    console.log(result, "task-comm-res");
    if (result.code === 200) {
      setLoadingComments(false);
      setSelectedTaskComments(result.project_list.comment_list);
      setSelectedTaskCommentsData({
        total: result.project_list.total_comments,
        load_more_url: result.project_list.load_more_url,
      });
    } else {
      setLoadingComments(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleApplyFilter = async (e) => {
    closeFilters(true);
    getFilteredSections(null, null, true);
  };

  const getFiltersCount = () => {
    let count = 0;
    const date = filterOptions.date;
    const status = filterOptions.status;
    const priority = filterOptions.priority;
    const members = filterOptions.search_users;
    // Object.values(date).map((val) => {
    //   if (val) count += 1;
    // });
    Object.values(date ?? {}).map((val) => {
      if (val && val !== "createdAt") {
        count += 1;
      }
    });
    Object.values(status).map((val) => {
      if (val === true) {
        count += 1;
      }
    });
    Object.values(priority).map((val) => {
      if (val === true) {
        count += 1;
      }
    });
    count += members ? members.length : 0;
    return count;
  };

  const handleClearSelectedFilters = (filterType) => {
    setFilterOptions((previousFilters) => ({
      ...previousFilters,
      [filterType]: [],
    }));
    // Clear date
    if (filterType === "date") {
      setTaskFilterOptions((prevOptions) => ({
        ...prevOptions,
        date: {
          date_type: "createdAt",
          start_date: "",
          end_date: "",
        },
      }));
    }
    // Clear status and priority
    if (filterType === "status" || filterType === "priority") {
      setTaskFilterOptions((prevOptions) => ({
        ...prevOptions,
        status: filterType === "status" ? [] : prevOptions.status,
        priority: filterType === "priority" ? [] : prevOptions.priority,
        sort_status: "",
        search_users: [],
      }));
    }
  };

  const handleClearFilter = async (e) => {
    setTaskFilterOptions({
      date: {
        date_type: "createdAt",
        start_date: "",
        end_date: "",
      },
      status: [],
      priority: [],
      sort_status: "",
      search_users: [],
      type: 0,
    });
    let explicit_req_data = {
      date: {
        date_type: "createdAt",
        start_date: "",
        end_date: "",
      },
      task_status: [],
      priority: [],
      sort_status: "",
      workspace_id: workspaceId,
      list_type: taskFilterOptions.type,
    };
    setFilterOptions({
      date: {
        date_type: "createdAt",
        start_date: "",
        end_date: "",
      },
      priority: {
        critical: false,
        high: false,
        medium: false,
        low: false,
      },
      status: {
        pending: false,
        inProgress: false,
        completed: false,
        cancelled: false,
      },
      search_users: [],
    });
    closeFilters(true);
    getFilteredSections(undefined, explicit_req_data, true);
  };

  const handleNavToVerifiedList = () => {
    navigate(`/workspaces/${workspaceId}/${slug}`);
  };

  const handleScrollToTask = () => {
    if (Object.keys(allFilteredTasksBySections).length === 0) {
      return;
    }
    if (scrollTaskOnce.current === true) {
      console.log("already expanded");
      return;
    }
    scrollTaskOnce.current = true;
    const task_id = state?.scroll_task_data?.task_id;
    const section_id = state?.scroll_task_data?.section_id;
    if (!task_id || !section_id) {
      return;
    }
    let temp = allFilteredTasksBySections[section_id];
    setAllFilteredTasksBySections((prev) => {
      return {
        ...prev,
        [section_id]: {
          ...temp,
          task_list: trueExpandTargetSection(prev.section_list, section_id),
        },
      };
    });
    setTimeout(() => {
      const section_el = document.getElementById(section_id);
      if (section_el) {
        section_el.scrollIntoView({ block: "center", behavior: "auto" });
      }
      const task_el = document.getElementById(task_id);
      task_el.scrollIntoView({ block: "center", behavior: "auto" });
      task_el.classList.toggle("highlight-task");
      setTimeout(() => {
        task_el.classList.toggle("highlight-task");
      }, 3000);
    }, 2000);
  };
  const getVariant = (task) => {
    if (role === "admin") {
      return "default";
    }
    if (role === "manager") {
      return "default";
    }
    if (task.creator_profile.user_id === dispatch_get_user_profile().user_id) {
      return "default";
    }
    if (task.user_task_status === true) {
      return "default";
    }
    return "disabled";
  };
  const disableComment = () => {
    if (!selectedTask) {
      return true;
    }
    if (currentUserRole === "admin") {
      return false;
    }
    if (currentUserRole === "manager") {
      return false;
    }
    if (
      selectedTask?.creator_profile?.user_id ===
      dispatch_get_user_profile().user_id
    ) {
      return false;
    }
    if (selectedTask?.user_task_status === true) {
      return false;
    }
    return true;
  };

  //====================Copy task to another workspace======================
  const getWorkspaces = async () => {
    setLoadingCopyTask(true);
    try {
      const result = await _workspace_list_for_task_shift(workspaceId);
      if (result.code == 200) {
        setAutoCompleteOptions({
          projects: [],
          workspaces: result.workspace.workspace_list.map((workspace) => {
            return { ...workspace, label: workspace.name, value: workspace };
          }),
          sections: [],
        });
        setLoadingCopyTask(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setLoadingCopyTask(false);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching workspace list to copy task");
      setLoadingCopyTask(false);
    }
  };

  const getProjects = async () => {
    setLoadingCopyTaskWorkspace(true);
    try {
      const result = await _project_list_for_task_shift(
        autoCompleteValues.workspace._id
      );
      if (result.code === 200) {
        console.log(projectDetail);
        setAutoCompleteOptions((prev) => {
          return {
            ...prev,
            projects: result.project_list.project_list.map((project) => {
              return { ...project, label: project.title, value: project };
            }),
            sections: [],
          };
        });
        setLoadingCopyTaskWorkspace(false);

        // if (result.code == 200) {
        //   console.log(projectDetail);
        //   setAutoCompleteOptions((prev) => {
        //     return {
        //       ...prev,
        //       projects: result.project_list.project_list
        //         .filter((project) => project._id !== projectDetail._id)
        //         .map((project) => {
        //           return { ...project, label: project.title, value: project };
        //         }),
        //       sections: [],
        //     };
        //   });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setLoadingCopyTaskWorkspace(false);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching project list to copy task");
      setLoadingCopyTaskWorkspace(false);
    }
  };

  const getSections = async () => {
    setLoadingCopyTaskProject(true);
    try {
      const result = await _project_sections_filter(
        {
          list_type: 0,
          priority: [],
          sort_status: "",
          task_status: [],
          workspace_id: autoCompleteValues.workspace._id,
        },
        autoCompleteValues.project._id,
        0,
        200
      );
      if (result.code == 200) {
        setAutoCompleteOptions((prev) => {
          return {
            ...prev,
            sections: result.section_list.section_lists.map((section) => {
              return {
                ...section,
                label: section.section_title,
                value: section,
              };
            }),
          };
        });
        setLoadingCopyTaskProject(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setLoadingCopyTaskProject(false);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching sections list to copy task");
      setLoadingCopyTaskProject(false);
    }
  };

  const handleMoveTaskToOtherWorkspace = async () => {
    try {
      setMovingTaskLoading(true);
      const data = {
        task_id: currentTaskToMove._id,
        project_id: autoCompleteValues.project._id,
        section_id: autoCompleteValues.section._id,
        link_task: syncData,
      };

      const result = await _shift_task_to_other_workspace(data);

      enqueueSnackbar(result.message, {
        variant: result.code == 200 ? "success" : "error",
      });

      if (result.code == 200) {
        if (result?.task_detail?.project?.id == slug) {
          let sectionId = autoCompleteValues.section._id;
          let temp = allFilteredTasksBySections[sectionId];
          setAllFilteredTasksBySections((prev) => {
            return {
              ...prev,
              [sectionId]: {
                ...temp,
                total: temp.total + 1,
                task_list: [...temp?.task_list, result?.task_detail],
                completed_task_count:
                  result?.task_detail.task_status == 3
                    ? temp.completed_task_count - 1
                    : temp.completed_task_count,
              },
            };
          });
        }
        setMoveToWorkspaceDrawerOpen(false);
        setCurrentTaskToMove();
        setAutoCompleteValues({
          project: null,
          section: null,
          workspace: null,
        });
        setAutoCompleteOptions({
          projects: [],
          sections: [],
          workspaces: [],
        });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while moving task to other workspace");
    } finally {
      setMovingTaskLoading(false);
    }
  };
  useEffect(() => {
    if (currentTaskToMove) {
      getWorkspaces();
    }
  }, [currentTaskToMove]);

  useEffect(() => {
    if (autoCompleteValues.workspace) {
      getProjects();
    }
  }, [autoCompleteValues.workspace]);

  useEffect(() => {
    if (autoCompleteValues.project) {
      getSections();
    }
  }, [autoCompleteValues.project]);

  // ---------------------------------------------------------

  useLayoutEffect(() => {
    let filters = get_from_localStorage("sidebarFilters");
    if (filters) {
      setFilterOptions(filters);
    }
  }, []);
  useLayoutEffect(() => {
    setTimeout(() => {
      set_to_localStorage("sidebarFilters", filterOptions);
    }, 0);
  });
  useLayoutEffect(() => {
    let filters = get_from_localStorage("taskFilterOptions");
    if (filters) {
      setTaskFilterOptions(filters);
      setFilterOptions(filters);
      let index = undefined;
      TASK_SORT_OPTIONS.map((item, _index) => {
        if (String(item.value) === String(filters.sort_status)) {
          index = _index;
        }
      });
      if (index !== undefined) {
        setTaskSortOptionIndex(index);
      }
    }
  }, []);
  useLayoutEffect(() => {
    setTimeout(() => {
      set_to_localStorage("taskFilterOptions", taskFilterOptions);
    }, 0);
  });
  // ---------------------------------------------------------
  useLayoutEffect(() => {
    /* data fetching on load */
    if (state?.project_detail) {
      startTransition(() => {
        setWorkspaceId(state?.project_detail.workspace.id);
        setProjectDetail({
          ...state?.project_detail,
          workspace: state?.project_detail.workspace.id,
          workspace_name: state?.project_detail.workspace.name,
        });
        setProjectTags(state?.project_detail.tag_colors);
        setCurrentUserRole(state?.project_detail.role);
        setLoadingDetail(false);
      });
      setTimeout(() => {
        getProjectDetail();
      }, 0);
    } else {
      getProjectDetail();
    }
    dispatch_set_search_visibility(true);
    return () => {
      dispatch_set_search_visibility(false);
    };
  }, []);
  useLayoutEffect(() => {
    /* data fetching on change state */
    if (workspaceId) {
      if (state?.project_detail) {
        let modified = state?.project_detail.team.map((user) => {
          return {
            ...user,
            query_field: user.first_name + " " + user.last_name,
            creator: user.user_id === dispatch_get_user_profile().user_id,
          };
        });
        console.log(modified, "mft");
        setProjectTeam(modified);
      } else {
        getProjectTeam();
      }
      getFilteredSections();
    }
    /* getFilteredSections on Filter button */
  }, [workspaceId]);

  useLayoutEffect(() => {
    if (state?.task_id) {
      // setTaskDetailLoading(true);
      setSelectedTask({ _id: state.task_id });
      setTaskDetailOpen(true);
    }
  }, []);

  useLayoutEffect(() => {
    if (filteredSectionsData.section_lists.length > 0) {
      filteredSectionsData.section_lists.map((section) => {
        if (section.expanded) {
          getFilteredTasksBySection(section._id);
        }
      });
    }
  }, [filteredSectionsData]);
  useEffect(() => {
    getProgress();
  }, [
    projectDetail.completed_task,
    projectDetail.total_task,
    projectDetail.pending_task,
    projectDetail,
  ]);
  /* persistence */
  const getExpandAllState = () => {
    let states = get_from_localStorage("expand-all-states");
    if (states) {
      let [found] = states.filter(
        (item) => String(item.project_id) === String(slug)
      );
      if (found) {
        console.log(found, "fah");
        setSettingOptions((prev) => {
          return { ...prev, expandAllSections: found.expandAll };
        });
      }
    }
  };
  const setExpandAllState = (value) => {
    let states = get_from_localStorage("expand-all-states");
    if (states) {
      let [found] = states.filter(
        (item) => String(item.project_id) === String(slug)
      );
      if (found) {
        let updated = states.map((item) => {
          if (String(item.project_id) === String(found.project_id)) {
            return { ...item, expandAll: value };
          }
          return item;
        });
        set_to_localStorage("expand-all-states", updated);
      }
      if (!found) {
        let updated = [...states, { project_id: slug, expandAll: value }];
        set_to_localStorage("expand-all-states", updated);
      }
    }
    if (!states) {
      let updated = [{ project_id: slug, expandAll: value }];
      set_to_localStorage("expand-all-states", updated);
    }
  };
  const isDragAllowed = () => {
    let filter_on = false;
    Object.keys(filterOptions.priority).map((key) => {
      let val = filterOptions.priority[key];
      if (val === true) {
        filter_on = true;
      }
    });
    Object.keys(filterOptions.status).map((key) => {
      let val = filterOptions.status[key];
      if (val === true) {
        filter_on = true;
      }
    });
    if (filter_on === true) {
      return false;
    }

    if (!currentUserRole) {
      return false;
    }
    if (currentUserRole === "admin") {
      return true;
    }
    if (currentUserRole === "manager") {
      return true;
    }
    if (
      currentUserRole === "team" ||
      currentUserRole === "client" ||
      currentUserRole === "client_team"
    ) {
      if (!projectTeam) {
        return false;
      }
      if (!user) {
        return false;
      }
      const found = projectDetail.team.find(
        (item) => String(item.user_id) === String(user.user_id)
      );
      console.log(found, "found-user");
      if (found?.allow_drag_drop === true) {
        return true;
      }
      return false;
    }

    return false;
  };
  useEffect(() => {
    set_to_localStorage("workspaceData", workspaceData);
    return () => {
      del_from_local_storage("workspaceData");
    };
  }, [workspaceData]);

  /* <<--------------------------------------------------------->> */
  useLayoutEffect(() => {
    /*get persisted data here */
    getExpandAllState();
  }, []);
  // useEffect(() => {
  //   const element = document.getElementsByClassName("task-section-parent")[0];
  //   if (!element) {
  //     setLoadingTasks(true);
  //   } else {
  //     setLoadingTasks(false);
  //   }
  //   console.log(element, "ascascjaslckjasklcjas");
  // });
  useLayoutEffect(() => {
    /*persist data here */
    if (loadingTasks) {
      return;
    }
    let sections = filteredSectionsData.section_lists.map((section) => {
      return { _id: section._id, expanded: section.expanded };
    });
    set_to_localStorage("sections-expand-states", sections);
  });

  // ---------------------------------------------------------
  if (loadingDetail || loadingTasks) {
    return (
      <Page title="Workspace Project">
        <Container maxWidth="xl">
          <Grid container className="project-header">
            <Grid item display="flex" alignItems="center">
              <IconButton onClick={handleNavBack} sx={{ mr: "6px" }}>
                <Iconify icon="ep:back" />
              </IconButton>
              {loadingDetail ? (
                <Skeleton sx={{ width: "8rem" }} variant="text" />
              ) : (
                <div className="d-flex">
                  <Typography
                    sx={{
                      color: "#00AB55",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/workspaces")}
                  >
                    Workspaces
                  </Typography>
                  <Iconify icon="grommet-icons:next" sx={{ m: 1 }} />
                  <Typography
                    sx={{
                      color: "#00AB55",
                      cursor: "pointer",
                    }}
                    onClick={() => handleNavToProjects()}
                  >
                    {projectDetail.workspace_name}
                  </Typography>
                  <Iconify icon="grommet-icons:next" sx={{ m: 1 }} />
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => setOpenProjectDetails(true)}
                  >
                    {capitalCase(projectDetail.title)}
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
          <CircularLoader />
        </Container>
      </Page>
    );
  }

  return (
    <Page title="Workspace Project">
      <Container maxWidth="xl" sx={{ pb: 5 }} ref={pageRef}>
        <Grid container className="project-header mb-3">
          <Grid
            xs={12}
            sm={6.5}
            sx={{ pr: 2 }}
            item
            display="flex"
            alignItems="center"
          >
            <IconButton onClick={handleNavBack} sx={{ mr: "6px" }}>
              <Iconify icon="ep:back" />
            </IconButton>
            {loadingDetail ? (
              <Skeleton sx={{ width: "8rem" }} variant="text" />
            ) : (
              <div className="d-flex align-items-center">
                <Typography
                  sx={{
                    color: "#00AB55",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/workspaces")}
                >
                  Workspaces
                </Typography>
                <Iconify icon="grommet-icons:next" sx={{ m: 1 }} />
                <Typography
                  sx={{
                    color: "#00AB55",
                    cursor: "pointer",
                  }}
                  onClick={() => handleNavToProjects()}
                >
                  {projectDetail.workspace_name}
                </Typography>
                <Iconify icon="grommet-icons:next" sx={{ m: 1 }} />
                <Typography
                  sx={{ cursor: "pointer" }}
                  onClick={() => setOpenProjectDetails(true)}
                >
                  {capitalCase(projectDetail.title)}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid
            xs
            item
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <ButtonSelect
                options={TASK_SORT_OPTIONS}
                title="Sort by"
                onFilterOptionClick={handleTaskSortClick}
                selectedIndex={taskSortOptionIndex}
              />
              <Tooltip title="Section Settings">
                <IconButton
                  onClick={openSettingsAndStats}
                  sx={{ bgcolor: (theme) => theme.palette.action.hover }}
                >
                  <Iconify icon="fluent:text-box-settings-24-regular" />
                </IconButton>
              </Tooltip>
              <Tooltip onClick={openFilters} title="Filters">
                <Badge
                  color="primary"
                  overlap="circular"
                  badgeContent={getFiltersCount()}
                  variant="standard"
                  sx={{
                    "& .MuiBadge-badge": {
                      top: "9px",
                      right: "7px",
                    },
                  }}
                >
                  <IconButton
                    sx={{ bgcolor: (theme) => theme.palette.action.hover }}
                  >
                    <Iconify icon="fluent:filter-28-filled" />
                  </IconButton>
                </Badge>
              </Tooltip>
              <div onClick={() => setProgressDrawerOpen(true)}>
                <Progress value={progress} />
              </div>
              <div className="pointer">
                <Tooltip title="Project team">
                  <Avatar
                    onClick={openTeam}
                    sx={{ height: "2rem", width: "2rem" }}
                    src={avatarTeam}
                  />
                </Tooltip>
              </div>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={matches_mobile ? 1 : 0}
          columnSpacing={1}
          className="mb-3"
        >
          <Grid item xs={12} sm={7}>
            {taskField === false ? (
              <div
                style={{
                  display: "flex",
                  border: "1px dashed black",
                  width: "100%",
                  padding: 15,
                  borderRadius: 10,
                  alignItems: "center",
                }}
                role="button"
                tabIndex="0"
                onClick={() => showAddTask()}
                onKeyDown={() => showAddTask()}
              >
                <Iconify
                  icon="akar-icons:plus"
                  sx={{ color: "green", height: "1rem", width: "1rem" }}
                />
                <Typography sx={{ ml: 1 }}>Create Task</Typography>
              </div>
            ) : (
              <CreateTaskInput
                createTask={createTask}
                addingTask={addingTask}
                setTaskField={setTaskField}
              />
            )}
          </Grid>
          <Grid item xs={8} sm={3} sx={{ display: "flex" }}>
            <SectionSelect
              options={filteredSectionsData.section_lists}
              title="Section"
              selectedIndex={selectedSectionIndex}
              onFilterOptionClick={handleSectionItemClick}
            />
          </Grid>
          {/* Add Section */}
          <Grid item xs={4} sm={2}>
            <AddSection
              projectDetail={projectDetail}
              slug={slug}
              workspaceId={workspaceId}
              getUpdatedSectionList={getUpdatedSectionList}
              setAllFilteredTasksBySections={setAllFilteredTasksBySections}
              setFilteredSectionsData={setFilteredSectionsData}
              setProjectDetail={setProjectDetail}
            />
          </Grid>
        </Grid>
        <div className="mt-4">
          {loadingTasks && (
            <Stack direction="column" spacing={2}>
              {getSkeletonCount().map((item, index) => (
                <Skeleton
                  key={index}
                  sx={{ width: "100%", height: "3rem" }}
                  variant="text"
                />
              ))}
            </Stack>
          )}
          <InfiniteScroll
            dataLength={memoFilteredSections.length}
            next={getFilteredSections}
            hasMore={
              memoFilteredSections.length < filteredSectionsData.total
                ? true
                : false
            }
            loader={
              <CircularProgress
                sx={{ position: "absolute", left: "50%", padding: "10px" }}
              />
            }
          >
            <Stack spacing={2}>
              {memoFilteredSections.map((item, index) => {
                return (
                  <TaskSection
                    getFilteredSections={getFilteredSections}
                    workspaceId={workspaceId}
                    handleLoadMoreTasks={handleLoadMoreTasks}
                    moreTasksLoading={moreTasksLoading}
                    key={index}
                    section={{ ...item }}
                    onExpand={ucbOnExpand}
                    projectDetail={memoProjectDetail}
                    projectSections={memoFilteredSections}
                    projectTeam={memoProjectTeam}
                    onSetSelectedTask={ucbOnSetSelectedTask}
                    onOpenTaskDetail={ucbOpenTaskDetail}
                    onOpenComments={ucbOpenComments}
                    onChangePriority={ucbOnChangePriority}
                    onChangeStatus={ucbOnChangeStatus}
                    onChangeDate={ucbOnChangeDate}
                    onChangeTags={ucbOnChangeTags}
                    onChangeMembers={ucbOnChangeMembers}
                    loadingMember={loadingMember}
                    onMovetoWorkspace={ucbOnMovetoWorkspace}
                    onDelTask={ucbOnDelTask}
                    onMakeSectionDefault={usbOnMakeSectionDefault}
                    onDelSection={ucbOnDelSection}
                    onEditSection={ucbOnEditSection}
                    onMoveSection={ucbOnMoveSection}
                    onMoveTask={ucbOnMoveTask}
                    onDragEnd={ucbOnDragEnd}
                    onDragStart={ucbOnDragStart}
                    allowDrag={isDragAllowed()}
                    role={currentUserRole}
                    projectTags={projectTags}
                    setProjectTags={setProjectTags}
                    sections={filteredSectionsData}
                    getFilteredTasksBySection={getFilteredTasksBySection}
                    handleUpdateSection={handleUpdateSection}
                    setSelectedSection={setSelectedSection}
                  />
                );
              })}
            </Stack>
          </InfiniteScroll>
        </div>
      </Container>

      {/* sidebars */}
      {/* {currentTask() && ( */}
      <TaskDetailDialog
        open={taskDetailOpen}
        task={selectedTask}
        setTask={setSelectedTask}
        onClose={closeTaskDetail}
        projectTeam={projectTeam}
        taskFiles={getTaskImages()}
        projectTags={projectTags}
        setProjectTags={setProjectTags}
        onChangeTags={ucbOnChangeTags}
        getFileIconType={getFileIconType}
        onChangePriority={ucbOnChangePriority}
        onChangeStatus={ucbOnChangeStatus}
        onChangeMembers={ucbOnChangeMembers}
        loadingMember={loadingMember}
        handleSendComment={handleSendComment}
        handleUpdateTaskComment={handleUpdateTaskComment}
        newComment={newComment}
        setNewComment={setNewComment}
        onDelComment={onDelComment}
        editCommentTitle={editCommentTitle}
        setEditCommentTitle={setEditCommentTitle}
        onEditComment={onEditComment}
        editingCommentId={editingCommentId}
        setEditingCommentId={setEditingCommentId}
        setTasks={ucbOnUpdatetask}
        getFilteredTasksBySection={getFilteredTasksBySection}
        onChangeDate={ucbOnChangeDate}
        addCommentLoading={sendingComment}
        sections={filteredSectionsData}
        canMoveTask={true}
        onMovetoWorkspace={ucbOnMovetoWorkspace}
        taskDetailLoading={taskDetailLoading}
        setTaskDetailLoading={setTaskDetailLoading}
        onDelTask={ucbOnDelTask}
        getFilteredSections={getFilteredSections}
      />
      {/* )} */}
      {/* <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 540, md: 400 } }}
        isOpen={taskDetailOpen}
        onClose={closeTaskDetail}
        title="Task Detail"
        bottomComponent={
          <div className="centered-row pb-1">
            <LoadingButton
              onClick={handleEditTask}
              className="active-border"
              variant="outlined"
              loading={updatingTask}
              disabled={isDetailDisabled()}
            >
              Update
            </LoadingButton>
          </div>
        }
      >
        {currentTask() && (
          <>
            {isDetailDisabled() ? (
              <Stack spacing={2}>
                <Stack spacing={0.1}>
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.disabled }}
                    fontWeight="bold"
                  >
                    Created at :
                  </Typography>
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.disabled }}
                  >
                    {get_zone_date(
                      currentTask().createdAt,
                      "DD-MMM-YYYY hh:mm a"
                    )}
                  </Typography>
                </Stack>
                <TextField
                  value={selectedTaskTitle}
                  disabled
                  onChange={(e) => {}}
                  label="Title"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                />
                <Stack direction={"row"} spacing={1} flexWrap={"wrap"}>
                  {currentTask().tag_colors.map((tag) => (
                    <Tooltip title={tag.name ? tag.name : ""} key={tag._id}>
                      <div
                        style={{
                          backgroundColor: tag.color_code,
                          borderRadius: "5px",
                          minWidth: "50px",
                          maxWidth: "100px",
                          maxHeight: tagExpand ? "20px" : "5px",
                          minHeight: "5px",
                          fontSize: "10px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          color: isColorLight(tag.color_code) ? "#000" : "#fff",
                          transition: "max-height 0.3s ease",
                          cursor: "pointer",
                        }}
                        className="px-1"
                        onClick={() => setTagExpand((prev) => !prev)}
                      >
                        {tagExpand && tag.name}
                      </div>
                    </Tooltip>
                  ))}
                </Stack>
                <Typography>Description:</Typography>
                {selectedTaskDescription && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedTaskDescription,
                    }}
                  />
                )}
                {!selectedTaskDescription && (
                  <Typography>No description added!</Typography>
                )}

                {getTaskImages().length === 0 &&
                  getLocalImages().length === 0 && (
                    <span className="not-task-files">
                      <Typography
                        sx={{ color: (theme) => theme.palette.text.disabled }}
                      >
                        No task files.
                      </Typography>
                    </span>
                  )}
                <Scrollbar
                  sx={{
                    height: "15rem",
                    "& .simplebar-content": {
                      height: "15rem",
                      width: 1,
                    },
                  }}
                >
                  <Stack
                    sx={{ minWidth: "max-content", width: "100%" }}
                    direction="row"
                    spacing={1}
                  >
                    {getTaskImages().map((item, index) => {
                      return (
                        <div className="task-files-container" key={index}>
                          {item.type === "file" ? (
                            <a
                              href={s3baseUrl + item.db_path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Iconify
                                sx={{
                                  height: "10rem",
                                  width: "10rem",
                                  color: item.color,
                                }}
                                icon={item.path}
                              />
                            </a>
                          ) : (
                            <a
                              href={s3baseUrl + item.path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="add-task-img"
                                width="100%"
                                height="100%"
                                src={s3baseUrl + item.path}
                              />
                            </a>
                          )}
                        </div>
                      );
                    })}
                    {getLocalImages().map((src, index) => {
                      return (
                        <div className="task-files-container" key={index}>
                          <img
                            className="add-task-img"
                            width="100%"
                            height="100%"
                            src={src}
                          />
                        </div>
                      );
                    })}
                  </Stack>
                </Scrollbar>
              </Stack>
            ) : (
              <Stack spacing={2}>
                <Stack spacing={0.1}>
                  <Typography fontWeight="bold">Created at :</Typography>
                  <Typography>
                    {get_zone_date(
                      currentTask().createdAt,
                      "DD-MMM-YYYY hh:mm a"
                    )}
                  </Typography>
                </Stack>
                <TextField
                  value={selectedTaskTitle}
                  onChange={(e) => setSelectedTaskTitle(e.target.value)}
                  label="Title"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                />
                <Stack direction={"row"} spacing={1} flexWrap={"wrap"}>
                  {currentTask().tag_colors.map((tag) => (
                    <Tooltip title={tag.name ? tag.name : ""} key={tag._id}>
                      <div
                        style={{
                          backgroundColor: tag.color_code,
                          borderRadius: "5px",
                          minWidth: "50px",
                          maxWidth: "100px",
                          maxHeight: tagExpand ? "20px" : "5px",
                          minHeight: "5px",
                          fontSize: "10px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          color: isColorLight(tag.color_code) ? "#000" : "#fff",
                          transition: "max-height 0.3s ease",
                          cursor: "pointer",
                        }}
                        className="px-1"
                        onClick={() => setTagExpand((prev) => !prev)}
                      >
                        {tagExpand && tag.name}
                      </div>
                    </Tooltip>
                  ))}
                </Stack>
                <RichEditor
                  editorRef={editorRef}
                  value={selectedTaskDescription}
                  setValue={(val) => setSelectedTaskDescription(val)}
                />
                <Grid container spacing={1} direction={"row"}>
                  {getTaskImages().map((item, index) => {
                    return (
                      <Grid
                        sx={{ position: "relative" }}
                        key={index}
                        item
                        xs={3}
                      >
                        <div className="task-files-container" key={index}>
                          {item.type === "file" ? (
                            <a
                              href={s3baseUrl + item.db_path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Iconify
                                sx={{
                                  height: "4rem",
                                  width: "4rem",
                                  color: item.color,
                                }}
                                icon={item.path}
                              />
                            </a>
                          ) : (
                            <a
                              href={s3baseUrl + item.path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="add-task-img"
                                src={s3baseUrl + item.path}
                              />
                            </a>
                          )}
                        </div>
                        <div className="del-task-file-btn">
                          <IconButton
                            disabled={updatingTask}
                            onClick={() => removeTaskFile(item.db_path)}
                          >
                            <Iconify
                              sx={{ color: "red" }}
                              icon="gridicons:cross-circle"
                            />
                          </IconButton>
                        </div>
                      </Grid>
                    );
                  })}
                  {getLocalImages().map((item, index) => {
                    return (
                      <Grid
                        sx={{ position: "relative" }}
                        key={index}
                        item
                        xs={3}
                      >
                        <div className="task-files-container" key={index}>
                          {item.type === "file" ? (
                            <Iconify
                              sx={{
                                height: "4rem",
                                width: "4rem",
                                color: item.color,
                              }}
                              icon={item.path}
                            />
                          ) : (
                            <img className="add-task-img" src={item.path} />
                          )}
                        </div>
                        <div className="del-task-file-btn">
                          <IconButton
                            disabled={updatingTask}
                            onClick={() => removeLocalImage(item, index)}
                          >
                            <Iconify
                              sx={{ color: "red" }}
                              icon="gridicons:cross-circle"
                            />
                          </IconButton>
                        </div>
                      </Grid>
                    );
                  })}
                  <Grid item xs={3}>
                    <div>
                      <label htmlFor="icon-button-file">
                        <input
                          style={{ display: "none" }}
                          accept={FILE_ACCEPT_TYPE_STRING}
                          id="icon-button-file"
                          type="file"
                          value=""
                          onChange={handleFileChange}
                          multiple
                          disabled={updatingTask}
                        />
                        <Tooltip title="upload files">
                          <Button
                            disabled={updatingTask}
                            variant="outlined"
                            aria-label="upload picture"
                            component="span"
                            sx={{ height: "4rem" }}
                          >
                            <Iconify
                              sx={{
                                color: (theme) => theme.palette.primary.main,
                                width: "2rem",
                                height: "2rem",
                              }}
                              icon="codicon:add"
                            />
                          </Button>
                        </Tooltip>
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Stack>
            )}
          </>
        )}
      </MuiDrawer> */}
      {/* comments */}
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 540, md: 450 } }}
        isOpen={commentsOpen}
        onClose={() => {
          if (!sendingComment) closeComments();
        }}
        title="Task Comments"
        bottomComponent={
          <Stack sx={{ px: 2.5, pb: "5px" }}>
            <Stack sx={{ py: 1.5 }}>
              {editingComment ? (
                <RichEditor
                  key={editorKey}
                  editorRef={editorRef}
                  // value={selectedComment?.comment_title}
                  value={editCommentTitle}
                  setValue={(val) => {
                    setSelectedComment((prev) => {
                      return {
                        ...prev,
                        comment_title: val,
                      };
                    });
                  }}
                  disabled={processingEditComment || disableComment() === true}
                />
              ) : (
                <RichEditor
                  key={editorKey}
                  editorRef={editorRef}
                  value={newComment}
                  setValue={handleNewCommentValue}
                  disabled={sendingComment || disableComment() === true}
                />
              )}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center">
                <div className="centered-row">
                  {editingComment ? (
                    <FormControl>
                      <RadioGroup
                        row
                        disabled={
                          processingEditComment || disableComment() === true
                        }
                        aria-labelledby="gender-select-radio-buttons-label"
                        name="row-radio-buttons-group"
                        value={selectedComment?.comment_type}
                        onChange={({ target }) =>
                          setSelectedComment({
                            ...selectedComment,
                            comment_type: target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Public"
                          disabled={
                            processingEditComment || disableComment() === true
                          }
                        />
                        <FormControlLabel
                          disabled={
                            processingEditComment || disableComment() === true
                          }
                          value="1"
                          control={<Radio />}
                          label="Private"
                        />
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="gender-select-radio-buttons-label"
                        name="row-radio-buttons-group"
                        value={newCommentType}
                        disabled={sendingComment || disableComment() === true}
                        onChange={({ target }) =>
                          setNewCommentType(target.value)
                        }
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Public"
                          disabled={sendingComment || disableComment() === true}
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Private"
                          disabled={sendingComment || disableComment() === true}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </div>
              </Stack>
              {editingComment ? (
                <Stack
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <LoadingButton
                    loading={false}
                    size="small"
                    variant="contained"
                    onClick={() => handleCancelEditComment()}
                    startIcon={<Iconify icon="gridicons:cross" />}
                    color="error"
                    disabled={
                      processingEditComment || disableComment() === true
                    }
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    loading={processingEditComment}
                    size="small"
                    disabled={
                      processingEditComment || disableComment() === true
                    }
                    variant="contained"
                    onClick={() => handleUpdateTaskComment()}
                    startIcon={<Iconify icon="dashicons:saved" />}
                  >
                    Update
                  </LoadingButton>
                </Stack>
              ) : (
                <LoadingButton
                  loading={sendingComment}
                  size="small"
                  variant="contained"
                  disabled={
                    newComment.trim() === "" || disableComment() === true
                  }
                  onClick={() => {
                    handleSendComment(false, newCommentType, "all");
                  }}
                  startIcon={<Iconify icon="fluent:send-24-regular" />}
                >
                  Send
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        }
        headerComponent={
          currentTask() && (
            <Box sx={{ pt: "12px" }}>
              <Typography sx={{ px: 2.5 }} noWrap fontWeight="bold">
                {currentTask().task_title}
              </Typography>
            </Box>
          )
        }
      >
        {currentTask() && (
          <>
            <Stack className="position-relative" spacing={1}>
              {selectedTaskComments.map((item, index) => {
                return (
                  <CommentCard
                    onEdit={onEditComment}
                    onDel={onDelComment}
                    key={index}
                    item={item}
                    currentUserRole={currentUserRole}
                  />
                );
              })}
              {selectedTaskComments.length !== 0 &&
                selectedTaskCommentsData.total >
                  selectedTaskComments.length && (
                  <LoadingButton
                    sx={{ width: "20%", margin: "10px auto !important" }}
                    onClick={handleLoadMoreComments}
                    loading={moreCommentsLoading}
                    loadingPosition="end"
                    endIcon={<ExpandMoreIcon />}
                    variant="outlined"
                  >
                    More
                  </LoadingButton>
                )}
              {loadingComments && (
                <span className="comment-loader-wrap">
                  <span className="comment-loader">
                    <CircularLoader size="3rem" />
                  </span>
                </span>
              )}
              {selectedTaskComments.length === 0 && (
                <span className="comment-loader-wrap">
                  <span className="comment-loader">
                    <Typography
                      color={(theme) => theme.palette.text.disabled}
                      sx={{ mt: 30 }}
                      textAlign="center"
                    >
                      No comments added
                    </Typography>
                  </span>
                </span>
              )}
            </Stack>
          </>
        )}
      </MuiDrawer>
      {/* Section Settings Drawer */}
      <SectionSettingsDrawer
        settingsAndStatsOpen={settingsAndStatsOpen}
        closeSettingsAndStats={closeSettingsAndStats}
        isPending={isPending}
        onExpandAll={onExpandAll}
        settingOptions={settingOptions}
        workspaceId={workspaceId}
        slug={slug}
        getFilteredSections={getFilteredSections}
        orderChanged={setOrderChanged}
      />

      {/* filters */}

      <FiltersDrawer
        open={filtersOpen}
        closeFilters={closeFilters}
        handleNavToVerifiedList={handleNavToVerifiedList}
        loadingTasks={loadingTasks}
        handleApplyFilter={handleApplyFilter}
        handleClearFilter={handleClearFilter}
        handleClearSelectedFilters={handleClearSelectedFilters}
        handleChangeFilter={handleChangeFilter}
        filterOptions={filterOptions}
        projectDetail={projectDetail}
      />

      {/* edit section */}
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 540, md: 300 } }}
        isOpen={editSectionOpen}
        onClose={closeEditSection}
        title="Edit Section"
        bottomComponent={
          <Stack spacing={1} sx={{ px: "20px", pb: "5px" }}>
            <LoadingButton
              loading={processing}
              onClick={handleUpdateSection}
              size="large"
              variant="outlined"
              className="outline-btn"
            >
              Update
            </LoadingButton>
          </Stack>
        }
      >
        {selectedSection && (
          <Stack spacing={3}>
            <TextField
              label="Title"
              variant="outlined"
              value={selectedSectionTitle}
              onChange={handleEditSectionName}
              multiline
              maxRows={5}
            />
            {(projectDetail.role == "admin" ||
              projectDetail.role == "manager") && (
              <>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    PopperProps={{ placement: "auto" }}
                    label="Sprint Start Date"
                    inputFormat="DD-MMM-YYYY"
                    value={
                      selectedSectionSprint.start_date_time
                        ? moment(selectedSectionSprint.start_date_time)
                        : null
                    }
                    onChange={(date) =>
                      setSelectedSectionSprint((prev) => {
                        return {
                          ...prev,
                          start_date_time: date ? date.toISOString() : "",
                          
                        };
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        name="startDate"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MMM-YYYY",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    PopperProps={{ placement: "auto" }}
                    label="Sprint End Date"
                    inputFormat="DD-MMM-YYYY"
                    value={
                      selectedSectionSprint.end_date_time
                        ? moment(selectedSectionSprint.end_date_time)
                        : null
                    }
                    onChange={(date) =>
                      setSelectedSectionSprint((prev) => {
                        return {
                          ...prev,
                          end_date_time: date ? date.toISOString() : "",
                        };
                      })
                    }
                    shouldDisableDate={(date) =>
                      selectedSectionSprint.start_date_time &&
                      date.isBefore(
                        moment(selectedSectionSprint.start_date_time),
                        "day"
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        name="startDate"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MMM-YYYY",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
            {(projectDetail.role == "admin" ||
              projectDetail.role == "manager" ||
              (projectDetail.role == "team" &&
                !selectedSection.complete_date_time)) &&
              selectedSection.start_date_time &&
              selectedSection.end_date_time &&
              selectedSection.task_count ===
                selectedSection.completed_task_count &&
              selectedSection.task_count > 0 && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    PopperProps={{ placement: "auto" }}
                    label="Sprint Complete Date"
                    inputFormat="DD-MMM-YYYY"
                    minDate={
                      selectedSectionSprint.start_date_time
                        ? moment(selectedSectionSprint.start_date_time)
                        : moment(selectedSectionSprint.createdAt)
                    }
                    value={
                      selectedSectionSprint.complete_date_time
                        ? moment(selectedSectionSprint.complete_date_time)
                        : null
                    }
                    onChange={(date) =>
                      setSelectedSectionSprint((prev) => {
                        return {
                          ...prev,
                          complete_date_time: date ? date.toISOString() : "",
                        };
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        name="startDate"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MMM-YYYY",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
          </Stack>
        )}
      </MuiDrawer>
      {/* team */}
      {/* <MuiDrawer
        // paperProps={{ width: { xs: 1, sm: 540, md: 620 } }}
        isOpen={teamOpen}
        onClose={closeTeam}
        title="Project Team"
      >
        <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
          <SearchBar
            notransition="true"
            sx={{ width: "100%", mb: 2 }}
            value={projectTeamSearch}
            onChange={(e) => setProjectTeamSearch(e.target.value)}
          />
        </Paper>
        <Stack spacing={2}>
          {getFilteredProjectTeam().map((user, index) => {
            return (
              <UserCard
                key={index}
                item={user}
                userRole={currentUserRole}
                onRemoveUser={onRemoveUser}
                onAddUser={() => {}}
              />
            );
          })}
          {getFilteredProjectTeam().length === 0 && (
            <Typography
              color={(theme) => theme.palette.text.disabled}
              sx={{ mt: 35 }}
              textAlign="center"
            >
              No project members found
            </Typography>
          )}
        </Stack>
      </MuiDrawer> */}
      {workspaceData && (
        <MuiDrawer
          paperProps={{ width: { xs: 1, sm: 540, md: 490 } }}
          onClose={() => setTeamOpen(false)}
          isOpen={teamOpen}
          title={
            <Typography sx={{ fontWeight: "700" }}>Project Team</Typography>
          }
          titleButton={
            workspaceData?.role !== "team" &&
            workspaceData?.role !== "client_team" && (
              <Button
                variant="contained"
                size="small"
                onClick={openInvitationDialog}
              >
                invite members
              </Button>
            )
          }
        >
          <Tabs
            sx={{ pt: 0 }}
            value={currentTab}
            onChange={(e, val) => {
              setProjectTeamSearch("");
              setCurrentTab(val);
            }}
          >
            <Tab
              sx={{ pt: 0, width: "33%" }}
              value={"added"}
              label="Project Team"
            />
            <Tab
              value="toAdd"
              label="Workspace Team"
              sx={{ width: "34%", pt: 0 }}
            />
            <Tab
              value="projectInvitations"
              label="Invited Team"
              sx={{ width: "33%", pt: 0 }}
            />
          </Tabs>
          {currentTab == "added" && (
            <>
              <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
                <SearchBar
                  notransition="true"
                  sx={{ width: "100%", mb: 2 }}
                  value={projectTeamSearch}
                  onChange={(e) => setProjectTeamSearch(e.target.value)}
                />
              </Paper>
              <Stack spacing={2}>
                {getFilteredProjectTeam().added.map((item, index) => (
                  <ProjectUserCard
                    onRemoveUser={onRemoveUser}
                    onAddUser={(user) => {
                      handleAddUserInProject(user);
                    }}
                    userRole={projectDetail.role}
                    key={index}
                    item={item}
                  />
                ))}
              </Stack>
              {getFilteredProjectTeam().added.length === 0 && (
                <Typography
                  color={(theme) => theme.palette.text.disabled}
                  sx={{ mt: 20 }}
                  textAlign="center"
                >
                  No Project team members found
                </Typography>
              )}
            </>
          )}
          {currentTab == "toAdd" && (
            <>
              <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
                <SearchBar
                  notransition="true"
                  sx={{ width: "100%", mb: 2 }}
                  value={projectTeamSearch}
                  onChange={(e) => setProjectTeamSearch(e.target.value)}
                />
              </Paper>
              <Stack spacing={2}>
                {getFilteredProjectTeam().toAdd.map((item, index) => (
                  <ProjectUserCard
                    onRemoveUser={onRemoveUser}
                    onAddUser={(user) => {
                      handleUpdateProjectTeam("add", user);
                    }}
                    userRole={projectDetail.role}
                    key={index}
                    item={item}
                  />
                ))}
              </Stack>
              {getFilteredProjectTeam().toAdd.length === 0 && (
                <Typography
                  color={(theme) => theme.palette.text.disabled}
                  sx={{ mt: 20 }}
                  textAlign="center"
                >
                  No team members found
                </Typography>
              )}
            </>
          )}
          {currentTab == "projectInvitations" && (
            <>
              <Paper sx={{ mb: 2 }} elevation={0} className="search-bar">
                <SearchBar
                  notransition="true"
                  sx={{ width: "100%", mb: 2 }}
                  value={projectTeamSearch}
                  onChange={(e) => setProjectTeamSearch(e.target.value)}
                />
              </Paper>
              <Stack spacing={2}>
                {getFilteredInvitations(
                  workspaceData?.invitations,
                  // .filter((inv) =>
                  //   inv.project.includes(projectDetail._id)
                  // )
                  projectTeamSearch,
                  projectDetail._id
                ).map((item, index) => (
                  <InvitationCard
                    onCancel={onCancelWorkspaceInvite}
                    onResend={onResendWorkspaceInvite}
                    key={item._id}
                    item={item}
                  />
                ))}
              </Stack>
              {getFilteredInvitations(
                workspaceData?.invitations,
                // .filter((inv) =>
                //   inv.project.includes(projectDetail._id)
                // )
                projectTeamSearch,
                projectDetail._id
              ).length === 0 && (
                <Typography
                  color={(theme) => theme.palette.text.disabled}
                  sx={{ mt: 20 }}
                  textAlign="center"
                >
                  No invitations found!
                </Typography>
              )}
            </>
          )}
        </MuiDrawer>
      )}
      {/* dialogs */}
      {invitationDialogOpen && (
        <InviteMembersDialog
          open={invitationDialogOpen}
          onClose={closeInvitationDialog}
          workspace={workspaceData}
          project={projectDetail}
          onSuccess={(inv) =>
            setWorkspaceData((prev) => {
              return { ...prev, invitations: [...prev.invitations, inv] };
            })
          }
          setWorkspace={setWorkspaceData}
        />
      )}
      <MuiDialog
        open={cancelInvOpen}
        message="Are you sure you want to cancel your invite?"
        title="Cancel invite"
        onToggle={(value) => setCancelInvOpen(value)}
        onAgree={handleCancelWorkspaceInvite}
        loading={processing}
      />
      <MuiDialog
        open={resendInvOpen}
        message="Are you sure you want to re-send this invitation?"
        title="Resend invite"
        onToggle={(value) => setResendInvOpen(value)}
        onAgree={handleResendInvitation}
        loading={resendLoading}
      />
      <MuiDialog
        open={delTaskOpen}
        onToggle={closeDelTask}
        onAgree={handleDeleteTask}
        loading={processing}
        title="Delete Task"
        message="Are you sure you want to delete this task?"
      />
      <MuiDialog
        open={delSectionOpen}
        onToggle={closeDelSection}
        onAgree={handleDelSection}
        loading={processing}
        title={`${
          delSectionWithTasks
            ? "Delete Section With Tasks"
            : "Delete Section Without Tasks"
        }`}
        message={`${
          !delSectionWithTasks
            ? "Deleting section will move its tasks to Default section. Are you sure you want to delete this section?"
            : "Section and its tasks will be deleted permanently, are you sure to delete?"
        }`}
      />
      <MuiDialog
        open={makeDefaultSection}
        onToggle={closeMakeDefaultSection}
        onAgree={handleMakeDeafultSection}
        loading={processing}
        title="Make this section default"
        message={`Are you sure you want to set "${selectedSectionTitle}" as default section`}
      />
      <MuiDialog
        open={delUserOpen}
        onToggle={closeDelUserDialog}
        onAgree={() => handleUpdateProjectTeam("remove", selectedMember)}
        loading={fullRefresh}
        title="Remove member"
        message={`Are you sure you want to remove ${
          selectedMember?.first_name + " " + selectedMember?.last_name
        } member from project?`}
        ExtraComponent={
          <>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={removeProjectUserType}
              onChange={(e) => setRemoveProjectUserType(e.target.value)}
            >
              <FormControlLabel
                value="remove"
                control={<Radio />}
                label="Delete all tasks created by this user and remove him as task member"
              />
              <FormControlLabel
                value="assign"
                control={<Radio />}
                label="Assign the tasks, this user created, to some other team member"
              />
            </RadioGroup>
            {removeProjectUserType === "assign" && (
              <MuiAutoComplete
                onChange={(val) => setAutoCompleteValue(val)}
                value={autoCompleteValue}
                label="Team Members"
                options={getProjectTeamForRemoveUser()}
              />
            )}
          </>
        }
      />
      <MuiDialog
        open={delCommentOpen}
        onToggle={closeDelCommentDialog}
        onAgree={handleDelTaskComment}
        loading={deletingTaskComment}
        title="Delete Comment"
        message={`Are you sure you want to delete this comment?`}
      />
      <MuiDrawer
        paperProps={{
          width: { xs: 1, sm: 340 },
          zIndex: 1301,
        }}
        sx={{ zIndex: 1301 }}
        title={<Typography sx={{ fontWeight: "700" }}>Copy Task</Typography>}
        isOpen={moveToWorkspaceDrawerOpen}
        onClose={() => {
          setMoveToWorkspaceDrawerOpen(false);
          setCurrentTaskToMove();
          setAutoCompleteValues({
            project: null,
            section: null,
            workspace: null,
          });
          setAutoCompleteOptions({
            projects: [],
            sections: [],
            workspaces: [],
          });
        }}
      >
        <Stack spacing={2}>
          {currentTaskToMove && (
            <MuiAutoCompleteDefault
              disablePortal
              task={currentTaskToMove}
              label="Select Workspace"
              value={autoCompleteValues.workspace}
              // value={autoCompleteValues.workspace || currentTaskToMove.workspace.name}
              options={autoCompleteOptions.workspaces}
              loading={loadingCopyTask}
              onChange={(val) =>
                setAutoCompleteValues({
                  workspace: val,
                  section: null,
                  project: null,
                })
              }
            />
          )}
          {autoCompleteValues.workspace && (
            <MuiAutoCompleteDefault
              disablePortal
              task={currentTaskToMove}
              label="Select Project"
              value={autoCompleteValues.project}
              options={autoCompleteOptions.projects}
              loading={loadingCopyTaskWorspace}
              onChange={(val) =>
                setAutoCompleteValues((prev) => {
                  return {
                    ...prev,
                    project: val,
                    section: null,
                  };
                })
              }
            />
          )}
          {autoCompleteValues.project && (
            <MuiAutoCompleteDefault
              disablePortal
              task={currentTaskToMove}
              label="Select Section"
              value={autoCompleteValues.section}
              options={autoCompleteOptions.sections}
              loading={loadingCopyTaskProject}
              onChange={(val) =>
                setAutoCompleteValues((prev) => {
                  return {
                    ...prev,
                    section: val,
                  };
                })
              }
            />
          )}
        </Stack>
        {autoCompleteValues.project &&
          autoCompleteValues.workspace &&
          autoCompleteValues.section && (
            <>
              <div className="d-flex flex-column">
                <Box mt={1} p={1}>
                  <Typography variant="body2">
                    Do you want to sync this task?
                  </Typography>
                  <RadioGroup
                    row
                    value={syncData ? "yes" : "no"}
                    onChange={(e) => setSyncData(e.target.value === "yes")}
                  >
                    <FormControlLabel
                      sx={{ marginRight: 3 }}
                      value="yes"
                      control={<Radio color="primary" size="small" />}
                      label={<Typography variant="body2">Yes</Typography>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" size="small" />}
                      label={<Typography variant="body2">No</Typography>}
                    />
                  </RadioGroup>
                </Box>

                <LoadingButton
                  // sx={{ marginTop: 1 }}
                  loading={movingTaskLoading}
                  variant="contained"
                  onClick={handleMoveTaskToOtherWorkspace}
                  fullWidth
                >
                  Copy Task
                </LoadingButton>
              </div>
            </>
          )}
      </MuiDrawer>
      <MuiDrawer
        title="Project Details"
        isOpen={openProjectDetails}
        onClose={() => setOpenProjectDetails(false)}
      >
        <div className="row mb-4">
          <div className="col-4">Project Name:</div>
          <div className="col-8">
            <Typography fontWeight={"bold"}>{projectDetail.title}</Typography>
          </div>
        </div>
        {projectDetail.description && (
          <>
            <div className="mb-2">
              <Typography fontWeight={"bold"}>Description:</Typography>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: projectDetail.description }}
            />
          </>
        )}
        {!projectDetail.description && (
          <div className="d-flex justify-content-center">
            <Typography>No Description Added!</Typography>
          </div>
        )}
      </MuiDrawer>

      {/* Project Progress --------------------- */}
      <MuiDrawer
        isOpen={progressDrawerOpen}
        onClose={() => setProgressDrawerOpen(false)}
        title={
          <Typography sx={{ fontWeight: "700" }}>Project Progress</Typography>
        }
        paperProps={{ width: { xs: 1, sm: 340 } }}
      >
        <Stack spacing={1}>
          <div className="d-flex justify-content-between align-items-center">
            <Typography>Total Sprints</Typography>
            <Typography>{projectDetail.all_sprint_count}</Typography>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <Typography>Sprints in progress</Typography>
            <Typography>{projectDetail.in_progress_sprint_count}</Typography>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <Typography>Sprints completed on time</Typography>
            <Typography>
              {projectDetail.on_time_completed_sprint_count}
            </Typography>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <Typography>Sprints completed late</Typography>
            <Typography>{projectDetail.late_sprint_count}</Typography>
          </div>
        </Stack>
      </MuiDrawer>
    </Page>
  );
};

export default WorkspaceProject;
