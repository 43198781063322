import React from "react";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
} from "@mui/material";
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  border: "1px solid #c5c5c5",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // "&.Mui-focused": { boxShadow: theme.customShadows.z8 },
  // '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
    display: "none",
  },
  "& input": {
    padding: "6px",
    marginTop: "5px"
  },
}));
const StyledChatSearch = ({ value, onChange, placeHolder, sx }) => {
  return (
    <SearchStyle
      value={value}
      onChange={onChange}
      placeholder={placeHolder}
      sx={{ ...sx }}
      startAdornment={
        <InputAdornment position="start">
          <Box
            component={Icon}
            icon={searchFill}
            sx={{ color: "text.disabled", fontSize: "16px" }}
          />
        </InputAdornment>
      }
    />
  );
};

export default StyledChatSearch;
