import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function EditChecklistTitleDialog({
  open,
  onClose,
  item,
  handleSave,
  loading
}) {
  const [newTitle, setNewTitle] = useState("");

  useEffect(() => {
    if (open) {
      setNewTitle(item?.name);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="dialog-title"
    >
      <DialogTitle>Edit Checklist Title</DialogTitle>
      <DialogContent sx={{ padding: "10px 24px" }}>
        <div className="pt-2">
          <TextField
            label="New Checklist Title"
            fullWidth
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            variant="outlined"
            size="small"
            autoFocus
          />
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton
        loading={loading}
          onClick={() => {
            handleSave({ list_id: item._id, name: newTitle });
          }}
          color="primary"
          variant="contained"
        >
          Save
        </LoadingButton>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
