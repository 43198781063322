import { get_from_localStorage, invokeApi } from "src/utils";

export const _update_task_files = async (data) => {
  const requestObj = {
    path: `api/task/update_task_images`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _task_detail = (id) => {
  const requestObj = {
    path: `api/task/task_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _start_pause_stop_time_tracking = (taskId, data) => {
  const requestObj = {
    path: "api/task/start_pause_stop_time_tracking", 
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: {
      task_id: taskId,      
      action: data.action,
      manual_time: data.manual_time,
    },
  };
  return invokeApi(requestObj);
};

export const _add_planned_time = (data) => {
  const requestObj = {
    path: "api/task/add_planned_time", 
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _time_slots_list = (id) => {
  const requestObj = {
    path: `api/time_slot/list_time_slots/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_time_slot = (data) => {
  const requestObj = {
    path: "api/time_slot/edit_time_slot", 
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_time_slot = (id) => {
  const requestObj = {
    path: `api/time_slot/delete_time_slot/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_manual_time_slot = (data) => {
  const requestObj = {
    path: "api/time_slot/add_time_slot_manually", 
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
