import {
    Box,
    Button,
    Card,
    CardContent,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    CircularProgress,
    Container,
    Grid,
    Stack,
    Tab,
    Typography,
    TextField,
    Paper,
    Tooltip,
    Menu,
    MenuchosedNote,
} from "@mui/material";
import {
    Iconify,
    MuiDialog,
} from "src/components";
import { useEffect } from "react";



const CustomDialog = ({ isOpened, heading, content, action, handleClose }) => {

    useEffect(() => {
        if (isOpened) {
            document.body.classList.add("no-scroll-notes");
        } else {
            document.body.classList.remove("no-scroll-notes");
        }
        return () => {
            document.body.classList.remove("no-scroll-notes");
        };
    }, [isOpened]);

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            PaperProps={{ className: "notes-dialog" }}
        >
            <DialogTitle className="dialog-header">
                <h5 style={{ fontSize: "1.15rem" }}>{heading}</h5>
                <IconButton className="p-0 mb-1"
                    onClick={handleClose}>
                    <Iconify
                        icon="mdi:close"
                        sx={{ width: 19, height: 19, }}
                        color="#212b36"
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent className="dialog-content">
                {content}
            </DialogContent>

            <DialogActions className="dialog-actions">
                {action}
            </DialogActions>
        </Dialog>);
}

export default CustomDialog;