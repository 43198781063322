import { get_from_localStorage, invokeApi } from "src/utils";

export const _dashboard_tasks = async (data) => {
  const requestObj = {
    path: `api/dashboard/get_task`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _all_completed_tasks = async (page, limit, data) => {
  const requestObj = {
    path: `api/dashboard/get_all_completed_task?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _create_task = async (data, id) => {
  const requestObj = {
    path: `api/task/add_task/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _create_task_old = async (data, id) => {
  const requestObj = {
    path: `api/section/add_task/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_task_priority = async (data) => {
  const requestObj = {
    path: `api/task/update_task_priority`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_task_end_date = async (data, id) => {
  const requestObj = {
    path: `api/task/update_target_date`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_task_status = async (data) => {
  const requestObj = {
    path: `api/task/update_task_status`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_task_members = async (data) => {
  const requestObj = {
    path: `api/task/update_task_member`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_task = async (id) => {
  const requestObj = {
    path: `api/task/delete_task/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _move_task_to_section = async (data) => {
  const requestObj = {
    path: `api/task/move_task_to_other_section`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _edit_task = async (data) => {
  const requestObj = {
    path: `api/task/edit_task`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_task_order = async (data) => {
  const requestObj = {
    path: `api/task/update_task_order`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_task_comment = async (data, id) => {
  const requestObj = {
    path: `api/task_comment/add_task_comment/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _task_comment_detail = async (data, id) => {
  const requestObj = {
    path: `api/task_comment/detail_task_comment/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_task_comment = async (data, id) => {
  const requestObj = {
    path: `api/task_comment/delete_task_comment/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_task_comment = async (data, id) => {
  const requestObj = {
    path: `api/task_comment/update_task_comment/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _verified_tasks = async (id, page) => {
  const requestObj = {
    path: `api/task/get_verify_task_list/${id}?page=${page}&limit=50&search=`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _all_tasks = async (page, limit, data) => {
  const requestObj = {
    path: `api/dashboard/get_all_taks?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_time_track = (data) => {
  const reqObj = {
    path: `api/task/edit_task_time`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(reqObj);
};


