import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Typography,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { _team_working_on_tasks_list } from "src/DAL/team";
import { useSnackbar } from "notistack";
import { Scrollbar } from "src/components";
import { useNavigate } from "react-router-dom";
import StartedTasksDetailCard from "src/components/task-components/StartedTasksDetailCard";
import { noTasks } from "src/assets";
import OpenTaskDetail from "src/pages/task-calendar/components/OpenTaskDetail";

export default function TeamTaskDialog({ open, onClose, selectedUser }) {
  const [userTasks, setUserTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  // ---------------------------------------------------------------
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // ------------------TEAM Working on Tasks------------------------
  const getTeamStartedTasksList = async () => {
    if (!selectedUser) return;
    setLoading(true);
    const workspaceIds = selectedUser.workspace.map(
      (workspace) => workspace._id
    );
    const req_data = {
      user_id: selectedUser.user_id,
      workspace_ids: workspaceIds,
    };
    const result = await _team_working_on_tasks_list(req_data);
    if (result.code === 200) {
      setUserTasks(result.data);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setLoading(false);
  };

  const handleNavigate = (task) => {
    const { section_id, _id } = task;
    const project_id = task?.project.id;
    navigate(
      `/workspaces/copied-task-link/${project_id}/${section_id}/${_id}`,
      { state: { task_id: _id } }
    );
  };

  useEffect(() => {
    if (open && selectedUser) {
      getTeamStartedTasksList();
    }
  }, [open, selectedUser]);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      {loading && (
        <div
          className="centered-row"
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "420px",
            padding: "16px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {/* User Tasks --------------------- */}
      {!loading && (
        <>
          <DialogTitle>
            <b>{`${selectedUser?.first_name} ${selectedUser?.last_name}`}</b>'s
            Started Tasks
          </DialogTitle>
          <DialogContent
            sx={{
              padding: "8px !important",
              minHeight: "360px",
              maxHeight: "80vh",
            }}
          >
            {userTasks.length > 0 &&
              userTasks.map((task, index) => (
                <Scrollbar sx={{ maxHeight: "80vh" }} key={index}>
                  <Stack key={index} spacing={1} sx={{ padding: "4px 16px" }}>
                    {/* <StartedTasksDetailCard
                      handleNavigate={handleNavigate}
                      task={task?.task_detail}
                      user={selectedUser}
                    /> */}
                    <OpenTaskDetail
                    task={task?.task_detail}
                    selectedUser={selectedUser}
                    isWorking={true}
                    />
                  </Stack>
                </Scrollbar>
              ))}
            {/* No Task --------------------- */}
            {userTasks.length === 0 && (
              <Box
                sx={{
                  minHeight: "310px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div
                  className="d-flex justify-content-center mb-1"
                  style={{ width: "40%" }}
                >
                  <img
                    width="100%"
                    src={noTasks}
                    alt="No Tasks"
                    style={{ margin: "auto" }}
                  />
                </div>
                <Typography
                  color={(theme) => theme.palette.text.disabled}
                  variant="body2"
                  fontWeight="bold"
                >
                  Not Found!
                </Typography>
                <Typography
                  color={(theme) => theme.palette.text.disabled}
                  variant="body2"
                >
                  There are no started tasks
                </Typography>
              </Box>
            )}
          </DialogContent>
        </>
      )}

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
