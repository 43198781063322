import { get_from_localStorage, invokeApi } from "src/utils";

export const _upload_audio = async (data) => {
  const requestObj = {
    path: `api/app_api/upload_audio`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _upload_file = async (data) => {
  const requestObj = {
    path: `api/app_api/uplaod_file_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
