import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import Iconify from "../../Iconify";
import PropTypes from "prop-types";
import { TASK_STATUS_OPTIONS } from "src/constants";
// ---------------------------------------------------------
const icon_props = {
  width: "1.3rem",
  height: "1.3rem",
};
const menu_icon_props = {
  width: "1rem",
  height: "1rem",
};
// ---------------------------------------------------------
const ActivityStatusButton = ({
  value,
  allowOpen,
  extraStatus = [],
}) => {
  const [open, setOpen] = useState(null);
  // ---------------------------------------------------------
  const handleOpen = (event) => {
    if (!allowOpen) {
      console.log("not allowed");
      return;
    }
    setOpen(event.currentTarget);
  };

  const getSelectedColor = () => {
    let [target] = TASK_STATUS_OPTIONS.concat(
      extraStatus.map((status) => {
        return {
          label: status.title,
          value: status.value,
          color: status.color_code,
          lookup: status.title,
          option_type: "status",
        };
      })
    ).filter((item) => item.value === value);
    if (target) {
      return target.color;
    }
    return "#969292";
  };
  const getTitle = () => {
    let [target] = TASK_STATUS_OPTIONS.concat(
      extraStatus.map((status) => {
        return {
          label: status.title,
          value: status.value,
          color: status.color_code,
          lookup: status.title,
          option_type: "status",
        };
      })
    ).filter((item) => item.value === value);
    if (target) {
      return "Status" + " " + target?.label;
    }
    return "Status";
  };

  // ---------------------------------------------------------
  return (
    <div>
      <Tooltip title={getTitle()}>
        <div
          className="sidebar-button d-flex align-items-center"
          style={{ minHeight: "36px" }}
          onClick={handleOpen}
        >
          {
            TASK_STATUS_OPTIONS.concat(
              extraStatus.map((status) => {
                return {
                  label: status.title,
                  value: status.value,
                  color: status.color_code,
                  lookup: status.title,
                  option_type: "status",
                };
              })
            ).find((st) => st.value == value).label
          }
          <IconButton
            sx={{
              bgcolor: (theme) => theme.palette.action.hover,
              ml: 1,
              height: "1.3rem",
              width: "1.3rem",
            }}
            size="small"
          >
            <Iconify
              sx={{ ...icon_props, color: getSelectedColor() }}
              icon="carbon:dot-mark"
            />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

export default ActivityStatusButton;
ActivityStatusButton.propTypes = {
  value: PropTypes.number.isRequired,
  allowOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
