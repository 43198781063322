import { get_from_localStorage, invokeApi } from "src/utils";

export const _notificationList = async (page) => {
  const requestObj = {
    path: `api/notification/notification_list?page=${page}&limit=50`,
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _notificationUnreadCount = async () => {
  const requestObj = {
    path: "api/notification/unread_notification_count",
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _readNotification = async (data) => {
  const requestObj = {
    path: "api/notification/mark_notification_read",
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _reset_unread_notification_count = () => {
  const requestObj = {
    path: "api/notification/mark_unread_notification_read",
    method: "GET",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
  };
  return invokeApi(requestObj);
};
// --------- Mute Notifications of Workspaces,Projects,Tasks ----------
export const _mute_workspace_project_notifications = async (data) => {
  const requestObj = {
    path: "api/notification/add_to_mute_preference",
    method: "POST",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
// --------- Un-Mute Notifications ------------------------ ----------
export const _un_mute_notifications = async (data) => {
  const requestObj = {
    path: "api/notification/remove_from_mute_preference",
    method: "DELETE",
    headers: {
      "x-sh-auth": get_from_localStorage("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
