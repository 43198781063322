import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Card,
  Typography,
  Stack,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  IconButton,
  ButtonBase,
  AvatarGroup,
  Avatar,
  CardContent,
  LinearProgress,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import { baseUri, s3baseUrl } from "src/config/config";
import { useAppContext } from "src/hooks";
import { MenuPopover, Iconify, Label, UserAvatar } from "src/components";
import { get_zone_date } from "src/utils";
import moment from "moment";

// ----------------------------------------------------------------------

const ProjectImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});
const MenuContainer = styled("div")({
  top: 0,
  right: 0,
  position: "absolute",
  zIndex: 2,
});
////======================================================================
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
// ----------------------------------------------------------------------

ProjectCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
  }),
  onClickMenuItem: PropTypes.func.isRequired,
};

export default function ProjectCard({
  item,
  menuOptions,
  onClickMenuItem,
  onClickTeam,
  onFavouriteClick,
  handleOpenDetails,
  slugDetail,
  onDeleteButtonClick,
  openDialog,
}) {
  const { completed_task, role, creator, description, end_date, image, _id } =
    item;
  const {
    pending_task,
    start_date,
    status,
    team,
    title,
    total_task,
    is_favorite,
    workspace,
  } = item;
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch_set_current_selected_item } = useAppContext();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);
  // ------------------------------------------------------
  const handleClose = (e) => {
    e?.stopPropagation();
    setOpen(null);
  };
  const showMenu = () => {
    if (role === "admin") {
      return true;
    }
    if (role === "manager") {
      return true;
    }
    return false;
  };

  const handleClickItem = (option, index) => {
    onClickMenuItem(option, index, item);
    handleClose();
  };
  const handleOpenOptions = (e, item) => {
    console.log(item);
    setOpen(e.currentTarget);
    dispatch_set_current_selected_item(item);
  };
  const handleNav = () => {
    navigate(`/workspaces/projects/${item._id}`, {
      state: {
        project_detail: item,
      },
    });
  };
  const getProgress = () => {
    let progress = (Number(completed_task) / Number(total_task)) * 100;
    let percentage = `${Math.round(progress)}`;
    if (Number(completed_task) === 0) {
      return "0%";
    }
    return `${percentage}%`;
  };

  const getDateColor = (date) => {
    const diff = moment(date).diff(moment(), "days");
    if (diff > 2) {
      return "default";
    } else if (diff < 2 && diff > 0) {
      return "warning";
    } else if (diff <= 0) {
      return "error";
    }
    return "default";
  };
  const handleTeamClick = (e) => {
    e.stopPropagation();
    console.log(item);
    dispatch_set_current_selected_item({
      project: item,
    });
    onClickTeam(item);
  };
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(true);
  };

  const handleLeave = () => {
    setHovered(false);
  };
  // ------------------------------------------------------
  return (
    <>
      <Card
        sx={{ height: "100%" }}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        {!status && (
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 4,
            }}
          >
            <div className="d-flex align-items-center justify-content-center gap-2 h-100">
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() =>
                  navigate(`/workspaces/edit-project/${_id}`, {
                    state: {
                      workspace_data: slugDetail,
                      project: item,
                    },
                  })
                }
              >
                Activate
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={onDeleteButtonClick}
              >
                Delete
              </Button>
            </div>
          </div>
        )}
        <MenuContainer id="menu-container" sx={{ width: "100%" }}>
          <div className="d-flex justify-content-between">
            <Tooltip
              title={is_favorite ? "Remove from favorites" : "Add to favorites"}
            >
              <IconButton
                style={{
                  float: "left",
                  marginLeft: hovered || is_favorite ? "0" : "-100px",
                  marginRight: "auto",
                  transition: "0.3s",
                }}
                // sx={{ bgcolor: "rgba(255, 255, 255, 0.438)" }}
                onClick={() => onFavouriteClick(item)}
              >
                <Iconify
                  sx={{ color: "#dbb90f" }}
                  icon={is_favorite ? "ion:star" : "ion:star-outline"}
                />
              </IconButton>
            </Tooltip>
            {/* {showMenu() && ( */}
            <IconButton
              sx={{ bgcolor: "rgba(255, 255, 255, 0.438)" }}
              ref={anchorRef}
              onClick={(e) => handleOpenOptions(e, item)}
            >
              <Iconify
                sx={{ color: "black" }}
                // icon='mi:options-horizontal'
                icon="fluent:more-vertical-16-filled"
              />
            </IconButton>
            {/* )} */}
          </div>
          <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            sx={{
              p: 0.5,
              mt: 1.5,
              ml: 0.75,
              width: "7rem",
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.75,
              },
            }}
          >
            {menuOptions.map((option, index) => {
              return (
                <MenuItem
                  onClick={() => handleClickItem(option, index)}
                  key={index}
                  sx={{ width: "6.5rem" }}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </MenuPopover>
        </MenuContainer>

        <Box sx={{ pt: "100%", position: "relative" }}>
          {image ? (
            <ProjectImgStyle
              alt={"name"}
              style={{ cursor: "pointer" }}
              src={s3baseUrl + image}
              className="image-hov"
              onClick={handleNav}
            />
          ) : (
            <ProjectImgStyle
              alt={"name"}
              style={{ cursor: "pointer" }}
              src="https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg"
              className="image-hov"
              onClick={handleNav}
            />
          )}
        </Box>

        <Stack spacing={1} sx={{ px: 2, pt: 1, pb: 1 }} onClick={handleNav}>
          <Typography variant="body1" fontWeight="bold" noWrap>
            {title}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="start"
          >
            <Typography
              variant="body2"
              // noWrap
              fontWeight="bold"
              // onClick={() => null}
              component="span"
            >
              End Date
            </Typography>
            <Typography
              variant="body2"
              noWrap
              // onClick={() => null}
              component="span"
            >
              {end_date ? get_zone_date(end_date, "DD MMM YYYY") : "N/A"}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="start"
          >
            <Typography
              variant="body2"
              // noWrap
              fontWeight="bold"
              onClick={() => null}
              component="span"
            >
              Tasks Completed
            </Typography>
            <Typography
              variant="body2"
              noWrap
              onClick={() => null}
              component="span"
            >
              {completed_task}/{total_task}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="start"
          >
            <Typography
              variant="body2"
              // noWrap
              fontWeight="bold"
              onClick={() => null}
              component="span"
            >
              Progress
            </Typography>
            <Typography variant="body2" noWrap component="span">
              {getProgress()}
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={1} sx={{ pb: 2, px: 2 }}>
          <Stack
            justifyContent={item?.is_mute ? "space-between" : "flex-end"}
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={() => {}}
          >
            {/* ---------- mute notification ---------- */}
            {item?.is_mute && (
              <Tooltip title="Project Muted">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  openDialog(item);
                }}
              >
                <Iconify
                  style={{
                    fontSize: "20px",
                  }}
                  icon="eva:bell-off-fill"
                />
              </IconButton>
            </Tooltip>
            )}
            {/* ---------- Add team button ---------- */}
            {team.length === 0 && (
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleTeamClick}
              >
                add team
              </Button>
            )}
            {/* ------------ Project Team ------------ */}
            {team.length !== 0 && (
              <Tooltip title="Project team">
                <div className="avatar-container">
                  <AvatarGroup
                    onClick={handleTeamClick}
                    sx={{ height: 33, width: 33 }}
                    max={3}
                  >
                    {team.map((user) => {
                      const { first_name, last_name, email, image, status } =
                        user;
                      return (
                        <UserAvatar
                          key={user._id}
                          name={first_name}
                          image={image}
                          alt={first_name}
                        />
                      );
                    })}
                  </AvatarGroup>
                </div>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Card>
      {/* <Card
        onClick={handleNav}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
        sx={{ height: "100%" }}
      >
        <CardContent sx={{ p: 2 }} style={{ paddingBottom: "16px" }}>
          <div className="d-flex justify-content-between align-items-center">
            {image ? (
              <img
                src={s3baseUrl + image}
                style={{
                  height: "60px",
                  width: "60px",
                  objectFit: "contain",
                  borderRadius: "5px",
                }}
              />
            ) : (
              <img
                src="https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg"
                style={{
                  height: "60px",
                  width: "60px",
                  objectFit: "contain",
                  borderRadius: "5px",
                }}
              />
            )}
            <div className="d-flex flex-column">
              {showMenu() && (
                <IconButton
                  sx={{ bgcolor: "rgba(255, 255, 255, 0.438)" }}
                  ref={anchorRef}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenOptions(e, item);
                  }}
                >
                  <Iconify
                    sx={{ color: "black" }}
                    icon="mi:options-horizontal"
                    // icon="fluent:more-vertical-16-filled"
                  />
                </IconButton>
              )}
              <div
                style={{
                  float: "right",
                  marginLeft: "auto",
                  marginRight: hovered || is_favorite ? "0" : "-100px",
                  transition: "0.3s",
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onFavouriteClick(item);
                  }}
                >
                  <Iconify
                    sx={{ color: "#dbb90f" }}
                    icon={is_favorite ? "ion:star" : "ion:star-outline"}
                  />
                </IconButton>
              </div>
            </div>
          </div>
          <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            sx={{
              p: 0.5,
              mt: 1.5,
              ml: 0.75,
              width: "7rem",
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.75,
              },
            }}
          >
            {menuOptions.map((option, index) => {
              return (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickItem(option, index);
                  }}
                  key={index}
                  sx={{ width: "6.5rem" }}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </MenuPopover>
          <div className="d-flex justify-content-between">
            <Typography variant="h6" fontWeight={"bold"}>
              {title}
            </Typography>
            <Tooltip title="View description">
              <IconButton
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenDetails(item);
                }}
              >
                <Iconify icon="pajamas:details-block" />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <LinearProgressWithLabel value={getProgress()} />
          </div>
          <div className="d-flex justify-content-between align-items-center pt-2">
            <div>
              {end_date && (
                <Label variant="ghost" color={getDateColor(end_date)}>
                  <Iconify
                    icon="ic:outline-access-time"
                    sx={{ width: "20px", height: "20px" }}
                  />
                  {moment().to(moment(end_date))}
                </Label>
              )}
            </div>
            <div>
              {team.length !== 0 && (
                <Tooltip title="Project team">
                  <div className="avatar-container">
                    <AvatarGroup
                      onClick={handleTeamClick}
                      sx={{ height: 33, width: 33 }}
                      max={3}
                    >
                      {team.map((user) => {
                        console.log(team, "team,,");
                        const { first_name, last_name, email, image, status } =
                          user;
                        return (
                          <UserAvatar
                            key={user._id}
                            name={first_name}
                            image={image}
                            alt={first_name}
                          />
                        );
                      })}
                    </AvatarGroup>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </CardContent>
      </Card> */}
    </>
  );
}
