import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Typography,
  Box,
  Stack,
  Button,
  Paper,
  Avatar,
  Checkbox,
  Badge,
  TextField,
  FormControlLabel,
  FormGroup,
  Radio,
} from "@mui/material";
import { Iconify, MuiDrawer } from "src/components";
import { s3baseUrl } from "src/config";
import { capitalCase } from "change-case";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { useSnackbar } from "notistack";

// -------------------------------------------------
const ICON_SIZE = { height: "10px", width: "10px" };

const MAP_TASK_PRIORITY_OPTIONS = [
  { label: "critical", value: 0, color: "#FF4842" },
  { label: "high", value: 1, color: "#ffd500" },
  { label: "medium", value: 2, color: "#54D62C" },
  { label: "low", value: 3, color: "#969292" },
];
/*  */
const MAP_TASK_STATUS_OPTIONS = [
  { label: "pending", value: 0, color: "#FFC107" },
  { label: "inProgress", value: 2, color: "#3366FF" },
  { label: "readyForTesting", value: 11, color: "#a4bdfc" },
  { label: "completed", value: 3, color: "#00AB55" },
  { label: "cancelled", value: 1, color: "#FF4842" },
];
// ------------------------------------------ Props
export default function FiltersDrawer({
  open,
  closeFilters,
  handleNavToVerifiedList,
  loadingTasks,
  handleApplyFilter,
  handleClearFilter,
  handleClearSelectedFilters,
  handleChangeFilter,
  filterOptions,
  projectDetail,
}) {
  const { enqueueSnackbar } = useSnackbar();
  // ----------------------------------------- States
  const [dateType, setDateType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // ----------------------------------------- Functions

  const isEndDateInvalid =
    endDate && moment(endDate).isBefore(moment(startDate));

  const handleDateChange = (e, type) => {
    let value;
    if (type === "date_type") {
      value = e.target.value;
      setDateType(value);
    } else {
      value = moment(e.target.value, "YYYY-MM-DD", true).isValid()
        ? moment(e.target.value).format("YYYY-MM-DD")
        : "";
      type === "start_date" ? setStartDate(value) : setEndDate(value);
    }

    const updatedFilters = {
      date_type: type === "date_type" ? value : dateType,
      start_date: type === "start_date" ? value : startDate,
      end_date: type === "end_date" ? value : endDate,
    };
    console.log(updatedFilters, "updated_Filters");
    handleChangeFilter(_, "date", updatedFilters);
    // e.target.blur();
  };
  const handleApply = () => {
    if (isEndDateInvalid) {
      enqueueSnackbar("Start date must be earlier than End date!", { variant: "warning" });
      return;
    }
    handleApplyFilter();
  };
  // ------------------------------------------ useeffect
  useEffect(() => {
    if (open) {
      setDateType(filterOptions.date?.date_type || "createdAt");
      setStartDate(filterOptions.date?.start_date || "");
      setEndDate(filterOptions.date?.end_date || "");
    }
  }, [open]);
  // ------------------------------------------- Design
  return (
    <MuiDrawer
      paperProps={{ width: { xs: 1, sm: 340 } }}
      isOpen={open}
      onClose={() => closeFilters()}
      title={<Typography sx={{ fontWeight: "700" }}>Filters</Typography>}
      titleButton={
        <Button
          sx={{ marginRight: "12px" }}
          variant="contained"
          onClick={handleNavToVerifiedList}
          size="small"
          startIcon={
            <Iconify
              sx={{ height: "1rem", width: "1rem" }}
              icon="teenyicons:tick-circle-solid"
            />
          }
        >
          View Verified Tasks
        </Button>
      }
      bottomComponent={
        <Stack direction="row" spacing={1} sx={{ px: "20px", py: "10px" }}>
          <LoadingButton
            loading={loadingTasks}
            size="large"
            variant="contained"
            className="outline-btn"
            onClick={handleApply}
            startIcon={<Iconify icon="ic:baseline-filter-alt" />}
            fullWidth
          >
            Filter
          </LoadingButton>
          <LoadingButton
            disabled={loadingTasks}
            size="large"
            variant="outlined"
            className="outline-btn"
            onClick={handleClearFilter}
            startIcon={<Iconify icon="material-symbols:clear-all-rounded" />}
            fullWidth
          >
            Clear All
          </LoadingButton>
        </Stack>
      }
    >
      {/* ----------------------- Filters ------------------------- */}
      <Stack spacing={2}>
        <div
          className="filters-accordion"
          style={{ display: "flex", flexDirection: "column", gap: "12px" }}
        >
          {/* Date Filter ---------------------- */}
          <Paper elevation={2}>
            <Accordion defaultExpanded>
              <AccordionSummary
                className="accordion-Summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="95%"
                >
                  <Typography fontWeight="bold">Date Range</Typography>
                  <div>
                    {(filterOptions?.date?.start_date ||
                      filterOptions?.date?.end_date ||
                      filterOptions?.date?.date_type === "target_date") && (
                      <Button
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClearSelectedFilters("date");
                          setDateType("createdAt");
                          setStartDate("");
                          setEndDate("");
                        }}
                        color="primary"
                      >
                        Clear
                      </Button>
                    )}
                    <Badge
                      badgeContent={
                        [
                          filterOptions?.date?.start_date,
                          filterOptions?.date?.end_date,
                          filterOptions?.date?.date_type === "target_date",
                        ].filter(Boolean).length
                      }
                      color="primary"
                      sx={{ marginLeft: 2 }}
                    />
                  </div>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "10px" }} className="pb-0">
                <TextField
                  type="date"
                  label="Start Date"
                  variant="outlined"
                  size="small"
                  value={startDate}
                  onChange={(e) => handleDateChange(e, "start_date")}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  onClick={(e) =>
                    e.currentTarget.querySelector("input").showPicker()
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& input": {
                        color: isEndDateInvalid && "#C23232",
                        fontSize: "12px",
                      },
                    },
                  }}
                />
                {startDate && !endDate && (
                  <div className="d-flex align-items-center ">
                    <Iconify
                      icon="dashicons:warning"
                      color="#FF9800"
                      fontSize="14px"
                    />
                    <Typography
                      sx={{
                        marginLeft: "5px",
                        fontSize: "11px",
                        color: "text.disabled",
                        fontWeight: "500",
                      }}
                    >
                      Please select end date!
                    </Typography>
                  </div>
                )}
                <TextField
                  disabled={!startDate}
                  type="date"
                  label="End Date"
                  variant="outlined"
                  size="small"
                  value={endDate}
                  onChange={(e) => handleDateChange(e, "end_date")}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  onClick={(e) =>
                    e.currentTarget.querySelector("input").showPicker()
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& input": {
                        color: isEndDateInvalid && "#C23232",
                        fontSize: "12px",
                      },
                    },
                  }}
                />

                {isEndDateInvalid && (
                  <div className="d-flex align-items-center mb-2 mt-1">
                    <Iconify
                      icon="dashicons:warning"
                      color="#FF9800"
                      fontSize="14px"
                    />
                    <Typography
                      sx={{
                        marginLeft: "5px",
                        fontSize: "11px",
                        color: "text.disabled",
                        fontWeight: "500",
                      }}
                    >
                      Start date must be earlier than End date
                    </Typography>
                  </div>
                )}
                {startDate && endDate && (
                  <div className="ms-2 mt-2">
                    <Typography fontSize="12px" fontWeight="500">
                      Filter Tasks By :
                    </Typography>
                    <FormGroup row className="ms-2">
                      <FormControlLabel
                        className="row-h-effect "
                        control={
                          <Radio
                            className="check-box-filter me-2"
                            value="createdAt"
                            checked={dateType === "createdAt"}
                            onChange={(e) => handleDateChange(e, "date_type")}
                          />
                        }
                        label={
                          <Typography sx={{ fontSize: "12px" }}>
                            Created Date
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        className="row-h-effect"
                        control={
                          <Radio
                            className="check-box-filter me-2"
                            value="target_date"
                            checked={dateType === "target_date"}
                            onChange={(e) => handleDateChange(e, "date_type")}
                          />
                        }
                        label={
                          <Typography sx={{ fontSize: "12px" }}>
                            Target Date
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </Paper>

          {/* Status --------- */}
          <Paper elevation={2}>
            <Accordion defaultExpanded>
              <AccordionSummary
                className="accordion-Summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="95%"
                >
                  <Typography fontWeight="bold">Status</Typography>

                  <div>
                    {MAP_TASK_STATUS_OPTIONS.filter(
                      (option) => filterOptions["status"][option.label]
                    ).length > 0 && (
                      <Button
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents the accordion from collapsing
                          handleClearSelectedFilters("status");
                        }}
                      >
                        Clear
                      </Button>
                    )}
                    <Badge
                      badgeContent={
                        MAP_TASK_STATUS_OPTIONS.filter(
                          (option) => filterOptions["status"][option.label]
                        ).length
                      }
                      color="primary"
                      sx={{ marginLeft: 2 }}
                    />
                  </div>
                </Stack>
              </AccordionSummary>
              <AccordionDetails className="p-1 pb-0">
                {MAP_TASK_STATUS_OPTIONS.map((option, index) => (
                  <label key={index} style={{ width: "100%" }}>
                    <Stack
                      className="row-h-effect"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <Checkbox
                        sx={{ marginRight: "10px" }}
                        className="check-box-filter"
                        value={option.label}
                        onChange={(e) =>
                          handleChangeFilter(e, "status", option.label)
                        }
                        checked={filterOptions.status[option.label] || false}
                      />
                      <Box
                        sx={{
                          width: ICON_SIZE.width,
                          height: ICON_SIZE.height,
                          borderRadius: "50%",
                          backgroundColor: option.color,
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, userSelect: "none" }}
                      >
                        {capitalCase(option.label)}
                      </Typography>
                    </Stack>
                  </label>
                ))}
              </AccordionDetails>
            </Accordion>
          </Paper>

          {/* Priority --------- */}
          <Paper elevation={2}>
            <Accordion defaultExpanded>
              <AccordionSummary
                className="accordion-Summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="95%"
                >
                  <Typography fontWeight="bold">Priority</Typography>

                  <div>
                    {MAP_TASK_PRIORITY_OPTIONS.filter(
                      (option) => filterOptions["priority"][option.label]
                    ).length > 0 && (
                      <Button
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClearSelectedFilters("priority");
                        }}
                      >
                        Clear
                      </Button>
                    )}
                    <Badge
                      badgeContent={
                        MAP_TASK_PRIORITY_OPTIONS.filter(
                          (option) => filterOptions["priority"][option.label]
                        ).length
                      }
                      color="primary"
                      sx={{ marginLeft: 2 }}
                    />
                  </div>
                </Stack>
              </AccordionSummary>
              <AccordionDetails className="p-1 pb-0 ">
                {MAP_TASK_PRIORITY_OPTIONS.map((option, index) => (
                  <label key={index} style={{ width: "100%" }}>
                    <Stack
                      className="row-h-effect"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <Checkbox
                        sx={{ marginRight: "10px" }}
                        className="check-box-filter"
                        value={option.label}
                        onChange={(e) =>
                          handleChangeFilter(e, "priority", option.label)
                        }
                        checked={filterOptions.priority[option.label] || false}
                      />
                      <Iconify
                        icon="carbon:flag-filled"
                        sx={{
                          width: "15px",
                          height: "15px",
                          color: option.color,
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, userSelect: "none" }}
                      >
                        {capitalCase(option.label)}
                      </Typography>
                    </Stack>
                  </label>
                ))}
              </AccordionDetails>
            </Accordion>
          </Paper>

          {/* Members --------- */}
          <Paper elevation={2}>
            <Accordion defaultExpanded>
              <AccordionSummary
                className="accordion-Summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="95%"
                >
                  <Typography fontWeight="bold">Members</Typography>
                  <div>
                    {filterOptions.search_users.length > 0 && (
                      <Button
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents the accordion from collapsing
                          handleClearSelectedFilters("search_users");
                        }}
                      >
                        Clear
                      </Button>
                    )}
                    <Badge
                      badgeContent={filterOptions.search_users.length}
                      color="primary"
                      sx={{ marginLeft: 2 }}
                    />
                  </div>
                </Stack>
              </AccordionSummary>
              <AccordionDetails className="p-1 pb-0">
                {projectDetail?.team?.map((member, index) => (
                  <label key={index} style={{ width: "100%" }}>
                    <Stack
                      className="row-h-effect"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ width: "100%" }}
                    >
                      <Checkbox
                        sx={{ marginRight: "10px" }}
                        className="check-box-filter"
                        value={member.user_id}
                        onChange={(e) =>
                          handleChangeFilter(e, "search_users", member.user_id)
                        }
                        checked={filterOptions?.search_users?.includes(
                          member.user_id
                        )}
                      />
                      <Avatar
                        src={s3baseUrl + member.image}
                        sx={{ width: "25px", height: "25px" }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ width: "100%", userSelect: "none" }}
                      >
                        {capitalCase(
                          member.first_name + " " + member.last_name
                        )}
                      </Typography>
                    </Stack>
                  </label>
                ))}
              </AccordionDetails>
            </Accordion>
          </Paper>
        </div>
      </Stack>
    </MuiDrawer>
  );
}
