import { LoadingButton } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Editor } from "@tinymce/tinymce-react";
import {
  Container,
  Typography,
  Skeleton,
  Box,
  Stack,
  Grid,
  IconButton,
  Button,
  Tooltip,
  useMediaQuery,
  TextField,
  InputAdornment,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback } from "react";
import { useLayoutEffect, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Iconify,
  MuiDialog,
  MuiDrawer,
  Page,
  RichEditor,
  Scrollbar,
} from "src/components";
import {
  ALLOWED_TASK_FILES_WITH_ICONS,
  ALLOWED_TASK_FILE_TYPES,
  FILE_ACCEPT_TYPE_STRING,
  TASK_PRIORITY_OPTIONS,
  TASK_SORT_OPTIONS,
  TASK_STATUS_OPTIONS,
} from "src/constants";
import {
  _add_template_section,
  _add_template_task,
  _delete_template_task,
  _make_template_section_default,
  _delete_template_section,
  _project_sections,
  _get_template_sections_list,
  _template_task_list,
  _update_task_priority,
  _edit_template_section,
  _edit_template_task,
  _update_template_section_order,
  _update_template_task_order,
  _load_more_template_tasks,
} from "src/DAL";
import { useAppContext } from "src/hooks";
import Progress from "./components/Progress";
import {
  get_from_localStorage,
  get_zone_date,
  set_to_localStorage,
  upload_task_files,
} from "src/utils";
import SectionSelect from "./components/SectionSelect";
import TaskSection from "./components/TaskSection";
import { useTransition } from "react";
import { useMemo } from "react";
import { s3baseUrl } from "src/config";
import { _make_section_default } from "src/DAL/sections";
import InfiniteScroll from "react-infinite-scroll-component";
// ---------------------------------------------------------
/*  */
// ---------------------------------------------------------
const TemplateProject = () => {
  const [isPending, startTransition] = useTransition();
  const matches_mobile = useMediaQuery("(max-width:768px)");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const slug = params.template_id;
  const { state } = useLocation();
  const {
    dispatch_get_user_profile,
    dispatch_set_search_visibility,
    socketEmit,
  } = useAppContext();
  //////===========================================///////////

  const [filteredSectionsData, setFilteredSectionsData] = useState({
    load_more_url: "",
    section_lists: [],
    total: 0,
  });

  const [allFilteredTasksBySections, setAllFilteredTasksBySections] = useState(
    {}
  );

  const [sectionPage, setSectionPage] = useState(0);
  const [moreTasksLoading, setMoreTasksLoading] = useState(false);

  /////////====================================/////////////////
  const pageRef = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState("team");
  const [workspaceId, setWorkspaceId] = useState("");
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [delTaskOpen, setDelTaskOpen] = useState(false);
  const [delSectionOpen, setDelSectionOpen] = useState(false);
  const [taskSortOptionIndex, setTaskSortOptionIndex] = useState(0);
  const [addingTask, setAddingTask] = useState(false);
  const [taskField, setTaskField] = useState(false);
  const [task, setTask] = useState("");
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const [taskDetailOpen, setTaskDetailOpen] = useState(false);
  const [editSectionOpen, setEditSectionOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [selectedMember, setSelectedMember] = useState(undefined);
  const [selectedSection, setSelectedSection] = useState(undefined);
  const [selectedSectionTitle, setSelectedSectionTitle] = useState("");
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [delSectionWithTasks, setDelSectionWithTasks] = useState(false);
  const [settingsAndStatsOpen, setSettingsAndStatsOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectedTaskDescription, setSelectedTaskDescription] = useState("");
  const [selectedTaskTitle, setSelectedTaskTitle] = useState("");
  const [projectTeam, setProjectTeam] = useState([]);
  const [editingComment, setEditingComment] = useState(false);
  const [selectedComment, setSelectedComment] = useState(undefined);
  const [editCommentTitle, setEditCommentTitle] = useState("");
  const [editCommentType, setEditCommentType] = useState("0");
  const [addSectionDialogOpen, setAddSectionDialogOpen] = useState(false);
  const [newSectionName, setNewSectionName] = useState("");
  const [addSectionLoading, setAddSectionLoading] = useState(false);
  const [taskFilterOptions, setTaskFilterOptions] = useState({
    status: [],
    priority: [],
    sort_status: "",
    type: 0,
  });
  const [filterOptions, setFilterOptions] = useState({
    priority: {
      critical: false,
      high: false,
      medium: false,
      low: false,
    },
    status: {
      pending: false,
      inProgress: false,
      completed: false,
      // verified: false,
      cancelled: false,
    },
  });
  const [tempFilters, setTempFilters] = useState(filterOptions);
  const [projectDetail, setProjectDetail] = useState({
    _id: "",
    title: "",
    description: "",
    image: "",
    status: true,
    creator: "",
    workspace: "",
    team: [],
    createdAt: "",
    start_date: "",
    end_date: "",
    total_task: 0,
    pending_task: 0,
    completed_task: 0,
  });
  const [taskInputs, setTaskInputs] = useState({
    description: "",
    type: "0",
    team: [],
    status: 0,
    priority: 3,
    endDate: "",
  });
  const [progress, setProgress] = useState(0);

  const [settingOptions, setSettingOptions] = useState({
    expandAllSections: false,
  });
  const firstExpand = useRef(true);
  const editorRef = useRef();
  const [updatingTask, setUpdatingTask] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [addTaskFiles, setAddTaskFiles] = useState([]);
  const [selectedTaskComments, setSelectedTaskComments] = useState([]);
  const [selectedTaskCommentsData, setSelectedTaskCommentsData] = useState({});
  const [makeDefaultSection, setMakeDefaultSection] = useState(false);
  // -------------------------OPTIMIZATION--------------------------------
  /* momoization */
  // const memoTaskSections = useMemo(() => {
  //   return taskData.sections;
  // }, [
  //   taskData.sections,
  //   taskData.count,
  //   taskData.loadMoreUrl,
  //   { ...taskData.sections },
  // ]);
  // const memoTaskSections = useMemo(() => {
  //   return projectSectionsData;
  // }, [projectSectionsData]);

  const memoFilteredSections = useMemo(() => {
    let sectionsWithTasks = filteredSectionsData.section_lists.map(
      (section) => {
        return {
          ...section,
          task: allFilteredTasksBySections[section._id].task_list,
          task_count: allFilteredTasksBySections[section._id].total,
          load_more_tasks:
            allFilteredTasksBySections[section._id].load_more_url,
        };
        // }
      }
    );
    return sectionsWithTasks;
  }, [filteredSectionsData, allFilteredTasksBySections]);
  const memoProjectDetail = useMemo(() => {
    return projectDetail;
  }, [
    projectDetail.completed_task,
    projectDetail.image,
    projectDetail.team,
    projectDetail.pending_task,
    projectDetail.total_task,
  ]);
  const memoProjectTeam = useMemo(() => {
    return projectTeam;
  }, [projectTeam]);
  // const memoProjectSections = useMemo(() => {
  //   return projectSectionsData;
  // }, [projectSectionsData]);
  /* callbacks */
  const ucbOnExpand = useCallback(
    (section) => handleExpandSection(section),
    []
  );
  const ucbOnSetSelectedTask = useCallback(
    (task) => onSetSelectedTask(task),
    []
  );
  const ucbOpenTaskDetail = useCallback((task, workspace_id) => {
    setSelectedTaskDescription(task.task_description);
    setSelectedTaskTitle(task.task_title);
    openTaskDetail(task);
  }, []);
  const ucbOpenComments = useCallback(
    (task) => {
      getTaskComments(task._id);
      openComments(task);
    },
    [allFilteredTasksBySections]
  );
  const ucbOnChangePriority = useCallback(
    (data, workspace_id) => {
      handleChangePriority(data, workspace_id);
    },
    [allFilteredTasksBySections]
  );
  const ucbOnChangeStatus = useCallback(
    (data, workspace_id) => {
      handleChangeStatus(data, workspace_id);
    },
    [allFilteredTasksBySections]
  );
  const ucbOnChangeDate = useCallback(
    (data, workspace_id) => {
      handleChangeDate(data, workspace_id);
    },
    [allFilteredTasksBySections]
  );
  const ucbOnChangeMembers = useCallback(
    (data, workspace_id) => {
      handleChangeMembers(data, workspace_id);
    },
    [allFilteredTasksBySections]
  );
  const ucbOnDelTask = useCallback((data, workspace_id) => {
    handleOpenDelTask(data, workspace_id);
  }, []);
  const ucbOnDelSection = useCallback((data, type) => {
    console.log(type, "del-type");
    if (type === "with_tasks") {
      setDelSectionWithTasks(true);
    }
    if (type === "without_tasks") {
      setDelSectionWithTasks(false);
    }
    handleOpenDelSection();
    setSelectedSection(data);
  }, []);
  const usbOnMakeSectionDefault = useCallback((data) => {
    openMakeDefaultSection();
    setSelectedSection(data);
    setSelectedSectionTitle(data.section_title);
  }, []);
  const ucbOnEditSection = useCallback((data) => {
    console.log(data, "edit-section");
    openEditSection();
    setSelectedSection(data);
    setSelectedSectionTitle(data.section_title);
  }, []);
  const ucbOnMoveSection = useCallback(
    (data) => {
      handleMoveSection(data);
    },
    [
      filteredSectionsData.section_lists,
      filteredSectionsData.total,
      filteredSectionsData.load_more_url,
    ]
  );
  const ucbOnMoveTask = useCallback(
    (data) => {
      handleMoveTask(data);
    },
    [
      filteredSectionsData.section_lists,
      filteredSectionsData.total,
      filteredSectionsData.load_more_url,
      allFilteredTasksBySections,
    ]
  );
  const ucbOnDragEnd = useCallback(
    (data, workspaceId) => {
      handleDragEnd(data, workspaceId);
    },
    [
      filteredSectionsData.section_lists,
      filteredSectionsData.total,
      filteredSectionsData.load_more_url,
      allFilteredTasksBySections,
    ]
  );
  const ucbOnDragStart = useCallback(
    (data, workspaceId) => {
      handleDragStart(data, workspaceId);
    },
    [
      filteredSectionsData.section_lists,
      filteredSectionsData.total,
      filteredSectionsData.load_more_url,
      allFilteredTasksBySections,
    ]
  );
  // ---------------------------------------------------------

  const expandFirstSectionOnly = (_id, _index) => {
    // if (settingOptions.expandAllSections === true) {
    //   return true;
    // }
    if (_index === 0) {
      return true;
    }
    return false;
  };
  // const isSectionExpanded = (_id, _index) => {
  //   if (settingOptions.expandAllSections === true) {
  //     return true;
  //   }
  //   const expand_section_id = state?.expand_section_id;
  //   const scroll_to_task = state?.scroll_to_task;
  //   if (String(expand_section_id) === String(_id)) {
  //     return true;
  //   }
  //   let local_sections = get_from_localStorage("sections-expand-states");
  //   let [found] = local_sections.filter(
  //     (item) => String(item._id) === String(_id)
  //   );
  //   if (found) {
  //     return found.expanded;
  //   }
  //   return false;
  // };
  const onSetSelectedTask = (task) => {
    setSelectedTask(task);
  };
  const currentTask = () => {
    if (selectedTask) {
      return selectedTask;
    }
    return undefined;
  };
  // const shouldScroll = (_id) => {
  //   if (!state) {
  //     return false;
  //   }
  //   const { scroll_to_task } = state;
  //   if (!scroll_to_task) {
  //     return false;
  //   }

  //   if (String(scroll_to_task) === String(_id)) {
  //     return true;
  //   }
  //   return false;
  // };
  const getModifiedTasks = (_tasks) => {
    return _tasks.map((task) => {
      return {
        ...task,
        query_field: task.task_title,
        // ["scroll_to_view"]: shouldScroll(task._id),
      };
    });
  };
  const getModifiedSectionList = (data) => {
    const first_expand = firstExpand.current;
    let modified = data.map((item, index) => {
      return {
        ...item,
        task: [],
        // task: getModifiedTasks(item.task),
        expanded: first_expand
          ? expandFirstSectionOnly(item._id, index)
          : isSectionExpanded(item._id, index),
        move_up: index !== 0,
        move_down: index + 1 !== data.length,
        scroll_to_view: state?.expand_section_id == item._id,
      };
    });
    console.log(modified, "modified-sections");
    return modified;
  };
  const getUpdatedSectionList = (data) => {
    let modified = data.map((item, index) => {
      return {
        ...item,
        task: getModifiedTasks(item.task),
        move_up: index !== 0,
        move_down: index + 1 !== data.length,
      };
    });
    console.log(modified, "modified-sections");
    return modified;
  };

  // const getProjectDetail = async () => {
  //   const result = await _project_detail(null, slug);
  //   if (result.code === 200) {
  //     console.log(result, "detail-res");
  //     startTransition(() => {
  //       setWorkspaceId(result.project_detail.workspace);
  //       setLoadingDetail(false);
  //       setProjectDetail(result.project_detail);
  //       setCurrentUserRole(result.project_detail.role);
  //     });
  //   } else {
  //     setLoadingDetail(false);
  //     enqueueSnackbar(result.message, { variant: "error" });
  //   }
  // };
  const getFilteredSections = async (
    workspace_id,
    explicit_req_data,
    filters
  ) => {
    console.log(sectionPage, "<===REQUEST-OBJECT===> ");
    if (filters) {
      setSectionPage(0);
    }
    let workspace = workspaceId;
    if (workspace_id) {
      workspace = workspace_id;
    }
    // if (!workspace) {
    //   console.log("no workspaceId");
    //   return;
    // }
    console.log(taskFilterOptions, "taskFilterOptions-req");
    console.log(filterOptions, "filterOptions-req");

    let req_data = {
      task_status: [],
      priority: [],
      workspace_id: workspace,
      sort_status: taskFilterOptions.sort_status,
      list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
    };
    let task_FilterOptions = get_from_localStorage("taskFilterOptions");
    let sidebar_Filters = get_from_localStorage("sidebarFilters");
    if (task_FilterOptions) {
      req_data = {
        task_status: task_FilterOptions.status,
        priority: task_FilterOptions.priority,
        workspace_id: workspace,
        sort_status:
          task_FilterOptions.sort_status === "default"
            ? ""
            : task_FilterOptions.sort_status,
        list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
      };
    }
    if (explicit_req_data) {
      console.log("explicit", explicit_req_data);
      req_data = explicit_req_data;
    }

    console.log(task_FilterOptions, "taskFilterOptions");
    console.log(sidebar_Filters, "sidebarFilters");
    console.log(req_data, "req-data-task-list");
    // setLoadingTasks(true);
    let tempPage = filters ? 0 : sectionPage;
    req_data = { search: "" };
    const result = await _get_template_sections_list(req_data, slug, tempPage);
    if (result.code === 200) {
      console.log(result, "sections-res");
      const { section_list } = result;
      startTransition(() => {
        // setTaskData({
        //   loadMoreUrl: task_detail.load_more_url,
        //   sections: getModifiedSectionList(task_detail.section_lists),
        //   count: task_detail.total,
        // });
        let temp_tasks = { ...allFilteredTasksBySections };
        section_list.map((section) => {
          temp_tasks = {
            ...temp_tasks,
            [section._id]: {
              total: section.task_count,
              task_list: temp_tasks[section._id]
                ? temp_tasks[section._id].task_list
                : [],
              load_more_url: `api/template_task/task_template_list/${section._id}?page=0&limit=15`,
            },
          };
        });
        setAllFilteredTasksBySections(temp_tasks);
        let old_sections = filteredSectionsData.section_lists;
        let new_sections = !filters
          ? old_sections.concat(section_list)
          : section_list;
        console.log(new_sections, "sacdasldifas", filters);
        setFilteredSectionsData({
          loadMoreUrl: section_list.load_more_url,
          section_lists: getModifiedSectionList(new_sections),
          total: section_list.total,
        });
        setSectionPage((prev) => prev + 1);
        setLoadingTasks(false);
        setLoadingDetail(false);
      });
    } else {
      setLoadingTasks(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getFilteredTasksBySection = async (section_id) => {
    let req_data = {
      task_status: [],
      priority: [],
      sort_status: taskFilterOptions.sort_status,
      list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
    };
    let task_FilterOptions = get_from_localStorage("taskFilterOptions");
    let sidebar_Filters = get_from_localStorage("sidebarFilters");
    if (task_FilterOptions) {
      req_data = {
        task_status: task_FilterOptions.status,
        priority: task_FilterOptions.priority,
        sort_status:
          task_FilterOptions.sort_status === "default"
            ? ""
            : task_FilterOptions.sort_status,
        list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
      };
    }
    req_data = { search: "" };
    const result = await _template_task_list(req_data, section_id);
    if (result.code == 200) {
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section_id]: {
            ...result.task_detail,
            task_list: getModifiedTasks(result.task_detail.task_list),
          },
        };
      });
    }
  };
  const handleLoadMoreTasks = async (path, section_id) => {
    setMoreTasksLoading(true);
    let req_data = {
      task_status: [],
      priority: [],
      sort_status: taskFilterOptions.sort_status,
      list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
    };
    let task_FilterOptions = get_from_localStorage("taskFilterOptions");
    let sidebar_Filters = get_from_localStorage("sidebarFilters");
    if (task_FilterOptions) {
      req_data = {
        task_status: task_FilterOptions.status,
        priority: task_FilterOptions.priority,
        sort_status:
          task_FilterOptions.sort_status === "default"
            ? ""
            : task_FilterOptions.sort_status,
        list_type: taskFilterOptions.type /* 0--->Public, 1--->Private */,
      };
    }
    const result = await _load_more_template_tasks(path, req_data);
    console.log(result, "aflasjfafhask");
    if (result.code == 200) {
      let temp = allFilteredTasksBySections[section_id];
      let old_tasks = temp.task_list;
      let new_tasks = old_tasks.concat(result.task_detail.task_list);
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section_id]: {
            ...temp,
            load_more_url: result.task_detail.load_more_url,
            total: result.task_detail.total,
            task_list: getModifiedTasks(new_tasks),
          },
        };
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setMoreTasksLoading(false);
  };
  const handleNavBack = () => {
    navigate(-1);
  };
  const getSkeletonCount = () => {
    let dummy = [];
    let length = Number(filteredSectionsData.section_lists.length);
    // let length = Number(projectDetail.total_task);
    if (length === 0) {
      return [];
    }
    for (let i = 0; i < length; i++) {
      dummy.push("dummy");
    }
    return dummy;
  };
  const openTaskDetail = (task) => {
    setSelectedTask(task);
    setTaskDetailOpen(true);
  };
  const closeTaskDetail = () => {
    setTaskDetailOpen(false);
    setAddTaskFiles([]);
  };
  const openComments = (task) => {
    console.log(task, "on open comments");
    setSelectedTask(task);
    setCommentsOpen(true);
    let temp = allFilteredTasksBySections[task.section_id];
    let tempTask = temp.task_list.map((tsk) => {
      if (tsk._id == task._id) {
        return {
          ...tsk,
          task_comment_unread_count: 0,
        };
      } else {
        return { ...tsk };
      }
    });
    setAllFilteredTasksBySections((prev) => {
      return {
        ...prev,
        [task.section_id]: {
          ...temp,
          task_list: tempTask,
        },
      };
    });
  };
  const closeComments = () => {
    setCommentsOpen(false);
    pageRef.current.focus();
    startTransition(() => {
      setEditingComment(false);
      setSelectedComment(undefined);
      setEditCommentTitle("");
      setEditCommentType("0");
      setSelectedTaskComments([]);
    });
  };
  const openSettingsAndStats = () => {
    setSettingsAndStatsOpen(true);
  };
  const closeSettingsAndStats = () => {
    setSettingsAndStatsOpen(false);
  };
  const openFilters = () => {
    setFiltersOpen(true);
  };
  const closeFilters = (applied) => {
    if (!applied) {
      setFilterOptions({ ...tempFilters });
    } else {
      setTempFilters({ ...filterOptions });
    }
    setFiltersOpen(false);
  };
  const openDelSection = () => {
    setDelSectionOpen(true);
  };
  const closeDelSection = () => {
    setDelSectionOpen(false);
  };
  const openMakeDefaultSection = () => {
    setMakeDefaultSection(true);
  };
  const closeMakeDefaultSection = () => {
    setMakeDefaultSection(false);
  };
  const openEditSection = () => {
    setEditSectionOpen(true);
  };
  const closeEditSection = () => {
    setEditSectionOpen(false);
  };
  const getProgress = () => {
    if (projectDetail.completed_task === Number(0)) {
      setProgress(0);
      return;
    }
    if (projectDetail.total_task === Number(0)) {
      setProgress(0);
      return;
    }
    let progress = Number(
      projectDetail.completed_task / (projectDetail.total_task / 100)
    );
    setProgress(progress);
  };

  const getSelectedSection = () => {
    if (filteredSectionsData.section_lists.length > 0) {
      let selected_section =
        filteredSectionsData.section_lists[selectedSectionIndex];
      if (selected_section) {
        return selected_section;
      }
    }
    return undefined;
  };

  const handleTaskInputChange = (e) => {
    setTask(e.target.value);
  };
  const showAddTask = () => {
    setTaskField((prev) => !prev);
  };
  const handleTaskInputBlur = () => {
    if (task === "") {
      setTaskField(false);
    }
  };
  const isSection = (task) => {
    task = task.trim();
    let length = task.length;
    let char = task.charAt(length - 1);
    if (String(char) === ":") {
      return true;
    }
    return false;
  };
  const isDetailDisabled = () => {
    let task = currentTask();
    if (!task) {
      return true;
    }
    return false;
  };
  const getFormattedDate = (date, format) => {
    if (!date) {
      return "";
    }
    return get_zone_date(date, format);
  };

  const createTask = async (e) => {
    e.preventDefault();
    let section = getSelectedSection();
    if (addingTask) {
      return;
    }
    let is_section = isSection(task);
    console.log(is_section, "is_section");
    // if (!is_section) {
    if (!section) {
      console.log("no section selected");
      return;
    }
    if (section._id.trim() === "") {
      console.log("no section selected");
      return;
    }
    if (task.trim() === "") {
      enqueueSnackbar("Task cannot be empty", { variant: "error" });
      return;
    }
    const data = {
      task_title: task.trim(),
      task_description: taskInputs.description.trim(),
      status: true,
      image: [],
      task_status: taskInputs.status,
      priority: taskInputs.priority,
      task_type: "0",
    };

    console.log(data, "add-task-req");
    console.log(section, "task-section");
    let [target_section] = filteredSectionsData.section_lists.filter(
      (item) => String(item._id) === String(section._id)
    );
    setAddingTask(true);
    const result = await _add_template_task(data, section._id);
    /* returns updated section obj */
    if (result.code === 200) {
      console.log(result, "create-task-res");
      let new_task = { ...result.task_detail, task_comment_unread_count: 0 };
      let temp = allFilteredTasksBySections[section._id];
      temp.task_list.unshift(new_task);
      console.log(temp, "ascaskcjhaskcjhas");
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section._id]: {
            ...temp,
            total: temp.total + 1,
            task_list: getModifiedTasks(temp.task_list),
          },
        };
      });
      setAddingTask(false);
      setTaskInputs({
        description: "",
        type: "0",
        team: [],
        status: 0,
        priority: 3,
        endDate: "",
      });
      setTask("");

      /* don't call this after api response fix */
      enqueueSnackbar("Task Created Successfully", { variant: "success" });
    } else {
      setAddingTask(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
    // }
    // if (is_section) {
    //   const removeColon = (text) => {
    //     let target_text = text;
    //     for (let i = 0; i < text.length; i++) {
    //       target_text = target_text.replace(":", "");
    //     }
    //     return target_text.trim();
    //   };
    //   const req_data = {
    //     section_title: removeColon(task),
    //     section_status: true,
    //     section_type: "0",
    //     project_id: slug,
    //     // workspace_id: workspaceId,
    //   };

    //   setAddingTask(true);
    //   const result = await _add_template_section(req_data);
    //   if (result.code === 200) {
    //     // getProjectSections();
    //     // setProjectSectionsData((prev)=>[...prev,])

    //     const updateSections = (_data) => {
    //       let item = {
    //         ...result.section_detail,
    //         expanded: false,
    //         createdAt: new Date().toISOString(),
    //         creator: false,
    //         task: [],
    //       };
    //       let updated = Array.from(_data);
    //       updated.push(item);
    //       return getUpdatedSectionList(updated);
    //     };
    //     console.log(result, "add-section-res");
    //     enqueueSnackbar("Section Added Successfully", { variant: "success" });
    //     setAddingTask(false);
    //     setTask("");
    //     // setTaskData((prev) => {
    //     //   return { ...prev, sections: updateSections(prev.sections) };
    //     // });
    //     setAllFilteredTasksBySections((prev) => {
    //       return {
    //         ...prev,
    //         [result.section_detail._id]: {
    //           total: result.section_detail.task_count,
    //           task_list: [],
    //           load_more_url: `/api/task_filter_list/${result.section_detail._id}?page=0&limit=15`,
    //         },
    //       };
    //     });
    //     setFilteredSectionsData((prev) => {
    //       return {
    //         ...prev,
    //         total: prev.total + 1,
    //         section_lists: updateSections(prev.section_lists),
    //       };
    //     });
    //   } else {
    //     enqueueSnackbar(result.message, { variant: "error" });
    //     setAddingTask(false);
    //     enqueueSnackbar(result.message, { variant: "error" });
    //   }
    // }
  };
  const handleCreateSection = async (e) => {
    e.preventDefault();
    if (!newSectionName) {
      enqueueSnackbar("Section name cannot be empty", { variant: "error" });
      return;
    }
    try {
      setAddSectionLoading(true);
      const req_data = {
        section_title: newSectionName,
        section_status: true,
        section_type: "0",
        project_id: slug,
      };
      const result = await _add_template_section(req_data);
      if (result.code === 200) {
        const updateSections = (_data) => {
          let item = {
            ...result.section_detail,
            expanded: false,
            createdAt: new Date().toISOString(),
            creator: false,
            task: [],
          };
          let updated = Array.from(_data);
          updated.push(item);
          return getUpdatedSectionList(updated);
        };
        console.log(result, "add-section-res");
        enqueueSnackbar("Section Added Successfully", { variant: "success" });
        setNewSectionName("");
        setAddSectionDialogOpen(false);
        // setTaskData((prev) => {
        //   return { ...prev, sections: updateSections(prev.sections) };
        // });
        setAllFilteredTasksBySections((prev) => {
          return {
            ...prev,
            [result.section_detail._id]: {
              total: result.section_detail.task_count,
              task_list: [],
              load_more_url: `/api/task_filter_list/${result.section_detail._id}?page=0&limit=15`,
            },
          };
        });
        setFilteredSectionsData((prev) => {
          return {
            ...prev,
            total: prev.total + 1,
            section_lists: updateSections(prev.section_lists),
          };
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setAddingTask(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while adding section");
    } finally {
      setAddSectionLoading(false);
    }
  };
  const handleSectionItemClick = (_option, _index) => {
    console.log(_option);
    setSelectedSectionIndex(_index);
  };
  const expandTargetSection = (data, target_id) => {
    let updated = data.map((item) => {
      if (String(item._id) === String(target_id)) {
        return { ...item, expanded: !item.expanded };
      }
      return item;
    });
    return updated;
  };

  const trueExpandTargetSection = (data, target_id) => {
    let updated = data.map((item) => {
      if (String(item._id) === String(target_id)) {
        return { ...item, expanded: true };
      }
      return item;
    });
    return updated;
  };
  const handleExpandSection = (section) => {
    firstExpand.current = false;
    setFilteredSectionsData((prev) => {
      return {
        ...prev,
        section_lists: expandTargetSection(prev.section_lists, section._id),
      };
    });
  };
  const closeDelTask = () => {
    setDelTaskOpen(false);
  };
  const openDelTask = () => {
    setDelTaskOpen(true);
  };
  /*  */
  const handleChangePriority = async (data, workspace_id) => {
    if (!workspace_id) {
      console.log("no wps id");
      return;
    }
    const { task, section, priority } = data;
    let prev_state_section = null;
    console.log(data, "on-change-Priority");
    console.log(priority, "new-priority");
    const req_data = {
      task_id: task._id,
      task_priority: priority,
      list_type: "0",
    };
    console.log(req_data, "req_data");

    let updated_task = { ...task, priority: priority };
    const getUpdatedTasks = (_data) => {
      let updated = _data.map((_task) => {
        if (String(_task._id) === String(task._id)) {
          return updated_task;
        }
        return _task;
      });
      console.log(updated, "updated tasks");
      return updated;
    };
    const old_state = allFilteredTasksBySections;
    let temp = allFilteredTasksBySections[section._id];
    setAllFilteredTasksBySections((prev) => {
      return {
        ...prev,
        [section._id]: {
          ...temp,
          task_list: getUpdatedTasks(temp.task_list),
        },
      };
    });
    const result = await _update_task_priority(req_data);
    if (result.code === 200) {
      console.log(result, "res");
      let receiver = [result.task_detail.creator_profile.user_id];
      result.task_detail.team.map((member) => {
        receiver.push(member.user_id);
      });
      receiver = receiver.filter(
        (id) => id !== dispatch_get_user_profile().user_id
      );
      const { project, section_id, _id,workspace } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.allow_members,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      socketEmit("send_notification_to_all", {
        receiver: result.task_detail.allow_members,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `A member updated the task priority`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      enqueueSnackbar("Priority Updated Successfully", { variant: "success" });
    } else {
      setAllFilteredTasksBySections(old_state);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeDate = async (data, workspace_id) => {
    if (!workspace_id) {
      console.log("no wps id");
      return;
    }
    const { task, section, endDate } = data;
    let prev_state_section = undefined;
    console.log(data, "on-change-date");
    console.log(endDate, "new-date");
    let updated_task = { ...task, end_date: endDate };
    const getUpdatedTasks = (_data) => {
      let updated = _data.map((_task) => {
        if (String(_task._id) === String(task._id)) {
          return updated_task;
        }
        return _task;
      });
      console.log(updated, "updated tasks");
      return updated;
    };
    // let updated_section = { ...section, task: getUpdatedTasks(section.task) };
    // const getUpdatedSections = (_data) => {
    //   let updated = _data.map((_section) => {
    //     if (String(_section._id) === String(section._id)) {
    //       prev_state_section = _section;
    //       return updated_section;
    //     }
    //     return _section;
    //   });
    //   console.log(updated, "updated section");
    //   return updated;
    // };
    // setTaskData((prev) => {
    //   return { ...prev, sections: getUpdatedSections(prev.sections) };
    // });
    let temp = allFilteredTasksBySections[section._id];
    setAllFilteredTasksBySections((prev) => {
      return {
        ...prev,
        [section._id]: {
          ...temp,
          task_list: getUpdatedTasks(temp.task_list),
        },
      };
    });
    const req_data = {
      task_id: task._id,
      end_date: endDate,
      list_type: "0",
    };
    const result = await _update_task_end_date(req_data);
    if (result.code === 200) {
      console.log(result, "res");
      let receiver = [result.task_detail.creator_profile.user_id];
      result.task_detail.team.map((member) => {
        receiver.push(member.user_id);
      });
      receiver = receiver.filter(
        (id) => id !== dispatch_get_user_profile().user_id
      );
      const { project, section_id, _id,workspace } = result.task_detail;
      socketEmit("send_notification_to_all", {
        receiver,
        // data: result.Project,
        data: {
          task_id: _id,
          project_id: project.id,
          workspace_id: workspace.id,
        },
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } updated the target date of the task`,
        link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
      });
      // enqueueSnackbar("Target Date Updated Successfully", {
      //   variant: "success",
      // });
    } else {
      const getResetSections = (_data) => {
        let reset = _data.map((_section) => {
          if (String(_section._id) === String(section._id)) {
            return prev_state_section;
          }
          return _section;
        });
        console.log(reset, "reset section");
        return reset;
      };
      setTaskData((prev) => {
        return { ...prev, sections: getResetSections(prev.sections) };
      });
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeStatus = async (data) => {
    const { checked, section, task, is_checkbox, status } = data;
    console.log(data, "on-change-Status");
    const old_state = allFilteredTasksBySections;
    const hitStatusApi = async (req_data, custom_msg) => {
      const result = await _update_task_status(req_data);
      if (result.code === 200) {
        console.log(result, "res");
        if (custom_msg) {
          enqueueSnackbar(custom_msg, { variant: "info" });
          return;
        }
        // let receiver = [result.task_detail.creator_profile.user_id];
        // result.task_detail.team.map((member) => {
        //   receiver.push(member.user_id);
        // });
        // receiver = receiver.filter(
        //   (id) => id !== dispatch_get_user_profile().user_id
        // );
        const { project, section_id, _id,workspace } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.allow_members,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
        socketEmit("send_notification_to_all", {
          receiver: result.task_detail.hidden_members,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `A member updated the task status`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
        enqueueSnackbar("Status Updated Successfully", { variant: "success" });
      } else {
        setAllFilteredTasksBySections(old_state);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    };
    if (is_checkbox) {
      let updated_status = checked === true ? 3 : 0;
      console.log(updated_status, "new-status");
      let updated_task = { ...task, task_status: updated_status };
      const getUpdatedTasks = (_data) => {
        let updated = _data.map((_task) => {
          if (String(_task._id) === String(task._id)) {
            return updated_task;
          }
          return _task;
        });
        console.log(updated, "updated tasks");
        return updated;
      };
      // let updated_section = { ...section, task: getUpdatedTasks(section.task) };
      // const getUpdatedSections = (_data) => {
      //   let updated = _data.map((_section) => {
      //     if (String(_section._id) === String(section._id)) {
      //       return updated_section;
      //     }
      //     return _section;
      //   });
      //   console.log(updated, "updated section");
      //   return updated;
      // };
      // setTaskData((prev) => {
      //   return { ...prev, sections: getUpdatedSections(prev.sections) };
      // });
      let temp = allFilteredTasksBySections[section._id];
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section._id]: {
            ...temp,
            task_list: getUpdatedTasks(temp.task_list),
          },
        };
      });
      const req_data = {
        task_id: task._id,
        task_status: updated_status,
        list_type: "0",
      };
      hitStatusApi(req_data);
    }
    if (!is_checkbox) {
      console.log(status, "new-status");
      let updated_task = { ...task, task_status: status };
      const getUpdatedTasks = (_data) => {
        let updated = _data.map((_task) => {
          if (String(_task._id) === String(task._id)) {
            return updated_task;
          }
          return _task;
        });
        console.log(updated, "updated tasks");
        return updated;
      };
      const getUpdatedTasksVerified = (_data) => {
        return _data.filter((_task) => String(_task._id) !== String(task._id));
      };
      // let updated_section = { ...section, task: getUpdatedTasks(section.task) };
      // let updated_section_if_task_verified = {
      //   ...section,
      //   task: getUpdatedTasksVerified(section.task),
      // };
      // const getUpdatedSections = (_data) => {
      //   let updated = _data.map((_section) => {
      //     if (String(_section._id) === String(section._id)) {
      //       return updated_section;
      //     }
      //     return _section;
      //   });
      //   console.log(updated, "updated section");
      //   return updated;
      // };
      // const getUpdatedSectionsVerified = (_data) => {
      //   let updated = _data.map((_section) => {
      //     if (String(_section._id) === String(section._id)) {
      //       return updated_section_if_task_verified;
      //     }
      //     return _section;
      //   });
      //   console.log(updated, "updated section");
      //   return updated;
      // };
      // setTaskData((prev) => {
      //   return { ...prev, sections: getUpdatedSections(prev.sections) };
      // });
      let temp = allFilteredTasksBySections[section._id];
      if (String(status) === "4") {
        setAllFilteredTasksBySections((prev) => {
          return {
            ...prev,
            [section._id]: {
              ...temp,
              task_list: getUpdatedTasksVerified(temp.task_list),
              total: getUpdatedTasksVerified(temp.task_list).length,
            },
          };
        });
      }
      if (String(status) !== "4") {
        setAllFilteredTasksBySections((prev) => {
          return {
            ...prev,
            [section._id]: {
              ...temp,
              task_list: getUpdatedTasks(temp.task_list),
            },
          };
        });
      }
      let custom_msg = undefined;
      const req_data = {
        task_id: task._id,
        task_status: status,
        list_type: "0",
      };
      if (String(status) === "4") {
        custom_msg = "Task moved to verified listing";
      }
      if (custom_msg) {
        hitStatusApi(req_data, custom_msg);
        return;
      }
      hitStatusApi(req_data);
    }
  };
  const handleChangeMembers = async (data, workspace_id) => {
    if (!workspace_id) {
      console.log("no wps id");
      return;
    }
    const { section, task, taskTeam, isSelected, targetUser, action } = data;
    console.log(data, "payload");
    let updated_team = Array.from(taskTeam);
    if (action === "add") {
      if (isSelected) {
        return;
      }
      if (!isSelected) {
        updated_team.push(targetUser);
      }
    }
    if (action === "remove") {
      if (isSelected) {
        updated_team = updated_team.filter(
          (user) => String(user.user_id) !== String(targetUser.user_id)
        );
      }
    }

    console.log(data, "on-change-Members");

    console.log(updated_team, "new-team");
    let updated_task = { ...task, team: updated_team };
    const getUpdatedTasks = (_data) => {
      let updated = _data.map((_task) => {
        if (String(_task._id) === String(task._id)) {
          return updated_task;
        }
        return _task;
      });
      console.log(updated, "updated tasks");
      return updated;
    };

    let updated_task_team = [];
    updated_team.map((user) => {
      updated_task_team.push(user);
    });
    const req_data = {
      task_id: task._id,
      team: updated_task_team,
      list_type: "0",
    };
    const updatedTasks = (_data) => {
      return _data.map((item) => {
        if (String(item._id) === String(task._id)) {
          return { ...task_detail, query_field: item.task_title };
        }
        return item;
      });
    };
    const old_state = allFilteredTasksBySections;
    let temp = allFilteredTasksBySections[section._id];
    setAllFilteredTasksBySections((prev) => {
      return {
        ...prev,
        [section._id]: {
          ...temp,
          task_list: getUpdatedTasks(temp.task_list),
        },
      };
    });
    const result = await _update_task_members(req_data);
    if (result.code === 200) {
      console.log(result, "update-members-res");
      const { task_detail } = result;

      // enqueueSnackbar("Task Members Updated Successfully", {
      //   variant: "success",
      // });
      if (action == "add") {
        let receiver = [targetUser.user_id];
        receiver = receiver.filter(
          (id) => id !== dispatch_get_user_profile().user_id
        );
        const { project, section_id, _id,workspace } = result.task_detail;
        socketEmit("send_notification_to_all", {
          receiver,
          // data: result.Project,
          data: {
            task_id: _id,
            project_id: project.id,
            workspace_id: workspace.id,
          },
          message: `${dispatch_get_user_profile().first_name} ${
            dispatch_get_user_profile().last_name
          } assigned you a task`,
          link: `${window.location.origin}/workspaces/copied-task-link/${project.id}/${section_id}/${_id}`,
        });
      }
    } else {
      setAllFilteredTasksBySections(old_state);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleOpenDelTask = async (data) => {
    openDelTask();
  };
  const handleDeleteTask = async () => {
    let selectedTask = currentTask();
    const { sectionData } = selectedTask;
    console.log(sectionData, "asdaaskjcha");
    let prev_state_section = undefined;
    const updatedTasks = (_data) => {
      return _data.filter(
        (item) => String(item._id) !== String(selectedTask._id)
      );
    };
    let updated_section = {
      ...sectionData,
      task: updatedTasks(sectionData.task),
    };
    console.log(selectedTask, "-on-del-task");
    const data = {
      task_id: selectedTask._id,
      task_type: selectedTask.task_type,
      task_order: selectedTask.order,
    };
    console.log(data, "del-task-req");
    const updatedSections = (_data) => {
      let updated = _data.map((item) => {
        if (String(item._id) === String(sectionData._id)) {
          prev_state_section = item;
          return updated_section;
        }
        return item;
      });
      return updated;
    };
    setProcessing(true);
    const result = await _delete_template_task(selectedTask._id);
    if (result.code === 200) {
      let temp = allFilteredTasksBySections[sectionData._id];
      let new_task_list = temp.task_list.filter(
        (task) => task._id !== selectedTask._id
      );
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [sectionData._id]: {
            ...temp,
            total: temp.total - 1,
            task_list: new_task_list,
          },
        };
      });

      // setTaskData({
      //   ...taskData,
      //   sections: updatedSections(taskData.sections),
      // });
      closeDelTask();
      console.log(result, "res");
      setProcessing(false);
      enqueueSnackbar("Task Deleted Successfully", { variant: "success" });
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleOpenDelSection = async () => {
    openDelSection();
  };
  const handleAddComment = async (data) => {
    console.log(data, "on-change-AddComment");
  };
  const handleDelComment = async (data) => {
    console.log(data, "on-change-DelComment");
  };
  const expandAllSections = () => {
    let updated = filteredSectionsData.section_lists.map((item) => {
      return { ...item, expanded: true };
    });
    startTransition(() => {
      // setTaskData((prev) => {
      //   return { ...prev, sections: updated };
      // });
      // setProjectSectionsData(updated);
      setFilteredSectionsData((prev) => {
        return { ...prev, section_lists: updated };
      });
    });
  };
  const onExpandAll = (e) => {
    const { target } = e;
    setExpandAllState(target.checked);
    setSettingOptions((prev) => {
      return { ...prev, expandAllSections: target.checked };
    });
    if (target.checked) {
      expandAllSections();
    } else {
      let updated = filteredSectionsData.section_lists.map((item, index) => {
        return { ...item, expanded: index === 0 };
      });
      startTransition(() => {
        // setTaskData((prev) => {
        //   return { ...prev, sections: updated };
        // });
        // setProjectSectionsData(updated);
        setFilteredSectionsData((prev) => {
          return { ...prev, section_lists: updated };
        });
      });
    }
  };
  const handleFilterOptionChange = (type, _filterOptions) => {
    let selected_priority = [];
    let selected_status = [];
    if (type === "status") {
      Object.keys(_filterOptions).map((item, i) => {
        if (_filterOptions[item] === true) {
          let [found] = TASK_STATUS_OPTIONS.filter((_item, i) => {
            if (
              String(_item.lookup.toLowerCase()) === String(item.toLowerCase())
            ) {
              return _item;
            }
          });
          if (found) {
            selected_status.push(found.value);
          }
        }
      });
      setTaskFilterOptions((prev) => {
        return { ...prev, status: selected_status };
      });
      console.log(selected_status, "selected_status");
    }
    if (type === "priority") {
      Object.keys(_filterOptions).map((item, i) => {
        if (_filterOptions[item] === true) {
          let [found] = TASK_PRIORITY_OPTIONS.filter((_item, i) => {
            if (
              String(_item.lookup.toLowerCase()) === String(item.toLowerCase())
            ) {
              return _item;
            }
          });
          if (found) {
            selected_priority.push(found.value);
          }
        }
      });
      setTaskFilterOptions((prev) => {
        return { ...prev, priority: selected_priority };
      });
      console.log(selected_priority, "selected_priority");
    }
  };
  const handleChangeFilter = (e, _type, _value) => {
    const { target } = e;
    const updateObject = (Obj) => {
      let updated = { ...Obj, [_value]: target.checked };
      return updated;
    };
    if (_type === "status") {
      let updated_filter_options = filterOptions;
      updated_filter_options = {
        ...updated_filter_options,
        status: updateObject(updated_filter_options.status),
      };
      console.log(updated_filter_options, "updated_filter_options");
      setFilterOptions(updated_filter_options);
      handleFilterOptionChange("status", updated_filter_options.status);
    }
    if (_type === "priority") {
      let updated_filter_options = filterOptions;
      updated_filter_options = {
        ...updated_filter_options,
        priority: updateObject(updated_filter_options.priority),
      };
      console.log(updated_filter_options, "updated_filter_options");
      setFilterOptions(updated_filter_options);
      handleFilterOptionChange("priority", updated_filter_options.priority);
    }
  };

  const handleDelSection = async () => {
    console.log(selectedSection, "section-to-del");
    if (!selectedSection) {
      return;
    }
    setProcessing(true);
    const result = await _delete_template_section(selectedSection._id);
    if (result.code === 200) {
      console.log(result, "res");
      setProcessing(false);
      enqueueSnackbar("Section Deleted Successfully", {
        variant: "success",
      });
      const updatedSections = (_data) => {
        return _data.filter(
          (item) => String(item._id) !== String(selectedSection._id)
        );
      };
      setFilteredSectionsData((prev) => {
        return {
          ...prev,
          total: prev.total - 1,
          section_lists: updatedSections(prev.section_lists),
        };
      });
      let defaultSection = null;
      let defaultSectionIndex = null;
      filteredSectionsData.section_lists.map((section, index) => {
        if (section.is_default) {
          defaultSection = section;
          defaultSectionIndex = index;
        }
      });
      closeDelSection();
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleMakeDeafultSection = async () => {
    if (!selectedSection) {
      return;
    }
    const data = {
      project_id: slug,
    };
    setProcessing(true);
    const result = await _make_template_section_default(
      data,
      selectedSection._id
    );
    if (result.code == 200) {
      let updated = (temp) => {
        temp.map((section) => {
          if (section._id == selectedSection._id) {
            section.is_default = true;
          } else {
            section.is_default = false;
          }
        });
        return temp;
      };
      setFilteredSectionsData((prev) => {
        return { ...prev, section_lists: updated(prev.section_lists) };
      });
      console.log(filteredSectionsData, "asdasdhkasjchkas");
      enqueueSnackbar("Default section changed successfuly", {
        variant: "success",
      });
      closeMakeDefaultSection();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setProcessing(false);
  };
  const handleUpdateSection = async () => {
    if (!selectedSection) {
      return;
    }
    const data = {
      section_title: selectedSectionTitle.trim(),
      section_status: true,
      section_type: "0",
      project_id: slug,
    };

    setProcessing(true);
    const result = await _edit_template_section(data, selectedSection._id);
    if (result.code === 200) {
      const updated = (_data) => {
        let updated_sections = _data.map((item) => {
          if (String(item._id) === String(selectedSection._id)) {
            return { ...item, section_title: selectedSectionTitle.trim() };
          }
          return item;
        });
        return updated_sections;
      };
      console.log(result, "edit-section-res");
      enqueueSnackbar("Section Updated Successfully", { variant: "success" });
      let temp = filteredSectionsData.section_lists;
      setFilteredSectionsData((prev) => {
        return {
          ...prev,
          section_lists: updated(prev.section_lists),
        };
      });
      setProcessing(false);
      closeEditSection();
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleMoveTask = async (data) => {
    console.log(data, "move-task");
    const { sourceTaskSection, sourceTask, moveTo } = data;
    console.log(data, "payload");
    let updated_source_section_tasks = sourceTaskSection.task.filter(
      (item) => String(item._id) !== String(sourceTask._id)
    );
    let updated_source_section = {
      ...sourceTaskSection,
      task: updated_source_section_tasks,
    };
    let [updated_destination_section] =
      filteredSectionsData.section_lists.filter(
        (item) => String(item._id) === String(moveTo._id)
      );
    updated_destination_section = {
      ...updated_destination_section,
      task: [...updated_destination_section.task, sourceTask],
    };
    console.log(updated_source_section, "updated_source_section");
    console.log(updated_destination_section, "updated_destination_section");
    const updatedSections = (_data) => {
      let updated = _data.map((item) => {
        if (String(item._id) === String(sourceTaskSection._id)) {
          return updated_source_section;
        }
        if (String(item._id) === String(moveTo._id)) {
          return updated_destination_section;
        }
        return item;
      });
      return updated;
    };
    const req_data = {
      task_id: sourceTask._id,
      destination_section_id: moveTo._id,
    };
    const result = await _move_task_to_section(req_data);
    if (result.code === 200) {
      console.log(result, "move-to-res");
      let old_sectionId = sourceTaskSection._id;
      let new_sectionId = moveTo._id;
      let task = sourceTask;
      let old_sectionData = { ...allFilteredTasksBySections[old_sectionId] };
      let updated_oldSectionTasks = old_sectionData.task_list.filter(
        (tempTask) => tempTask._id !== task._id
      );
      let new_sectionData = allFilteredTasksBySections[new_sectionId];
      let updated_newSectionTasks = new_sectionData.task_list.concat(task);
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [old_sectionId]: {
            ...old_sectionData,
            task_list: updated_oldSectionTasks,
            total: old_sectionData.total - 1,
          },
          [new_sectionId]: {
            ...new_sectionData,
            task_list: updated_newSectionTasks,
            total: new_sectionData.total + 1,
          },
        };
      });
      enqueueSnackbar("Task Moved Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleMoveSection = async (data) => {
    console.log(data, "payload");
    // console.log(taskData.sections, "main-section");
    const { source_section, type } = data;

    if (source_section.order < 0) {
      // console.log("order cannot be 0 or less");
      return;
    }
    let updated_order = undefined;
    let destination_section = undefined;
    if (type === "up") {
      let prev = undefined;
      filteredSectionsData.section_lists.filter((item, index) => {
        if (String(item._id) === String(source_section._id)) {
          prev = Number(index) - 1;
        }
      });
      destination_section = filteredSectionsData.section_lists[prev];
      console.log(destination_section, "destination_section");
      updated_order = Number(source_section.order) - 1;
      if (!destination_section) {
        console.log("no destination section");
        return;
      }
    }
    if (type === "down") {
      let next = undefined;
      filteredSectionsData.section_lists.filter((item, index) => {
        if (String(item._id) === String(source_section._id)) {
          next = Number(index) + 1;
        }
      });
      destination_section = filteredSectionsData.section_lists[next];
      console.log(destination_section, "destination_section");
      updated_order = Number(source_section.order) + 1;
      if (!destination_section) {
        console.log("no destination section");
        return;
      }
    }
    let updated_sections = filteredSectionsData.section_lists.map(
      (_item, index) => {
        if (String(_item._id) === String(source_section._id)) {
          return {
            ...destination_section,
            order: source_section.order,
            move_up: index !== 0,
            move_down: filteredSectionsData.section_lists.length !== index + 1,
          };
        }
        if (String(_item._id) === String(destination_section._id)) {
          return {
            ...source_section,
            order: destination_section.order,
            move_up: index !== 0,
            move_down: filteredSectionsData.section_lists.length !== index + 1,
          };
        }
        return _item;
      }
    );
    console.log(updated_sections, "updated_sections");

    const req_data = {
      section_id: source_section._id,
      list_type: "0",
      order: updated_order,
    };
    const result = await _update_template_section_order(req_data, slug);
    if (result.code === 200) {
      setFilteredSectionsData((prev) => {
        return { ...prev, section_lists: updated_sections };
      });
      if (type == "down") {
        setSelectedSectionIndex((prev) => prev + 1);
      } else {
        setSelectedSectionIndex((prev) => prev - 1);
      }
      console.log(result, "order-change-res");
      enqueueSnackbar("Section Order Updated", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const removeLocalImage = async (_item, _index) => {
    let filtered = addTaskFiles.filter((item, index) => index !== _index);
    setAddTaskFiles(filtered);
  };
  const getFileIcon = (type) => {
    const [found] = ALLOWED_TASK_FILES_WITH_ICONS.filter(
      (item) => String(item.type) === String(type)
    );
    if (found) {
      return { path: found.icon, color: found.color };
    }
    return { path: "akar-icons:file", color: "black" };
  };
  const getLocalImages = () => {
    let paths = [];
    for (let i = 0; i < addTaskFiles.length; i++) {
      if (addTaskFiles[i].type === "file" || addTaskFiles[i].type === "video") {
        paths.push({ type: "file", ...getFileIcon(addTaskFiles[i].file.type) });
      }
      if (addTaskFiles[i].type === "image") {
        let path = URL.createObjectURL(addTaskFiles[i].file);
        paths.push({ path: path, type: "image" });
      }
    }
    return paths;
  };
  const removeTaskFile = (_path) => {
    const task = currentTask();
    if (!task) {
      return;
    }
    let filtered = task.image.filter((path) => String(path) !== String(_path));
    let updated_task = { ...task, image: filtered };
    setSelectedTask(updated_task);
  };
  const getFileIconType = (path) => {
    if (!path) {
      return "";
    }
    let ext = path.split("/")[0];
    let found = undefined;
    ALLOWED_TASK_FILES_WITH_ICONS.map((item) => {
      item.baseTypes.map((_type) => {
        if (String(_type) === String(ext)) {
          found = item;
        }
      });
    });

    if (found) {
      return { file_type: "file", file_path: found.icon, color: found.color };
    }
    if (!found) {
      return { file_type: "image", file_path: path };
    }
  };
  const getTaskImages = () => {
    let files = [];
    let task = currentTask();
    const { image } = task;
    for (let path of image) {
      let data = getFileIconType(path);
      const { file_type, file_path, color } = data;
      files.push({
        path: file_path,
        type: file_type,
        db_path: path,
        color: color,
      });
    }
    return files;
  };
  const uploader = async () => {
    if (addTaskFiles.length === 0) {
      console.log("no files");
      return [];
    }
    let paths = [];
    for (let i = 0; i < addTaskFiles.length; i++) {
      let res = await upload_task_files(addTaskFiles[i].file);
      paths.push(res.path);
    }
    return paths;
  };
  const handleFileChange = async (e) => {
    console.log(e.target.files[0]);
    let final_files = [];
    if (updatingTask) {
      return;
    }
    let files = e.target.files;
    console.log(files, "target-files");
    for (let i = 0; i < files.length; i++) {
      console.log(files[i], "file");
    }
    for (let i = 0; i < files.length; i++) {
      ALLOWED_TASK_FILE_TYPES.map((item, index) => {
        if (item === String(files[i].type)) {
          console.log(files[i].type, "type");
          if (
            files[i].type.includes("application") ||
            files[i].type.includes("text")
          ) {
            final_files.push({ file: files[i], type: "file" });
          }
          if (files[i].type.includes("image")) {
            final_files.push({ file: files[i], type: "image" });
          }
          if (files[i].type.includes("video")) {
            final_files.push({ file: files[i], type: "video" });
          }
        }
      });
    }
    console.log(final_files, "final_files");
    setAddTaskFiles([...addTaskFiles, ...final_files]);
    //
  };
  const handleEditTask = async (e) => {
    let target_task = currentTask();
    console.log(target_task);
    let task_file_paths = [...target_task.image];
    setUpdatingTask(true);
    let image_paths = await uploader();
    if (image_paths) {
      console.log("file-paths", image_paths);
      task_file_paths = [...target_task.image, ...image_paths];
    }
    console.log(task_file_paths, "final-paths");
    const req_data = {
      image: task_file_paths /* include current task paths too */,
      task_title: selectedTaskTitle,
      task_description: selectedTaskDescription,
    };
    console.log(req_data, "edit-req");

    const result = await _edit_template_task(target_task._id, req_data);
    if (result.code === 200) {
      setAddTaskFiles([]);
      console.log(result, "Dsafasdjkashd");
      let temp_tasks =
        allFilteredTasksBySections[result.task_detail.section_id];
      let new_tasks = [];
      temp_tasks.task_list.map((task) => {
        if (task._id === result.task_detail._id) {
          new_tasks.push(result.task_detail);
        } else {
          new_tasks.push(task);
        }
      });
      console.log(new_tasks, "asaldidad");
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [result.task_detail.section_id]: {
            ...temp_tasks,
            task_list: getModifiedTasks(new_tasks),
          },
        };
      });
      setUpdatingTask(false);
      closeTaskDetail();
      enqueueSnackbar("Task Updated Successfully", { variant: "success" });
    } else {
      setUpdatingTask(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDragEnd = async (data, workspaceId) => {
    console.log(data, "on-drag end");
    const prev_state = structuredClone(filteredSectionsData);
    const { dragResult, section } = data;
    const { draggableId, destination, source } = dragResult;
    const destination_task_index = destination.index;
    let source_task_index = source.index;
    const source_task = section.task[source_task_index];
    const destination_task = section.task[destination_task_index];
    console.log(source_task, "source_task");
    console.log(destination_task, "destination_task");
    console.log(destination_task_index, source_task_index, "dest+src index");

    const getReorderedTasks = (_tasks) => {
      let spliced_section_tasks = Array.from(_tasks);
      console.log(spliced_section_tasks, "spl");
      const [splicedItem] = spliced_section_tasks.splice(source.index, 1);
      spliced_section_tasks.splice(destination.index, 0, splicedItem);
      return spliced_section_tasks;
    };

    if (String(source_task._id) === String(destination_task._id)) {
      console.log("same dest");
      return;
    }
    if (Number(destination_task.order) === 0) {
      console.log("zero new order");
      return;
    }
    const req_data = {
      new_order: destination_task.order,
      task_id: source_task._id,
      past_order: source_task.order,
    };
    let old_state = allFilteredTasksBySections;
    let temp = allFilteredTasksBySections[section._id];
    setAllFilteredTasksBySections((prev) => {
      return {
        ...prev,
        [section._id]: {
          ...temp,
          task_list: getReorderedTasks(temp.task_list),
        },
      };
    });
    console.log(req_data, "req-reorder");
    const result = await _update_template_task_order(req_data);
    if (result.code === 200) {
      // console.log(result, "reorder-res", getReorderedTasks(temp.task_list));
      setAllFilteredTasksBySections((prev) => {
        return {
          ...prev,
          [section._id]: {
            ...temp,
            task_list: getModifiedTasks(result.task_order),
            // task_list: getReorderedTasks(temp.task_list),
          },
        };
      });
      enqueueSnackbar("Task Order Updated", { variant: "success" });
    } else {
      // setFilteredSectionsData(prev_state);
      setAllFilteredTasksBySections(old_state);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDragStart = (data, workspaceId) => {
    console.log(data, "on-drag start");
  };
  const getTaskComments = async (task_id) => {
    setLoadingComments(true);
    const result = await _task_comment_list(task_id);
    console.log(result, "task-comm-res");
    if (result.code === 200) {
      setLoadingComments(false);
      setSelectedTaskComments(result.project_list.comment_list);
      setSelectedTaskCommentsData({
        total: result.project_list.total_comments,
        load_more_url: result.project_list.load_more_url,
      });
    } else {
      setLoadingComments(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  console.log(state, "state--");
  // ---------------------------------------------------------
  useLayoutEffect(() => {
    //get
    let filters = get_from_localStorage("sidebarFilters");
    if (filters) {
      setFilterOptions(filters);
    }
  }, []);
  useLayoutEffect(() => {
    setTimeout(() => {
      set_to_localStorage("sidebarFilters", filterOptions);
    }, 500);
  });
  useLayoutEffect(() => {
    //get
    let filters = get_from_localStorage("taskFilterOptions");
    if (filters) {
      setTaskFilterOptions(filters);
      let index = undefined;
      TASK_SORT_OPTIONS.map((item, _index) => {
        if (String(item.value) === String(filters.sort_status)) {
          index = _index;
        }
      });
      if (index !== undefined) {
        setTaskSortOptionIndex(index);
      }
    }
  }, []);
  useLayoutEffect(() => {
    //set
    setTimeout(() => {
      set_to_localStorage("taskFilterOptions", taskFilterOptions);
    }, 500);
  });
  // ---------------------------------------------------------
  useLayoutEffect(() => {
    /* data fetching on load */
    // getProjectDetail();
    /*  */
    dispatch_set_search_visibility(true);
    return () => {
      dispatch_set_search_visibility(false);
    };
  }, []);
  useLayoutEffect(() => {
    getFilteredSections();
  }, [workspaceId]);

  useLayoutEffect(() => {
    if (filteredSectionsData.section_lists.length > 0) {
      filteredSectionsData.section_lists.map((section) => {
        if (section.expanded) {
          getFilteredTasksBySection(section._id);
        }
      });
    }
  }, [filteredSectionsData]);
  useEffect(() => {
    getProgress();
  }, [
    projectDetail.completed_task,
    projectDetail.total_task,
    projectDetail.pending_task,
    projectDetail,
  ]);
  /* persistence */
  const getExpandAllState = () => {
    let states = get_from_localStorage("expand-all-states");
    if (states) {
      let [found] = states.filter(
        (item) => String(item.project_id) === String(slug)
      );
      if (found) {
        console.log(found, "fah");
        setSettingOptions((prev) => {
          return { ...prev, expandAllSections: found.expandAll };
        });
      }
    }
  };
  const setExpandAllState = (value) => {
    let states = get_from_localStorage("expand-all-states");
    if (states) {
      let [found] = states.filter(
        (item) => String(item.project_id) === String(slug)
      );
      if (found) {
        let updated = states.map((item) => {
          if (String(item.project_id) === String(found.project_id)) {
            return { ...item, expandAll: value };
          }
          return item;
        });
        set_to_localStorage("expand-all-states", updated);
      }
      if (!found) {
        let updated = [...states, { project_id: slug, expandAll: value }];
        set_to_localStorage("expand-all-states", updated);
      }
    }
    if (!states) {
      let updated = [{ project_id: slug, expandAll: value }];
      set_to_localStorage("expand-all-states", updated);
    }
  };
  const isDragAllowed = () => {
    return true;
  };
  /* <<--------------------------------------------------------->> */
  useLayoutEffect(() => {
    /*get persisted data here */
    getExpandAllState();
  }, []);

  useLayoutEffect(() => {
    /*persist data here */
    if (loadingTasks) {
      return;
    }
    let sections = filteredSectionsData.section_lists.map((section) => {
      return { _id: section._id, expanded: section.expanded };
    });
    set_to_localStorage("sections-expand-states", sections);
  });

  // ---------------------------------------------------------
  // if (loadingDetail) {
  //   return (
  //     <Page title="Template Project">
  //       <Container maxWidth="xl">
  //         <Grid container className="project-header">
  //           <Grid item display="flex" alignItems="center">
  //             <IconButton onClick={handleNavBack} sx={{ mr: "6px" }}>
  //               <Iconify icon="ep:back" />
  //             </IconButton>
  //             {loadingDetail ? (
  //               <Skeleton sx={{ width: "8rem" }} variant="text" />
  //             ) : (
  //               <div className="d-flex">
  //                 <Typography
  //                   sx={{
  //                     color: "#00AB55",
  //                     cursor: "pointer",
  //                     // textDecoration: "underline",
  //                   }}
  //                   onClick={() => navigate("/workspaces")}
  //                 >
  //                   Workspaces
  //                 </Typography>
  //                 <Iconify icon="grommet-icons:next" sx={{ m: 1 }} />
  //                 <Typography
  //                   sx={{
  //                     color: "#00AB55",
  //                     cursor: "pointer",
  //                     // textDecoration: "underline",
  //                   }}
  //                   onClick={() => handleNavToProjects()}
  //                 >
  //                   Projects
  //                 </Typography>
  //                 <Iconify icon="grommet-icons:next" sx={{ m: 1 }} />
  //                 <Typography
  //                   sx={{ cursor: "pointer" }}
  //                   onClick={() => setOpenProjectDetails(true)}
  //                 >
  //                   {capitalCase(projectDetail.title)}
  //                 </Typography>
  //               </div>
  //             )}
  //           </Grid>
  //         </Grid>
  //         <CircularLoader />
  //       </Container>
  //     </Page>
  //   );
  // }

  return (
    <Page title="Workspace Project">
      <Container maxWidth="xl" sx={{ pb: 5 }} ref={pageRef}>
        <Grid container className="project-header mb-3">
          <Grid
            xs={12}
            sm={6.5}
            sx={{ pr: 2 }}
            item
            display="flex"
            alignItems="center"
          >
            <IconButton onClick={handleNavBack} sx={{ mr: "6px" }}>
              <Iconify icon="ep:back" />
            </IconButton>
            <div className="d-flex align-items-center">
              <Typography
                sx={{
                  color: "#00AB55",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/project-templates")}
              >
                Project Templates
              </Typography>
              <Iconify icon="grommet-icons:next" sx={{ m: 1 }} />
              <Typography
                sx={{ cursor: "pointer" }}
                // onClick={() => setOpenProjectDetails(true)}
              >
                {state.template.title}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={matches_mobile ? 1 : 0}
          columnSpacing={1}
          className="mb-3"
        >
          <Grid item xs={12} sm={7}>
            {taskField === false ? (
              <div
                style={{
                  display: "flex",
                  border: "1px dashed black",
                  width: "100%",
                  padding: 15,
                  borderRadius: 10,
                  alignItems: "center",
                }}
                role="button"
                tabIndex="0"
                onClick={() => showAddTask()}
                onKeyDown={() => showAddTask()}
              >
                <Iconify
                  icon="akar-icons:plus"
                  sx={{ color: "green", height: "1rem", width: "1rem" }}
                />
                <Typography sx={{ ml: 1 }}>Create Task</Typography>
              </div>
            ) : (
              <Box component="form" width="100%" onClickOut>
                <Stack direction="row" spacing={1}>
                  <TextField
                    value={task}
                    onChange={handleTaskInputChange}
                    fullWidth
                    // name='newPassword'
                    autoComplete="current-password"
                    // type='text'
                    label="Task"
                    onKeyPress={(event) =>
                      event.key === "Enter" ? createTask(event) : null
                    }
                    autoFocus
                    onBlur={() => {
                      handleTaskInputBlur();
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {addingTask ? (
                            <IconButton onClick={createTask} edge="end">
                              <Iconify icon="eos-icons:loading" />
                              {/* <CircularProgress /> */}
                            </IconButton>
                          ) : (
                            <>
                              {task !== "" && (
                                <IconButton onClick={createTask} edge="end">
                                  <Iconify icon="fluent:send-24-regular" />
                                </IconButton>
                              )}
                            </>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Box>
            )}
          </Grid>
          <Grid item xs={8} sm={3} className="d-flex justify-content-beetween">
            <SectionSelect
              options={filteredSectionsData.section_lists}
              title="Section"
              selectedIndex={selectedSectionIndex}
              onFilterOptionClick={handleSectionItemClick}
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <Button
              onClick={() => setAddSectionDialogOpen(true)}
              className="btn-select"
              // id="button-select"
              color="inherit"
              sx={{
                bgcolor: (theme) => theme.palette.action.hover,
                alignItems: "center",
                width: "100%",
                height: "100%",
                padding: "5px",
              }}
              endIcon={
                <Iconify
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    height: "1rem",
                    width: "1rem",
                  }}
                  icon="fluent:add-24-filled"
                />
              }
            >
              <Typography
                component="span"
                variant="subtitle2"
                fontWeight=""
                fontSize="12px"
              >
                add section
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <div className="mt-4">
          {loadingTasks && (
            <Stack direction="column" spacing={2}>
              {getSkeletonCount().map((item, index) => (
                <Skeleton
                  key={index}
                  sx={{ width: "100%", height: "3rem" }}
                  variant="text"
                />
              ))}
            </Stack>
          )}
          <InfiniteScroll
            dataLength={memoFilteredSections.length}
            next={getFilteredSections}
            hasMore={
              memoFilteredSections.length < filteredSectionsData.total
                ? true
                : false
            }
            loader={
              <CircularProgress
                sx={{ position: "absolute", left: "50%", padding: "10px" }}
              />
            }
          >
            <Stack spacing={2}>
              {memoFilteredSections.map((item, index) => {
                // before-new-optimization
                return (
                  <TaskSection
                    workspaceId={workspaceId}
                    handleLoadMoreTasks={handleLoadMoreTasks}
                    moreTasksLoading={moreTasksLoading}
                    key={index}
                    section={{ ...item }}
                    onExpand={ucbOnExpand}
                    projectDetail={memoProjectDetail}
                    // projectSections={memoTaskSections}
                    projectSections={memoFilteredSections}
                    projectTeam={memoProjectTeam}
                    onSetSelectedTask={ucbOnSetSelectedTask}
                    onOpenTaskDetail={ucbOpenTaskDetail}
                    onOpenComments={ucbOpenComments}
                    onChangePriority={ucbOnChangePriority}
                    onChangeStatus={ucbOnChangeStatus}
                    onChangeDate={ucbOnChangeDate}
                    onChangeMembers={ucbOnChangeMembers}
                    onDelTask={ucbOnDelTask}
                    onMakeSectionDefault={usbOnMakeSectionDefault}
                    onDelSection={ucbOnDelSection}
                    onEditSection={ucbOnEditSection}
                    onMoveSection={ucbOnMoveSection}
                    onMoveTask={ucbOnMoveTask}
                    onDragEnd={ucbOnDragEnd}
                    onDragStart={ucbOnDragStart}
                    allowDrag={isDragAllowed()}
                    role={currentUserRole}
                  />
                );
              })}
            </Stack>
          </InfiniteScroll>
        </div>
      </Container>

      {/* sidebars */}
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 540, md: 400 } }}
        isOpen={taskDetailOpen}
        onClose={closeTaskDetail}
        title="Task Detail"
        bottomComponent={
          <div className="centered-row pb-1">
            <LoadingButton
              onClick={handleEditTask}
              className="active-border"
              variant="outlined"
              loading={updatingTask}
              disabled={isDetailDisabled()}
            >
              Update
            </LoadingButton>
          </div>
        }
      >
        {currentTask() && (
          <>
            {isDetailDisabled() ? (
              <Stack spacing={2}>
                <Stack spacing={0.1}>
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.disabled }}
                    fontWeight="bold"
                  >
                    Created at :
                  </Typography>
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.disabled }}
                  >
                    {get_zone_date(
                      currentTask().createdAt,
                      "DD-MMM-YYYY hh:mm a"
                    )}
                  </Typography>
                </Stack>
                <TextField
                  value={selectedTaskTitle}
                  disabled
                  onChange={(e) => {}}
                  label="Title"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                />
                <TextField
                  value={selectedTaskDescription}
                  disabled
                  onChange={(e) => {}}
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                />
                {getTaskImages().length === 0 &&
                  getLocalImages().length === 0 && (
                    <span className="not-task-files">
                      <Typography
                        sx={{ color: (theme) => theme.palette.text.disabled }}
                      >
                        No task files.
                      </Typography>
                    </span>
                  )}
                <Scrollbar
                  sx={{
                    height: "15rem",
                    "& .simplebar-content": {
                      height: "15rem",
                      width: 1,
                    },
                  }}
                >
                  <Stack
                    sx={{ minWidth: "max-content", width: "100%" }}
                    direction="row"
                    spacing={1}
                  >
                    {getTaskImages().map((item, index) => {
                      return (
                        <div className="task-files-container" key={index}>
                          {item.type === "file" ? (
                            <a
                              href={s3baseUrl + item.db_path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Iconify
                                sx={{
                                  height: "10rem",
                                  width: "10rem",
                                  color: item.color,
                                }}
                                icon={item.path}
                              />
                            </a>
                          ) : (
                            <a
                              href={s3baseUrl + item.path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="add-task-img"
                                width="100%"
                                height="100%"
                                src={s3baseUrl + item.path}
                              />
                            </a>
                          )}
                        </div>
                      );
                    })}
                    {getLocalImages().map((src, index) => {
                      return (
                        <div className="task-files-container" key={index}>
                          <img
                            className="add-task-img"
                            width="100%"
                            height="100%"
                            src={src}
                          />
                        </div>
                      );
                    })}
                  </Stack>
                </Scrollbar>
              </Stack>
            ) : (
              <Stack spacing={2}>
                <Stack spacing={0.1}>
                  <Typography fontWeight="bold">Created at :</Typography>
                  <Typography>
                    {get_zone_date(
                      currentTask().createdAt,
                      "DD-MMM-YYYY hh:mm a"
                    )}
                  </Typography>
                </Stack>
                <TextField
                  value={selectedTaskTitle}
                  onChange={(e) => setSelectedTaskTitle(e.target.value)}
                  label="Title"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                />
                <RichEditor
                  editorRef={editorRef}
                  value={selectedTaskDescription}
                  setValue={(val) => setSelectedTaskDescription(val)}
                />
                <Grid container spacing={1} direction={"row"}>
                  {getTaskImages().map((item, index) => {
                    return (
                      <Grid
                        sx={{ position: "relative" }}
                        key={index}
                        item
                        xs={3}
                      >
                        <div className="task-files-container" key={index}>
                          {item.type === "file" ? (
                            <a
                              href={s3baseUrl + item.db_path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Iconify
                                sx={{
                                  height: "4rem",
                                  width: "4rem",
                                  color: item.color,
                                }}
                                icon={item.path}
                              />
                            </a>
                          ) : (
                            <a
                              href={s3baseUrl + item.path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="add-task-img"
                                src={s3baseUrl + item.path}
                              />
                            </a>
                          )}
                        </div>
                        <div className="del-task-file-btn">
                          <IconButton
                            disabled={updatingTask}
                            onClick={() => removeTaskFile(item.db_path)}
                          >
                            <Iconify
                              sx={{ color: "red" }}
                              icon="gridicons:cross-circle"
                            />
                          </IconButton>
                        </div>
                      </Grid>
                    );
                  })}
                  {getLocalImages().map((item, index) => {
                    return (
                      <Grid
                        sx={{ position: "relative" }}
                        key={index}
                        item
                        xs={3}
                      >
                        <div className="task-files-container" key={index}>
                          {item.type === "file" ? (
                            <Iconify
                              sx={{
                                height: "4rem",
                                width: "4rem",
                                color: item.color,
                              }}
                              icon={item.path}
                            />
                          ) : (
                            <img className="add-task-img" src={item.path} />
                          )}
                        </div>
                        <div className="del-task-file-btn">
                          <IconButton
                            disabled={updatingTask}
                            onClick={() => removeLocalImage(item, index)}
                          >
                            <Iconify
                              sx={{ color: "red" }}
                              icon="gridicons:cross-circle"
                            />
                          </IconButton>
                        </div>
                      </Grid>
                    );
                  })}
                  <Grid item xs={3}>
                    <div>
                      <label
                        htmlFor="icon-button-file"
                        // style={{ width: "fit-content" }}
                      >
                        <input
                          style={{ display: "none" }}
                          accept={FILE_ACCEPT_TYPE_STRING}
                          id="icon-button-file"
                          type="file"
                          value=""
                          onChange={handleFileChange}
                          multiple
                          disabled={updatingTask}
                        />
                        <Tooltip title="upload files">
                          <Button
                            disabled={updatingTask}
                            // color="primary"
                            variant="outlined"
                            aria-label="upload picture"
                            component="span"
                            // size="small"
                            sx={{ height: "4rem" }}
                          >
                            <Iconify
                              sx={{
                                color: (theme) => theme.palette.primary.main,
                                width: "2rem",
                                height: "2rem",
                              }}
                              icon="codicon:add"
                            />
                          </Button>
                        </Tooltip>
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Stack>
            )}
          </>
        )}
      </MuiDrawer>

      {/* edit section */}
      <MuiDrawer
        paperProps={{ width: { xs: 1, sm: 540, md: 300 } }}
        isOpen={editSectionOpen}
        onClose={closeEditSection}
        title="Edit Section"
        bottomComponent={
          <Stack spacing={1} sx={{ px: "20px", pb: "5px" }}>
            <LoadingButton
              loading={processing}
              onClick={handleUpdateSection}
              size="large"
              variant="outlined"
              className="outline-btn"
            >
              Update
            </LoadingButton>
          </Stack>
        }
      >
        {selectedSection && (
          <Stack spacing={3}>
            <TextField
              label="Title"
              variant="outlined"
              value={selectedSectionTitle}
              onChange={(e) => setSelectedSectionTitle(e.target.value)}
              multiline
              maxRows={5}
            />
          </Stack>
        )}
      </MuiDrawer>
      {/* dialogs */}
      <MuiDialog
        open={delTaskOpen}
        onToggle={closeDelTask}
        onAgree={handleDeleteTask}
        loading={processing}
        title="Delete Task"
        message="Are you sure you want to delete this task?"
      />
      <MuiDialog
        open={delSectionOpen}
        onToggle={closeDelSection}
        onAgree={handleDelSection}
        loading={processing}
        title="Delete Section"
        message="Are you sure you want to delete this section?"
      />
      <MuiDialog
        open={makeDefaultSection}
        onToggle={closeMakeDefaultSection}
        onAgree={handleMakeDeafultSection}
        loading={processing}
        title="Make this section default"
        message={`Are you sure you want to set "${selectedSectionTitle}" as default section`}
      />
      <MuiDialog
        open={addSectionDialogOpen}
        onToggle={(val) => setAddSectionDialogOpen(val)}
        onAgree={handleCreateSection}
        loading={addSectionLoading}
        title="Add Section"
        message=" "
        ExtraComponent={
          <>
            <FormControl sx={{ minWidth: "400px" }}>
              <TextField
                value={newSectionName}
                onChange={(e) => setNewSectionName(e.target.value)}
                label="Section Name"
              />
            </FormControl>
          </>
        }
      />
    </Page>
  );
};

export default TemplateProject;
