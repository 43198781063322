// component
import { useAppContext } from "src/hooks";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = () => {
  const { badge } = useAppContext();
  return [
    {
      title: "dashboard",
      path: "/dashboard",
      icon: getIcon("eva:pie-chart-2-fill"),
      /* "children" key can be added in these objects, example:children:[{...}] */
    },
    {
      title: "calendar",
      path: "/calendar",
      icon: getIcon("bx:calendar"),
    },
    {
      title: "workspaces",
      path: "/workspaces",
      icon: getIcon("bi:boxes"),
    },
    {
      title: "All Tasks",
      path: "/all-tasks",
      icon: getIcon("fluent:clipboard-more-20-regular"),
    },
    {
      title: "All Completed Tasks",
      path: "/all-completed-tasks",
      icon: getIcon("akar-icons:circle-check"),
    },
    {
      title: "Private Notes",
      path: "/notes",
      icon: getIcon("fluent-mdl2:note-pinned"),
      // icon: getIcon("bi:sticky"),
    },
    {
      title: "Invoices",
      path: "/invoices",
      icon: getIcon("bx:file"),
    },
    
    // {
    //   title: "Finance",
    //   // path:"/reports",
    //   icon: getIcon("ant-design:bar-chart-outlined"),
    //   children: [
    //     {
    //       title: "Tasks Reports",
    //       path: "/reports",
    //       icon: getIcon("carbon:dashboard"),
    //     },
    //     {
    //       title: "Invoices",
    //       path: "/invoices",
    //       icon: getIcon("bx:file"),
    //     },
    //   ],
    // },
    {
      title: "Project Templates",
      path: "/project-templates",
      icon: getIcon("octicon:project-symlink-24"),
    },
    {
      title: "invitations",
      path: "/invitations",
      icon: getIcon("system-uicons:mail-add"),
      badge: badge.invitation > 0,
      badgeContent: badge.invitation,
    },
    {
      title: "My Team",
      path: "/team",
      icon: getIcon("fe:users"),
    },
    {
      title: "My Clients",
      path: "/clients",
      icon: getIcon("tabler:user-dollar"),
    },
    {
      title: "Support",
      path: "/support",
      icon: getIcon("bx:support"),
      badge: badge.support > 0,
      badgeContent: badge.support,
    },

    // {
    //   title: "chats",
    //   path: "/chats",
    //   icon: getIcon("ci:chat"),
    //   badge: badge.chat,
    // },
  ];
};

export default navConfig;
